
/*import pakcages*/
import { useState, useEffect } from 'react';

/*import components*/
import { ReportAccordion } from '../../components/dataFormating/index';

/* import services */
import GetURLVar from '../../services/getUrl';

/*import assets */

const ReportDowload = (props) => {

    const [userId, setUserId] = useState(null)

    useEffect(() => {

        getUrl()

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        // getScripFilter()

    }, []);

    const getUrl = async () => {
        let url = await GetURLVar();
        setUserId(url.id);
    }
    const item = [
        {
            id: 1,
            name: 'Booking report',
            // verified : false,
        },
        {
            id: 2,
            name: 'Wallet report',

        },
        {
            id: 3,
            name: 'M2E Booking summary report',
        },
        {
            id: 4,
            name: 'M2M Booking summary report',
        },
        {
            id: 5,
            name: 'M2E report',
        },
        {
            id: 6,
            name: 'M2M report',
        },
        {
            id: 7,
            name: 'Skill race report',

        },
    ];
    return (
        <div className="row e-dashboard-container ">
            <div className="col-lg-12">
                <div className="card e-dashboard-card border-0 p-4">
                    <div className="row">
                        <div className="row">
                            <div className="col-lg-12">
                                <h6 className="e-card-title">Download reports</h6>
                                {/* <h6 className="e-card-title">Game summary</h6> */}
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-lg-12">
                                <ReportAccordion data={item} id={userId} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default ReportDowload;