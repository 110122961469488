/*
 *   File : userDetail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : User Detail Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-11-2021
 */

/*import packages*/
import { useEffect, useState } from 'react';
import $ from 'jquery'

/*import style*/
import styles from '../../styles/components/userDetail.module.scss';


/*import components */
import { DownloadBtn, PrimaryLink } from '../cta';
import { Toast } from '../note';
import { Toggle } from '../cta';

/*import assets */
import Edit from '../../assets/images/card/edit.svg';
import Leaderboard from '../../assets/images/card/leaderboard.svg';
import Rating from '../../assets/images/card/rating.svg';
import Wallet from '../../assets/images/card/wallet.svg';



/* import services */
import service from '../../services/service';
import { BlockUser, EditName, Update } from '../modal';
import { Modal } from 'bootstrap';


const UserDetail = (props) => {

    const [error, setError] = useState(null);
    const [influencer, setInfluencer] = useState(false);
    const [status, setStatus] = useState("")
    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        if (props.data) {
            setInfluencer(props.data.is_influencer);
            setStatus(props.data.status)
        }

    }, [props.data])

    // user detail download
    const downloadDetail = () => {
        let url = "/user/user-detail-download";

        let data = {
            user_id: Number(props.userId)
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                setError(response.data.message);
            }

        })
    }

    $(".fade").removeClass("modal-backdrop")
    $('body').removeAttr("style")
    //  set as influencer 
    const toggleInfluencer = () => {
        let url = '/user/mark-as-influencer';
        let data = JSON.stringify({
            customer_id: props.data.id
        })
        service(true, url, data).then((response) => {
            if (!response.status_code === 200) {
                setError(response.message);
            }
        })
    }

    //status update 

    return (
        <>
            <div className={`${styles.e_user_detail_card} card`}>
                <div className="row align-items-center pe-3">
                    <div className="col-lg-8 col-12 text-start d-inline-flex">
                        {/* <img src={props.data.profile} className={styles.e_user_icon}/> */}
                        <span className={styles.e_user_icon}>{props.data.image ? <img src={props.data.image} alt="" style={{ borderRadius: "50%", width: "100%", height: "100%" }} /> : props.data.short_name}</span>
                        <span className={`${styles.e_span}`}>
                            <div className='d-inline-flex pt-1'>
                                <h4 className=''>{props.data.name}
                                    <img src={Edit}
                                        draggable={false}
                                        alt=""
                                        className={styles.e_edit}
                                        data-bs-target="#edit-name"
                                        data-bs-toggle="modal" />
                                </h4>

                                {/* <span className='pt-1'>

                                    {
                                        props.data && props.data.status !== "DELETED" ?

                                            <span className={`${styles.e_kyc_verified} ps-3`}>{props.data?.status}</span>
                                            :

                                            < span className={`${styles.e_kyc_pending} ps-3`}>{props.data?.status}</span>

                                    }
                                </span> */}
                                <div className='pt-1 ps-3 d-inline'>
                                    {

                                        <>
                                            <span className={
                                                status === 'DELETED' ?
                                                    `${styles.e_toggle_deleted_active} pe-3`
                                                    :
                                                    `${styles.e_toggle_deleted} pe-3`
                                            }>
                                                DELETED
                                            </span>
                                            <Toggle className={`${styles.e_toggle_status} e-toggle-status `}
                                                onChange={() => {
                                                    // setStatus("");
                                                    const modal = new Modal(document.getElementById("update"));
                                                    modal.show();
                                                }}
                                                checked={status === 'ACTIVE' ? true : false} />
                                            <span className={
                                                status === 'ACTIVE' ?
                                                    `${styles.e_toggle_active} ps-3`
                                                    :
                                                    `${styles.e_toggle_inactive} ps-3`
                                            }>
                                                ACTIVE
                                            </span>
                                        </>
                                    }
                                </div>

                            </div>
                            <div className="row">
                                <span className='d-inline-flex'>
                                    <span className={`${styles.e_labels}`}>
                                        <img src={Leaderboard} className='pe-2' alt="" />
                                        Leaderboard rank: <span className={`${styles.e_rank} ps-1`}>{props.data.platform_rank}</span>
                                    </span>
                                    <span className={`${styles.e_labels} ps-3`}>
                                        {props.data.rating !== "-" ?
                                            <>
                                                <img src={Rating} className='pe-2' alt="" />
                                                Rating: <span className={`${styles.e_rank} ps-1`}>{props.data.rating}</span>
                                            </>
                                            :
                                            <>
                                                <img src={Rating} className='pe-2' alt="" />
                                                Rating: <span className={`${styles.e_rank} ps-1`}> 0/5</span>
                                            </>
                                        }

                                    </span>
                                    <span className={`${styles.e_label_earnings} ps-3`}>
                                        Total earnings <span className={`${styles.e_earnings} ps-2`}>{props.data["p&l"]}</span>
                                    </span>

                                </span>
                                {/* <span className={`${styles.e_label_earnings} pt-1`}>
                                    P&L: <span className={`${styles.e_earnings} ps-2`}>{props.data["p&l"] !== "-" ? "₹" + parseFloat(props.data["p&l"]).toFixed(2) : "-"}</span>
                                </span> */}

                                <span className={`${styles.e_user_id} pt-1`}>Client ID: <span>{props.data.customer_id}</span></span>
                                <div className='d-inline mt-3'>
                                    <Toggle className={`${styles.e_toggle}`} onChange={() => { setInfluencer(!influencer); toggleInfluencer() }} checked={influencer} />
                                    <span className={influencer === true ? `${styles.e_toggle_label} ps-3` : `${styles.e_toggle_label_false} ps-3`}>Influencer</span>
                                </div>
                            </div>
                        </span>
                    </div>

                    <div className='col-lg-4 px-0 '>
                        <div className='d-flex justify-content-between'>
                            <div className='ps-5 e-download-btn'>
                                <DownloadBtn check={["abc"]} onClick={() => downloadDetail()} />
                                <div className='pe-4 text-end'>
                                    <PrimaryLink
                                        className="mt-4"
                                        name={props.data.is_blocked ? "Unblock user" : "Block user"}
                                        toggle="modal"
                                        target="#block-user" />
                                </div>
                            </div>
                            <div>
                                <div className={`${styles.e_wallet_wrapper}`}>

                                    <p>
                                        <img src={Wallet} alt="" className='pe-2' />
                                        Wallet balance
                                    </p>
                                    <p className={`${styles.e_wallet_balance} mb-0`}>₹{props.data.wallet_balance}</p>
                                </div>

                            </div>
                        </div>
                        <p className={`${styles.e_last_login} pt-4 mt-1 mb-0 text-end`}>Last login: <span>{props.data.last_login}</span></p>
                        <p className={`${styles.e_last_login} pt-2 mb-0 text-end`}>Joining date: <span>{props.data.doj}</span></p>
                        <p className={`${styles.e_last_login} pt-2 mb-0 text-end`}>Remarks: <span>{props.data.block_remarks}</span></p>
                        {
                            localStorage.getItem("type") === "1" ?
                                <p className={`${styles.e_last_login} pt-2 mb-0 text-end`}>Modified by: <span>{props.data.modified_by ? props.data.modified_by : "-"}</span></p>
                                :
                                null
                        }
                        <p className={`${styles.e_last_login} pt-2 mb-2 text-end`}>Blocked on: <span>{props.data.blocked_on ? props.data.blocked_on : "-"}</span></p>
                    </div>

                </div>

            </div >
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
            {/* block user modal */}
            <BlockUser
                name={
                    props.data?.is_blocked ?
                        "Unblock user"
                        :
                        "Block user"
                }
                remark={props.data.block_remarks !== "-" ? props.data.block_remarks : ""}
                blockId={props.data.id}
                blocked={props.data.is_blocked}
                Close={() => { props.Close() }} />
            <EditName
                data={props.data}
                update={() => {
                    props.Close()
                }} />

            <Update id={props.data.id}
                update={(status) => {
                    setStatus(status)
                }}
                title={`${status === 'ACTIVE' ? 'Are you sure you want to Delete the user ' : 'Are you sure you want to Restore the user '} ?`}
                close={() => {

                }} />
        </>
    )
}

export default UserDetail