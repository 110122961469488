// import packeges
import { useState, useEffect } from 'react';
import moment from 'moment';
import $ from 'jquery'

// import components
import { Input, SingleDate } from '../input';
import { PrimaryBtn } from '../cta';
import service from '../../services/service';
import { Toast } from '../note';
// import 

const BlockUser = (props) => {

    const [blockTill, setBlockTill] = useState(null);
    const [reason, setReason] = useState("");
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        setReason(props.remark);
    }, [props.remark])

    //block or unblock user

    const blockUnblock = () => {
        setLoader(true)
        let url = "/auth/block-unblock-user";

        let data = {
            "user_id": props.blockId,
            "blocked_till": blockTill,
            "remark": reason
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                $("#block-user").trigger("click");
                if (!props.blocked) {
                    setBlockTill(null);
                    setReason("");
                }
                props.Close()
            } else {
                setError(response.data.message);
                setLoader(false);
            }
        })
    }

    const disabledDate = (current) => {
        // Can not select future dates
        return current < moment().endOf('day');
    }
    return (
        <div className={` modal fade e-block-user-modal`} id="block-user" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>{props.blocked ? "Unblock user" : "Block user"}</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="container px-3">
                        <div className="row">
                            {
                                props.blocked ?
                                    null
                                    :
                                    <div className='col-lg-6'>
                                        <label className="mb-1">Block till*</label>
                                        <SingleDate
                                            placeholder="Block till"
                                            value={blockTill}
                                            className="mt-2"
                                            disabledDate={disabledDate}
                                            onChange={(date) => setBlockTill(date)} />
                                    </div>
                            }
                            <div className='col-lg-12 mt-3'>
                                <label className="mb-1">Reason*</label>
                                <Input
                                    placeholder="Reason"
                                    value={reason}
                                    valueChange={(temp) => setReason(temp)} />
                            </div>

                        </div>
                        <div className="row pt-4 pb-2">
                            <div className="col-lg-12">
                                {
                                    props.blocked ?
                                        reason ?
                                            <div className="d-flex justify-content-end">
                                                <PrimaryBtn
                                                    name={props.name}
                                                    className={
                                                        loader ?
                                                            "e-btn-loader e-modal-submit"
                                                            :
                                                            "e-modal-submit"
                                                    }
                                                    handleClick={() => {
                                                        blockUnblock()
                                                    }} />
                                            </div>
                                            :
                                            <div className="d-flex justify-content-end">
                                                <PrimaryBtn
                                                    name={props.name}
                                                    className="e-disabled e-modal-submit"
                                                />
                                            </div>
                                        :
                                        reason && blockTill ?
                                            <div className="d-flex justify-content-end">
                                                <PrimaryBtn
                                                    name={props.name}
                                                    className={
                                                        loader ?
                                                            "e-btn-loader e-modal-submit"
                                                            :
                                                            "e-modal-submit"
                                                    }
                                                    handleClick={() => {
                                                        blockUnblock()
                                                    }} />
                                            </div>
                                            :
                                            <div className="d-flex justify-content-end">
                                                <PrimaryBtn
                                                    name={props.name}
                                                    className="e-disabled e-modal-submit"
                                                />
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </div>
    )
}

export default BlockUser;