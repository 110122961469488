/*
 *   File : filterBtn.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Filter
 *   Integrations :
 *   Version : v1.0
 *   Created : 29-10-2021
 */

/*Import Packages*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/filter.module.scss';

import Filter from '../../assets/images/cta/filter.svg';


export default function FilterBtn(props) {
  return (
    <div className={`${styles.e_filter_btn}  ${props.className}`} onClick={props.handleClick}><img src={Filter} alt="" /></div>

  )
}
