/*
 *   File : faq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Faq container
 *   Integrations :
 *   Version : v1.0
 *   Created : 17-02-2022
 */
/*import packages*/
import { useState, useEffect } from 'react';
import { PrimaryBtn } from '../../components/cta';

/*import components*/
import { FaqAccordion } from '../../components/dataFormating/index';
import SelectBox from '../../components/dropdown/selectBox';
import Loader from '../../components/loader/loader';
import { AddFaq } from '../../components/modal/index';

/* import services */
import service from '../../services/service';

const Faq = (props) => {
  const [faqData, setFaqData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState({ value: "en", label: "English" })

  // const accordion = [
  //   {
  //     title: "I Would Like a Refund",
  //     data: "In some cases, a refund will be possible, though it depends on the status of the domain; such as when it was purchased and whether it has been claimed to a cryptocurrency wallet.",
  //     id: 1
  //   },
  //   {
  //     title: "How do I activate my account?",
  //     data: "In some cases, a refund will be possible, though it depends on the status of the domain; such as when it was purchased and whether it has been claimed to a cryptocurrency wallet.",
  //     id: 2
  //   },
  //   {
  //     title: "How do I activate my account?",
  //     data: "In some cases, a refund will be possible, though it depends on the status of the domain; such as when it was purchased and whether it has been claimed to a cryptocurrency wallet.",
  //     id: 3
  //   },
  //   {
  //     title: "What do you mean by points? How do I earn it.",
  //     data: "In some cases, a refund will be possible, though it depends on the status of the domain; such as when it was purchased and whether it has been claimed to a cryptocurrency wallet.",
  //     id: 4
  //   },
  //   {
  //     title: "How do I use my remaining Account Credits",
  //     data: "In some cases, a refund will be possible, though it depends on the status of the domain; such as when it was purchased and whether it has been claimed to a cryptocurrency wallet.",
  //     id: 5
  //   },
  //   {
  //     title: "How do I use my remaining Account Credits",
  //     data: "In some cases, a refund will be possible, though it depends on the status of the domain; such as when it was purchased and whether it has been claimed to a cryptocurrency wallet.",
  //     id: 6
  //   },
  //   {
  //     title: "Why must I make payment immediately at checkout",
  //     data: "In some cases, a refund will be possible, though it depends on the status of the domain; such as when it was purchased and whether it has been claimed to a cryptocurrency wallet.",
  //     id: 7
  //   },
  //   {
  //     title: "Why must I make payment immediately at checkout",
  //     data: "In some cases, a refund will be possible, though it depends on the status of the domain; such as when it was purchased and whether it has been claimed to a cryptocurrency wallet.",
  //     id: 8
  //   },
  //   {
  //     title: "Why must I make payment immediately at checkout",
  //     data: "In some cases, a refund will be possible, though it depends on the status of the domain; such as when it was purchased and whether it has been claimed to a cryptocurrency wallet.",
  //     id: 9
  //   },
  //   {
  //     title: "Why must I make payment immediately at checkout",
  //     data: "In some cases, a refund will be possible, though it depends on the status of the domain; such as when it was purchased and whether it has been claimed to a cryptocurrency wallet.",
  //     id: 10
  //   },
  // ]

  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    getFaq();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (error !== null) {
        setError(null)
      }
    }, 3000);
  }, [error])
  /*--------------get faq -----------*/
  const getFaq = () => {
    setLoader(true)
    let url = '/faq/get-faq';

    service(false, url).then((response) => {

      if (response.data.status_code === 200) {
        setLoader(false);
        setFaqData(response.data.data);
      }
      else {
        setError(response.data.message);
        setLoader(false);
      }
    });
  }

  const options = [
    { value: 'en', label: "English" },
    { value: 'hi', label: "Hindi" },
    { value: 'gu', label: "Gujarati" },
    { value: 'bn', label: "Bengali" }
  ]
  return (
    <>
      <div className="row">
        <div className="col-lg-10 col-8">
          <div className='row'>
            <div className='col-lg-2'>
              <h6 className="e-card-title pt-2 mt-1">Added Questions</h6>
            </div>
            <div className='col-lg-2 e-faq-select'>
              <SelectBox value={selectedLanguage} isSearchable={false} options={options} placeholder="Language" selectchange={(temp) => { setSelectedLanguage(temp) }} />
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-4 text-end">
          <PrimaryBtn name="+ Add question" className="e-add-question-btn" toggle="modal" target="#addFaq" />
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          {
            loader ?
              <Loader />
              :
              <FaqAccordion data={faqData} selected={selectedLanguage?.value} update={() => getFaq()} />
          }
        </div>
      </div>
      <AddFaq update={() => getFaq()} />
    </>

  )
}

export default Faq;
