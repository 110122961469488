// import packeges
import { useEffect, useState } from 'react';
import $ from 'jquery';

/* import components */
import { PrimaryBtn, SecondaryBtn } from '../cta';
import SelectBox from '../dropdown/selectBox';
import { Toast } from '../note';

/* import services */
import service from '../../services/service';

// import components
// import 

const EditAdmin = (props) => {

    const [type, setType] = useState({ label: "All", value: null });
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [access, setAcess] = useState({ label: "Select", value: null });

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        var myModalEl = document.getElementById('edit-admin')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            props.close()
        })
        //eslint-disable-next-line
    }, [])
    useEffect(() => {
        var type = props.details.type
        // var userType = ""
        if (type === "Super Admin") {
            setType({ label: "Super admin", value: 1 })
        } else if (type === "Admin") {
            setType({ label: "Admin", value: 2 })
        } else if (type === "Accounts User") {
            setType({ label: "Account", value: 3 })
        } else {
            setType({ label: "Staffuser", value: 4 })
        }

        var access = props.details.active
        if (access) {
            setAcess({ value: true, label: "Enable" })
        } else {
            setAcess({ value: false, label: "Disable" })
        }
    }, [props.details])

    const options = [
        // { value: null, label: "All" },
        { value: 1, label: "Super admin" },
        { value: 2, label: "Admin" },
        { value: 3, label: "Account" },
        { value: 4, label: "Staffuser" }
    ]

    const accessOptions = [
        { value: true, label: "Enable" },
        { value: false, label: "Disable" }
    ]

    //add admin 
    const _addAdmin = () => {
        setLoader(true);
        let url = "/auth/update-admin"

        let data = {
            admin_id: props.details.id,
            active: access?.value,
            type: type?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false)
                props.update()
                $("#edit-admin").trigger("click")
                // setAcess({ label: "Select", value: null })
            } else {
                setLoader(false);
                setError(response.data.message)
            }
        })
    }

    return (
        <>
            <div className={` modal fade e-add-admin`} id="edit-admin" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered`}>

                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Edit admin</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="container px-3">
                            <div className="row">
                                <div className='col-lg-6 mt-4'>
                                    <label className="mb-1">User type</label>
                                    <SelectBox
                                        placeholder="Type"
                                        options={options}
                                        value={type}
                                        selectchange={(temp) => setType(temp)} />
                                </div>
                                <div className='col-lg-6 mt-4'>
                                    <label className="mb-1">Active</label>
                                    <SelectBox
                                        placeholder="Active"
                                        options={accessOptions}
                                        value={access}
                                        selectchange={(temp) => setAcess(temp)} />
                                </div>
                            </div>
                            <div className="row pt-4 pb-2">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className="e-cancel"
                                            cancel="modal" />
                                        {
                                            access?.value !== null ?
                                                <PrimaryBtn
                                                    name="Save"
                                                    handleClick={() => _addAdmin()}
                                                    className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"} />
                                                :
                                                <PrimaryBtn
                                                    name="Save"
                                                    className={"e-modal-submit e-disabled"} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </>
    )
}

export default EditAdmin;