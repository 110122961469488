/*
 *   File : m2e.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : m2e container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 16-02-2022
 */
/*import packages*/
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/*import components*/
import { MilestoneCard } from '../../components/card/index';
import { PrimaryLink } from '../../components/cta/index';
import AddMilestone from './add-milestone';


/*import assets*/
import NoData from '../../assets/images/note/no-data.png';
import { EmptyNote } from '../../components/note';
// #9c1fe9
const M2E = (props) => {
    const navigate = useNavigate()
    const [mileStoneData, setMileStoneData] = useState({});

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    useEffect(() => {
        setMileStoneData(props.data)
    }, [props.data])

    return (

        <>
            {
                Object.values(mileStoneData).length > 0 ?
                    <>
                        <AddMilestone index={props.id} data={mileStoneData} edit={true} update={() => props.update()} />
                    </>
                    :
                    <div className='e-users-container'>
                        <div className='row mt-2'>
                            <div className="row">
                                <div className="col-lg-7">
                                    <h6 className="pt-3">Active milestones</h6>
                                </div>
                                <div className="col-lg-5 text-end pe-0">
                                    <PrimaryLink
                                        name="+Add new"
                                        className="pt-3"
                                        handleClick={() => { navigate(`/add-milestone?id=` + props.id) }} />
                                </div>

                            </div>
                            {
                                props.list.length > 0 ?
                                    props.list.map((item, key) => {
                                        console.log("ke", Object.keys(props.list))
                                        return (
                                            <>
                                                {/* 
                                        <div className="col-lg-12">
                                            <div className="card e-dashboard-card border-0">
                                                <span className='pt-3 mt-1 e-labels me-3'>{item.time_period === "Monthly" ? "Monthly" : item.time_period === "Yearly" ? "Yearly" : "Date range"}</span>
                                                {/* <div className="row">
                                                <div className="col-lg-7">
                                                    <div className='d-flex'>
                                                        
                                                        <SelectBox
                                                            isSearchable={false}
                                                            options={options}
                                                            placeholder="All months 2022"
                                                            className="e-sort e-month-sort mt-2"
                                                            selectchange={(temp) => setSelected(temp)} />

                                                    </div>
                                                </div>
                                                <div className="col-lg-5 text-end">
                                                    <PrimaryLink
                                                        name="+Add new"
                                                        className="pt-3"
                                                        handleClick={() => { navigate(`/add-milestone?id=${key}`) }} />
                                                </div>

                                            </div> */}

                                                {/* </div>
                                        </div> */}

                                                {/* <div className='row pt-3 mb-4'> */}
                                                <div className='col-lg-6 mb-2 mt-2'>
                                                    {/* <span className='pt-3 mt-1 e-labels me-3 mb-2'>{item.time_period === "Monthly" ? "Monthly" : item.time_period === "Yearly" ? "Yearly" : "Date range"}</span> */}
                                                    <MilestoneCard data={item} index={props.id} id={key} mileStoneData={(data) => { setMileStoneData(data); props.mileStoneData(data) }} update={() => props.update()} />
                                                </div>
                                                {/* </div> */}
                                            </>
                                        )
                                    })
                                    :
                                    <div className='mt-5 pt-5 mb-5 pb-5'>
                                        <EmptyNote data="No data" img={NoData} />
                                    </div>
                            }
                        </div>
                    </div>
            }

        </>

    )
}

export default M2E;
