/*
 *   File : input.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-07-2022
 */

/*import package*/
import { useState, useEffect, useRef } from 'react';
import service from '../../services/service';
import Input from './input';

/*import style*/


const Search = (props) => {
    const wrapperRef = useRef(null);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState("");
    const [userId, setUserId] = useState({});
    const [customerData, setCustomerData] = useState([]);

    useEffect(() => {

        setSearch(props.search)
    }, [props.search]);

    useEffect(() => {
        if (search === "") {
            setShow(false);
            // props.setSearch("")
        }
    }, [search])

    useEffect(() => {
        setUserId(props.value)
    }, [props.value])

    const _closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShow(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);

    /*---- search user ----*/
    const searchUser = (value, status) => {
        setShow(status);

        let url = "/user/search";

        let data = {
            query: value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setCustomerData(response.data.data);
            } else {
                setCustomerData([]);
            }
        });
    }

    return (
        <>
            <Input
                placeholder="Search user"
                // defaultValue={search}
                value={Object.keys(userId).length > 0 ? `${userId.name}` : search}
                valueChange={(value) => {
                    setSearch(value)
                    if (value === "") {
                        props.setSearch(value)
                    }
                    setUserId({});
                    searchUser(value, true);
                }}
                type="text"
                className={props.className}
            />
            {
                show ?
                    customerData.length > 0 ?
                        <div className='e-get-list' ref={wrapperRef}>
                            {
                                customerData.map((item) => {
                                    return (
                                        <div className='e-list-items' onClick={() => { props.userDetail(item); setShow(false) }}>
                                            {item.name},{` (${item.client_id}) `}</div>
                                    )
                                })
                            }
                        </div>
                        :
                        <div className='e-get-list d-flex justify-content-center align-items-center'>
                            No user found
                        </div>
                    :
                    null
            }
        </>
    );
}
export default Search;

