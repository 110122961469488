/*
 *   File : add-milestone.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : m2e container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 16-02-2022
 */
/*import packages*/
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
/*import components*/
import SelectBox from '../../components/dropdown/selectBox';
import { Input, Rangepicker } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';

/*import assets*/
import Arrow from '../../assets/images/milestone/arrow.svg';
import { Link, useNavigate } from 'react-router-dom';

/*import services*/
import service from '../../services/service';
import GetURLVar from '../../services/getUrl';
import { Toast } from '../../components/note';

const AddMilestone = (props) => {
    const navigate = useNavigate()
    const wrapperRef = useRef(null)
    const [index, setIndex] = useState(null);
    const [selected, setSelectedGame] = useState({});
    //eslint-disable-next-line
    const [timeFilter, setShowTimeFilter] = useState(false);
    //eslint-disable-next-line
    const [dashbaordFilter, setDashboardFilter] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("Select");
    const [filterId, setFilterId] = useState(null);
    const [name, setName] = useState("");
    const [days, setDays] = useState(null);
    const [bookingsDay, setBookingsDay] = useState(null);
    const [bookingsMonth, setBookingsMonth] = useState(null);
    const [targetPoints, setTargetPoints] = useState(null);
    const [rewardsDay, setRewardsDay] = useState(null);
    const [rewards, setRewards] = useState(null);
    const [bookingScripPer, setBookingScripPer] = useState(null);
    const [targetPer, setTargetPer] = useState(null);
    //eslint-disable-next-line
    const [bookingScripCount, setBookingScripCount] = useState(null);
    const [error, setError] = useState(null);
    //eslint-disable-next-line
    const [open, setOpen] = useState(false);
    //eslint-disable-next-line
    const [range, setRange] = useState({ start: "", end: "" })
    //eslint-disable-next-line
    const [dates, setDates] = useState({
        checkin: "",
        checkout: ""
    })
    const [mileDates, setMileDates] = useState({
        start: null,
        end: null
    })

    const disabledDate = (current) => {
        // Can not select days before today 
        return current && current < moment().startOf('day');
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        getDasboardFilter();

        getUrl();
    }, [])

    const getUrl = async () => {
        let query = await GetURLVar()
        setIndex(query.id)
    }

    useEffect(() => {
        if (selected?.value && mileDates.start && mileDates.end && filterId) {
            getDisableData()
        }
        //eslint-disable-next-line
    }, [selected, mileDates, targetPer, filterId, bookingScripPer])

    useEffect(() => {
        if (props.edit === true && props.data) {
            setName(props.data.milestone_name);
            setRewards(props.data.rewards);
            setSelectedGame({ label: props.data.game_type, value: props.data?.game_type_id })
            setDays(props.data.total_trading_days);
            // setTargetPoints(props.data.target_score);
            setSelectedFilter(props.data.time_period);
            setFilterId(props.data.time_period_id);
            setBookingsDay(props.data.max_possible_bookings_in_a_day);
            setBookingsMonth(props.data.max_possible_bookings_in_a_month);
            setRewardsDay(props.data.max_reward_in_a_day);
            // setPenaltyDay(props.data.max_penalty_points_in_a_day);
            // setMilestonePoints(props.data.max_possible_milestone_points);
            if (index === "3" || props.index === 3 || props.data?.game_type_id === 3) {
                setBookingScripPer(props.data.min_required_booking_scrip_percentage);
                setTargetPoints(props.data.min_required_no_of_bookings)
            } else {
                setTargetPoints(props.data.required_target_points);
            }
            setBookingScripCount(props.data.min_required_booking_scrip_count);
            setTargetPer(props.data.required_target_points_percentage);
            var tempDate = props.data.time_period_range.split(" - ")

            // if (props.data.time_period_id === 1) {
            //     setMileDates({
            //         start: moment(tempDate[0], "YYYY-MM-DD").format("MM-YYYY"),
            //         end: moment(tempDate[1], "YYYY-MM-DD").format("MM-YYYY"),
            //     })
            // } else if (props.data.time_period_id === 3) {
            //     setMileDates({
            //         start: moment(tempDate[0], "YYYY-MM-DD").format("YYYY"),
            //         end: moment(tempDate[1], "YYYY-MM-DD").format("YYYY"),
            //     })
            // } else {
            setMileDates({
                start: moment(tempDate[0], "YYYY-MM-DD").format("YYYY-MM-DD"),
                end: moment(tempDate[1], "YYYY-MM-DD").format("YYYY-MM-DD"),
            })
            // }

        }
        //eslint-disable-next-line
    }, [props.data, props.edit])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        if (dates.checkin !== "" && dates.checkout !== "") {
            setOpen(false)
            let start = moment(dates.checkin).format("YYYY-MM-DD")
            let end = moment(dates.checkout).format("YYYY-MM-DD")
            setRange({
                start: start,
                end: end
            });
            setShowTimeFilter(false)
            setSelectedFilter("")
        }
    }, [dates.checkin, dates.checkout]);

    const _closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowTimeFilter(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);

    const options = [
        {
            value: 1, label: 'Monthly',
        },
        {
            value: 2, label: 'Quarterly'
        },
        {
            value: 3, label: 'Yearly'
        },

    ]

    const gameOptions = [
        {
            value: 1, label: 'M2E',
        },
        {
            value: 2, label: 'M2M'
        },
        {
            value: 3, label: 'Skilrace'
        }
    ]

    /* ---- get dashboard filter ---*/
    const getDasboardFilter = () => {

        let url = "/dashboard/filter-data";

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setDashboardFilter(response.data.data);
            }
        });
    }

    // const returnDate = () => {
    //     if (selectedFilter === "Monthly") {
    //         var tempDate = moment(mileDates.start, "YYYY-MM-DD").format("MM-YYYY")
    //         var tempEnd = moment(mileDates.end, "YYYY-MM-DD").format("MM-YYYY")
    //     } else if (selectedFilter === "Yearly") {
    //         var tempDate = moment(mileDates.start, "YYYY-MM-DD").format("YYYY")
    //         var tempEnd = moment(mileDates.end, "YYYY-MM-DD").format("YYYY")
    //     } else {
    //         var tempDate = mileDates.start
    //         var tempEnd = mileDates.end
    //     }
    //     return tempDate
    //     return tempEnd
    // }

    const getDisableData = () => {
        // if (selectedFilter === "Monthly") {
        //     var tempDate = moment(mileDates.start, "YYYY-MM-DD").format("MM-YYYY")
        //     var tempEnd = moment(mileDates.end, "YYYY-MM-DD").format("MM-YYYY")
        // } else if (selectedFilter === "Yearly") {
        //     var tempDate = moment(mileDates.start, "YYYY-MM-DD").format("YYYY")
        //     var tempEnd = moment(mileDates.end, "YYYY-MM-DD").format("YYYY")
        // } else {
        //     var tempDate = mileDates.start
        //     var tempEnd = mileDates.end
        // }
        let url = "/milestone/get-data";
        if (selected.value === 3) {
            var data = {

                "game_type": selected.value,
                "milestone_type": filterId,
                "start_date": mileDates.start,
                "end_date": mileDates.end,
                "required_target_points_percentage": targetPer,
                "min_required_booking_scrip_percentage": bookingScripPer
            }
        } else {
            //eslint-disable-next-line
            var data = {
                "game_type": selected.value,
                "milestone_type": filterId,
                "start_date": mileDates.start,
                "end_date": mileDates.end,
                "required_target_points_percentage": targetPer,
            }
        }

        // if (index === "3" || props.index === 3 || props.data?.game_type_id === 3) {
        //     data["min_required_booking_scrip_percentage"] = bookingScripPer
        // }

        service(true, url, data).then((response) => {

            if (response.data.status_code === 200) {
                setRewardsDay(response.data.data.max_reward_in_a_day);
                setBookingsDay(response.data.data.max_possible_bookings_in_a_day);
                setBookingsMonth(response.data.data.max_possible_bookings_in_a_month_or_period);
                if (selected.value === 3) {
                    setTargetPoints(response.data.data.min_required_no_of_bookings)
                } else {
                    setTargetPoints(response.data.data.min_required_points);
                }
                // setTargetPoints(response.data.data.min_required_points);
                setDays(response.data.data.total_trading_days);
            }
        });
    }

    const _addMilestone = () => {
        // if (selectedFilter === "Monthly") {
        //     var tempDate = moment(mileDates.start, "YYYY-MM-DD").format("MM-YYYY")
        //     var tempEnd = moment(mileDates.end, "YYYY-MM-DD").format("MM-YYYY")
        // } else if (selectedFilter === "Yearly") {
        //     var tempDate = moment(mileDates.start, "YYYY-MM-DD").format("YYYY")
        //     var tempEnd = moment(mileDates.end, "YYYY-MM-DD").format("YYYY")
        // } else {
        //     var tempDate = mileDates.start
        //     var tempEnd = mileDates.end
        // }

        // navigate("/milestones", { state: { id: index } })

        let url = "/milestone/add"


        let data = {
            "milestone_id": null,
            "milestone_name": name,
            "game_type_id": selected.value,
            "time_period_id": filterId,
            "start_date": mileDates.start,
            "end_date": mileDates.end,
            "total_trading_days": Number(days),
            "max_reward_in_a_day": Number(rewardsDay),
            "max_possible_bookings_in_a_day": Number(bookingsDay),
            "max_possible_bookings_in_a_month": Number(bookingsMonth),
            "required_target_points_percentage": Number(targetPer),
            "required_target_points": Number(targetPoints),
            "reward_amount": Number(rewards),
        }
        if (index === "3" || props.index === 3 || selected?.value === 3) {
            data["min_required_booking_scrip_percentage"] = Number(bookingScripPer)
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                navigate("/milestones", { state: { id: index } })
            } else {
                setError(response.data.message)
            }
        })
    }

    const _editMilestone = () => {
        let url = "/milestone/edit"

        // if (selectedFilter === "Monthly") {
        //     var tempDate = moment(mileDates.start, "YYYY-MM-DD").format("MM-YYYY")
        //     var tempEnd = moment(mileDates.end, "YYYY-MM-DD").format("MM-YYYY")
        // } else if (selectedFilter === "Yearly") {
        //     var tempDate = moment(mileDates.start, "YYYY-MM-DD").format("YYYY")
        //     var tempEnd = moment(mileDates.end, "YYYY-MM-DD").format("YYYY")
        // } else {
        //     var tempDate = mileDates.start
        //     var tempEnd = mileDates.end
        // }

        let data = {
            "milestone_id": props.data.milestone_id,
            "milestone_name": name,
            "game_type_id": selected.value,
            "time_period_id": filterId,
            "start_date": mileDates.start,
            "end_date": mileDates.end,
            "total_trading_days": Number(days),
            "max_reward_in_a_day": Number(rewardsDay),
            "max_possible_bookings_in_a_day": Number(bookingsDay),
            "max_possible_bookings_in_a_month": Number(bookingsMonth),
            // "max_penalty_points_in_a_day": Number(penaltyDay),
            // "min_required_booking_scrip_percentage": Number(bookingScripPer),
            "required_target_points_percentage": Number(targetPer),
            "required_target_points": Number(targetPoints),
            "reward_amount": Number(rewards),

        }
        if (index === "3" || props.index === 3) {
            data["min_required_booking_scrip_percentage"] = Number(bookingScripPer)
        }
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                props.update()
            } else {
                setError(response.data.message)
            }
        })
    }

    return (
        <>
            {
                props.edit ?
                    null :
                    <h3 className='e-header-title'>
                        <Link to="/milestones"><img src={Arrow} className="me-3" alt="" /></Link>
                        Add milestone</h3>
            }
            <div className='e-users-container'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card e-dashboard-card e-milestone-wrap e-add-milestone-wrap e-add-new-milestone border-0">
                            {
                                props.edit ?
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <span className='pt-3 mt-1 e-labels me-3'>Edit milestone</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <span className='pt-3 mt-1 e-labels me-3'>Add new milestone</span>
                                        </div>
                                    </div>
                            }

                            <div className='row pt-3'>
                                <div className='col-lg-8 mb-4'>
                                    <label className="mb-1">Milestone name</label>
                                    <Input
                                        placeholder="Name"
                                        value={name}
                                        valueChange={(temp) => setName(temp)} />
                                </div>
                                <div className='col-lg-4 mb-4'>
                                    <label className="mb-1">Choose game</label>
                                    <SelectBox
                                        isSearchable={false}
                                        options={gameOptions}
                                        value={Object.values(selected).length > 0 ? selected : null}
                                        placeholder="Choose game"
                                        className="e-milestone-sort"
                                        selectchange={(temp) => { setSelectedGame(temp); }} />
                                </div>

                                <div className='col-lg-4 mb-4'>
                                    <label className="mb-1">Milestone type</label>
                                    <SelectBox
                                        isSearchable={false}
                                        options={options}
                                        value={{ label: selectedFilter, value: filterId }}
                                        placeholder="Choose duration"
                                        className="e-milestone-sort"
                                        selectchange={(temp) => {
                                            setSelectedFilter(temp.label);
                                            setFilterId(temp.value);
                                            setMileDates({ start: null, end: null })
                                        }} />

                                </div>
                                <div className='col-lg-4 mb-4'>
                                    <label className="mb-1">Milestone duration</label>
                                    <Rangepicker
                                        start={mileDates.start}
                                        end={mileDates.end}
                                        format={"YYYY-MM-DD"}
                                        placeholder={["Start date", "End date"]}
                                        disabledDate={disabledDate}
                                        onDatesChange={(start, end) => setMileDates({
                                            start: start,
                                            end: end
                                        })} />

                                </div>
                                <div className='col-lg-4 mb-4'>
                                    <label className="mb-1">Maximum possible bookings in a day</label>
                                    <Input
                                        type="number"
                                        // placeholder="1"
                                        disabled={true}
                                        value={bookingsDay}
                                        valueChange={(temp) => setBookingsDay(temp)} />
                                </div>
                                <div className='col-lg-4 mb-4'>
                                    <label className="mb-1">Total trading days</label>
                                    <Input
                                        // disabled={true}
                                        type="number"
                                        placeholder="Maximum rewards in  a day"
                                        value={days}
                                        valueChange={(temp) => setDays(temp)} />
                                </div>
                                <div className='col-lg-4 mb-4'>
                                    <label className="mb-1">Maximum possible bookings in a month</label>
                                    <Input
                                        type="number"
                                        // placeholder="10"
                                        disabled={true}
                                        value={bookingsMonth}
                                        valueChange={(temp) => setBookingsMonth(temp)} />
                                </div>
                                {
                                    index === "3" || props.index === 3 || selected.value === 3 ?
                                        null
                                        :
                                        <div className='col-lg-4 mb-4'>
                                            <label className="mb-1">Maximum reward points in a day</label>
                                            <Input
                                                type="number"
                                                // placeholder="1"
                                                value={rewardsDay}
                                                disabled={true}
                                                valueChange={(temp) => setRewardsDay(temp)} />
                                        </div>
                                }
                                <div className='col-lg-4 mb-4'>
                                    {
                                        index === "3" || props.index === 3 || selected.value === 3 ?
                                            <label className="mb-1">Required average score</label>
                                            :
                                            <label className="mb-1">Required target point %</label>

                                    }

                                    <Input
                                        type="number"
                                        // placeholder="10%"
                                        value={targetPer}
                                        valueChange={(temp) => setTargetPer(temp)} />
                                </div>
                                {
                                    index === "3" || props.index === 3 || selected.value === 3 ?
                                        <div className='col-lg-4 mb-4'>
                                            <label className="mb-1">Minimum required booking scrip percentage</label>
                                            <Input
                                                type="number"
                                                placeholder="1"
                                                // disabled={true}
                                                value={bookingScripPer}
                                                valueChange={(temp) => setBookingScripPer(temp)} />
                                        </div>
                                        :
                                        null
                                }
                                {
                                    index === "3" || props.index === 3 || selected.value === 3 ?
                                        <div className='col-lg-4 mb-4'>
                                            <label className="mb-1">Required no of bookings
                                            </label>
                                            <Input
                                                type="number"
                                                value={targetPoints}
                                                disabled={true}
                                                valueChange={(temp) => setTargetPoints(temp)} />
                                        </div>
                                        :
                                        <div className='col-lg-4 mb-4'>
                                            <label className="mb-1">Required target points</label>
                                            <Input
                                                type="number"
                                                value={targetPoints}
                                                disabled={true}
                                                valueChange={(temp) => setTargetPoints(temp)} />
                                        </div>

                                }
                                <div className={index === "3" || props.index === 3 || selected?.value === 3 ? 'col-lg-4 mb-4 ' : 'col-lg-4 mb-4'}>
                                    <label className="mb-1">Rewards</label>
                                    <Input
                                        placeholder="Rewards"
                                        suffix="₹"
                                        value={rewards}
                                        type="number"
                                        valueChange={(temp) => setRewards(temp)} />
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className="d-flex justify-content-start">
                                        <SecondaryBtn
                                            name="Clear"
                                            className="me-3" />
                                        {
                                            // "game_type_id": selected.value,
                                            // "time_period_id": filterId,
                                            // "milestone_name": name,
                                            // "max_reward_in_a_day": Number(rewardsDay),
                                            // "max_penalty_points_in_a_day": Number(penaltyDay),
                                            // "min_required_booking_scrip_percentage": Number(bookingScripPer),
                                            // "required_target_points_percentage": Number(targetPer),
                                            // "reward_amount": Number(rewards),
                                            // "start_date": tempDate,
                                            // "end_date": tempEnd
                                        }
                                        {
                                            name &&
                                                // filterId &&
                                                // selected?.value &&
                                                // mileDates.start &&
                                                // mileDates.end &&
                                                // rewards &&
                                                targetPer
                                                ?

                                                <PrimaryBtn
                                                    name="Save milestone"
                                                    handleClick={() => {
                                                        props.edit === true ?
                                                            _editMilestone() :
                                                            _addMilestone()
                                                    }} />
                                                :
                                                <PrimaryBtn
                                                    name="Save milestone"
                                                    className="e-disabled" />
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }

        </>

    )
}

export default AddMilestone;
