/*
 *   File : confirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Confirmation Modal
 *   Integrations : 03-03-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import components*/
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/confirmation.module.scss';

/* import services */
import service from '../../services/service';

export default function Confirmation(props) {

	const [loader, setLoader] = useState(false);
	const [error, setError] = useState(null);
	const [id, setId] = useState(null);


	useEffect(() => {
		setId(props.id)
	}, [props.id]);

	useEffect(() => {
		setTimeout(() => {
			if (error !== null) {
				setError(null);
				$("#confirmation").trigger('click');
			}
		}, 3000);
	}, [error]);

	useEffect(() => {
		var myModalEl = document.getElementById('confirmation')
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			props.close()
		})

		//eslint-disable-next-line
	}, [])

	/* update m2e info ---*/
	const _updateM2eInfo = () => {
		setLoader(true);
		if (props.type === "m2e") {
			let url = "/m2e/update-basicinfo";

			let data = props.apiData;

			service(true, url, data).then((response) => {
				if (response.data.status_code === 200) {
					setLoader(false);
					$("#confirmation").trigger('click');
					props.update(true)
				} else {
					setError(response.data.message);
					setLoader(false);
				}
			});
		} else if (props.type === "skillrace") {

			let url = "/skillrace/update-basicinfo";

			let data = props.apiData;

			service(true, url, data).then((response) => {
				if (response.data.status_code === 200) {
					setLoader(false);
					$("#confirmation").trigger('click');
					props.update(true)
				} else {
					setError(response.data.message);
					setLoader(false);
				}
			});
		} else {
			let url = "/m2m/update-basicinfo";

			let data = props.apiData;

			service(true, url, data).then((response) => {
				if (response.data.status_code === 200) {
					setLoader(false);
					$("#confirmation").trigger('click');
					props.update(true)
				} else {
					setError(response.data.message);
					setLoader(false);
				}
			});
		}
	}

	/* update m2e info ---*/
	const _updateOpenCloseInfo = () => {

		if (props.type === "m2m") {
			setLoader(true);
			let url = "/m2m/open-close-update";

			let data = props.openClose;

			service(true, url, data).then((response) => {
				if (response.data.status_code === 200) {
					setLoader(false);
					$("#confirmation").trigger('click');
					props.update(true)
				} else {
					setError(response.data.message);
					setLoader(false);
				}
			});
		}
	}

	/*---- delete ----*/
	const _deleteFaq = () => {
		setLoader(true)
		let url = '/faq	/delete-question';

		let data = {
			"faq_id": props.faqId
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				$("#confirmation").trigger("click")
				setLoader(false);
				props.update(true)
			}
			else {
				setError(response.data.message);
				setLoader(false);
			}
		});
	}

	const _deleteMileStone = () => {
		setLoader(true);
		let url = "/milestone/delete";

		let data = {
			milestone_id: props.mileStoneId
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				$("#confirmation").trigger("click");
				setLoader(false);
				props.update(true);
			} else {
				setError(response.data.message);
				setLoader(false);
			}
		});
	}

	/* delete exception */
	const _deleteException = () => {
		setLoader(true);
		let url = '/reports/wallet-exceptions/delete';;

		let data = {
			customer_id: props.exceptionId
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				$("#confirmation").trigger("click");
				setLoader(false);
				props.update(true);
			} else {
				setError(response.data.message);
				setLoader(false);
			}
		});
	}

	/* delete exception */
	const _deleteNotification = () => {
		setLoader(true);
		let url = '/notifications/remove-popup-notifications';;

		let data = {
			popup_id: props.popup_id
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				$("#confirmation").trigger("click");
				setLoader(false);
				props.update(true);
			} else {
				setError(response.data.message);
				setLoader(false);
			}
		});
	}

	//block user api
	const _blockUser = () => {
		setLoader(true)

		let url = '/scrip/block-unblock';

		let data = {
			"scrip_id": id
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				$("#confirmation").trigger("click")
				setLoader(false);
				props.update(true)
			}
			else {
				setError(response.data.message);
				setLoader(false);
			}
		});
	}

	/* delete block rule */
	const _deleteBlockRule = () => {
		setLoader(true);

		if (props.type === "m2e") {
			var url = "/m2e/delete-blockrule";
		}
		else if (props.type === "skillrace") {
			//eslint-disable-next-line
			var url = "/skillrace/delete-blockrule";
		} else {
			//eslint-disable-next-line
			var url = "/m2m/delete-blockrule";
		}

		let data = {
			block_rule_id: props.block_rule_id
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				$("#confirmation").trigger("click")
				setLoader(false);
				props.update(true)

			} else {
				setError(response.data.message);
				setLoader(false);
			}
		})
	}
	return (
		<div className={`${styles.e_confirmation} modal fade`} id="confirmation" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className="modal-content">
					<div className="modal-header">
						<h6>{props.title}</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.close()}></button>
					</div>
					<div className="container px-3">
						{/* <div className="row">
							<div className="col-lg-12 text-start">
								<p>{props.content}</p>
							</div>
						</div> */}
						<div className="row pt-4 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" handleClick={() => props.close()} />
									<PrimaryBtn
										name={props.btn_name ? props.btn_name : "Save settings"}
										className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
										handleClick={() => {
											props.status === true ?
												_blockUser()
												:
												props.status === false ?
													setLoader(false)
													:
													props.popup_id ?
														_deleteNotification()
														:
														props.faqId ?
															_deleteFaq()
															:
															props.block_rule_id ?
																_deleteBlockRule()
																:
																props.mileStoneId ?
																	_deleteMileStone()
																	:
																	props.exceptionId ?
																		_deleteException()
																		:
																		props.click === 5 ?
																			_updateOpenCloseInfo()
																			:
																			_updateM2eInfo()


										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast
						data={error}
						format={1}
						type="fail"
					/>
					:
					null
			}
		</div>
	)
}
