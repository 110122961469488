/*
 *   File : index.js.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : settings container;
 *   Integrations : 
 *   Version : v1.0
 *   Created : 12-04-2022
 */
/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { GameTabs } from '../../components/dataFormating/index';

/*import container */
import PlatformSetings from './platform-settings.js';
import TaxSettings from './tax-settings.js';
import ReferralSettings from './referralSettings';

const Index = (props) => {

	const [tabIndex, setTabindex] = useState(1);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, []);


	return (
		<>
			<div className='e-tab-position'>
				<GameTabs data={
					localStorage.getItem("type") !== "4" ?
						['Platform settings', 'Tax settings', 'Referral settings']
						:
						['Platform settings']}
					index={tabIndex}
					setTab={(temp) => setTabindex(temp)}
					type={true} />
			</div>
			<div className='e-users-container pt-3'>
				<div className="row">
					<div className="col-lg-12">
						<div className="card e-dashboard-card pt-2 border-0">
							{
								tabIndex === 1 ?
									<PlatformSetings />
									:
									localStorage.getItem("type") !== "4" ?
										tabIndex === 2 ?
											<TaxSettings />
											:
											<ReferralSettings />
										:
										null

							}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index;
