// import packeges
import { useState, useEffect, useRef } from 'react';
import $ from 'jquery'

//components
import { Input } from '../input';
import { PrimaryBtn, SecondaryBtn } from '../cta';
import { Toast } from '../note';

//services
import service from '../../services/service';

import Down from '../../assets/images/dataFormating/down.svg'

const AddBlockRule = (props) => {
	const wrapperRef = useRef()
	const [amount, setAmount] = useState("");
	const [period, setPeriod] = useState("");
	const [periodType, setPeriodType] = useState(null)
	const [block, setBlock] = useState("");
	const [code, setCode] = useState("");
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState(null);
	const [show, setShow] = useState(false)

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	useEffect(() => {
		var myModalEl = document.getElementById('add-block-rule')
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			setShow(false)
			setPeriod("")
			setPeriodType(null)
			setCode("")
			setBlock("")
			setAmount("")
		})
		//eslint-disable-next-line
	}, [])

	const _closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setShow(false)
		}
	}
	useEffect(() => {
		document.addEventListener('mouseup', _closeMenu);
		return () => {
			document.removeEventListener('mouseup', _closeMenu);
		};
	}, []);

	const options = [
		{ value: 1, label: "Days" },
		{ value: 2, label: "Month" },
		{ value: 3, label: "Quarter" },
		{ value: 4, label: "Year" },
	]

	/* add block rule */
	const addRule = () => {
		setLoader(true);
		if (props.type === "skillrace") {
			var url = "/skillrace/add-blockrule";
		}
		else if (props.type === "m2e") {
			//eslint-disable-next-line
			var url = "/m2e/add-blockrule";
		} else {
			//eslint-disable-next-line
			var url = "/m2m/add-blockrule"
		}
		if (props.type === "m2m") {
			var data = {
				"loss_amount": amount,
				"block_rule_type": periodType,
				"block_period": block,
				"block_code": code,
				"transaction_period": period,
			}
		} else {
			//eslint-disable-next-line
			var data = {
				"loss_amount": amount,
				"period": period,
				"period_type": periodType,
				"block_for_days": block,
				"block_code": code
			}
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				$("#add-block-rule").trigger("click");
				setLoader(false);
				props.ruleAdded(true)
			} else {
				setError(response.data.message);
				setLoader(false);
			}
		})
	}
	return (
		<div className={` modal fade e-add-block-rule`} id="add-block-rule" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className={`modal-dialog modal-dialog-centered`}>
				<div className="modal-content">
					<div className="modal-header">
						<h6>Add new block rule</h6>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="container px-3">
						<div className="row">
							<div className='col-lg-6'>
								<label className="mb-1">Loss amount</label>
								<Input
									type="number"
									placeholder="Loss amount"
									value={amount}
									valueChange={(temp) => setAmount(temp)}
								/>
							</div>
							{/* <div className='col-lg-6'>
								<label className="mb-1">Period</label>
								<SelectBox
									isSearchable={false}
									options={options}
									placeholder="select"
									selectchange={(temp) => setPeriod(temp)}
								/>
							</div> */}
							<div className='col-lg-6 e-add-block-rule-period position-relative'>
								<label className="mb-1">Period</label>

								<Input
									type="number"
									suffix={periodType === 1 ? <span onClick={() => { setShow(true) }}>Days <img src={Down} alt="" /></span> : periodType === 2 ? <span onClick={() => { setShow(true) }}>Month <img src={Down} alt="" /></span> : periodType === 3 ? <span onClick={() => { setShow(true) }}>Quarter <img src={Down} alt="" /></span> : periodType === 3 ? <span onClick={() => { setShow(true) }}>Year <img src={Down} alt="" /></span> : <span onClick={() => { setShow(true) }}> {" "}<img src={Down} alt="" /></span>}
									placeholder="Period"
									suffixClass={periodType === null ? "e-suffix-class" : ""}
									value={period}
									valueChange={(temp) => {
										setPeriod(temp)
									}}
								/>
								{
									show ?
										<div className="e-block-show" ref={wrapperRef}>
											{
												options.map((ruleItem) => {
													return (
														<p
															className={ruleItem.value === periodType ? "e-selected my-2" : "my-2"}
															onClick={() => {
																// m2eBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setperiodType(ruleItem.value) : item.period_type);
																// let tempArr = [...m2eBlockRuleData];
																// tempArr[i]['period_type'] = parseInt(ruleItem.value);
																// m2eBlockRuleData = tempArr;
																// setM2eBlockRuleData(m2eBlockRuleData)
																setShow(false)
																setPeriodType(ruleItem.value)
															}}>{ruleItem.label}</p>
													)
												})
											}

										</div>
										:
										null
								}
							</div>
							<div className='col-lg-6 mt-4'>
								<label className="mb-1">Block for</label>
								<Input
									placeholder="days "
									type="number"
									suffix={period?.label}
									value={block}
									valueChange={(temp) => setBlock(temp)}
								/>
							</div>
							<div className='col-lg-6 mt-4'>
								<label className="mb-1">Block code</label>
								<Input
									placeholder="code "
									type="text"
									value={code}
									valueChange={(temp) => setCode(temp)} />
							</div>
						</div>
						<div className="row pt-4 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn
										name="Cancel"
										className="e-cancel"
										cancel="modal"
									/>
									{
										amount && period && block && code && periodType ?
											<PrimaryBtn
												name="Save"
												className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
												handleClick={() =>
													props.type === "skillrace" ?
														addRule()
														:
														props.type === "m2e" || props.type === "m2m" ?
															addRule()
															:
															null
												}
											/>
											:
											<PrimaryBtn
												name="Save"
												className="e-modal-submit e-disabled"
											/>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
		</div>
	)
}

export default AddBlockRule;