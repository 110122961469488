/*
 *   File : m2etable.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Columns
 *   Integrations : 03-03-2022
 *   Version : 1.0.0
 *   Created : 03-03-2022
 */
import React from 'react';

/* assets */
import HideIcon from '../../assets/images/admins/hide.svg';
import ShowIcon from '../../assets/images/admins/show.svg';

/*services*/
import service from '../../services/service';

/*Style Import*/
import style from '../../styles/components/setPageAccess.module.scss';
export default class M2Etable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			topic: "",
			error: "",
			tableData: [],
			status: "",
		};
	}

	componentDidMount() {
		this.getTableInfo();
	}

	/*---m2e table info ---*/
	getTableInfo = () => {
		if (this.props.type === "m2e") {
			let url = '/m2e/custom-table-info';

			service(false, url).then((response) => {
				if (response.status === 200) {
					this.setState({ tableData: response.data.data });
					this.props.tableData(response.data.data)
				} else {
					this.setState({ error: response.message });
				}
			})
		} else if (this.props.type === "skillrace") {
			let url = '/skillrace/custom-table-info';

			service(false, url).then((response) => {
				if (response.status === 200) {
					this.setState({ tableData: response.data.data });
					this.props.tableData(response.data.data)
				} else {
					this.setState({ error: response.message });
				}
			});
		} else {
			let url = '/m2m/custom-table-info';

			service(false, url).then((response) => {
				if (response.status === 200) {
					this.setState({ tableData: response.data.data });
					this.props.tableData(response.data.data)
				} else {
					this.setState({ error: response.message });
				}
			})
		}
	}

	dragStart(e) {
		this.dragged = e.currentTarget;
	}

	dragEnd(e) {
		console.log(this)
		this.dragged.style.display = 'block';

		e.target.classList.remove("drag-up");
		this?.over?.classList.remove("drag-up");

		e.target.classList.remove("drag-down");
		this?.over?.classList.remove("drag-down");

		var data = this?.state?.tableData;
		var from = Number(this.dragged.dataset.id);
		var to = Number(this.over.dataset.id);
		data.splice(to, 0, data.splice(from, 1)[0]);

		//set newIndex to judge direction of drag and drop
		data = data.map((doc, index) => {
			doc.order = index + 1;
			return doc;
		})

		this.setState({ data: data });
		this.props.tableData(data)
	}

	dragOver(e) {
		e.preventDefault();
		this.dragged.style.display = "none";
		if (e.target.tagName !== "LI") {
			return;
		}


		const dgIndex = JSON.parse(this.dragged.dataset.item).newIndex;
		const taIndex = JSON.parse(e.target.dataset.item).newIndex;
		const animateName = dgIndex > taIndex ? "drag-up" : "drag-down";


		if (this.over && e.target.dataset.item !== this.over.dataset.item) {
			this.over.classList.remove("drag-up", "drag-down");
		}

		if (!e.target.classList.contains(animateName)) {
			e.target.classList.add(animateName);
			this.over = e.target;
		}
	}

	getPublished = (data) => {
		let tempdata = [...this.state.tableData];
		let idx = this.state.tableData.findIndex((item) => item.column_id === data.column_id);
		tempdata[idx]['published'] = !data.published;
		this.setState({
			tableData: tempdata
		});
		this.props.tableData(tempdata)
	}

	render() {
		return (
			<div>
				{
					this.state?.tableData?.map((item, i) => (


						<span className="d-inline-block" onDragOver={this.dragOver.bind(this)}>
							<li data-id={i}
								style={{ display: "flex", listStyle: "none" }}
								key={i}
								draggable='true'
								onDragEnd={this.dragEnd.bind(this)}
								onDragStart={this.dragStart.bind(this)}
								data-item={JSON.stringify(item)}>
								{/* <div className='d-inline-block'> */}
								<span className={`${style.e_page_name} me-3`}>{item.name === "Ltp" ? item.name.toUpperCase() : item.name}
									{item.published === true ?
										<img src={ShowIcon} onClick={() => this.getPublished(item)} style={{ cursor: "pointer" }} alt="" />
										:
										<img src={HideIcon} onClick={() => this.getPublished(item)} style={{ cursor: "pointer" }} alt="" />}
								</span>
								{/* </div> */}

							</li>
						</span>

					))
				}
			</div>
		);
	}
}
