/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';

/*import componets*/
import { Input } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';


/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';
import service from '../../services/service';


export default function ChangePassword(props) {

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        var myModalEl = document.getElementById('changePassword')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            setNewPassword("");
            setCurrentPassword("");
            setConfirmPassword("");
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    const validate = () => {
        var pattern = new RegExp(/^(?=.*\d)(?=.*?[a-zA-Z])(?=.*?[\W_]).{8,100}$/);
        if (newPassword !== confirmPassword) {
            setError("Your password and confirm password does not match.");
        }
        else if (!pattern.test(newPassword)) {
            setError("Password contain one special character and one digit with minimum 8 characters");
        } else {
            changePassword();
        }
    }

    /*--------- change password api ------*/
    const changePassword = () => {
        setLoader(true);
        let url = "/auth/change-password"

        let data = JSON.stringify({
            current_password: currentPassword,
            new_password: newPassword
        })
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                setTimeout(() => {
                    window.location.href = '/'
                    localStorage.removeItem('token');
                }, 500)

            } else {
                setLoader(false);
                setError(response.message);
            }
        })
    }



    return (
        <div className={`${styles.e_add_faq} modal fade`} id="changePassword" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>Change password</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="container px-3">
                        <div className="row">
                            <div className="col-lg-12">
                                <label>Current password</label>
                                <Input
                                    placeholder="Current password"
                                    value={currentPassword}
                                    valueChange={(value) => {
                                        setCurrentPassword(value)
                                    }}
                                    type="password"
                                />
                            </div>
                            <div className="col-lg-12 pt-3 mt-2">
                                <label>New password</label>
                                <Input
                                    placeholder="New password"
                                    value={newPassword}
                                    valueChange={(value) => {
                                        setNewPassword(value)
                                    }}
                                    type="password"
                                />
                            </div>
                            <div className="col-lg-12 pt-3 mt-2">
                                <label>Confirm password</label>
                                <Input
                                    placeholder="Confirm password"
                                    value={confirmPassword}
                                    valueChange={(value) => {
                                        setConfirmPassword(value)
                                    }}
                                    type="password"
                                />
                            </div>
                        </div>
                        <div className="row pt-4 mt-2 pb-2">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-end">
                                    <SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" />
                                    {
                                        currentPassword && newPassword && confirmPassword ?
                                            <PrimaryBtn
                                                name="Save password"
                                                className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                handleClick={() => {
                                                    validate()
                                                }}
                                            />
                                            :
                                            <PrimaryBtn
                                                name="Save password"
                                                className={`${styles.e_disabled} e-modal-submit `}
                                            />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
