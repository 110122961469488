// import packeges
import { useState, useEffect } from 'react';
import moment from 'moment';
import $ from 'jquery'

// import components
import { Rangepicker } from '../input';
import { PrimaryBtn, } from '../cta';
import service from '../../services/service';
import { Toast } from '../note';
// import 

const Updatevoting = (props) => {

    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        var myModalEl = document.getElementById('update-voting')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            setStart(null);
            setEnd(null);
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        var myModalEl = document.getElementById('update-voting')
        myModalEl.addEventListener('shown.bs.modal', (event) => {
            let tempStart = moment(props.dates.start_date, "DD MMM YYYY").format("YYYY-MM-DD")
            let tempEnd = moment(props.dates.end_date, "DD MMM YYYY").format("YYYY-MM-DD")
            setStart(tempStart)
            setEnd(tempEnd)
        })
        //eslint-disable-next-line
    }, [props.dates])


    //block or unblock user

    const updateSettings = () => {
        setLoader(true)
        let url = "/scrip/configure-voting";

        let data = {
            start_date: start,
            end_date: end
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                $("#update-voting").trigger("click");
                props.success()
            } else {
                setError(response.data.message);
                setLoader(false);
            }
        })
    }

    const disabledDate = (current) => {
        // Can not select future dates
        return current < moment().endOf('day');
    }


    return (
        <div className={` modal fade e-block-user-modal`} id="update-voting" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>Update scrip voting settings</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="container px-3">
                        <div className="row">

                            <div className='col-lg-6'>
                                <label className="mb-1">Start-End</label>
                                <Rangepicker
                                    placeholder={["Start", "End"]}
                                    start={start}
                                    end={end}
                                    className="mt-2"
                                    disabledDate={disabledDate}
                                    onDatesChange={(start, end) => {
                                        setStart(start);
                                        setEnd(end)
                                    }} />
                            </div>

                        </div>
                        <div className="row pt-4 pb-2">
                            <div className="col-lg-12">
                                {
                                    start && end ?
                                        <div className="d-flex justify-content-end">
                                            <PrimaryBtn
                                                name="Update"
                                                className={
                                                    loader ?
                                                        "e-btn-loader e-modal-submit"
                                                        :
                                                        "e-modal-submit"
                                                }
                                                handleClick={() => {
                                                    updateSettings()
                                                }} />
                                        </div>
                                        :
                                        <div className="d-flex justify-content-end">
                                            <PrimaryBtn
                                                name="Update"
                                                className="e-disabled e-modal-submit"
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </div>
    )
}

export default Updatevoting;