/*
 *   File : blocked-users.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Blocked users;
 *   Integrations :
 *   Version : v1.0
 *   Created : 15-02-2022
 */
/*import pakcages*/
import { useState, useEffect } from 'react';


/*import components*/
import { DataTable } from '../../../components/dataFormating/index';
import { SearchInput } from '../../../components/input/index';
import { DownloadBtn } from '../../../components/cta/index';

/*import styles*/
import styles from '../../../styles/components/searchInput.module.scss';
import service from '../../../services/service';
import Loader from '../../../components/loader/loader';
import { Modal } from 'bootstrap';
import { BlockUser } from '../../../components/modal';

/* import assets */

const BlockedUsers = (props) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const [search, setSearch] = useState();
    const [check, setCheck] = useState();
    const [page, setPage] = useState(1)
    const [blockData, setBlockData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [id, setId] = useState(null);
    const [remark, setRemark] = useState()
    const [size, setSize] = useState(50);
    useEffect(() => {
        getBlockedUsers();
        //eslint-disable-next-line
    }, [page, size])

    const getBlockedUsers = () => {
        setLoader(true)
        var url = "/general/blocked-customers"

        var data = {
            page_num: page,
            page_size: size
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false)
                setBlockData(response.data.data)
            } else {
                setLoader(false);
                setBlockData([])
            }
        })
    }
    const columns = [
        {
            name: 'Client ID',
            selector: 'client_id',
            sortable: false,
            left: true,
            width: "120px",
        },
        {
            name: 'Name',
            selector: 'customer_name',
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <>
                {
                    row.customer_name
                }
            </>
            // maxWidth: "160px",
        },
        {
            name: 'Blocked on',
            selector: 'blocked_on',
            sortable: false,
            left: true,
            cell: row => <div className="d-flex">
                {row.blocked_on}
            </div>
        },
        {
            name: 'Block code',
            selector: 'block_code',
            sortable: false,
            left: true,
            width: "120px",

        },

        {
            name: 'Blocked by',
            selector: 'blocked_by',
            sortable: true,
            left: true,
            width: "180px",
        },
        {
            name: 'Modified by',
            selector: row => row.modified_by,
            sortable: false,
            right: false,
            minWidth: "160px",
            omit: localStorage.getItem("type") === "1" ? false : true
        },
        {
            name: 'Action',
            selector: 'action',
            sortable: false,
            left: true,
            cell: (row) => <div className="e-action-primary" onClick={() => {
                setId(row.id)
                setRemark(row.remark)
                var modal = new Modal(document.getElementById("block-user"), {})
                modal.show()
            }}>
                Unblock
            </div>
        },
        {
            name: 'Remark',
            selector: "remark",
            sortable: true,
            left: true,
            minWidth: '240px',
            // sortFunction: (a,b)=>customDateSort(a,b, 'transaction_date',),
            cell: row => <div className="col-lg-12">
                {row.remark}
            </div>
        },


    ]
    const handleChange = (row) => {
        setCheck(row.selectedRows);
    }


    return (
        <div className='e-users-container'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className='row pe-4'>
                            <div className="offset-lg-7 col-lg-2">
                                <DownloadBtn check={check} />
                            </div>
                            <div className="col-lg-3 position-relative ">
                                <SearchInput placeholder="User name/client id" value={search} valueChange={(value) => { setSearch(value) }} />
                                {
                                    search && search.length ?
                                        <>
                                            <span className={`${styles.e_search_submit} `} onClick={() => setSearch("")} ></span>
                                            <button className={`${styles.e_search_close} pe-3`} onClick={() => setSearch("")} ></button>
                                        </>
                                        :
                                        null
                                }

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 e-blocked-users pt-2">
                                {
                                    loader ?
                                        <Loader />
                                        :
                                        <>
                                            {
                                                page !== 1 && blockData.length === 0 ?
                                                    <span className='e-labels ps-4' style={{ cursor: "pointer" }} onClick={() => { setPage(1) }} >Back</span>
                                                    :
                                                    null
                                            }
                                            <DataTable
                                                pagination={true}
                                                columns={columns}
                                                size={size}
                                                onChangeRowsPerPage={(p) => setSize(p)}
                                                onChangePage={() => setPage(page + 1)}
                                                data={blockData}
                                                selectableRows={true}
                                                selectRows={(row) => { handleChange(row) }}

                                            />
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <BlockUser name="Unblock user" blocked={true} blockId={id} remark={remark} Close={() => { getBlockedUsers() }} />
        </div>

    )
}

export default BlockedUsers;
