/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 12-04-2022
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakcages*/
import React, { useState, useEffect, } from 'react';
import moment from 'moment';

/*import components*/
import { DataTable, GameTabs } from '../../components/dataFormating/index';
import { Rangepicker, Search, SearchInput } from '../../components/input/index';
import { DownloadBtn } from '../../components/cta/index';
import SelectBox from '../../components/dropdown/selectBox';
import Loader from '../../components/loader/loader';
import { FilterBtn } from '../../components/cta/index';
import { EmptyNote } from '../../components/note';

/*import  assets*/
import Nodara from '../../assets/images/note/no-data.png';
import Next from '../../assets/images/dataFormating/next.svg';
import Prev from '../../assets/images/dataFormating/prev.svg';

/* import services */
import service from '../../services/service';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

const GAME_OPTIONS = [
    {
        label: 'All',
        value: 0
    },
    {
        label: 'M2E',
        value: 1
    },
    {
        label: 'M2M',
        value: 2
    },
    {
        label: 'Skillrace',
        value: 3
    },
    {
        label: 'V2R',
        value: 4
    },
]

const WINNING_OPTIONS = [
    { vale: null, label: "All" },
    { value: true, label: 'Won' },
    { value: false, label: 'Lost' }
]

const OTPTYPE_OPTIONS = [
    {
        label: 'All',
        value: null
    },
    {
        label: "Signup",
        value: 1
    },
    {
        label: "Login",
        value: 2
    },
    {
        label: "Add email",
        value: 3
    },
    {
        label: "Update email",
        value: 4
    },
    {
        label: "Update mobile",
        value: 5
    },
]
//v2r frequecy
const FREQUENCY_OPTIONS = [
    { value: null, label: 'All' },
    { value: 1, label: 'V2R Daily' },
    { value: 2, label: 'V2R Weekly' },
    { value: 3, label: 'V2R Bi-weekly' },
    { value: 4, label: 'V2R Monthly' },
]

const DATE_FILTER_OPTIONS = [
    { value: false, label: "Game date" },
    { value: true, label: "Result date" },
]

const Index = (props) => {
    // const [search, setSearch] = useState("");
    const [filterDown, setFilterDown] = useState(false);
    const [tabIndex, setTabindex] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    //eslint-disable-next-line
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [gameIds, setGameIds] = useState([]);
    const [gameId, setGameId] = useState({ label: "All", value: 0 })
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [userId, setUserId] = useState({});
    const [gameType, setGameType] = useState(GAME_OPTIONS[0]);
    const [searchuser, setSearchuser] = useState("");
    const [otpType, setOtpType] = useState(OTPTYPE_OPTIONS[0]);
    const [frequencyId, setFrequencyId] = useState(FREQUENCY_OPTIONS[0]);
    const [filterType, setFilterType] = useState(DATE_FILTER_OPTIONS[0]);


    //eslint-disable-next-line

    const [total, setTotal] = useState(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(200);
    const [totalLength, setTotalLength] = useState(null);
    const [reportsData, setReportsData] = useState([]);
    const [totals, setTotals] = useState({})

    const [payouttotal, setPayoutTotal] = useState(null);
    const [payoutPage, setPayoutPage] = useState(1);
    const [payoutperPage, setPayoutPerPage] = useState(200);
    const [payouttotalLength, setPayoutTotalLength] = useState(null);
    const [payoutData, setPayoutData] = useState([]);

    const [resulttotal, setResultTotal] = useState(null);
    const [resultPage, setResultPage] = useState(1);
    const [resultperPage, setResultPerPage] = useState(200);
    const [resulttotalLength, setResultTotalLength] = useState(null);
    const [resultData, setResultData] = useState([]);
    const [winningType, setWinningType] = useState(WINNING_OPTIONS[0])

    const [paginationDataReport, setPaginationDataReport] = useState({})
    const [paginationDataResult, setPaginationDataResult] = useState({})
    const [paginationDataPayin, setPaginationDataPayin] = useState({})
    const [paginationDataPayout, setPaginationDataPayout] = useState({})
    const [paginationDataLog, setPaginationDataLog] = useState({})
    const [paginationDataBooking, setPaginationDataBooking] = useState({})

    const [platformData, setPlatformData] = useState([]);
    const [reportPage, setReportPage] = useState(1);
    const [reportperPage, setReportPerPage] = useState(200);
    const [reporttotalLength, setReportTotalLength] = useState(null);
    const [reportTotal, setReportTotal] = useState(null);
    const [paginationPlatform, setPaginationPlatform] = useState({});


    // const [payintotal, setPayinTotal] = useState(null);
    // const [payinPage, setPayinPage] = useState(1);
    // const [payinperPage, setPayinPerPage] = useState(200);
    // const [payintotalLength, setPayinTotalLength] = useState(null);
    // const [payinstartDate, setpayinStartDate] = useState(null);
    // const [payinendDate, setpayinEndDate] = useState(null);
    // const [payinData, setPayinData] = useState([]);

    // const [bookingtotal, setBookingTotal] = useState(null);
    // const [bookingPage, setBookingPage] = useState(1);
    // const [bookingperPage, setBookingPerPage] = useState(200);
    // const [bookingtotalLength, setBookingTotalLength] = useState(null);
    // const [bookingstartDate, setbookingStartDate] = useState(null);
    // const [bookingendDate, setbookingEndDate] = useState(null);
    // const [bookingData, setBookingData] = useState([]);

    // const [logtotal, setLogTotal] = useState(null);
    // const [logPage, setLogPage] = useState(1);
    // const [logperPage, setLogPerPage] = useState(200);
    // const [logtotalLength, setLogTotalLength] = useState(null);
    // const [logstartDate, setlogStartDate] = useState(null);
    // const [logendDate, setlogEndDate] = useState(null);
    // const [logData, setLogData] = useState([]);

    const [search, setSearch] = useState("");


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        // getCustomersList();
    }, []);

    // useEffect(() => {
    //     if (downloadLoader === true) {
    //         setDownloadLoader(false)
    //     }
    // }, [downloadLoader])

    useEffect(() => {
        if (tabIndex === 1) {
            _resportsList()
        }
        //eslint-disable-next-line
    }, [page, perPage, gameId?.value, tabIndex, startDate, endDate, gameType, filterType])

    useEffect(() => {
        if (tabIndex !== 1 && tabIndex < 6) {
            _maxPayoutList()
        }
        //eslint-disable-next-line
    }, [payoutPage, payoutperPage, tabIndex, startDate, endDate, gameType, otpType])

    useEffect(() => {
        if (tabIndex === 6) {
            _resultHistory()
        }
        //eslint-disable-next-line
    }, [resultPage, resultperPage, tabIndex, startDate, endDate, gameType, winningType, userId, gameId, frequencyId]);

    useEffect(() => {
        if (tabIndex > 6) {
            _reportsHistory()
        }
        //eslint-disable-next-line
    }, [payoutPage, payoutperPage, search, tabIndex, startDate, endDate, gameType])




    // useEffect(() => {
    //     searchScrip();
    // }, [gameType])

    // const getCustomersList = () => {
    //     var url = "/general/customer-list-filter";

    //     service(false, url).then((response) => {
    //         if (response.data.status_code === 200) {
    //             let temp_options = []
    //             temp_options.push({ label: "All", value: 0 })
    //             response.data.data.map((item) => {
    //                 return (
    //                     temp_options.push({
    //                         label: item.customer_name,
    //                         value: item.customer_id
    //                     })
    //                 )
    //             })
    //             setCustomerData(temp_options)
    //             setUserId(temp_options[0])
    //         }
    //     })
    // }

    /*------ reports list ------*/
    const _resportsList = (is_download) => {
        if (is_download) {
            setDownloadLoader(true)
        }
        else {
            setLoader(true);
        }
        let url = "/report/game-recheck";

        let data = {
            "game_id": gameId?.value,
            "from_date": startDate,
            "to_date": endDate,
            "page_num": page,
            "page_size": perPage,
            "is_download": is_download ? is_download : false,
            "game_type_id": gameType.value,
            "is_result_date": filterType.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                if (is_download) {
                    setDownloadLoader(false)
                    let a = document.createElement("a");
                    a.href = response.data.data.url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    setReportsData(response.data.data);
                    setTotal(response.data.number_of_records);
                    setTotals(response.data.total_data)
                    let remainder = response.data.number_of_records % perPage
                    let quotient = response.data.number_of_records / perPage
                    if (Number(remainder) > 0) {
                        setTotalLength(parseInt(Number(quotient) + 1))

                    } else {
                        setTotalLength(Number(quotient))
                    }
                    setLoader(false);
                    let temp_options = [];
                    temp_options.push({ label: "All", value: 0 })
                    response.data.game_ids.map((item) => {
                        return (
                            temp_options.push({
                                label: `Game id ${item}`,
                                value: item,
                            })
                        )
                    })
                    setPaginationDataReport({
                        rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                    })
                    setGameIds(temp_options)
                }
            } else {
                setReportsData([]);
                setError(response.data.message);
                setLoader(false);
            }
        });
    }

    /*------ reports list ------*/
    const _maxPayoutList = (temp, download) => {

        if (download) {
            setDownloadLoader(true)
        } else {
            setLoader(true);
        }
        if (tabIndex === 2) {
            //eslint-disable-next-line
            var url = "/report/max-payin";
        } else if (tabIndex === 3) {
            //eslint-disable-next-line
            var url = "/report/max-payout";
        } else if (tabIndex === 4) {
            //eslint-disable-next-line
            var url = "/report/max-booking-report";
        } else if (tabIndex === 5) {
            //eslint-disable-next-line
            var url = "/report/login-log";
        }

        var data = {
            "query": temp ? temp.query : search,
            "from_date": startDate,
            "to_date": endDate,
            "page_num": payoutPage,
            "page_size": payoutperPage,
            "is_download": download ? download : false

        }
        if (tabIndex === 4) {
            data.game_type_id = gameType.value
        }

        if (tabIndex === 5) {
            data.otp_type = otpType.value
        }


        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                if (download) {

                    let a = document.createElement("a");
                    a.href = response.data.data.url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setDownloadLoader(false)
                } else {
                    setPayoutData(response.data.data);
                    setPayoutTotal(response.data.number_of_records);
                    let remainder = response.data.number_of_records % perPage
                    let quotient = response.data.number_of_records / perPage
                    if (Number(remainder) > 0) {
                        setPayoutTotalLength(parseInt(Number(quotient) + 1))

                    } else {
                        setPayoutTotalLength(Number(quotient))
                    }
                    setLoader(false);
                    if (tabIndex === 2) {
                        setPaginationDataPayin({
                            rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                        })
                    } else if (tabIndex === 3) {
                        setPaginationDataPayout({
                            rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                        })
                    } else if (tabIndex === 4) {
                        setPaginationDataBooking({
                            rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                        })
                    } else {
                        setPaginationDataLog({
                            rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                        })
                    }
                }
            } else {
                setPayoutData([]);
                setError(response.data.message);
                setLoader(false);
            }
        });
    }

    const _reportsHistory = () => {
        setLoader(true)
        if (tabIndex === 7) {
            var url = "/report/blocked-states-history";
        } else if (tabIndex === 8) {
            //eslint-disable-next-line
            var url = "/report/holidays-history";
        } else if (tabIndex === 9) {
            //eslint-disable-next-line
            var url = "/report/special-trading-days-history";
        } else if (tabIndex === 10) {
            //eslint-disable-next-line
            var url = "/report/cancelled-game-history";
        } else if (tabIndex === 11) {
            //eslint-disable-next-line
            var url = "/report/game-status-history";
        }

        var data = {
            "from_date": startDate,
            "to_date": endDate,
            "page_num": reportPage,
            "page_size": reportperPage,
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setPlatformData(response.data.data);
                setReportTotal(response.data.number_of_records);
                let remainder = response.data.number_of_records % reportperPage
                let quotient = response.data.number_of_records / reportperPage
                if (Number(remainder) > 0) {
                    setReportTotalLength(parseInt(Number(quotient) + 1))

                } else {
                    setReportTotalLength(Number(quotient))
                }
                setPaginationPlatform({
                    rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                })
                setLoader(false);
            } else {
                setLoader(false);
                setPlatformData([]);
            }
        })
    }

    const _resultHistory = (download) => {
        if (download) {
            setDownloadLoader(true)
        } else {
            setLoader(true);
        }
        const url = "/report/results-history";

        const data = {
            "start_date": startDate,
            "end_date": endDate,
            "game_id": gameId.value,
            "frequency_id": 0,
            "user_id": userId.id,
            "game_type": gameType.value,
            "win_status": winningType.value,
            "is_download": download ? download : false,
            "page_num": resultPage,
            "page_size": resultperPage,
        }

        service(true, url, data).then((response) => {

            if (response.data.status_code === 200) {
                if (download) {
                    setDownloadLoader(false);
                    let a = document.createElement("a");
                    a.href = response.data.data.url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    // console.log(response.data.number_of_records)
                    setResultData(response.data.data);
                    setResultTotal(response.data.number_of_records);
                    let remainder = response.data.number_of_records % resultperPage
                    let quotient = response.data.number_of_records / resultperPage
                    if (Number(remainder) > 0) {
                        setResultTotalLength(parseInt(Number(quotient) + 1))

                    } else {
                        setResultTotalLength(Number(quotient))
                    }
                    setLoader(false);
                    let temp_options = [];
                    temp_options.push({ label: "All", value: 0 })
                    // response.data.game_ids.map((item) => {
                    //     return (
                    //         temp_options.push({
                    //             label: `Game id ${item}`,
                    //             value: item,
                    //         })
                    //     )
                    // })
                    setPaginationDataResult({
                        rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                    })

                    setGameIds(temp_options)
                }
            } else {
                setResultData([]);
                setError(response.data.message);
                setLoader(false);
            }
        })

    }

    const _customDateSort = (a, b, selector, given) => {
        let format = given ? given : "DD MMM YYYY"
        if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
        else if (a[selector]) return -1;
        else if (b[selector]) return 1;
        else return 0;
    }

    const _openDetail = (id) => {
        window.open(`/user-detail?id=${id}`, "_blank");
    }

    const COLUMNS = [
        {
            name: 'Game ID',
            selector: 'game_id',
            sortable: true,
            left: true,
            minWidth: "140px"
        },
        {
            name: 'Created date',
            cell: (row) =>
                <div>
                    {row.created_date}</div>,
            sortable: true,
            left: true,
            minWidth: "140px",
            sortFunction: (a, b) => _customDateSort(a, b, 'created_date'),
        },
        {
            name: 'Result date',
            cell: (row) =>
                <div>
                    {row.result_date}</div>,
            sortable: true,
            left: true,
            minWidth: "140px",
            sortFunction: (a, b) => _customDateSort(a, b, 'result_date'),
        },

        {
            name: 'Gainer booking amount',
            selector: 'gainer_booking_amt',
            cell: (row) => <div>₹{row.gainer_booking_amt}</div>,
            sortable: true,
            left: true,
            minWidth: "220px",
        },
        {
            name: 'Loser booking amount',
            selector: 'loser_booking_amt',
            cell: (row) => <div>₹{row.loser_booking_amt}</div>,
            sortable: true,
            left: true,
            minWidth: "220px",
        },
        {
            name: 'Total booking amount',
            selector: 'total_booking_amt',
            cell: (row) => <div>₹{row.total_booking_amt}</div>,
            sortable: true,
            left: true,
            minWidth: "200px",
        },
        {
            name: 'Gainer price amount',
            selector: 'gainer_price_amt',
            cell: (row) => <div>₹{row.gainer_price_amt}</div>,
            sortable: true,
            left: true,
            minWidth: "200px",
        },
        {
            name: 'Loser price amount',
            selector: 'loser_price_amt',
            cell: (row) => <div>₹{row.loser_price_amt}</div>,
            sortable: true,
            left: true,
            minWidth: "200px",
        },
        {
            name: 'Total price amount',
            selector: 'total_price_amt',
            cell: (row) => <div>₹{row.total_price_amt}</div>,
            sortable: true,
            left: true,
            minWidth: "200px",
        },
        {
            name: 'Non forecaster amount',
            selector: 'non_forcaster_amt',
            cell: (row) => <div>₹{row.non_forcaster_amt}</div>,
            sortable: true,
            left: true,
            minWidth: "200px",
        },
        {
            name: 'Gainer TDS',
            selector: 'gainer_tds',
            cell: (row) => <div>{row.gainer_tds}</div>,
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Loser TDS',
            selector: 'loser_tds',
            cell: (row) => <div>{row.loser_tds}</div>,
            sortable: true,
            left: true,
            minWidth: "200px",
        },
        {
            name: 'Total TDS',
            selector: 'total_tds',
            cell: (row) => <div>{row.total_tds}</div>,
            sortable: true,
            left: true,
            minWidth: "200px",
        },
        {
            name: 'Gainer platform fee',
            selector: 'gainer_platform_fee',
            cell: (row) => <div>₹{row.gainer_platform_fee}</div>,
            sortable: true,
            left: true,
            minWidth: "200px",
        },
        {
            name: 'Loser platform fee',
            selector: 'loser_price_amt',
            cell: (row) => <div>₹{row.loser_platform_fee}</div>,
            sortable: true,
            left: true,
            minWidth: "200px",
        },
        {
            name: 'Total platform fee',
            selector: 'total_platform_fee',
            cell: (row) => <div>₹{row.total_platform_fee}</div>,
            sortable: true,
            left: true,
            minWidth: "200px",
        },
    ]

    const RESULT_COLUMNS = [
        {
            name: 'Customer ID',
            selector: 'client_id',
            sortable: true,
            left: true,
            minWidth: "140px"
        },
        {
            name: 'Name',
            // selector: 'name',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row => <div className='e-name-link' onClick={() => _openDetail(row.customer_id)}>{row.name}</div>,
        },
        {
            name: 'Email',
            selector: 'email',
            cell: row => row.email,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Phone',
            selector: row => row.mobile,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Game ID',
            selector: 'game_id',
            sortable: true,
            left: true,
            minWidth: "140px"
        },

        {
            name: 'Result date',
            cell: (row) =>
                <div>
                    {row.result_date_time}</div>,
            sortable: true,
            left: true,
            minWidth: "140px",
            // sortFunction: (a, b) => _customDateSort(a, b, 'result_date'),
        },
        {
            name: 'Booking amount',
            selector: 'booking_amount',
            cell: (row) => <div>₹{row.booking_amount}</div>,
            sortable: true,
            left: true,
            minWidth: "160px",
        },
        {
            name: 'P&L',
            selector: 'pandl',
            cell: (row) => <div>₹{row.pandl}</div>,
            sortable: true,
            left: true,
            minWidth: "120px",
        },

        {
            name: 'Rewards',
            selector: 'rewards',
            cell: (row) => <div>₹{row.rewards}</div>,
            sortable: true,
            left: true,
            minWidth: "120px",
        },
        {
            name: 'Game status',
            cell: (row) =>
                <div>
                    {row.game_status}</div>,
            sortable: true,
            left: true,
            minWidth: "140px",
            // sortFunction: (a, b) => _customDateSort(a, b, 'created_date'),
        },

    ]


    //user list columns
    const PAY_COLUMNS = [


        {
            name: 'Client ID',
            selector: "id",
            sortable: true,
            left: true,
            cell: row => row.client_id,
            maxWidth: "130px",
        },
        {
            name: 'Name',
            // selector: 'name',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row => <div className='e-name-link' onClick={() => _openDetail(row.user_id)}>{row.name}</div>,
        },

        {
            name: 'Email',
            selector: "email",
            cell: row => row.email,
            sortable: false,
            right: false,
            minWidth: "220px",
        },
        // {
        // 	name: 'Platform fee',
        // 	selector: 'platform_fee',
        // 	sortable: true,
        // 	right: false,
        // 	// maxWidth: "120px",
        // },
        {
            name: 'Phone',
            selector: row => row.mobile,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Transaction amount',
            selector: 'transaction_amount',
            minWidth: "180px",
            sortable: true,
            cell: row => <>
                ₹{
                    row.transaction_amount
                }
            </>
        },
        {
            name: 'No. of transactions',
            selector: 'no_of_transaction',
            minWidth: "180px",
            sortable: true,
        },
        {
            name: 'Date of joining',
            selector: 'doj',
            sortable: true,
            left: true,
            cell: row => row.doj,
            minWidth: "150px",
            sortFunction: (a, b) => _customDateSort(a, b, 'doj')
        },
        {
            name: 'Balance',
            selector: 'balance',
            cell: row => <>
                ₹{
                    row.balance
                }
            </>,
            minWidth: "150px"
        }

    ];

    const HISTORY_COLUMNS = [
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            left: true,
            cell: row => row.date,
            minWidth: "150px",
            sortFunction: (a, b) => _customDateSort(a, b, 'date')
        },
        {
            name: 'Created',
            selector: 'created',
            sortable: false,
            left: true,
            cell: row => row.created,
            minWidth: "150px",
        },
        {
            name: 'Modified',
            selector: 'modified',
            sortable: false,
            left: true,
            cell: row => row.modified,
            minWidth: "150px",
        },
        {
            name: 'Modified by',
            selector: 'modified_by',
            sortable: false,
            left: true,
            cell: row => row.modified_by,
            minWidth: "150px",
            omit: localStorage.getItem("type") === "1" ? false : true
        },
        {
            name: 'Remark',
            selector: 'remark',
            sortable: false,
            left: true,
            cell: row => row.remark,
            minWidth: "150px",
        },
        {
            name: 'Active',
            selector: 'active',
            sortable: false,
            left: true,
            cell: row => row.active ? 'YES' : 'NO',
            minWidth: "150px",
        },

    ];

    const TRADING_COLUMNS = [
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            left: true,
            cell: row => row.name,
            minWidth: "150px",
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            left: true,
            cell: row => row.date,
            minWidth: "150px",
            sortFunction: (a, b) => _customDateSort(a, b, 'date')
        },
        {
            name: 'Start time',
            selector: 'start_time',
            sortable: false,
            left: true,
            cell: row => row.start_time,
            minWidth: "150px",
        },
        {
            name: 'End time',
            selector: 'end_time',
            sortable: false,
            left: true,
            cell: row => row.end_time,
            minWidth: "150px",
        },
        {
            name: 'Created',
            selector: 'created',
            sortable: false,
            left: true,
            cell: row => row.created,
            minWidth: "150px",
        },
        {
            name: 'Modified',
            selector: 'modified',
            sortable: false,
            left: true,
            cell: row => row.modified,
            minWidth: "150px",
        },
        {
            name: 'Modified by',
            selector: 'modified_by',
            sortable: false,
            left: true,
            cell: row => row.modified_by,
            minWidth: "150px",
            omit: localStorage.getItem("type") === "1" ? false : true
        },
        {
            name: 'Active',
            selector: 'active',
            sortable: false,
            left: true,
            cell: row => row.active ? 'YES' : 'NO',
            minWidth: "150px",
        },
    ];

    const BLOCKED_COLUMNS = [
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            left: true,
            cell: row => row.name,
            minWidth: "150px",
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            left: true,
            cell: row => row.date,
            minWidth: "150px",
            sortFunction: (a, b) => _customDateSort(a, b, 'date')
        },
        {
            name: 'Created',
            selector: 'created',
            sortable: false,
            left: true,
            cell: row => row.created,
            minWidth: "150px",
        },
        {
            name: 'Unblocked at',
            selector: 'unblocked_at',
            sortable: false,
            left: true,
            cell: row => row.unblocked_at,
            minWidth: "150px",

        },
        {
            name: 'Modified by',
            selector: 'modified_by',
            sortable: false,
            left: true,
            cell: row => row.modified_by,
            minWidth: "150px",
            omit: localStorage.getItem("type") === "1" ? false : true
        },
        {
            name: 'Active',
            selector: 'active',
            sortable: false,
            left: true,
            cell: row => row.active ? 'YES' : 'NO',
            minWidth: "150px",
        },
    ];

    //booking columns
    const BOOKING_COLUMNS = [


        {
            name: 'Client ID',
            selector: "id",
            sortable: true,
            left: true,
            cell: row => row.client_id,
            maxWidth: "130px",
        },
        {
            name: 'Name',
            // selector: 'name',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row => <div className='e-name-link' onClick={() => _openDetail(row.user_id)}>{row.name}</div>,
        },
        {
            name: 'Email',
            selector: "email",
            cell: row => row.email,
            sortable: false,
            right: false,
            minWidth: "220px",
        },
        {
            name: 'Phone',
            selector: row => row.mobile,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'No. of games played',
            selector: 'games_played',
            sortable: true,
            right: false,
            cell: row => row.games_played,
            minWidth: '180px'
            // maxWidth: "120px",
        },
        {
            name: 'Booking amount',
            selector: row => Number(row.booking_amt),
            sortable: true,
            right: false,
            minWidth: "160px",
            cell: row => "₹" + row.booking_amt,
        },
        {
            name: 'No. of entries',
            selector: row => row.sr_entries_count,
            sortable: false,
            right: false,
            minWidth: "160px",
            omit: gameType.value === 3 || gameType.value === 0 ? false : true
        },
        {
            name: 'Gainer qty',
            selector: row => row.gainer_qty,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Loser qty',
            selector: row => row.loser_qty,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Gainer win qty',
            selector: row => row.gainer_win_qty,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Loser win qty',
            selector: row => row.loser_win_qty,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Platform fee',
            selector: 'plateform_fess',
            sortable: true,
            right: false,
            cell: row => "₹" + row.plateform_fess,
            minWidth: '160px'
            // maxWidth: "120px",
        },
        {
            name: 'Winning amount',
            selector: row => Number(row.winning_amt),
            sortable: true,
            right: false,
            // minWidth: "140px",
            cell: row => "₹" + row.winning_amt,
            minWidth: '180px'
        },
        {
            name: 'TDS',
            selector: 'tds',
            sortable: true,
            right: false,
            // maxWidth: "120px",
        },
        {
            name: 'P&L',
            selector: 'p&l',
            sortable: true,
            right: false,
            cell: row => "₹" + row.pl_amt,
            minWidth: '180px'
            // maxWidth: "120px",
        },


    ];

    //cancelled game columns
    const CANCELLED_GAME_COLUMNS = [
        {
            name: 'Game',
            selector: row => row.game,
            sortable: false,
            right: false,
            maxWidth: "120px",
        },
        {
            name: 'Game date',
            selector: 'game_date',
            sortable: false,
            right: false,
            cell: row => row.game_date,
            minWidth: '180px',
            maxWidth: "180px",
        },
        {
            name: 'Game ID',
            selector: "id",
            sortable: true,
            left: true,
            cell: row => row.custom_game_id,
            maxWidth: "150px",
        },
        {
            name: 'Slot',
            sortable: true,
            right: false,
            minWidth: "220px",
            maxWidth: "220px",
            cell: row => <>
                {`${row.slot_start_time} - ${row.slot_end_time}`}
            </>,
        },

        {
            name: 'Cancelled at',
            selector: row => row.cancelled_at,
            sortable: false,
            right: false,
            minWidth: "220px",
            maxWidth: "220px",
        },


    ];

    //game status columns
    const GAME_STATUS_COLUMNS = [
        {
            name: 'Game',
            selector: row => row.game,
            sortable: false,
            right: false,
            maxWidth: "120px",
        },
        {
            name: 'Game date',
            selector: 'game_date',
            sortable: false,
            right: false,
            cell: row => row.game_date,
            minWidth: '180px',
            maxWidth: "180px",
        },
        {
            name: 'Created',
            selector: 'created',
            sortable: false,
            right: false,
            cell: row => row.created,
            minWidth: '220px',
            maxWidth: "220px",
        },
        {
            name: 'Game ID',
            selector: "id",
            sortable: true,
            left: true,
            cell: row => row.custom_game_id,
            maxWidth: "130px",
        },

        {
            name: 'Slot',
            sortable: true,
            right: false,
            minWidth: "220px",
            maxWidth: "220px",
            cell: row => <>
                {`${row.slot_start_time} - ${row.slot_end_time}`}
            </>,
        },

        {
            name: 'Status',
            selector: row => row.status,
            sortable: false,
            right: false,

        },


    ];

    //user list columns
    const LOG_COLUMNS = [


        {
            name: 'Client ID',
            selector: "id",
            sortable: true,
            left: true,
            cell: row => row.client_id,
            maxWidth: "130px",
        },
        {
            name: 'Name',
            // selector: 'name',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row => <div className='e-name-link' onClick={() => _openDetail(row.user_id)}>{row.name}</div>,
        },
        {
            name: 'Email',
            selector: 'email',
            cell: row => row.email,
            sortable: false,
            right: false,
            minWidth: "160px",

        },
        {
            name: 'Phone',
            selector: row => row.mobile,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'OTP type',
            selector: 'otp_type',
            cell: row => row.otp_type,
            sortable: false,
            right: false,
            minWidth: "160px",

        },
        {
            name: 'Email OTP',
            selector: 'email_otp',
            cell: row => row.email_otp,
            sortable: false,
            right: false,
            minWidth: "160px",

        },
        {
            name: 'Mobile OTP',
            selector: row => row.mobile_otp,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'IP',
            selector: 'ip',
            cell: row => row.ip,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'State',
            selector: 'state',
            cell: row => row.state,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Last login',
            sortable: true,
            right: false,
            minWidth: "220px",
            cell: row => row.last_login_time,
            sortFunction: (a, b) => _customDateSort(a, b, 'last_login_time')
        },
    ];

    return (
        <div className='e-users-container e-pagination'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className="row pt-4 ">

                            <div className="col-lg-12 col-12  pt-2 ps-4 mb-3 pe-0">
                                <GameTabs
                                    index={tabIndex}
                                    data={[
                                        'Game Recheck',
                                        'M.Payin',
                                        'M.Payout',
                                        'M.Booking',
                                        'Login log',
                                        'Result',
                                        'Blocked states',
                                        'Holidays',
                                        'Special trading days',
                                        "Cancelled game",
                                        'Game status'
                                    ]}
                                    setTab={(temp) => {
                                        setTabindex(temp);
                                        setStartDate(null);
                                        setEndDate(null);
                                        setPayoutPage(1);
                                        setPayoutPerPage(200);
                                        setSearch("");
                                        setStartDate(null);
                                        setEndDate(null);
                                        setFilterDown(false)
                                        setPage(1);
                                        setPerPage(200);
                                        setGameType(GAME_OPTIONS[0]);
                                        setDownloadLoader(false);
                                        setReportPage(1);
                                        setReportPerPage(200);
                                        setOtpType(OTPTYPE_OPTIONS[0]);
                                        setFrequencyId(FREQUENCY_OPTIONS[0]);
                                        setFilterType(DATE_FILTER_OPTIONS);

                                    }} />
                            </div>
                            <div className='col-lg-6 mt-2 ps-4'></div>



                            {
                                tabIndex !== 1 && tabIndex <= 5 ?
                                    <div className='col-lg-3 position-relative ps-4'>
                                        <SearchInput
                                            placeholder="Name/ client id"
                                            value={search}
                                            onSearch={(value) => {
                                                _maxPayoutList({ query: value })
                                            }}
                                            valueChange={(value) => {
                                                setSearch(value);
                                                if (value === "") {
                                                    if (tabIndex !== 1 && tabIndex < 6) {
                                                        _maxPayoutList({ query: "" })
                                                    }
                                                }
                                            }}
                                        />
                                        {
                                            search && search.length ?
                                                <>
                                                    <span className={`${styles.e_search_submit} `} onClick={() => _maxPayoutList()} ></span>
                                                    <button className={`${styles.e_search_close} pe-3`} onClick={() => {
                                                        setSearch("");
                                                        _maxPayoutList({ query: "" })
                                                    }} ></button>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                    :
                                    <div className='col-lg-3'></div>
                            }


                            {/* <div className='col-lg-6'></div> */}
                            <div className={tabIndex === 1 ? "col-lg-2 col-4 px-0 text-end " : "col-lg-2 col-4 px-0 text-end"}>
                                {
                                    tabIndex > 6 ?
                                        null :

                                        <DownloadBtn
                                            check={["a"]}
                                            name={"Download report"}
                                            className={downloadLoader ? "e-disabled" : ""}
                                            loaderClass={downloadLoader ? "e-bounce" : ""}
                                            onClick={() => {
                                                if (tabIndex === 6) {
                                                    _resultHistory(true)
                                                } else {
                                                    if (tabIndex === 1) {
                                                        _resportsList(true)
                                                    } else {
                                                        _maxPayoutList({ query: search }, true)
                                                    }
                                                    // const csvData = convertArrayOfObjectsToCSV(check)
                                                    // downloadCSV(csvData)
                                                }
                                            }}
                                        />

                                }
                            </div>
                            <div className="col-lg-1 col-1">
                                <FilterBtn
                                    className={
                                        filterDown ?
                                            "e-filter-btn e-active"
                                            :
                                            "e-filter-btn"
                                    }
                                    handleClick={() => {
                                        setFilterDown(!filterDown)
                                    }}
                                />
                            </div>
                            {
                                filterDown ?
                                    <div className='row pe-0'>
                                        <div className='col-lg-12 px-0'>
                                            <div className='e-filter-wraper mt-3'>
                                                <div className='col-lg-12 '>
                                                    <div className='row'>
                                                        {
                                                            tabIndex === 1 ?
                                                                <div className='col-lg-3'>
                                                                    <label className='mb-2'>Game id</label>
                                                                    <SelectBox
                                                                        isSearchable={true}
                                                                        className="me-2 e-bookings-sort e-scroll"
                                                                        defaultValue={gameIds[0]}
                                                                        options={gameIds}
                                                                        selectchange={(temp) => {
                                                                            setGameId(temp)
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            tabIndex === 1 || tabIndex === 4 || tabIndex === 6 ?
                                                                <div className='col-lg-2'>
                                                                    <label className='mb-2'>Game type</label>
                                                                    <SelectBox
                                                                        isSearchable={false}
                                                                        className="me-2 e-bookings-sort e-scroll"
                                                                        value={gameType}
                                                                        options={GAME_OPTIONS}
                                                                        selectchange={(temp) => {
                                                                            setGameType(temp)
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            tabIndex === 6 ?
                                                                <>
                                                                    <div className='col-lg-2'>
                                                                        <label className='mb-2'>Winning type</label>
                                                                        <SelectBox
                                                                            isSearchable={false}
                                                                            className="me-2 e-bookings-sort e-scroll"
                                                                            value={winningType}
                                                                            options={WINNING_OPTIONS}
                                                                            selectchange={(temp) => {
                                                                                setWinningType(temp)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-lg-2'>
                                                                        <label className='mb-2'>Customer</label>
                                                                        {/* <SelectBox
                                                                            isSearchable={true}
                                                                            className="me-2 e-bookings-sort e-scroll"
                                                                            value={userId}
                                                                            options={customerData}
                                                                            selectchange={(temp) => {
                                                                                setUserId(temp)
                                                                            }}
                                                                        /> */}
                                                                        <Search
                                                                            value={Object.keys(userId).length === 0 ? {} : userId}
                                                                            search={searchuser}
                                                                            setSearch={(temp) => {
                                                                                setSearchuser(temp);
                                                                                if (temp === "") {
                                                                                    setUserId({});
                                                                                }
                                                                            }}
                                                                            userDetail={(temp) => setUserId(temp)} />
                                                                    </div>
                                                                    <div className='col-lg-2'>
                                                                        <label className='mb-2'>Game id</label>
                                                                        <SelectBox
                                                                            isSearchable={true}
                                                                            className="me-2 e-bookings-sort e-scroll"
                                                                            defaultValue={gameIds[0]}
                                                                            options={gameIds}
                                                                            selectchange={(temp) => {
                                                                                setGameId(temp)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {
                                                                        gameType.value === 4 ?
                                                                            <div className='col-lg-2'>
                                                                                <label className='mb-2'>Frequency</label>
                                                                                <SelectBox
                                                                                    isSearchable={true}
                                                                                    className="me-2 e-bookings-sort e-scroll"
                                                                                    value={frequencyId}
                                                                                    options={FREQUENCY_OPTIONS}
                                                                                    selectchange={(temp) => {
                                                                                        setFrequencyId(temp)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <div className='col-lg-3'>
                                                            <label className='mb-2'>{
                                                                tabIndex === 1 ?
                                                                    filterType.value === false ?
                                                                        "Game date"
                                                                        :
                                                                        "Result date"
                                                                    :
                                                                    tabIndex === 2 ?
                                                                        gameType.value === 1 ?
                                                                            "Result date"
                                                                            :
                                                                            "Game date"
                                                                        :
                                                                        tabIndex === 5 ?
                                                                            "Last login date"
                                                                            :
                                                                            tabIndex === 6 ?
                                                                                "Result date"
                                                                                :
                                                                                tabIndex === 8 ?
                                                                                    "Holiday date"
                                                                                    :
                                                                                    tabIndex === 3 || tabIndex === 2 ?
                                                                                        "Transactions from-till"
                                                                                        :
                                                                                        "Date"
                                                            }</label>
                                                            <Rangepicker
                                                                placeholder={['From', 'To']}
                                                                start={startDate}
                                                                end={endDate}
                                                                disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                onDatesChange={(start, end) => {
                                                                    setStartDate(start);
                                                                    setEndDate(end);
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            gameType.value === 1 ?
                                                                <div className='col-lg-3'>
                                                                    <label className='mb-2'>Date filter type</label>
                                                                    <SelectBox
                                                                        options={DATE_FILTER_OPTIONS}
                                                                        value={filterType}
                                                                        isSearchable={false}
                                                                        className="me-2 e-bookings-sort e-scroll"
                                                                        selectchange={(temp) => {
                                                                            setFilterType(temp);
                                                                            setStartDate(null);
                                                                            setEndDate(null);
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            tabIndex === 5 ?
                                                                <div className='col-lg-3'>
                                                                    <label className='mb-2'>Otp type</label>
                                                                    <SelectBox
                                                                        options={OTPTYPE_OPTIONS}
                                                                        value={otpType}
                                                                        isSearchable={false}
                                                                        className="me-2 e-bookings-sort e-scroll"
                                                                        selectchange={(temp) => {
                                                                            setOtpType(temp)
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className="col-lg-12 e-booking-table pt-3">
                                {
                                    tabIndex === 1 ?
                                        loader ?
                                            <Loader />
                                            :
                                            reportsData.length > 0 ?
                                                <>
                                                    <DataTable
                                                        pagination={true}
                                                        columns={COLUMNS}
                                                        data={reportsData}
                                                        // selectableRows={true}
                                                        paginationTotalRows={total}
                                                        paginationComponentOptions={paginationDataReport}
                                                        // selectRows={(row) => {
                                                        //     handleChange(row,);
                                                        // }}
                                                        onChangeRowsPerPage={(p) => {
                                                            setPage(1)
                                                            setPerPage(p)
                                                        }}
                                                        size={perPage}
                                                        paginationIconNext={totalLength === page ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setPage(page + 1)} style={{ cursor: "pointer" }} />}
                                                        paginationIconPrevious={page === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPage(page - 1) }} />}
                                                        clearSelectedRows={downloadLoader}
                                                    />
                                                    <div className='col-lg-12 text-end pe-3'>
                                                        <p>Page no : <span style={{ fontWeight: "600" }}>{page}</span></p>
                                                    </div>
                                                    <div className='row px-3 pb-3 pt-4'>

                                                        <div className='col-lg-2'>
                                                            <h6>Total gainer booking amount</h6>
                                                            <p>₹{totals?.total_gainer_booking_amt}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total loser booking amount</h6>
                                                            <p>₹{totals?.total_loser_booking_amt}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total booking amount</h6>
                                                            <p>₹{totals?.total_booking_amt}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total gainer price amount</h6>
                                                            <p>₹{totals?.total_gainer_price_amt}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total loser price amount</h6>
                                                            <p>₹{totals?.total_loser_price_amt}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total price amount</h6>
                                                            <p>₹{totals?.total_price_amt}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Non forecaster amount</h6>
                                                            <p>₹{totals?.total_non_forcaster_amt}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total gainer tds</h6>
                                                            <p>{totals?.total_gainer_tds}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total loser tds</h6>
                                                            <p>{totals?.total_loser_tds}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total tds</h6>
                                                            <p>{totals?.total_tds}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total gainer platform fee</h6>
                                                            <p>₹{totals?.total_gainer_platform_fee}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total loser platform fee</h6>
                                                            <p>₹{totals?.total_loser_platform_fee}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total platform fees </h6>
                                                            <p>₹{totals?.total_platform_fee}</p>
                                                        </div>

                                                    </div>
                                                </>
                                                :
                                                <EmptyNote img={Nodara} data="No data found" />
                                        :
                                        tabIndex === 6 ?
                                            loader ?
                                                <Loader />
                                                :
                                                resultData.length > 0 ?
                                                    <>
                                                        <DataTable
                                                            pagination={true}
                                                            columns={RESULT_COLUMNS}
                                                            data={resultData}
                                                            selectableRows={false}
                                                            paginationTotalRows={resulttotal}
                                                            paginationComponentOptions={paginationDataResult}
                                                            // selectRows={(row) => {
                                                            //     handleChange(row,);
                                                            // }}
                                                            onChangeRowsPerPage={(p) => {
                                                                setResultPage(1)
                                                                setResultPerPage(p)
                                                            }}
                                                            size={resultperPage}
                                                            paginationIconNext={resulttotalLength === resultPage ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setResultPage(resultPage + 1)} style={{ cursor: "pointer" }} />}
                                                            paginationIconPrevious={resultPage === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setResultPage(resultPage - 1) }} />}
                                                        // clearSelectedRows={downloadLoader}
                                                        />
                                                        <div className='col-lg-12 text-end pe-3'>
                                                            <p>Page no : <span style={{ fontWeight: "600" }}>{resultPage}</span></p>
                                                        </div>
                                                    </>
                                                    :
                                                    <EmptyNote img={Nodara} data="No data found" />
                                            :
                                            tabIndex < 6 && tabIndex > 1 ?
                                                loader ?
                                                    <Loader />
                                                    :
                                                    payoutData.length > 0 ?
                                                        <>
                                                            <DataTable
                                                                pagination={true}
                                                                columns={tabIndex === 5 ? LOG_COLUMNS : tabIndex === 4 ? BOOKING_COLUMNS : PAY_COLUMNS}
                                                                data={payoutData}
                                                                // selectableRows={true}
                                                                paginationTotalRows={payouttotal}
                                                                paginationComponentOptions={tabIndex === 5 ? paginationDataLog : tabIndex === 4 ? paginationDataBooking : tabIndex === 3 ? paginationDataPayout : paginationDataPayin}
                                                                // selectRows={(row) => {
                                                                //     handleChangePayout(row);
                                                                // }}
                                                                onChangeRowsPerPage={(p) => {
                                                                    setPayoutPage(1)
                                                                    setPayoutPerPage(p)
                                                                }}
                                                                size={payoutperPage}
                                                                paginationIconNext={payouttotalLength === payoutPage ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setPayoutPage(payoutPage + 1)} style={{ cursor: "pointer" }} />}
                                                                paginationIconPrevious={payoutPage === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPayoutPage(payoutPage - 1) }} />}
                                                                clearSelectedRows={downloadLoader}
                                                            />
                                                            <div className='col-lg-12 text-end pe-3'>
                                                                <p>Page no : <span style={{ fontWeight: "600" }}>{payoutPage}</span></p>
                                                            </div>
                                                        </>
                                                        :
                                                        <EmptyNote img={Nodara} data="No data found" />
                                                :
                                                loader ?
                                                    <Loader />
                                                    :
                                                    platformData.length > 0 ?
                                                        <>
                                                            <DataTable
                                                                pagination={true}
                                                                columns={tabIndex === 11 ? GAME_STATUS_COLUMNS : tabIndex === 10 ? CANCELLED_GAME_COLUMNS : tabIndex === 7 ? BLOCKED_COLUMNS : tabIndex === 8 ? HISTORY_COLUMNS : TRADING_COLUMNS}
                                                                data={platformData}
                                                                // selectableRows={true}
                                                                paginationTotalRows={reportTotal}
                                                                paginationComponentOptions={paginationPlatform}
                                                                // selectRows={(row) => {
                                                                //     handleChangePayout(row);
                                                                // }}
                                                                onChangeRowsPerPage={(p) => {
                                                                    setReportPage(1)
                                                                    setReportPerPage(p)
                                                                }}
                                                                size={reportperPage}
                                                                paginationIconNext={reporttotalLength === reportPage ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setReportPage(reportPage + 1)} style={{ cursor: "pointer" }} />}
                                                                paginationIconPrevious={reportPage === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setReportPage(reportPage - 1) }} />}
                                                                clearSelectedRows={downloadLoader}
                                                            />
                                                            <div className='col-lg-12 text-end pe-3'>
                                                                <p>Page no : <span style={{ fontWeight: "600" }}>{reportPage}</span></p>
                                                            </div>
                                                        </>
                                                        :
                                                        <EmptyNote img={Nodara} data="No data found" />

                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Index;
