/*
 *   File : datepicker.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Datepicker
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-01-2022
 */

/*import package*/
import { DatePicker } from 'antd';
import 'antd/dist/antd.min.css';
import moment from 'moment';
import { useState, useEffect } from 'react';

/*import style*/
// import styles from '../../styles/components/datepicker.module.scss';

// import Icon from '../../assets/images/input/calendar.svg';

const Rangepicker = (props) => {
    const { RangePicker } = DatePicker;
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {
        if (props.start) {
            let tempDate = moment(props.start, props.format ? props.format : dateFormat);
            setStart(tempDate);
        }
        else {
            setStart(null);
        }
        if (props.end) {
            let tempDate = moment(props.end, props.format ? props.format : dateFormat);
            setEnd(tempDate);
        }
        else {
            setEnd(null);
        }
        //eslint-disable-next-line
    }, [props.start, props.end])

    const handleChange = (date, dateString) => {

        if (dateString[0] !== '' && dateString[1] !== '') {
            var tempStart = moment(date[0]).format(props.format ? props.format : 'YYYY-MM-DD');
            var tempEnd = moment(date[1]).format(props.format ? props.format : 'YYYY-MM-DD');
            props.onDatesChange(tempStart, tempEnd);
        }
        else {
            props.onDatesChange(null, null);
        }
    }


    return (
        <div className='e-ant-range-picker' ref={props.ref}>
            <RangePicker
                placeholder={props.placeholder}
                format={props.format ? props.format : dateFormat}
                disabledDate={props.disabledDate}
                value={[start, end]}
                // minDate={moment().subtract(500, "years")}
                // maxDate={moment().subtract(18, "years")}
                onChange={(date, dateString) => { handleChange(date, dateString) }}
                // disabledDate={disabledDate}
                className={props.className}
                onKeyDown={e => e.preventDefault()}
            />
        </div>
    );
}

export default Rangepicker;