/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakcages*/
import { useState, useEffect } from 'react';
import moment from 'moment';

/*import components*/
import { ProgressBar } from '../../components/dataFormating/index';
import Loader from '../../components/loader/loader';
import { EmptyNote } from '../../components/note';
import Datatable from '../../components/dataFormating/dataTable';

/* import services */
import service from '../../services/service';

/*import assets*/
import Nodata from '../../assets/images/note/no-data.png'

const Milestones = (props) => {
    //eslint-disable-next-line
    const [milestoneData, setMilestoneData] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        getMileStones();
        //eslint-disable-next-line
    }, []);

    const customDateSort = (a, b, selector, given) => {
        let format = given ? given : "DD MMM YYYY"
        if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
        else if (a[selector]) return -1;
        else if (b[selector]) return 1;
        else return 0;
    }

    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            minWidth: '180px',
            cell: row => <>
                {row.name}
            </>
        },
        {
            name: 'Type',
            selector: 'game_type',
            sortable: false
        },
        {
            name: 'Points',
            selector: 'points',
            sortable: true
        },
        {
            name: 'Required target points',
            selector: 'required_target_points',
            sortable: true,
            minWidth: '220px'
        },
        {
            name: 'Reward',
            selector: 'reward',
            sortable: true,
            cell: row => <>₹{row.reward}</>
        },
        {
            name: 'Start date',
            selector: 'start_date',
            sortable: false,
            sortFunction: (a, b) => customDateSort(a, b, 'start_date'),
        },
        {
            name: 'End date',
            selector: 'end_date',
            sortable: false,
            sortFunction: (a, b) => customDateSort(a, b, 'end_date'),
        },
        {
            name: 'Status',
            selector: 'win_status',
            sortable: false,
            cell: row => <>{
                row.win_status ?
                    "Yes"
                    :
                    "No"
            }
            </>
        },
    ]
    //mile stone list
    const getMileStones = () => {
        setLoader(true)
        let url = "/user/milestone-history"

        let data = {
            user_id: Number(props.id)
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false)
                setMilestoneData(response.data.data)
            } else {
                setMilestoneData({})
                setLoader(false)
            }
        })
    }
    return (
        <div className='e-users-container'>
            <div className="card e-dashboard-card border-0 ">
                <div className="row px-0">

                    <div className="col-lg-12 p-4 mb-0 pb-2">
                        <h6 className="e-card-title">Active milestones</h6>
                    </div>
                    {
                        loader ?
                            <Loader />
                            :
                            Object.values(milestoneData).length > 0 ?
                                <>
                                    <div className='col-lg-12'>
                                        <div className='e-milestone-wrapper mb-4 mx-2'>
                                            <div className='row'>
                                                {
                                                    milestoneData && milestoneData.active_milestones && milestoneData.active_milestones.length > 0 ?
                                                        <>

                                                            {
                                                                milestoneData.active_milestones.map((item, key) => {
                                                                    return (
                                                                        <div className='col-lg-6 mb-2 pb-3'>
                                                                            <div className='row'>
                                                                                <div className='col-lg-8 d-flex'>
                                                                                    <h5>{item.name} </h5>
                                                                                    <p className='ps-2'>{`(${item.game_type})`}</p>
                                                                                </div>
                                                                                <div className='col-lg-4 text-end'>
                                                                                    <p>Reward: <span>₹{item.reward}</span></p>
                                                                                </div>
                                                                                <div className='col-lg-12'>
                                                                                    <div className='e-progress-scroll'>
                                                                                        <ProgressBar percent={item.points / item.required_target_points * 100} id={key + 1} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-lg-8'>
                                                                                    <p className='e-acheivements pt-2'>Required target points</p>
                                                                                </div>
                                                                                <div className='col-lg-4 text-end'>
                                                                                    <p className='e-count pt-2'>{item.points + "/" + item.required_target_points}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <EmptyNote img={Nodata} data="No milestones found" />
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    {
                                        milestoneData && milestoneData?.completed_milestones && milestoneData?.completed_milestones.length > 0 ?
                                            <>
                                                <div className="col-lg-12 ps-4 pt-2">
                                                    <h6 className="e-card-title">Completed milestones</h6>
                                                </div>
                                                <Datatable
                                                    data={milestoneData?.completed_milestones}
                                                    columns={columns} />
                                            </>
                                            :
                                            null
                                    }


                                </>
                                :
                                <EmptyNote img={Nodata} data="No milestones found" />
                    }
                </div>
            </div>
        </div>

    )
}

export default Milestones;
