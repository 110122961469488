/*
 *   File : blocked-users.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Blocked users;
 *   Integrations :
 *   Version : v1.0
 *   Created : 21-02-2022
 */
/*import pakcages*/
import { useState } from 'react';


/*import components*/
import { DataTable } from '../../../components/dataFormating/index';
import { SearchInput } from '../../../components/input/index';
import { DownloadBtn } from '../../../components/cta/index';

/*import styles*/
import styles from '../../../styles/components/searchInput.module.scss';

const BlockedUsers = (props) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  const [search, setSearch] = useState();
  const [check, setCheck] = useState();

  const data = [
    { id: 'EC31', name: 'Lance Oconnor', blocked_on: '12 Jun 2020', time: '5.00 PM', block_code: 'BL2', unblock_in: '10', action: 'Unblock', remarks: 'Add remark' },
    { id: 'EC32', name: 'Dan Jackson', blocked_on: '12 Jun 2020', time: '5.00 PM', block_code: 'BL5', unblock_in: '15', action: 'Unblock', remarks: 'Add remark' },
    { id: 'EC33', name: 'Dan Jackson', blocked_on: '12 Jun 2020 ', time: '5.00 PM', block_code: 'BL7', unblock_in: '8', action: 'Unblock', remarks: 'Add remark' },
    { id: 'EC34', name: 'Joyce Beck', blocked_on: '12 Jun 2020', time: '5.00 PM', block_code: 'BL2', unblock_in: '11', action: 'Unblock', remarks: 'Add remark' },
    { id: 'EC35', name: 'Oscar Christensen', blocked_on: '12 Jun 2020', time: '5.00 PM', block_code: 'BL4', unblock_in: '8', action: 'Unblock', remarks: 'Add remark' },
    { id: 'EC36', name: 'Joyce Beck', blocked_on: '12 Jun 2020', time: '5.00 PM', block_code: 'BL2', unblock_in: '3', action: 'Unblock', remarks: 'Add remark' },
    { id: 'EC37', name: 'Theodore Gallagher', blocked_on: '12 Jun 2020', time: '5.00 PM', block_code: 'BL2', unblock_in: '3', action: 'Unblock', remarks: 'Add remark' },
    { id: 'EC38', name: 'Theodore Gallagher', blocked_on: '12 Jun 2020', time: '5.00 PM', block_code: 'BL2', unblock_in: '1', action: 'Unblock', remarks: 'Add remark' },
    { id: 'EC39', name: 'Oscar Christensen', blocked_on: '12 Jun 2020', time: '5.00 PM', block_code: 'BL2', unblock_in: '1', action: 'Unblock', remarks: 'Add remark' },

  ]

  const columns = [
    {
      name: 'Client ID',
      selector: 'id',
      sortable: false,
      left: true,
      width: "120px",
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: false,
      left: true,
      // maxWidth: "160px",
    },
    {
      name: 'Blocked on',
      selector: 'blocked_on',
      sortable: false,
      left: true,
      minWidth: "220px",
      cell: row => <div className="d-flex">
        {row.blocked_on}
        <div className="e-time ps-3">5.00 PM</div>
      </div>
    },
    {
      name: 'Block code',
      selector: 'block_code',
      sortable: false,
      left: true,
      // width: "120px",

    },
    {
      name: 'Unblock in',
      selector: 'unblock_in',
      sortable: true,
      left: true,
      cell: row => <div className="">
        {row.unblock_in} days
      </div>
    },
    {
      name: 'Action',
      selector: 'action',
      sortable: false,
      left: true,
      cell: row => <div className="e-action-primary">
        {row.action}
      </div>
    },
    {
      name: 'Remarks',
      selector: 'remarks',
      sortable: false,
      left: true,
      cell: row => <div className="e-action-primary">
        {row.remarks}
      </div>
    },

  ]
  const handleChange = (row) => {
    // setSelectedUsers(row.selectedRows)
    // let selected = []
    // for (let i = 0; i < row.selectedRows.length; i++) {
    //   selected.push(row.selectedRows[i]['id']);
    // }
    setCheck(row.selectedRows);
  }


  return (
    <div className='e-users-container'>
      <div className="row">
        <div className="col-lg-12">
          <div className="card e-dashboard-card border-0">
            <div className='row pe-4'>
              <div className="offset-lg-7 col-lg-2">
                <DownloadBtn check={check} />
              </div>
              <div className="col-lg-3 position-relative ">
                <SearchInput placeholder="User name/client id" value={search} valueChange={(value) => { setSearch(value) }} />
                {
                  search && search.length ?
                    <>
                      <span className={`${styles.e_search_submit} `} onClick={() => setSearch("")} ></span>
                      <button className={`${styles.e_search_close} pe-3`} onClick={() => setSearch("")} ></button>
                    </>
                    :
                    null
                }

              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 e-blocked-users pt-2">
                <DataTable pagination={true} columns={columns} data={data} selectableRows={true} selectRows={(row) => { handleChange(row) }} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default BlockedUsers;
