/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 12-04-2022
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakcages*/
import React, { useState, useEffect, } from 'react';
import moment from 'moment';

/*import components*/
import { DataTable, GameTabs } from '../../components/dataFormating/index';
import { Rangepicker, Search, } from '../../components/input/index';
import { DownloadBtn } from '../../components/cta/index';
import Loader from '../../components/loader/loader';
import { FilterBtn } from '../../components/cta/index';
import { EmptyNote } from '../../components/note';

/*import  assets*/
import Nodara from '../../assets/images/note/no-data.png';
import Next from '../../assets/images/dataFormating/next.svg';
import Prev from '../../assets/images/dataFormating/prev.svg';

/* import services */
import service from '../../services/service';

/*import styles*/

const Index = (props) => {
    // const [search, setSearch] = useState("");
    const [filterDown, setFilterDown] = useState(false);
    const [tabIndex, setTabindex] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    //eslint-disable-next-line
    const [check, setCheck] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [userId, setUserId] = useState({});
    const [searchuser, setSearchuser] = useState("");

    //eslint-disable-next-line
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(200);

    const [payouttotal, setPayoutTotal] = useState(null);
    const [payoutPage, setPayoutPage] = useState(1);
    const [payoutperPage, setPayoutPerPage] = useState(200);
    const [payouttotalLength, setPayoutTotalLength] = useState(null);
    const [payoutData, setPayoutData] = useState([]);

    const [paginationDataLog, setPaginationDataLog] = useState({})
    //eslint-disable-next-line
    const [search, setSearch] = useState("");


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        // getCustomersList();
    }, []);

    // useEffect(() => {
    //     if (downloadLoader === true) {
    //         setDownloadLoader(false)
    //     }
    // }, [downloadLoader])


    useEffect(() => {

        maxPayoutList()
        //eslint-disable-next-line
    }, [payoutPage, payoutperPage, tabIndex, startDate, endDate, userId])



    // useEffect(() => {
    //     searchScrip();
    // }, [gameType])

    // const getCustomersList = () => {
    //     var url = "/general/customer-list-filter";

    //     service(false, url).then((response) => {
    //         if (response.data.status_code === 200) {
    //             let temp_options = []
    //             temp_options.push({ label: "All", value: 0 })
    //             response.data.data.map((item) => {
    //                 return (
    //                     temp_options.push({
    //                         label: item.customer_name,
    //                         value: item.customer_id
    //                     })
    //                 )
    //             })
    //             setCustomerData(temp_options)
    //         }
    //     })
    // }


    /*------ reports list ------*/
    const maxPayoutList = (temp, download) => {

        if (download) {
            setDownloadLoader(true)
        } else {
            setLoader(true);
        }
        if (tabIndex === 1) {
            //eslint-disable-next-line
            var url = "/tax-report/gst-report";
        } else if (tabIndex === 2) {
            //eslint-disable-next-line
            var url = "/tax-report/tds-report-game";
        } else if (tabIndex === 3) {
            //eslint-disable-next-line
            var url = "/tax-report/tds-report-referrals";
        } else {
            //eslint-disable-next-line
            var url = "/tax-report/tds-report-milestones";
        }


        var data = {
            "from_date": startDate,
            "to_date": endDate,
            "page_num": payoutPage,
            "page_size": payoutperPage,
            "is_download": download ? download : false,
            "customer_id": userId?.id
        }



        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {

                if (download) {
                    setDownloadLoader(false);
                    let a = document.createElement("a");
                    a.href = response.data.data.url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setCheck([]);
                } else {

                    setPayoutData(response.data.data);
                    setPayoutTotal(response.data.number_of_records);
                    let remainder = response.data.number_of_records % perPage
                    let quotient = response.data.number_of_records / perPage
                    if (Number(remainder) > 0) {
                        setPayoutTotalLength(parseInt(Number(quotient) + 1))

                    } else {
                        setPayoutTotalLength(Number(quotient))
                    }
                    setLoader(false);

                    setPaginationDataLog({
                        rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                    })
                }

            } else {
                setPayoutData([]);
                setError(response.data.message);
                setLoader(false);
            }
        });
    }


    const openDetail = (id) => {
        window.open(`/user-detail?id=${id}`, "_blank");
    }

    //user list columns
    const gstColumns = [


        {
            name: 'Client ID',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["client_id "],
            maxWidth: "130px",
        },
        {
            name: 'Name',
            // selector: 'name',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row => <div className='e-name-link' onClick={() => openDetail(row.customer_id)}>{row.name}</div>,
        },
        {
            name: 'Email',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["email"],
            minWidth: "180px",
        },
        {
            name: 'Phone',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["mobile "],
            minWidth: "180px",
        },
        {
            name: 'PAN',
            selector: row => row.pan_number,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Total booking amount',
            sortable: true,
            right: false,
            minWidth: "220px",
            cell: row => row.booking_amount !== "-" ? "₹" + row.booking_amount : "-",
            // sortFunction: (a, b) => customDateSort(a, b, 'last_login_time')
        },
        {
            name: 'Platform fee',
            selector: 'platform_fee',
            cell: row => "₹ " + row.platform_fee,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'GST %',
            selector: row => row.gst_percentage,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'GST amount',
            sortable: true,
            right: false,
            minWidth: "220px",
            cell: row => row.gst_amount !== "-" ? "₹" + row.gst_amount : "-",
            // sortFunction: (a, b) => customDateSort(a, b, 'last_login_time')
        },
    ];

    //user list columns
    const tdsColumns = [


        {
            name: 'Client ID',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["client_id "],
            maxWidth: "130px",
        },
        {
            name: 'Name',
            // selector: 'name',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row => <div className='e-name-link' onClick={() => openDetail(row.customer_id)}>{row.name}</div>,
        },
        {
            name: 'Email',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["email"],
            minWidth: "180px",
        },
        {
            name: 'Phone',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["mobile "],
            minWidth: "180px",
        },
        {
            name: 'PAN',
            selector: row => row.pan_number,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Date & Time',
            sortable: true,
            right: false,
            minWidth: "220px",
            cell: row => row.date,
            // sortFunction: (a, b) => customDateSort(a, b, 'last_login_time')
        },
        {
            name: 'Total booking amount',
            sortable: true,
            right: false,
            minWidth: "220px",
            cell: row => row["booking amount"] !== "-" ? "₹" + row["booking amount"] : "-",
            // sortFunction: (a, b) => customDateSort(a, b, 'last_login_time')
        },
        {
            name: 'Total winning amount',
            selector: 'reward_amount',
            cell: row => row.reward_amount !== "-" ? "₹" + row.reward_amount : "-",
            sortable: false,
            right: false,
            minWidth: "220px",
        },
        {
            name: 'TDS %',
            selector: row => row.tds_percentage,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'TDS amount',
            sortable: true,
            right: false,
            minWidth: "220px",
            cell: row => row.tds_amount !== "-" ? "₹" + row.tds_amount : "-",
            // sortFunction: (a, b) => customDateSort(a, b, 'last_login_time')
        },

    ];

    //user list columns
    const tdsReferralColumns = [


        {
            name: 'Client ID',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["client_id "],
            maxWidth: "130px",
        },
        {
            name: 'Name',
            // selector: 'name',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row => <div className='e-name-link' onClick={() => openDetail(row.customer_id)}>{row.name}</div>,
        },
        {
            name: 'Email',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["email"],
            minWidth: "180px",
        },
        {
            name: 'Phone',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["mobile "],
            minWidth: "180px",
        },
        {
            name: 'PAN',
            selector: row => row.pan_number,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Date & Time',
            sortable: true,
            right: false,
            minWidth: "220px",
            cell: row => row.date,
            // sortFunction: (a, b) => customDateSort(a, b, 'last_login_time')
        },
        {
            name: 'Total commission amount',
            selector: 'commission_amount',
            cell: row => row.commission_amount !== "-" ? "₹" + row.commission_amount : "-",
            sortable: false,
            right: false,
            minWidth: "220px",
        },
        {
            name: 'Total paid amount',
            selector: 'paid_amount',
            cell: row => row.paid_amount !== "-" ? "₹" + row.paid_amount : '-',
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'TDS %',
            selector: row => row.tds_percentage,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'TDS amount',
            sortable: true,
            right: false,
            minWidth: "220px",
            cell: row => row.tds_amount !== "-" ? "₹" + row.tds_amount : "-",
            // sortFunction: (a, b) => customDateSort(a, b, 'last_login_time')
        },
    ];

    const milestoneColumns = [


        {
            name: 'Client ID',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["client_id "],
            maxWidth: "130px",
        },
        {
            name: 'Name',
            // selector: 'name',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row => <div className='e-name-link' onClick={() => openDetail(row.customer_id)}>{row.name}</div>,
        },
        {
            name: 'Email',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["email"],
            minWidth: "180px",
        },
        {
            name: 'Phone',
            // selector: "client_id",
            sortable: true,
            left: true,
            cell: row => row["mobile "],
            minWidth: "180px",
        },
        {
            name: 'PAN',
            selector: row => row.pan_number,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Milestone name',
            selector: 'name',
            cell: row => row.name !== "-" ? row.name : "-",
            sortable: false,
            right: false,
            minWidth: "180px",
        },
        {
            name: 'Date & Time',
            sortable: true,
            right: false,
            minWidth: "220px",
            cell: row => row.date,
            // sortFunction: (a, b) => customDateSort(a, b, 'last_login_time')
        },
        {
            name: 'Winning amount',
            selector: 'reward_amount',
            cell: row => row.reward_amount !== "-" ? "₹" + row.reward_amount : '-',
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'TDS %',
            selector: row => row.tds_percentage,
            sortable: false,
            right: false,
            minWidth: "130px",
        },
        {
            name: 'TDS amount',
            sortable: true,
            right: false,
            minWidth: "220px",
            cell: row => row.tds_amount !== "-" ? "₹" + row.tds_amount : "-",
            // sortFunction: (a, b) => customDateSort(a, b, 'last_login_time')
        },
    ];

    const handleChangePayout = (row) => {
        setDownloadLoader(false)
        let selected = []
        for (let i = 0; i < row.selectedRows.length; i++) {
            selected.push(row.selectedRows[i]);
        }
        setCheck(selected);
    }


    return (
        <div className='e-users-container e-pagination'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className="row pt-4 ">

                            <div className="col-lg-5 col-5  pt-2 ps-4 mb-3 pe-0">
                                <GameTabs
                                    index={tabIndex}
                                    data={[

                                        'GST report',
                                        'TDS report',
                                        'TDS referrals',
                                        'TDS milestones'
                                    ]}
                                    setTab={(temp) => {
                                        setTabindex(temp);
                                        setStartDate(null);
                                        setEndDate(null);
                                        setPayoutPage(1);
                                        setPayoutPerPage(200);
                                        setSearchuser("");
                                        setFilterDown(false)
                                        setPage(1);
                                        setPerPage(200);
                                        setUserId({});
                                    }} />
                            </div>
                            {/* <div className='col-lg-6 mt-2'></div> */}

                            <div className='col-lg-4'></div>
                            <div className={tabIndex === 1 ? "col-lg-2 col-4 px-0 text-end " : "col-lg-2 col-4 px-0 text-end"}>
                                <DownloadBtn
                                    check={["check"]}
                                    className={downloadLoader ? "e-disabled" : ""}
                                    loaderClass={downloadLoader ? "e-bounce" : ""}
                                    onClick={() => {

                                        maxPayoutList(search, true)

                                    }}
                                />
                            </div>
                            <div className="col-lg-1 col-1">
                                <FilterBtn
                                    className={
                                        filterDown ?
                                            "e-filter-btn e-active"
                                            :
                                            "e-filter-btn"
                                    }
                                    handleClick={() => {
                                        setFilterDown(!filterDown)
                                    }}
                                />
                            </div>
                            {
                                filterDown ?
                                    <div className='row pe-0'>
                                        <div className='col-lg-12 px-0'>
                                            <div className='e-filter-wraper mt-3'>
                                                <div className='col-lg-12 '>
                                                    <div className='row'>
                                                        <div className='col-lg-3'>
                                                            <label className='mb-2'>Customer</label>
                                                            {/* <SelectBox
                                                                className="me-2 e-bookings-sort"
                                                                isSearchable={true}
                                                                options={customerData}
                                                                value={userId}
                                                                selectchange={(temp) => setUserId(temp)} /> */}
                                                            <Search
                                                                value={Object.keys(userId).length === 0 ? {} : userId}
                                                                search={searchuser}
                                                                setSearch={(temp) => {
                                                                    setSearchuser(temp);
                                                                    if (temp === "") {
                                                                        setUserId({});
                                                                    }
                                                                }}
                                                                userDetail={(temp) => setUserId(temp)} />

                                                        </div>
                                                        <div className='col-lg-3'>
                                                            <label className='mb-2'>Range</label>
                                                            <Rangepicker
                                                                placeholder={['From', 'To']}
                                                                start={startDate}
                                                                end={endDate}
                                                                disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                onDatesChange={(start, end) => {
                                                                    setStartDate(start);
                                                                    setEndDate(end);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className="col-lg-12 e-booking-table pt-3">
                                {

                                    loader ?
                                        <Loader />
                                        :
                                        payoutData.length > 0 ?
                                            <>
                                                <DataTable
                                                    pagination={true}
                                                    columns={tabIndex === 3 ? tdsReferralColumns : tabIndex === 2 ? tdsColumns : tabIndex === 4 ? milestoneColumns : gstColumns}
                                                    data={payoutData}
                                                    // selectableRows={true}
                                                    paginationTotalRows={payouttotal}
                                                    paginationComponentOptions={paginationDataLog}
                                                    selectRows={(row) => {
                                                        handleChangePayout(row);
                                                    }}
                                                    onChangeRowsPerPage={(p) => {
                                                        setPayoutPage(1)
                                                        setPayoutPerPage(p)
                                                    }}
                                                    size={payoutperPage}
                                                    paginationIconNext={payouttotalLength === payoutPage ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setPayoutPage(payoutPage + 1)} style={{ cursor: "pointer" }} />}
                                                    paginationIconPrevious={payoutPage === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPayoutPage(payoutPage - 1) }} />}
                                                    clearSelectedRows={downloadLoader}
                                                />
                                                <div className='col-lg-12 text-end pe-3'>
                                                    <p>Page no : <span style={{ fontWeight: "600" }}>{payoutPage}</span></p>
                                                </div>
                                            </>
                                            :
                                            <EmptyNote img={Nodara} data="No data found" />

                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Index;
