
/*import package*/
import React from 'react';
import Chart from "react-apexcharts";

/*import style*/
// import styles from '../../styles/components/pie.module.scss';


export default class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: this.props.data,
      options: {
        chart: {
          type: 'donut',
        },
        dataLabels: {
          enabled: false,

        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,

                total: {
                  show: true,
                  showAlways: true,
                  label: 'Total',
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',

                }
              }
            }
          }
        },
        legend: {
          fontSize: '14px',
          fontFamily: 'Inter',
          fontWeight: 600,
          show: false
        },
        fill: {
          type: "gradient",
          // gradient: {
          //   shade: 'light',
          //   type: 'horizontal',
          //   shadeIntensity: 0.5,
          //   gradientToColors: [],
          //   inverseColors: true,
          //   opacityFrom: 1,
          //   opacityTo: 1,
          //   stops: [0, 50, 100]
          // }
        },
        colors: this.props.colors,
        labels: this.props.categories,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };
  }
  render() {
    return (
      <div className="donut d-flex justify-content-center ">
        <Chart options={this.state.options} series={this.state.series} type="donut" width="280" height="280" />
      </div>
    );
  }
}

