/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Leaderboard;
 *   Integrations :
 *   Version : v1.0
 *   Created : 15-02-2022
 */
/*import pakcages*/
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

/*import components*/
import { GameTabs } from '../../components/dataFormating/index';
import { LeaderboardCard } from '../../components/card/index';
import Loader from '../../components/loader/loader';
import DatePicker from '../../components/input/date';

/*import containers */

/*import assets*/
import Earnings from '../../assets/images/card/summary/forecast.svg';
import Games from '../../assets/images/card/summary/earnings.svg';
import Wins from '../../assets/images/card/summary/wins.svg';
import Rating from '../../assets/images/card/summary/rating.svg';
import Leaderboards from '../../assets/images/card/leaderboard.svg';

/* import services */
import service from '../../services/service';


const Leaderboard = (props) => {
    const wrapperRef = useRef(null);
    const [tabIndex, setTabindex] = useState(1);
    const [loader, setLoader] = useState(false);
    const [leaderboardData, setLeaderboardData] = useState({});
    const [id, setId] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [filterId, setFilterId] = useState(null);
    const [timeFilter, setShowTimeFilter] = useState(false);
    const [dashbaordFilter, setDashboardFilter] = useState([]);
    const [secondTimeFilter, setShowSecondTimeFilter] = useState(false);
    const [dates, setDates] = useState({
        checkin: "",
        checkout: ""
    });
    const [range, setRange] = useState({
        start: "",
        end: ""
    });
    const [open, setOpen] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        getDasboardFilter();
    }, []);

    const _closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowTimeFilter(false);
            setShowSecondTimeFilter(false);
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);


    useEffect(() => {
        getLeaderboardList();
        //eslint-disable-next-line
    }, [tabIndex, filterId, range.end]);

    useEffect(() => {
        if (dates.checkin !== "" && dates.checkout !== "") {
            setOpen(false)
            setSelectedFilter("")
            let start = moment(dates.checkin).format("YYYY-MM-DD")
            let end = moment(dates.checkout).format("YYYY-MM-DD")
            setRange({
                start: start,
                end: end
            });
            setShowTimeFilter(false)
        }
    }, [dates.checkin, dates.checkout])

    /* get loaderboardlist */
    const getLeaderboardList = (query) => {
        console.log(range)
        setLoader(true);
        let url = "/user/leaderboard";

        let data = {
            "type": tabIndex - 1,
            "user_id": Number(props.id),
            "filter_id": id,
            "filter_sub_id": filterId,
            "start_date": range.start === '' ? null : range.start,
            "end_date": range.end === '' ? null : range.end,
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                setLeaderboardData(response.data.data);
            } else {
                setLoader(false);
            }
        });
    }

    /* ---- get dashboard filter ---*/
    const getDasboardFilter = () => {

        let url = "/dashboard/filter-data";

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setDashboardFilter(response.data.data);
            } else {
                setDashboardFilter([]);
            }
        });
    }
    return (
        <div className='e-users-container mb-5 pb-5'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className="row px-4">
                            <div className="col-lg-8 pt-4 mt-1 mb-4">
                                <GameTabs data={['Global topper', 'M2E topper', 'M2M topper', 'Skillrace topper']} index={tabIndex} setTab={(temp) => setTabindex(temp)} />
                            </div>
                            <div className='col-lg-4 pt-4 mt-1 mb-4'>
                                <div className="e-dashboard-graph-select">

                                    <div className='positon-relative d-flex'>

                                        <div className='e-dashbaord-dropdown' onClick={() => setShowTimeFilter(!timeFilter)}>
                                            {selectedFilter ? selectedFilter : dates.checkin !== "" && dates.checkout !== "" ? `${range.start} - ${range.end}` : "Choose date range"}
                                        </div>

                                        {
                                            timeFilter ?
                                                <div className='e-show-dropdown' ref={wrapperRef} style={{ top: "70px", height: "max-content" }} >
                                                    {
                                                        dashbaordFilter.map((item) => {
                                                            return (

                                                                <>
                                                                    <p className={item.data.length > 0 ? "e-arrow-show" : null}
                                                                        onClick={() => {
                                                                            if (item.data.length > 0) {
                                                                                setShowSecondTimeFilter(!secondTimeFilter);
                                                                                setId(item.id)
                                                                                // setDates({
                                                                                //     checkin: "",
                                                                                //     checkout: ""
                                                                                // })
                                                                            } else {
                                                                                setSelectedFilter("All");
                                                                                setFilterId(null);
                                                                                setShowTimeFilter(false);
                                                                                setShowSecondTimeFilter(false);
                                                                                setId(null);
                                                                                setRange({
                                                                                    checkin: "",
                                                                                    checkout: ""
                                                                                })
                                                                            }
                                                                        }}>{item.name}</p>
                                                                    {
                                                                        item.id === id && secondTimeFilter && item.data.length > 0 ?
                                                                            <div className='e-second-show-dropdown' >
                                                                                {
                                                                                    item.data.map((option) => {
                                                                                        return (

                                                                                            <p onClick={() => {
                                                                                                setSelectedFilter(option.name);
                                                                                                setFilterId(option.id);
                                                                                                setShowTimeFilter(!timeFilter);
                                                                                                setShowSecondTimeFilter(false);
                                                                                                setDates({
                                                                                                    checkin: "",
                                                                                                    checkout: ""
                                                                                                })
                                                                                            }}>{option.name}</p>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }

                                                    <DatePicker
                                                        dates={dates}
                                                        setDates={setDates}
                                                        open={open}
                                                        setOpen={setOpen}

                                                    >
                                                        <p className=" e-arrow-show mb-0" onClick={() => { setOpen(!open); setShowSecondTimeFilter(false); setId(1); setFilterId(null); }}>
                                                            Date range
                                                        </p>
                                                    </DatePicker>

                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                loader ?
                                    <Loader />
                                    :
                                    <>
                                        <div className='row mb-4 pt-1'>
                                            <div className='col-lg-12'>
                                                <div className='d-inline-flex'>
                                                    <span className="e-leaderboard-span">
                                                        <img src={Leaderboards} className='pe-2' alt="" />
                                                        {
                                                            tabIndex === 1 ?
                                                                "Global"
                                                                :
                                                                tabIndex === 2 ?
                                                                    "M2E" :
                                                                    tabIndex === 3 ?
                                                                        "M2M"
                                                                        :
                                                                        "Skillrace"
                                                        } leaderboard rank: <span className="">{leaderboardData.rank}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mb-3'>
                                            <div className="col-lg-3 mb-3">
                                                <LeaderboardCard
                                                    data={{
                                                        title: 'Earnings',
                                                        count: leaderboardData.earnings !== "-" ? leaderboardData.earnings : 0,
                                                        icon: Earnings
                                                    }} />
                                            </div>
                                            <div className="col-lg-3 mb-3">
                                                <LeaderboardCard
                                                    data={{
                                                        title: 'Total games',
                                                        count: leaderboardData.total_games !== "-" ? leaderboardData.total_games : 0,
                                                        icon: Games
                                                    }} />
                                            </div>
                                            <div className="col-lg-3 mb-3">
                                                <LeaderboardCard
                                                    data={{
                                                        title: 'Wins',
                                                        count: leaderboardData.wins !== "-" ? leaderboardData.wins : 0,
                                                        icon: Wins
                                                    }} />
                                            </div>
                                            <div className="col-lg-3 mb-3">
                                                <LeaderboardCard
                                                    data={{
                                                        title: 'Rating',
                                                        count: `${leaderboardData.rating !== "-" ? leaderboardData.rating : "0"}/5`,
                                                        icon: Rating
                                                    }} />
                                            </div>
                                        </div>



                                    </>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Leaderboard;
