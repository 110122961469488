/*
 *   File : card.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Card Style Tooltip
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-12-2021
 */

/*import package*/
import React from 'react';

/*import styles*/
import styles from '../../styles/components/cardIconStyleToolTip.module.scss';

const CardIconStyleToolTip = (props) => {
  const style = {
    "--background": props.bgColor,
  }
  return (
    <div className={styles.e_icon_tooltip} style={style}>
      <span>{props.content}</span>
    </div>

  );
}

export default CardIconStyleToolTip;