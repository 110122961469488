/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Notification container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 11-11-2021
*/

/*import packages*/
import { useState, useEffect } from 'react';
import moment from 'moment';
import $ from 'jquery';

/*import components*/
import { FileUpload, Input, RadioButton, Rangepicker, SingleDate, TextArea } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';
import SelectBox from '../../components/dropdown/selectBox';
import { DataTable, GameTabs } from '../../components/dataFormating';
import { EmptyNote, Toast } from '../../components/note';
import { FilterBtn } from '../../components/cta/index';
import Loader from '../../components/loader/loader';

//assets
import Nodata from '../../assets/images/note/no-data.png';
import Next from '../../assets/images/dataFormating/next.svg';
import Prev from '../../assets/images/dataFormating/prev.svg';

//services

import service from '../../services/service';
import { Confirmation } from '../../components/modal';

const userOptions = [
	{ value: null, label: "All users" },

	{ value: true, label: "Active users" },

	{ value: false, label: "Inactive users" },
]

const notficationOptions = [
	{ value: 0, label: "Dashboard" },
	{ value: 1, label: "M2E" },
	{ value: 2, label: "M2M" },
	{ value: 3, label: "Skillrace" },
]


const gameOptions = [
	{ value: 0, label: "All games" },
	{ value: 1, label: "M2E" },
	{ value: 2, label: "M2M" },
	{ value: 3, label: "Skillrace" },
]

const Index = () => {


	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [userType, setUserType] = useState(userOptions[0]);
	const [gameType, setGameType] = useState(gameOptions[0]);
	const [notificationType, setNotificationType] = useState(notficationOptions[0]);
	const [filterNotificationType, setFilterNotificationType] = useState(notficationOptions[0]);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState(null);
	const [imageUrl, setUploadImageUrl] = useState("");
	const [imageLoader, setImageLoader] = useState(false);
	const [uploadImage, setUploadImage] = useState("");
	const [success, setSuccess] = useState(null);
	const [expiryDate, setExpiryDate] = useState(null);
	const [index, setIndex] = useState(1);
	const [pageNum, setPageNum] = useState(1);
	const [perPage, setPerPage] = useState(200);
	const [notificationData, setNotificationData] = useState([]);
	const [dataLoader, setDataLoader] = useState(false);
	const [filterDown, setFilterDown] = useState(false);
	const [cutomerIds, setCustomerIds] = useState("");
	const [style, setStyle] = useState(1);
	const [notificationId, setNotificationId] = useState(null);
	const [dates, setDates] = useState({
		start: null,
		end: null
	})
	const [dataLog, setDataLog] = useState({});
	//eslint-disable-next-line
	const [total, setTotal] = useState(null);
	const [totalLength, setTotalLength] = useState(null);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (index === 2) {
			_listPopupNotification()
		}
		//eslint-disable-next-line
	}, [index, filterNotificationType.value, pageNum, perPage, dates])

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null)
			}, 3000);
		}
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null)
			}, 3000);
		}
	}, [success]);

	const getNotificationMeaage = () => {
		var start_date = moment(startDate).format("DD MMM YYYY")
		var end_date = moment(endDate, "YYYY-MM-DD").format("DD MMM YYYY")
		//All usrers
		if (gameType?.value === 0 && userType.value === null && startDate === null && endDate === null) {
			return "Notification will be sent to All users"
		} else if (gameType.value === 0 && userType.value === true && startDate === null && endDate === null) {
			return "Notification will be sent to all Active users"
		} else if (gameType.value === 0 && userType.value === false && startDate === null && endDate === null) {
			return "Notification will be sent to all Inactive users"
		} else if (gameType.value === 0 && userType.value === null && startDate !== null && endDate !== null) {
			return `Notification will be sent to all users between ${start_date} - ${end_date}`
		} else if (gameType.value === 0 && userType.value === true && startDate !== null && endDate !== null) {
			return `Notification will be sent to all Active users between ${start_date} and ${end_date}`
		} else if (gameType.value === 0 && userType.value === false && startDate !== null && endDate !== null) {
			return `Notification will be sent to all Inactive users between ${start_date} and ${end_date}`
		}

		//M2E users
		else if (gameType.value === 1 && userType.value === null && startDate === null && endDate === null) {
			return "Notification will be sent to all M2E players"
		} else if (gameType.value === 1 && userType.value === true && startDate === null && endDate === null) {
			return "Notification will be sent to all active M2E players"
		} else if (gameType.value === 1 && userType.value === false && startDate === null && endDate === null) {
			return "Notification will be sent to all users who hasn't played M2E game"
		} else if (gameType.value === 1 && userType.value === null && startDate !== null && endDate !== null) {
			return `Notification will be sent to all M2E players between ${start_date} and ${end_date}`
		} else if (gameType.value === 1 && userType.value === true && startDate !== null && endDate !== null) {
			return `Notification will be sent to all active M2E Active players between ${start_date} and ${end_date}`
		} else if (gameType.value === 1 && userType.value === false && startDate !== null && endDate !== null) {
			return `Notification will be sent to all users who hasn't played M2E game between ${start_date} and ${end_date}`
		}

		//M2M users
		else if (gameType.value === 2 && userType.value === null && startDate === null && endDate === null) {
			return "Notification will be sent to all M2M players"
		} else if (gameType.value === 2 && userType.value === true && startDate === null && endDate === null) {
			return "Notification will be sent to all active M2M players"
		} else if (gameType.value === 2 && userType.value === false && startDate === null && endDate === null) {
			return "Notification will be sent to all users who hasn't played M2M game"
		} else if (gameType.value === 2 && userType.value === null && startDate !== null && endDate !== null) {
			return `Notification will be sent to all M2M players between ${start_date} and ${end_date}`
		} else if (gameType.value === 2 && userType.value === true && startDate !== null && endDate !== null) {
			return `Notification will be sent to all active M2M Active players between ${start_date} and ${end_date}`
		} else if (gameType.value === 2 && userType.value === false && startDate !== null && endDate !== null) {
			return `Notification will be sent to all users who hasn't played M2M game between ${start_date} and ${end_date}`
		}

		//Skillrace users
		else if (gameType.value === 3 && userType.value === null && startDate === null && endDate === null) {
			return "Notification will be sent to all Skillrace players"
		} else if (gameType.value === 3 && userType.value === true && startDate === null && endDate === null) {
			return "Notification will be sent to all active Skillrace  players"
		} else if (gameType.value === 3 && userType.value === false && startDate === null && endDate === null) {
			return "Notification will be sent to all users who hasn't played Skillrace game"
		} else if (gameType.value === 3 && userType.value === null && startDate !== null && endDate !== null) {
			return `Notification will be sent to all Skillrace players between ${start_date} and ${end_date}`
		} else if (gameType.value === 3 && userType.value === true && startDate !== null && endDate !== null) {
			return `Notification will be sent to all active Skillrace Active players between ${start_date} and ${end_date}`
		} else if (gameType.value === 3 && userType.value === false && startDate !== null && endDate !== null) {
			return `Notification will be sent to all users who hasn't played Skillrace game between ${start_date} and ${end_date}`
		}
	}

	const preSignedUrl = (file) => {
		setImageLoader(true);
		let url = "/app/presigned-url-upload";
		let data = {
			key: `Admin-Notification/` + file.name,
			is_public: true
		};

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				setImageLoader(true);
				uploadDocument(response.data.data, file); // upload file in AWS s3
			} else {
				setError(response.data.message);
				setImageLoader(false);
			}
		});
	}

	/* Uploading to s3 */
	const uploadDocument = async (data, file) => {
		setImageLoader(true);
		var formdata = new FormData();
		formdata.append("key", data.upload_info.fields.key);
		formdata.append("x-amz-algorithm", data.upload_info.fields['x-amz-algorithm']);
		formdata.append("x-amz-credential", data.upload_info.fields['x-amz-credential']);
		formdata.append("x-amz-date", data.upload_info.fields['x-amz-date']);
		formdata.append("policy", data.upload_info.fields['policy']);
		formdata.append("x-amz-signature", data.upload_info.fields['x-amz-signature']);
		formdata.append("file", file, file.name);

		var requestOptions = {
			method: 'POST',
			body: formdata,
			redirect: 'follow'
		};

		fetch(data.upload_info.url, requestOptions)
			.then(response => { response.text(); console.log(response.text()) })
			.then(res => {
				setUploadImageUrl(data.view_info); // file got uploaded successfully
				setImageLoader(false);
				setUploadImage(file.name)

			})
			.catch(error => {
				console.log('error', error); // show error toast here
				setImageLoader(false);
			});
	}

	//send notification API
	const sendNotification = () => {
		setLoader(true);
		let url = "/notifications/send-notifications"

		let data = {
			"title": title,
			"description": content,
			"image": imageUrl,
			"game_type": gameType.value,
			"is_active": userType.value,
			"from_date": startDate,
			"to_date": endDate,
			"customer_ids": cutomerIds !== "" ? cutomerIds.split(",") : []
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				setLoader(false);
				setTitle("");
				setContent("");
				setStartDate(null);
				setEndDate(null);
				setUploadImage("");
				setUploadImageUrl("");
				setUserType(userOptions[0]);
				setCustomerIds("");
				setSuccess("Notification sent successfully");
			} else {
				setLoader(false);
				setError(response.data.message);
			}
		})
	}

	//send popup notification API
	const sendPopupNotification = () => {
		setLoader(true);
		let url = "/notifications/add-popup-notifications"

		let data = {
			"title": title,
			"description": content,
			"image_url": imageUrl,
			"game_type_id": notificationType.value,
			"expire_by": expiryDate,
			"popup_style": style
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				setLoader(false);
				setSuccess("Notification sent successfully");
				setTitle("");
				setContent("");
				setExpiryDate(null);
				setUploadImage("");
				setUploadImageUrl("");
				setNotificationType(notficationOptions[0]);
			} else {
				setLoader(false);
				setError(response.data.message);
			}
		})
	}

	//list notification API
	const _listPopupNotification = () => {
		setDataLoader(true);
		let url = "/notifications/list-popup-notifications"

		let data = {
			"game_type_id": filterNotificationType.value,
			"from_date": dates.start,
			"end_date": dates.end,
			"page_size": perPage,
			"page_num": pageNum
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				setDataLoader(false);
				setNotificationData(response.data.data);
				setTotal(response.data.number_of_records);
				let remainder = response.data.number_of_records % perPage
				let quotient = response.data.number_of_records / perPage
				if (Number(remainder) > 0) {
					setTotalLength(parseInt(Number(quotient) + 1))

				} else {
					setTotalLength(Number(quotient))
				}
				setDataLog({
					rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
				})
			} else {
				setDataLoader(false);
				setNotificationData([])
			}
		})
	}

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > moment().endOf('day');
	}

	//user list columns
	const columns = [


		{
			name: 'Title',
			selector: "title",
			sortable: true,
			left: true,
			cell: row => row.title,
			maxWidth: "180px",
		},

		{
			name: 'description',
			selector: 'description',
			cell: row => row.description,
			sortable: false,
			right: false,
			minWidth: "220px",

		},
		{
			name: 'Created at',
			selector: row => row.created,
			sortable: false,
			right: false,
			minWidth: "120px",
		},
		{
			name: 'Modified by',
			selector: 'modified_by',
			cell: row => row.modified_by,
			sortable: false,
			right: false,
			minWidth: "160px",

		},
		{
			name: 'Expiring',
			selector: 'expire_by',
			cell: row => row.expire_by,
			sortable: false,
			right: false,
			minWidth: "160px",

		},
		{
			name: '',
			cell: (row) => <>
				<span data-bs-toggle="modal" data-bs-target="#confirmation" className='e-red' style={{ color: "red" }} onClick={() => setNotificationId(row.popup_id)}> Remove</span>
			</>
		}

	];
	return (
		<div className="row">
			<div className="col-lg-6">
				<div className="card border-0 e-dashboard-card p-4">
					<div className="col-lg-12 mb-3">
						<GameTabs
							data={["Push notifications", "Popup notifications"]}
							index={index}
							setTab={(temp) => {
								setIndex(temp);
								setUploadImage("");
								setUploadImageUrl("");
								setTitle("");
								setContent("");

							}}
						/>
						{/* <h6 className="e-card-title"></h6> */}
					</div>
					{
						index === 1 ?
							<>
								<div className="col-lg-12 mb-4 e-add-block-rule">
									<label>User type</label>
									<SelectBox
										value={userType}
										isSearchable={false}
										options={userOptions}
										selectchange={(temp) => {
											setUserType(temp)
										}} />
								</div>
								<div className="col-lg-12 mb-4 e-add-block-rule">
									<label>Customer</label>
									<Input
										type="text"
										placeholder="Add customer ID's"
										className="e-notification-form-element"
										value={cutomerIds}
										valueChange={(temp) => {
											setCustomerIds(temp)
										}} />
								</div>
								<div className="col-lg-12 mb-4 e-add-block-rule">
									<label>Game</label>
									<SelectBox
										value={gameType}
										options={gameOptions}
										isSearchable={false}
										selectchange={(temp) => {
											setGameType(temp)
										}} />
								</div>
								<div className="col-lg-12 mb-4 e-push-notification">
									<label>Range</label>
									<Rangepicker
										start={startDate}
										end={endDate}
										disabledDate={disabledDate}
										onDatesChange={(start, end) => {
											setStartDate(start)
											setEndDate(end)
										}} />
								</div>
								<div className="col-lg-12 mb-4">
									<label>Title</label>
									<Input
										placeholder="Notification title"
										className="e-notification-form-element"
										value={title}
										valueChange={(temp) => {
											setTitle(temp)
										}} />
								</div>
								<div className="col-lg-12 mb-4">
									<label>Content</label>
									<TextArea
										placeholder="Content of the push notification"
										className="e-notification-form-element"
										rows="9"
										value={content}
										valueChange={(temp) => {
											setContent(temp)
										}} />
								</div>
								<div className='col-lg-12 e-game-settings-accordion mb-3'>
									<label className="mb-2">Notification image</label>
									<div className="e-uploader ms-0" onClick={() => { $('#e-upload').click() }}>
										{
											uploadImage !== "" ?
												<>
													<p className='text-center mb-0'>Choose file</p>
													<div className='text-center mb-0 setting-detail mt-2'>(Selected: {uploadImage})</div>
												</>
												:
												<>
													<p className={imageLoader ? 'e-image-loader text-center mb-0' : 'text-center mb-0'}>Choose file</p>
												</>
										}

										<FileUpload
											accept="application/pdf, image/*"
											className="hidden"
											id="e-upload"
											key="e-upload-image"
											upload={(temp) => {
												preSignedUrl(temp);
												// setUploadImage(temp.name)
											}} />
									</div>
								</div>
								<p>{getNotificationMeaage()}</p>
								<div className="col-lg-12 text-left mb-4">
									{
										title && content ?
											<PrimaryBtn
												name="Send now"
												className={loader ? "e-notification-form-btn e-btn-loader" : "e-notification-form-btn"}
												handleClick={() => {
													sendNotification()
												}} />
											:
											<PrimaryBtn
												name="Send now"
												className="e-notification-form-btn e-disabled" />
									}
								</div>
							</>
							:
							<>
								<div className="col-lg-12 mb-2 d-flex">
									<RadioButton
										checked={Number(style) === 1 ? true : false}
										label="Image only"
										id={1}
										onClick={(e) => {
											setStyle(1);
											setTitle("");
											setContent("");
											setExpiryDate(null);
										}} />
									<RadioButton
										checked={Number(style) === 2 ? true : false}
										label="Text only"
										id={2}
										onClick={(e) => {
											setStyle(2);
											setUploadImageUrl("");
											setExpiryDate(null);
											setUploadImage("")
										}} />
								</div>
								<div className="col-lg-12 mb-4 e-add-block-rule">
									<label>Game</label>
									<SelectBox
										value={notificationType}
										options={notficationOptions}
										isSearchable={false}
										selectchange={(temp) => {
											setNotificationType(temp)
										}} />
								</div>

								<div className="col-lg-12 mb-4 e-push-notification">
									<label>Expire by</label>
									<SingleDate
										value={expiryDate}
										disabledDate={(current) => current && current < moment().startOf('day')}
										onChange={(date) => {
											setExpiryDate(date)
										}} />
								</div>
								{
									style === 2 ?
										<>
											<div className="col-lg-12 mb-4">
												<label>Title</label>
												<Input
													placeholder="Notification title"
													className="e-notification-form-element"
													value={title}
													valueChange={(temp) => {
														setTitle(temp)
													}} />
											</div>
											<div className="col-lg-12 mb-4">
												<label>Content</label>
												<TextArea
													placeholder="Content of the push notification"
													className="e-notification-form-element"
													rows="9"
													value={content}
													valueChange={(temp) => {
														setContent(temp)
													}} />
											</div>
										</>
										:
										<div className='col-lg-12 e-game-settings-accordion mb-3'>
											<label className="mb-2">Notification image</label>
											<div className="e-uploader ms-0" onClick={() => { $('#e-notification-upload').click() }}>
												{
													uploadImage !== "" ?
														<>
															<p className='text-center mb-0'>Choose file</p>
															<div className='text-center mb-0 setting-detail mt-2'>(Selected: {uploadImage})</div>
														</>
														:
														<>
															<p className={imageLoader ? 'e-image-loader text-center mb-0' : 'text-center mb-0'}>Choose file</p>
														</>
												}

												<FileUpload
													accept="application/pdf, image/*"
													className="hidden"
													id="e-notification-upload"
													key="e-upload-image"
													upload={(temp) => {
														preSignedUrl(temp);

													}} />
											</div>
										</div>
								}
								{/* <p>{getNotificationMeaage()}</p> */}
								<div className="col-lg-12 text-left mb-4">
									{
										style === 1 ?
											imageUrl && expiryDate ?
												<PrimaryBtn
													name="Send now"
													className={loader ? "e-notification-form-btn e-btn-loader" : "e-notification-form-btn"}
													handleClick={() => {
														sendPopupNotification()
													}} />
												:
												<PrimaryBtn
													name="Send now"
													className="e-notification-form-btn e-disabled" />
											:

											title && content && expiryDate ?
												<PrimaryBtn
													name="Send now"
													className={loader ? "e-notification-form-btn e-btn-loader" : "e-notification-form-btn"}
													handleClick={() => {
														sendPopupNotification()
													}} />
												:
												<PrimaryBtn
													name="Send now"
													className="e-notification-form-btn e-disabled" />
									}
								</div>
							</>
					}
				</div>

			</div>
			<div className='col-lg-12 mt-3'>


				{
					index === 2 ?
						<div className='card border-0 e-dashboard-card '>
							<div className='row'>
								<div className='col-11 pt-2'>
									<h6 className='py-3 ps-3'>Notification list</h6>
								</div>
								<div className='col-1 d-flex justify-content-center pt-2'>
									<FilterBtn
										className={
											filterDown ?
												"e-filter-btn e-active"
												:
												"e-filter-btn"
										}
										handleClick={() => {
											setFilterDown(!filterDown)
										}}
									/>
								</div>
								{
									filterDown ?
										<div className='row'>
											<div className='col-lg-12 px-0'>
												<div className='e-filter-wraper mt-3'>
													<div className='col-lg-12 '>
														<div className='row'>
															<div className='col-lg-3'>
																<label className='mb-2'>Game type</label>
																<SelectBox
																	isSearchable={false}
																	className="me-2 e-bookings-sort"
																	value={filterNotificationType}
																	options={notficationOptions}
																	selectchange={(temp) => {
																		setFilterNotificationType(temp)
																	}}
																/>
															</div>
															<div className="col-lg-3 ">
																<label className='mb-2'>Range</label>
																<Rangepicker
																	start={dates.start}
																	end={dates.end}
																	disabledDate={disabledDate}
																	onDatesChange={(start, end) => {
																		setDates({
																			start: start,
																			end: end
																		})
																	}} />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										:
										null
								}
								{
									dataLoader ?
										<Loader />
										:
										notificationData.length > 0 ?
											<div className='col-12 '>

												<DataTable
													data={notificationData}
													columns={columns}
													pagination={true}
													paginationComponentOptions={dataLog}
													onChangeRowsPerPage={(p) => {
														setPageNum(1)
														setPerPage(p)
													}}
													paginationIconNext={totalLength === pageNum ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setPageNum(pageNum + 1)} style={{ cursor: "pointer" }} />}
													paginationIconPrevious={pageNum === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPageNum(pageNum - 1) }} />}
													size={perPage} />

											</div>
											:
											<EmptyNote img={Nodata} data="No data found" />
								}
								<div className='col-lg-12 text-end pe-3'>
									<p>Page no : <span style={{ fontWeight: "600" }}>{pageNum}</span></p>
								</div>
							</div>

						</div>
						:
						null
				}

			</div>

			{/* <div className="col-lg-12 mt-4">
				<div className="e-dashboard-card card border-0 p-3">
					<div className="row">
						<div className="col-lg-6">
							<h6 className="e-card-title mb-4">Push notification History</h6>
							<div className="card border-0 e-notification-history p-3 mb-3">
								<span className="e-notification-history-content d-block">
									Aug 25 2020, 11.05 AM
								</span>
								<h6 className="e-title mt-1 mb-3">Invest now</h6>
								<span className="e-notification-history-content d-block">
									Content of the push notification.
								</span>
							</div>
							<div className="card border-0 e-notification-history p-3 mb-3">
								<span className="e-notification-history-content d-block">
									Aug 25 2020, 11.05 AM
								</span>
								<h6 className="e-title mt-1 mb-3">Invest now</h6>
								<span className="e-notification-history-content d-block">
									Content of the push notification.
								</span>
							</div>
						</div>
						<div className="col-lg-6">
							<h6 className="e-card-title mb-4">Offer notification History</h6>
							<div className="card border-0 e-notification-history p-3 mb-3">
								<span className="e-notification-history-content d-block">
									Aug 25 2020, 11.05 AM
								</span>
								<h6 className="e-title mt-1 mb-3">It's akshaya trithiya</h6>
								<span className="e-notification-history-content d-block">
									Content of the push notification.
								</span>
							</div>
							<div className="card border-0 e-notification-history p-3 mb-3">
								<span className="e-notification-history-content d-block">
									Aug 25 2020, 11.05 AM
								</span>
								<h6 className="e-title mt-1 mb-3">Invest now</h6>
								<span className="e-notification-history-content d-block">
									Content of the push notification.
								</span>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<Confirmation
				title={"Remove notification"}
				content={content}
				btn_name={"Yes,Remove"}

				popup_id={notificationId}
				update={() => {
					_listPopupNotification()
				}}
				close={() => { }} />
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
			{
				success !== null ?
					<Toast
						data={success}
						type="success"
						format={1}
					/>
					:
					null
			}

		</div>
	)
}

export default Index;