/*import package*/
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/*import containers*/

import Login from './containers/auth/login.js';
import Dashboard from './containers/dashboard/index';
import Users from './containers/users/index';
import UserDetails from './containers/users/detail.js';
import ChangePassword from './containers/auth/changePassword.js';
import CreatePassword from './containers/auth/createPassword.js';
import Notification from './containers/notification/index';
import Funds from './containers/funds/index';
import Transactions from './containers/transactions/index';
import Bookings from './containers/bookings/index';
import Results from './containers/results/index';
import ManageGames from './containers/manage-games/index';
import M2EGame from './containers/manage-games/m2e/index';
import M2MGame from './containers/manage-games/m2m/index';
import SkillRace from './containers/manage-games/skill-race/index';
import Admins from './containers/manage-admins/index';
import Leaderboard from './containers/leaderboard/index';
import Earnings from './containers/earnings/index';
import Milestones from './containers/milestones/index';
import AddMilestone from './containers/milestones/add-milestone.js';
import AllScrips from './containers/scrips-voting/all-scrips.js';
import ManageContent from './containers/manage-content/index.js';
import Settings from './containers/settings/index.js';
import Reports from './containers/reports/index'
import Download from './containers/downloads/index.js';
import TaxReport from './containers/tax-reports/index.js'

/*import component*/
import { Footer, InnerHeader, Sidebar } from './components/navigation/index';

export default function App(props) {
    const [collapseMenu, setCollapseMenu] = useState(false);
    //eslint-disable-next-line
    const [width, setWidth] = useState(window.screen.width)
    var id = localStorage.getItem("type")

    useEffect(() => {
        window.addEventListener('resize', (event) => {
            setWidth(event.target.innerWidth)
            if (event.target.innerWidth <= 1024) {
                setCollapseMenu(true)
            } else {
                setCollapseMenu(false)
            }
        })
    }, [])

    useEffect(() => {
        if (!localStorage.getItem("token") && window.location.pathname !== "/") {
            window.location.href = "/"
        }
        //eslint-disable-next-line
    }, [localStorage.getItem("token"), window.location.pathname])

    return (
        <>
            {
                localStorage.getItem("token") ?
                    <Router>
                        <div className="container-fluid d-flex flex-column flex-lg-row p-0">
                            <div className={collapseMenu || width <= 1024 ? "e-sidebar-collapsed sticky-top p-0 " : "e-sidebar-wrap sticky-top p-0"}>
                                <Sidebar is_menu_collapse={collapseMenu} width={width} />
                            </div>
                            <div className="e-content-wrap position-relative p-0">
                                <InnerHeader collapse={() => { setCollapseMenu(!collapseMenu) }} />
                                <div className="p-5 e-content-body">
                                    <Routes>
                                        {
                                            Number(id) === 1 ?
                                                <Route exact path="/" element={<Dashboard />} />
                                                :
                                                Number(id) === 2 ?
                                                    <Route exact path="/" element={<Dashboard />} />
                                                    :
                                                    Number(id) === 3 ?
                                                        <Route exact path="/" element={<Transactions />} />
                                                        : Number(id) === 4 ?
                                                            <Route exact path="/" element={<Users />} />
                                                            : null
                                        }
                                        <Route path="/users" element={<Users />} />
                                        <Route path="/user-detail" element={<UserDetails />} />
                                        <Route path="/notifications" element={<Notification />} />
                                        <Route path="/funds" element={<Funds />} />
                                        <Route path="/bookings" element={<Bookings />} />
                                        <Route path="/results" element={<Results />} />
                                        <Route path="/reports" element={<Reports />} />
                                        <Route path="/tax-reports" element={<TaxReport />} />
                                        <Route path="/downloads" element={<Download />} />
                                        {
                                            Number(id) !== 3 ?
                                                <Route path="/transactions" element={<Transactions />} />
                                                :
                                                null
                                        }
                                        <Route path="/manage-games" element={<ManageGames />} />
                                        <Route path="/M2E-game" element={<M2EGame />} />
                                        <Route path="/M2M-game" element={<M2MGame />} />
                                        <Route path="/skill-race-game" element={<SkillRace />} />
                                        <Route path="/manage-admins" element={<Admins />} />
                                        <Route path="/leaderboard" element={<Leaderboard />} />
                                        <Route path="/earnings" element={<Earnings />} />
                                        <Route path="/milestones" element={<Milestones />} />
                                        <Route path="/add-milestone" element={<AddMilestone />} />
                                        <Route path="/scrips-voting" element={<AllScrips />} />
                                        <Route path="/manage-content" element={<ManageContent />} />
                                        <Route path="/platform-settings" element={<Settings />} />

                                    </Routes>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </Router>
                    :
                    <Router>
                        <div>
                            <Routes>
                                <Route exact path="/" element={<Login />} />
                                <Route exact path="/change-password" element={<ChangePassword />} />
                                <Route exact path="/create-password" element={<CreatePassword />} />
                            </Routes>
                        </div>
                    </Router>
            }
        </>
    )

}