/*
 *   File : milestone.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Milestone Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 16-02-2022
 */
/*import component */
import { useEffect, useState } from 'react';
import { Confirmation } from '../../components/modal';

/* import style */
import style from '../../styles/components/milestoneCard.module.scss';


/* import assets */
import Edit from '../../assets/images/card/edits.svg';
import Delete from '../../assets/images/card/delete.svg';
import { Modal } from 'bootstrap';

const MilestoneCard = (props) => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (status) {
      var modal = new Modal(document.getElementById("confirmation"), {});
      modal.show()
    }
  }, [status])
  return (
    <>

      {/* <div className='col-lg-6'> */}
      <div className={`${style.e_milestone_card} position-relative`}>
        <div className='row'>
          <div className='col-lg-12'>
            <h6 className=''>{props.data.milestone_name}</h6>
          </div>
        </div>
        <div className='row pt-1'>
          <div className='col-lg-3 col-3 pe-0'>
            <label>Trading days</label>
            <p>{props.data.total_trading_days}</p>

          </div>
          {
            props.index === 3 ?
              null
              :
              <div className='col-lg-3  col-3 ps-0'>
                <label>Total possible points</label>
                <p>{props.data.max_possible_milestone_points}</p>
              </div>
          }
          <div className='col-lg-3 col-3 ps-0'>
            {
              props.index === 3 ?
                <label>Required avg score</label>
                :
                <label>Required points %</label>
            }

            <p>{props.data.required_target_points_percentage}</p>
          </div>
          {
            props.index === 3 ?
              <div className='col-lg-4 col-4'>
                <label>Required min bookings %</label>
                <p>{props.data.min_required_booking_scrip_percentage}</p>
              </div>
              :
              <div className='col-lg-3 col-3'>
                <label>Required points</label>
                <p>{props.data.required_target_points}</p>
              </div>
          }
        </div>
        <div className='row pt-1'>
          <div className='col-lg-4 pe-0 col-4'>
            <label>Total possible booking</label>
            <p>{props.data.max_possible_bookings_in_a_month}</p>
          </div>
          <div className='col-lg-3 col-3 ps-0'>
            <label>Type</label>
            <p>{props.data.time_period}</p>
          </div>
          <div className='col-lg-5 ps-0 col-5'>
            <label>Period</label>
            <p>{props.data.time_period_range}</p>
          </div>
        </div>

        <div className='row pt-2'>
          <div className='col-lg-10 col-10'>
            <label>Reward: <span>₹{props.data.rewards}</span></label>
          </div>
          <div className='col-lg-2 col-2'>
            <div className='d-flex  ms-3'>
              <img alt="" src={Delete} className={`${style.e_icon} pe-3`}
                onClick={() => setStatus(true)} />
              <img alt="" src={Edit}
                className={`${style.e_icon}`}
                onClick={() => props.mileStoneData(props.data)} />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {
        status ?
          <Confirmation
            mileStoneId={props.data.milestone_id}
            title="Confirm delete"
            content="Do you really want to delete the milestone?"
            btn_name="Yes"
            update={() => { setStatus(false); props.update() }}
            close={() => { setStatus(false); }} />
          :
          null
      }

    </>

  )
}

export default MilestoneCard;