/*
 *   File : selectBox.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-11-2021
 */

/*import package*/
import React from 'react';
import Select from 'react-select';

/*import style*/
import styles from '../../styles/components/selectBox.module.scss';

const SelectBox = (props) => {

  const valueChange = (e) => {
    props.selectchange(e)

  }
  return (
    <Select
      isSearchable={props.isSearchable}
      isClearable={props.isClearable}
      //eslint-disable-next-line
      className={`${styles.e_select_box}` + " " + props.className}
      onChange={(e) => { valueChange(e) }}
      isDisabled={props.isDisabled}
      options={props.options}
      value={props.value}
      isMulti={props.selectType}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      // menuIsOpen={true}
      {...props} />
  );
}

export default SelectBox;
