/*
 *   File : privacy.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Privacy container
 *   Integrations : 11-04-2022
 *   Version : v1.0
 *   Created : 17-02-2022
 */
/*import packages*/
import { useState, useEffect } from 'react';
import { PrimaryBtn, PrimaryLink } from '../../components/cta';
import { PrivacyModal } from '../../components/modal/index'

/*import components*/
import { Editor } from '../../components/input/index';
import { Toast } from '../../components/note';
import Loader from '../../components/loader/loader';

/* import services */
import service from '../../services/service';

const Privacy = (props) => {
    const [editorData, setEditorData] = useState("");
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(null);

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        getPrivacy();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (error !== null) {
                setError(null)
            }
        }, 3000);
    }, [error])

    /*---------add privacy ----------*/
    const managePrivacy = () => {
        let url = '/general/manage-privacy';

        let data = {
            text: editorData
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                getPrivacy();
            }
            else {
                setError(response.data.message);
            }

        });
    }

    /*--------------get privacy -----------*/
    const getPrivacy = () => {
        setLoader(true)
        let url = '/general/get-privacy';

        service(false, url).then((response) => {

            if (response.data.status_code === 200) {
                setLoader(false);
                setEditorData(response.data.data.text);
            }
            else {
                setError(response.data.message);
                setLoader(false);
            }
        });
    }
    return (
        <>
            <div className="row">
                <div className="col-lg-10 col-8">
                    <h6 className="e-card-title">Privacy policy</h6>
                </div>
                <div className="col-lg-2 col-4 text-end">
                    <PrimaryLink
                        name="View current"
                        toggle="modal"
                        target="#privacy"
                        className="e-currnt-btn"
                    />
                </div>
            </div>
            <div className='row pt-4'>
                <div className='col-lg-12'>
                    {
                        loader ?

                            <Loader />
                            :
                            <Editor
                                editorChange={(temp) => {
                                    setEditorData(temp)
                                }}
                                value={editorData}
                            />
                    }
                </div>
            </div>
            <div className='row pt-4 mt-3'>
                <div className='col-lg-1'>
                    {
                        editorData ?
                            <PrimaryBtn
                                name="Save"
                                className="e-editor-save"
                                handleClick={() => { managePrivacy() }} />
                            :
                            <PrimaryBtn
                                name="Save"
                                className="e-editor-save e-disabled"
                            />
                    }
                </div>
            </div>
            <PrivacyModal data={editorData} />
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </>

    )
}

export default Privacy;
