/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for M2E container;
 *   Integrations : 11-04-2022
 *   Version : v1.0
 *   Created : 10-02-2022
 */
/*import pakcages*/
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'


/*import components*/
import { GameTabs } from '../../../components/dataFormating/index';
import { M2EGameSettingsAccordion } from '../../../components/dataFormating/index';

/*import containers */
import Scrips from './scrips.js';
import BlockedUsers from './blocked-users.js';

/*import assets*/
import Arrow from '../../../assets/images/milestone/arrow.svg';

const M2EGame = (props) => {
    const [tabIndex, setTabindex] = useState(1);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <h3 className='e-header-title'><Link to="/manage-games"><img src={Arrow} className="me-3" alt='' /></Link>Manage M2E</h3>
            <div className='e-users-container'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card e-dashboard-card border-0">
                            <div className="row">
                                <div className="col-lg-12 pt-4 ps-3 ms-3 mt-1">
                                    <GameTabs data={['Game settings', 'Scrips', 'Blocked users']} index={tabIndex} setTab={(temp) => setTabindex(temp)} />
                                </div>
                                {
                                    tabIndex === 1 ?
                                        <div className='mt-4'>
                                            <M2EGameSettingsAccordion />
                                            {/* <p>Yet to complete</p> */}
                                        </div>

                                        : tabIndex === 2 ?
                                            <div className='mt-4'>
                                                <Scrips />
                                            </div>

                                            :
                                            <div className='mt-4'>
                                                <BlockedUsers />
                                            </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default M2EGame;
