import { useEffect } from "react";
import "react-step-progress-bar/styles.css";

// import styles
import style from '../../styles/components/progress.module.scss';

const Progress = (props) => {

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(props.id).style.width = props.percent + '%';
    }, 200)
    //eslint-disable-next-line
  }, [props.percent])
  return (
    // <ProgressBar
    //   percent={props.percent}
    //   filledBackground= "#9C1FE9"
    //   height={4}
    //   unfilledBackground	="#0D041D"
    // />
    <div className={`${style.e_progress_track} position-relative`}>
      <div className={`${style.e_progress_bar}`} id={props.id ? props.id : 'progress'} ></div>
    </div>
  );
}
export default Progress;