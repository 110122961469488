/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input, TextArea } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';


/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';

/* import services */
import service from '../../services/service';
import { GameTabs } from '../dataFormating';

export default function AddFaq(props) {

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionHindi, setQuestionHindi] = useState("");
  const [answerHindi, setAnswerHindi] = useState("");
  const [questionGuj, setQuestionGuj] = useState("");
  const [answerGuj, setAnswerGuj] = useState("");
  const [questionBen, setQuestionBen] = useState("");
  const [answerBen, setAnswerBen] = useState("");
  //eslint-disable-next-line
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState("");
  const [index, setIndex] = useState(1);

  useEffect(() => {
    if (props.data?.question && props.data?.answer) {
      setQuestion(props.data.question.en);
      setAnswer(props.data.answer.en);
      setQuestionHindi(props.data.question.hi);
      setAnswerHindi(props.data.answer.hi);
      setQuestionGuj(props.data.question.gu);
      setAnswerGuj(props.data.answer.gu);
      setQuestionBen(props.data.question.bn);
      setAnswerBen(props.data.answer.bn);
      setId(props.data.faq_id);
    }
  }, [props.data]);

  /* --- edit faq----*/
  const editFaq = () => {
    setLoader(true)
    let url = '/faq/edit-question';

    let data = {
      faq_id: id,
      "question": {
        "en": question,
        "hi": questionHindi,
        "gu": questionGuj,
        "bn": questionBen
      },
      "answer": {
        "en": answer,
        "hi": answerHindi,
        "gu": answerGuj,
        "bn": answerBen
      }
    }

    service(true, url, data).then((response) => {
      if (response.data.status_code === 200) {
        $("#editFaq").trigger("click")
        setLoader(false);
        props.update(true)
      }
      else {
        setError(response.data.message);
        setLoader(false);
      }
    });
  }

  /* --- add faq----*/
  const addFaq = () => {
    setLoader(true)
    let url = '/faq/add-question';


    let data = {
      "question": {
        "en": question,
        "hi": questionHindi,
        "gu": questionGuj,
        "bn": questionBen
      },
      "answer": {
        "en": answer,
        "hi": answerHindi,
        "gu": answerGuj,
        "bn": answerBen
      }
    }

    service(true, url, data).then((response) => {
      if (response.data.status_code === 200) {
        $("#addFaq").trigger("click")
        setLoader(false);
        props.update(true);
        setQuestion("");
        setAnswer("");
      }
      else {
        setError(response.data.message);
        setLoader(false);
      }
    });
  }

  const clearFields = () => {
    setQuestion("");
    setAnswer("");
    setQuestionHindi("");
    setAnswerHindi("");
    setQuestionGuj("");
    setAnswerGuj("");
    setQuestionBen("");
    setAnswerBen("");
  }
  return (
    <div className={`${styles.e_add_faq} modal fade`} id={props.edit ? "editFaq" : "addFaq"} tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6>{props.edit ? "Edit question" : "Add question"}</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => clearFields()}></button>
          </div>
          <div className="container px-3">
            <div className='row'>
              <div className='col-lg-12'>
                <GameTabs
                  data={["English", "Hindi", "Gujarati", "Bengali"]}
                  index={index}
                  setTab={(temp) => { setIndex(temp) }} />
              </div>
            </div>
            {
              index === 1 ?
                <div className="row">
                  <div className="col-lg-12 pt-3 mt-2">
                    <label>Question</label>
                    <Input
                      placeholder="Question"
                      value={props.edit ? question : question}
                      valueChange={props.edit ? (value) => { setQuestion(value) } : (value) => { setQuestion(value) }} type="text" />
                  </div>
                  <div className="col-lg-12 pt-3 mt-2">
                    <label>Answer</label>
                    <TextArea rows="9" placeholder="Answer" value={props.edit ? answer : answer} valueChange={props.edit ? (value) => { setAnswer(value) } : (value) => { setAnswer(value) }} type="text" />
                  </div>
                </div>
                :
                index === 2 ?
                  <div className="row">
                    <div className="col-lg-12 pt-3 mt-2">
                      <label>Question</label>
                      <Input
                        placeholder="Question"
                        value={props.edit ? questionHindi : questionHindi}
                        valueChange={props.edit ? (value) => { setQuestionHindi(value) } : (value) => { setQuestionHindi(value) }} type="text" />
                    </div>
                    <div className="col-lg-12 pt-3 mt-2">
                      <label>Answer</label>
                      <TextArea rows="9" placeholder="Answer" value={props.edit ? answerHindi : answerHindi} valueChange={props.edit ? (value) => { setAnswerHindi(value) } : (value) => { setAnswerHindi(value) }} type="text" />
                    </div>
                  </div>
                  :
                  index === 3 ?
                    <div className="row">
                      <div className="col-lg-12 pt-3 mt-2">
                        <label>Question</label>
                        <Input
                          placeholder="Question"
                          value={props.edit ? questionGuj : questionGuj}
                          valueChange={props.edit ? (value) => { setQuestionGuj(value) } : (value) => { setQuestionGuj(value) }} type="text" />
                      </div>
                      <div className="col-lg-12 pt-3 mt-2">
                        <label>Answer</label>
                        <TextArea rows="9" placeholder="Answer" value={props.edit ? answerGuj : answerGuj} valueChange={props.edit ? (value) => { setAnswerGuj(value) } : (value) => { setAnswerGuj(value) }} type="text" />
                      </div>
                    </div>
                    :
                    index === 4 ?
                      <div className="row">
                        <div className="col-lg-12 pt-3 mt-2">
                          <label>Question</label>
                          <Input
                            placeholder="Question"
                            value={props.edit ? questionBen : questionBen}
                            valueChange={props.edit ? (value) => { setQuestionBen(value) } : (value) => { setQuestionBen(value) }} type="text" />
                        </div>
                        <div className="col-lg-12 pt-3 mt-2">
                          <label>Answer</label>
                          <TextArea rows="9" placeholder="Answer" value={props.edit ? answerBen : answerBen} valueChange={props.edit ? (value) => { setAnswerBen(value) } : (value) => { setAnswerBen(value) }} type="text" />
                        </div>
                      </div>
                      :
                      null
            }
            <div className="row pt-4 mt-3 pb-2">
              <div className="col-lg-12">
                {
                  props.edit ?
                    <div className="d-flex justify-content-end">
                      <SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" />
                      <PrimaryBtn name="Save a question" className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"} handleClick={() => editFaq()} />
                    </div>
                    :
                    <div className="d-flex justify-content-end">
                      <SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" handleClick={() => clearFields()} />
                      {
                        question && answer && questionHindi && answerHindi && questionGuj && answerGuj && questionBen && answerBen ?
                          <PrimaryBtn name="Save question" className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"} handleClick={() => addFaq()} />
                          :
                          <PrimaryBtn name="Save question" className="e-modal-submit e-disabled" />
                      }
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
