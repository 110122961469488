/*
 *   File : addTransaction.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add transaction Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input, RadioButton } from '../../components/input/index';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';
import service from '../../services/service';
import { Toast } from '../note';

import Logo from '../../assets/images/scrips/logo.svg'
import Delete from '../../assets/images/scrips/delete.svg'

export default function UpdateScrip(props) {
    //eslint-disable-next-line
    const wrapperRef = useRef(null);
    const [error, setError] = useState(null);
    //eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState("");
    const [show, setShow] = useState(false);
    const [id, setId] = useState(1)
    const [scripData, setscripData] = useState([]);
    const [chosenScrips, setChosenScrips] = useState([]);
    const [scripIds, setScripIds] = useState([])
    const [scripCount, setScriCount] = useState(null);
    //eslint-disable-next-line
    const [placeholders, setPlaceholder] = useState([

        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
    ]);

    useEffect(() => {
        var myModalEl = document.getElementById('updateScrips')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            // setChosenScrips([]);
            setScripIds([]);
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        var myModalEl = document.getElementById('updateScrips')
        myModalEl.addEventListener('shown.bs.modal', (event) => {
            getScripCount(1);
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    const _closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShow(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);

    /*---- search user ----*/
    const searchScrip = (value, status) => {
        setShow(status);
        // setSearch(value)
        let url = "/scrip/search";

        let data = {
            query: value,
            // type: id === 1 ? 'm2e' : 'm2m'
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setscripData(response.data.data);
            } else {
                setscripData([]);
            }
        });
    }

    const updateScrip = () => {
        let url = "/scrip/update-game-scrips";
        let data = {
            scrip_ids: scripIds,
            game_type_id: id
        }
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                $("#updateScrips").trigger("click");
                props.success()
                getScripCount()
            } else {
                setError(response.data.message)
            }
        })
    }

    /* m2e details */
    const getScripCount = (id) => {
        let url = ""
        if (Number(id) === 1) {
            url = '/m2e/detail';
        } else {
            url = '/m2m/details';
        }

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tempScrips = []
                response.data.data.scrip_data.map((item) => {
                    return (
                        tempScrips.push({
                            name: item.scrip_name,
                            code: item.scrip_code,
                            scrip_code: item.scrip_code,
                            scrip_id: item.scrip_id
                        })
                    )
                })
                let tempScripsIds = []
                response.data.data.scrip_data.map((item) => {
                    return (
                        tempScripsIds.push(
                            item.scrip_id
                        )
                    )
                })
                setScripIds(tempScripsIds)
                setChosenScrips(tempScrips)
                if (Number(id) === 1) {
                    setScriCount(response.data.data.basic_info.game_scrip_count);
                } else {
                    setScriCount(response.data.data.basic_info.stocks);
                }
                // console.log(response.data.data.basic_info.game_scrip_count)
            } else {
                setScriCount(null)

            }
        })
    }

    // console.log(scripCount)
    return (
        <>
            <div className={`${styles.e_add_faq} modal fade e-add-block-rule`} id="updateScrips" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Update scrips</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { }}></button>
                        </div>
                        <div className="container px-3">
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <span className='d-flex justify-content-center'>
                                        <RadioButton checked={parseInt(id) === 1 ? true : false} label="M2E" id={1} onClick={(e) => { setId(e.target.id); getScripCount(e.target.id) }} />
                                        <RadioButton checked={parseInt(id) === 2 ? true : false} label="M2M" id={2} onClick={(e) => { setId(e.target.id); getScripCount(e.target.id) }} />
                                    </span>


                                </div>
                            </div>
                            <div className="row">

                                <div className="col-lg-8" onClick={() => { searchScrip(" ", true) }}>
                                    <label className="mb-2">Scrips</label>
                                    <Input
                                        placeholder="Search scrips"
                                        value={search}
                                        valueChange={(value) => {
                                            searchScrip(value, true);
                                            setSearch(value)
                                        }}
                                        type="text"
                                    />
                                </div>

                                <div className='row '>
                                    {
                                        show ?
                                            scripData?.length > 0 ?
                                                <div className='e-get-scrip-list ms-3' ref={wrapperRef}>
                                                    {
                                                        scripData.map((element) => {
                                                            return (
                                                                <div className='e-list-items mb-2' >
                                                                    <div className='d-flex'>
                                                                        <span>{element.name},{` (${element.code}) `}</span>
                                                                        <span className='ms-auto'>
                                                                            {
                                                                                scripIds.includes(element.scrip_id) ?
                                                                                    null
                                                                                    :
                                                                                    <SecondaryBtn
                                                                                        name="+Add"
                                                                                        className={scripIds.length >= scripCount ? "e-disabled px-3 py-2" : "px-3 py-2"}
                                                                                        handleClick={() => {
                                                                                            let temp = [...chosenScrips]
                                                                                            let tempIds = [...scripIds]
                                                                                            let idx = tempIds.findIndex((item) => item === element.scrip_id)
                                                                                            let idxChosen = temp.findIndex((item) => item.scrip_id === element.scrip_id)
                                                                                            if (idx === -1) {
                                                                                                tempIds.push(element.scrip_id)
                                                                                            }
                                                                                            if (idxChosen === -1) {
                                                                                                temp.push(element)
                                                                                            }
                                                                                            setScripIds(tempIds)
                                                                                            setChosenScrips(temp)
                                                                                        }} />
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <div className='e-get-list d-flex justify-content-center align-items-center'>
                                                    No data found
                                                </div>
                                            :
                                            null
                                    }

                                </div>
                                <div className='row pt-4 mt-2 mb-4 pb-2'>
                                    <div className='col-lg-10 col-md-11 col-10'>
                                        <h5>Scrips you chose</h5>
                                    </div>
                                    <div className='col-lg-2 col-md-1 col-2 text-end'>
                                        <span>{chosenScrips.length}/{scripCount}</span>
                                    </div>

                                </div>
                                <div className='row e-scrip-voting-list'>
                                    {
                                        chosenScrips.length > 0 ?
                                            chosenScrips.map((item, key) => {
                                                return (
                                                    <div className='col-lg-6 col-md-6 mb-4 pb-2' key={key}>
                                                        <div className='d-flex'>
                                                            {/* <img alt="" src={item.scrip_image ? item.scrip_image : Logo} className='me-2 e-scrip-votin-logo' /> */}
                                                            <div className={item.name ? "" : "mt-2"}>
                                                                <span>{item.name ? item.name : ' To be added'}</span>
                                                                {
                                                                    item.scrip_code ?
                                                                        <span className='d-block'>{item.code}</span>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            <img src={Delete} alt="" className='ms-auto' style={{ cursor: "pointer" }} onClick={() => {

                                                                let tempChosen = [...chosenScrips]
                                                                let idxChosen = tempChosen.findIndex((element) => element.scrip_id === item.scrip_id)
                                                                tempChosen.splice(idxChosen, 1)
                                                                setChosenScrips(tempChosen)

                                                                let temp = [...scripIds]
                                                                let idx = temp.findIndex((element) => element === item.scrip_id)
                                                                temp.splice(idx, 1)
                                                                setScripIds(temp)

                                                            }} />
                                                        </div>

                                                    </div>
                                                )
                                            })
                                            :
                                            placeholders.map((item, key) => {
                                                return (
                                                    <div className='col-lg-6 col-md-6 mb-4 pb-2' key={key}>
                                                        <div className='d-flex'>
                                                            <img alt="" src={item.scrip_image ? item.scrip_image : Logo} className='me-2 e-scrip-votin-logo' />
                                                            <div className={item.scrip_name ? "" : "mt-2"}>
                                                                <span>{item.scrip_name ? item.scrip_name : ' To be added'}</span>
                                                                {
                                                                    item.scrip_code ?
                                                                        <span className='d-block'>{item.scrip_code}</span>
                                                                        :
                                                                        null
                                                                }

                                                            </div>

                                                        </div>

                                                    </div>
                                                )
                                            })
                                    }
                                </div>


                            </div>
                            <div className="row pt-3 mt-2  pb-2">


                                <div className="col-lg-12 pt-3 mt-2 text-end">
                                    <label></label>
                                    {
                                        scripIds.length > 0 ?  // userId?.id && amount && remarks && selectedType ?
                                            <PrimaryBtn
                                                name="Save"
                                                className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                handleClick={() => {
                                                    updateScrip()
                                                }}
                                            />
                                            :
                                            <PrimaryBtn
                                                name="Save"
                                                className={`${styles.e_disabled} e-modal-submit `}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </>
    )
}
