/*
 *   File : funds.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Funds Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 12-11-2021
*/

/*import packages*/

/*import styles*/
import styles from '../../styles/components/aggressiveFund.module.scss';

/*import assets*/


const AggresiveFund = (props) => {
  return (
    <>
      <div className={`${styles.e_fund_card} card border-0`}>
        <div className="row">
          <div className="col-lg-12 mt-3">
            <div className={`${styles.e_inner_card} p-3`}>

              <div className="row">
                <div className="col-lg-2">
                  <img src={props.data.icon} className={styles.e_logo} alt="" />
                </div>
                <div className="col-lg-10">
                  <h6 className={styles.e_inner_card_title}>{props.data.organization}</h6>
                </div>
              </div>
              <div className="row pt-1">
                <div className="col-lg-12">
                  <label>Returns</label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <p className={styles.e_returns}><span>{props.data.year}</span> {props.data.percentage}</p>
                </div>
                <div className="col-lg-3">
                  <p className={styles.e_returns}><span>{props.data.year}</span> {props.data.percentage}</p>
                </div>
                <div className="col-lg-3">
                  <p className={styles.e_returns}><span>{props.data.year}</span> {props.data.percentage}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default AggresiveFund;
