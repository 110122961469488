/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 26-07-2022
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakcages*/
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

/*import components*/
import { DataTable, GameTabs } from '../../components/dataFormating/index';
import { SearchInput } from '../../components/input/index';
import { PrimaryLink } from '../../components/cta/index';
import Loader from '../../components/loader/loader';
import { Summary } from '../../components/card/index';
import { EmptyNote } from '../../components/note';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import assets */
import Revenue from '../../assets/images/card/summary/revenue.svg';
import Users from '../../assets/images/card/summary/users.svg';
import Bookings from '../../assets/images/card/summary/bookings.svg';
import service from '../../services/service';
import Fees from '../../assets/images/card/summary/fee.svg';
import Nodata from '../../assets/images/note/no-data.png';


const Referrals = (props) => {
    // window.scrollTo({top: 0, behavior: 'smooth'});
    const wrapperRef = useRef(null);
    const [search, setSearch] = useState("");
    //eslint-disable-next-line
    const [more, setMore] = useState(false);
    //eslint-disable-next-line
    const [check, setCheck] = useState();
    const [loader, setLoader] = useState(false);
    const [summaryData, setSummaryData] = useState({});
    const [overviewLoader, setOverviewLoader] = useState(false);
    const [referralData, setReferralData] = useState({});
    const [index, setIndex] = useState(1);
    const [referralPayout, setReferralPayout] = useState([]);
    //eslint-disable-next-line
    const [pageNum, setPageNum] = useState(1);
    //eslint-disable-next-line
    const [pageSize, setPageSize] = useState(200)

    const closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setMore(false)
        }
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };
    }, []);

    useEffect(() => {
        getReferralSummary();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (index === 3) {
            getReferralPayout();
        }
        //eslint-disable-next-line
    }, [pageSize, pageNum, index])

    useEffect(() => {
        if (search === "") {
            getReferralList();
        }
        //eslint-disable-next-line
    }, [search])

    /* get referral summary details */
    const getReferralSummary = () => {
        setOverviewLoader(true);
        let url = "/user/referral/summary";

        let data = {
            "user_id": Number(props.id)
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setOverviewLoader(false);
                setSummaryData(response.data.data);
            } else {
                setOverviewLoader(false);
            }
        });
    }

    /* get referral list */
    const getReferralList = (temp, is_download) => {
        setLoader(true);
        let url = "/user/referral/list";

        let data = {
            "user_id": Number(props.id),
            "query": temp ? temp.query : search,
            "is_referral_history": index === 1 ? false : true,
            "is_download": is_download ? is_download : false
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {

                setLoader(false);
                if (is_download) {
                    let a = document.createElement("a");
                    a.href = response.data.data.url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    setReferralData(response.data.data);
                }
            } else {
                setLoader(false);
                setReferralData([]);
            }
        });
    }

    //referral payout history api
    const getReferralPayout = (is_download) => {
        setLoader(true);
        let url = "/report/referral-payout";
        let data = {
            user_id: Number(props.id),
            is_download: is_download ? is_download : false,
            page_num: pageNum,
            page_size: pageSize
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                if (is_download) {
                    let a = document.createElement("a");
                    a.href = response.data.data.url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    setReferralPayout(response.data.data);
                }
            }
        });
    }

    const customDateSort = (a, b, selector, given) => {
        let format = given ? given : "DD MMM YYYY"
        if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
        else if (a[selector]) return -1;
        else if (b[selector]) return 1;
        else return 0;
    }

    const referral = [
        {
            name: 'Referral ID',
            selector: 'referral_id',
            sortable: false,
            left: true,
            minWidth: '120px'
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            left: true,
            minWidth: "140px",
            sortFunction: (a, b) => customDateSort(a, b, 'date')
        },
        {
            name: 'Game ID.',
            selector: 'game_id',
            sortable: false,
            left: true,
            minWidth: "120px",
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            left: true,
            maxWidth: "190px",
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: false,
            left: true,
            minWidth: '250px',
            cell: row => <>
                {
                    row.email
                }
            </>
        },
        {
            name: 'Mobile number',
            selector: 'mobile',
            sortable: false,
            left: true,
            minWidth: '160px'
        },
        {
            name: 'DOJ',
            selector: 'doj',
            sortable: false,
            left: true,
            minWidth: '120px'
        },
        {
            name: "Type",
            selector: 'type',
            sortable: false,
            left: true,
            minWidth: '140px'
        },
        {
            name: 'KYC status',
            selector: 'kyc_status',
            sortable: false,
            left: true,
            minWidth: '140px'
        },
        {
            name: 'Booking amount',
            selector: 'booking_amount',
            sortable: false,
            center: true,
            minWidth: '150px',
            cell: row => <>
                ₹ {row.booking_amount}
            </>
        },

        {
            name: 'Commission %',
            selector: 'commission_percentage',
            sortable: false,
            center: true,
            minWidth: "150px",
            cell: row => <>
                {row.commission_percentage}
            </>
        },
        {
            name: 'Rewards',
            selector: 'rewards',
            sortable: false,
            left: true,
            maxWidth: "180px",
        },

        {
            name: 'Transaction status ',
            selector: 'status',
            sortable: false,
            left: true,
            minWidth: '180px',
            cell: row => <div className={row.status === "Pending" ? "e-status-pending" : row.status === "Success" ? "e-status-success" : "e-status-failed"}>{row.status}</div>,
        },


    ]

    const columns = [
        {
            name: 'Referral ID',
            selector: 'referral_id',
            sortable: false,
            left: true,
            minWidth: '120px'
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: false,
            left: true,
            minWidth: "120px",
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            left: true,
            minWidth: '150px'
        },
        {
            name: 'Game ID.',
            selector: 'game_id',
            sortable: false,
            left: true,
            minWidth: "120px",
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: false,
            left: true,
            minWidth: '250px',
            cell: row => <>
                {row.email}
            </>
        },
        {
            name: 'Mobile number',
            selector: 'mobile',
            sortable: false,
            left: true,
            minWidth: '150px'
        },
        {
            name: 'DOJ',
            selector: 'doj',
            sortable: false,
            left: true,
            minWidth: '120px'
        },
        {
            name: 'KYC status',
            selector: 'kyc_status',
            sortable: false,
            left: true,
            minWidth: '160px'
            // maxWidth: '190px'
        },
        {
            name: 'Booking amount',
            selector: 'booking_amount',
            sortable: false,
            left: true,
            minWidth: '150px',
            cell: row => <>
                ₹ {row.booking_amount}
            </>
        },
        {
            name: 'Commission',
            selector: 'commission_percentage %',
            sortable: false,
            left: true,
            minWidth: '150px',
            cell: row => <>
                {row.commission_percentage}
            </>
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: false,
            left: true,
            minWidth: '130px'
        },
        {
            name: 'Rewards',
            selector: 'rewards',
            sortable: false,
            left: true,
            cell: row => ` ₹ ${row.rewards}`
        },
        {
            name: 'Payout status ',
            selector: 'status',
            sortable: false,
            left: true,
            cell: row => <div className={row.status === "Pending" ? "e-status-pending" : row.status === "Success" ? "e-status-success" : "e-status-failed"}>{row.status}</div>,
            minWidth: '130px'
        },
    ]

    const payout = [
        {
            name: 'ID',
            selector: 'transaction_id',
            sortable: false,
            left: true,
            minWidth: "120px",
        },
        {
            name: 'Date',
            selector: 'transaction_date',
            sortable: false,
            left: true,
            minWidth: "120px",
        },
        {
            name: 'Time',
            selector: 'transaction_time',
            sortable: false,
            left: true,
            maxWidth: "190px",
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: false,
            center: true,
            minWidth: '250px',
            cell: row => <>
                {row.description}
            </>
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: false,
            center: true,
            minWidth: '150px',
            cell: row => <>
                ₹ {row.amount}
            </>
        },
    ]

    const Export = ({ onExport }) => <PrimaryLink name="Download" handleClick={e => onExport(e.target.value)}>Export</PrimaryLink>;

    return (
        <div className='e-users-container'>
            {
                overviewLoader ?
                    <div className='position-relative'>
                        <Loader />
                    </div>
                    :
                    <div className="row">
                        <div className="col-lg-3 mb-3">
                            <Summary
                                data={{
                                    title: 'Pending payout amount',
                                    count: summaryData.pending_payout_amount !== "-" ? summaryData.pending_payout_amount : 0,
                                    icon: Revenue,
                                    // prefix: "₹"
                                }}
                                className="e-summary-count" />
                        </div>
                        <div className="col-lg-3 mb-3">
                            <Summary
                                data={{
                                    title: 'Referral Count',
                                    count: summaryData.referral_count !== "-" ? summaryData.referral_count : 0,
                                    icon: Users,
                                    text: ""
                                }}
                                className="e-summary-count" />
                        </div>
                        <div className="col-lg-3 mb-3">
                            <Summary
                                data={{
                                    title: 'Remission amount',
                                    count: summaryData.remission_amount !== "-" ? summaryData.remission_amount : 0,
                                    icon: Bookings,
                                    // prefix: "₹"
                                }}
                                className="e-summary-count" />
                        </div>
                        <div className="col-lg-3 mb-3">
                            <Summary
                                data={{
                                    title: 'Total referral income',
                                    count: summaryData.referral_income !== "-" ? summaryData.referral_income : 0,
                                    icon: Fees,
                                    // prefix: "₹"
                                }}
                                className="e-summary-count" />
                        </div>
                        {/* <div className="col-lg-3 mb-3">
              <Summary
                data={{
                  title: 'Passout  commission',
                  count: '200',
                  icon: Bookings,
                  prefix: "₹"
                }}
                className="e-summary-count" />
          </div> */}
                    </div>
            }
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className="row pt-4">
                            <div className="col-lg-8 ps-4">
                                <GameTabs
                                    data={["Earnings", "Referral History", "Referral payout report"]}
                                    setTab={(temp) => setIndex(temp)}
                                    index={index} />
                            </div>
                            <div className="col-lg-3 ps-0 position-relative">
                                {
                                    index === 3 ?
                                        null
                                        :

                                        <div className=' '>
                                            <SearchInput
                                                placeholder="Search with referral name"
                                                value={search}
                                                onSearch={(value) => {
                                                    getReferralList({ query: value })
                                                }}
                                                valueChange={(value) => { setSearch(value) }} />
                                            {
                                                search && search.length ?
                                                    <>
                                                        <span className={`${styles.e_search_submit} `} onClick={() => getReferralList()} ></span>
                                                        <button className={`${styles.e_search_close} pe-3`} onClick={() => setSearch("")} ></button>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                }
                            </div>
                            <div className="col-lg-1 pt-2 px-0 ">
                                {
                                    index === 1 ?
                                        referralData?.remissions?.length ?
                                            <Export onExport={() => {
                                                getReferralList(undefined, true)
                                            }} />
                                            :
                                            <PrimaryLink
                                                name="Download"
                                                className="e-disabled e-download-booking"
                                            />
                                        :
                                        index === 2 ?

                                            referralData?.referrals?.length ?
                                                <Export onExport={() => {
                                                    // const csvdata = convertArrayOfObjectsToCSV(referralData?.referrals);
                                                    // downloadCSV(csvdata)
                                                    getReferralList(undefined, true)
                                                }} />
                                                :
                                                <PrimaryLink
                                                    name="Download"
                                                    className="e-disabled e-download-booking"
                                                />
                                            :
                                            referralPayout?.length ?
                                                <Export onExport={() => {
                                                    // const csvdata = convertArrayOfObjectsToCSV(referralData?.referrals);
                                                    // downloadCSV(csvdata)
                                                    getReferralPayout(true)
                                                }} />
                                                :
                                                <PrimaryLink
                                                    name="Download"
                                                    className="e-disabled e-download-booking"
                                                />

                                }
                            </div>

                            {/* <div className="col-lg-1">
                                <FilterBtn className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"} handleClick={() => setFilterDown(!filterDown)} />
                            </div>
                            {
                                filterDown ?
                                    <div className="e-filter-wraper mt-3">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <label className="mb-2">Joined from - till</label>
                                                <DateRangePicker start={dates.start} end={dates.end} selectPrevious={true} maxDate={moment().format('DD-MM-YYY')} onDatesChange={(start, end) => { setDates({ start: start, end: end }); }} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            } */}
                            <div className="col-lg-12 e-booking-table mt-3">
                                {
                                    loader ?
                                        <Loader />
                                        :

                                        index === 1 ?
                                            <>
                                                {
                                                    referralData?.remissions?.length ?
                                                        <DataTable
                                                            pagination={false}
                                                            columns={columns}
                                                            data={referralData?.remissions} />
                                                        :
                                                        <EmptyNote note="No earnings found" img={Nodata} />
                                                }
                                            </>
                                            :
                                            index === 2 ?
                                                <>
                                                    {
                                                        referralData?.referrals?.length ?
                                                            <DataTable
                                                                pagination={false}
                                                                columns={referral}
                                                                data={referralData?.referrals} />
                                                            :
                                                            <EmptyNote note="No history found" img={Nodata} />
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        referralPayout.length ?
                                                            <DataTable
                                                                pagination={true}
                                                                columns={payout}
                                                                data={referralPayout} />
                                                            :
                                                            <EmptyNote note="No history found" img={Nodata} />
                                                    }
                                                </>

                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >

    )
}

export default Referrals;