/*import packages*/
import React from 'react';
import DataTable from 'react-data-table-component';

/*import assets*/

export default function Datatable(props) {

  return (
    <>
      <DataTable
        columns={props.columns}
        className={props.className}
        data={props.data}
        paginationPerPage={props.size ? props.size : 50}
        pagination={props.pagination}
        onRowClicked={props.rowClick ? (row) => props.rowClick(row) : null}
        paginationRowsPerPageOptions={[50, 100, 200, 300]}
        // selectableRowsHighlight={true}
        selectableRows={props.selectableRows}
        onSelectedRowsChange={(row) => props.selectRows(row)}
        conditionalRowStyles={props.conditionalRowStyles}
        onChangePage={props.onChangePage}
        paginationIconNext={props.paginationIconNext}
        clearSelectedRows={props.clearSelectedRows}
        paginationIconPrevious={props.paginationIconPrevious}
        paginationTotalRows={props.paginationTotalRows}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        paginationComponentOptions={props.paginationComponentOptions}

      />
    </>
  );
}




