// import React from 'react'import React from 'react'
import React from 'react'
import { ChromePicker } from 'react-color'
import { PrimaryBtn } from '../cta';

class ColorPicker extends React.Component {
	state = {
		displayColorPicker: false,
		color: null
	};

	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};

	handleClose = () => {
		this.setState({ displayColorPicker: false })
	};

	colorPicker = e => {
		const newColor = {
			hex: e.hex,
			rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")"
		};
		this.setState({
			color: newColor
		})
	};

	render() {
		const popover = {
			position: 'absolute',
			zIndex: '2',
			top: '0%',
			backgroundColor: "#ffffff",
			left: '35%',
			// borderRadius: '2px',
			boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 2px, rgb(0 0 0 / 30%) 0px 4px 8px',
			boxSizing: 'initial',
			padding: '10px',
			borderRadius: "8px",
			fontFamily: "Inter",
			width: '225px'
		}
		const cover = {
			position: 'fixed',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
		}
		return (
			<>
				<div className="e-uploader" onClick={this.handleClick} >

					<p className='text-center mb-2'>Choose color</p>
					<p className='text-center mb-0'>
						<span>{this.state.color ? `(Selected: ${this.state.color.hex})` : "(Use dark shades)"}</span>
					</p>
				</div>
				{this.state.displayColorPicker ? <div style={popover}>
					<div style={cover} onClick={this.handleClose} />
					<ChromePicker
						color={this.state.color !== null && this.state.color.hex}
						onChange={e => this.colorPicker(e)}
						disableAlpha
						renderers={false}
						style={{ width: "210px" }}
					/>
					<PrimaryBtn name="save color" className="mt-5" handleClick={() => {
						this.setState({ displayColorPicker: false });
						this.props.color(this.state.color)
					}} />
				</div> : null}

			</>
		)
	}
}

export default ColorPicker

