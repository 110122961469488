/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 12-04-2022
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakcages*/
import React, { useState, useEffect } from 'react';
import moment from 'moment';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { PrimaryBtn, PrimaryLink } from '../../components/cta/index';
import { Rangepicker } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import { EmptyNote } from '../../components/note';
import Loader from '../../components/loader/loader';

/*import assets*/
import Nodata from '../../assets/images/note/no-data.png'
import Next from '../../assets/images/dataFormating/next.svg';
import Prev from '../../assets/images/dataFormating/prev.svg';

/* import services */
import GetURLVar from '../../services/getUrl';
import service from '../../services/service';

const Results = (props) => {

    // let date = new Date()
    // var today = moment(date).format("YYYY-MM-DD")
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [userId, setUserId] = useState(null)
    //eslint-disable-next-line
    const [gameType, setGameType] = useState({ label: "M2E", value: 1 });
    const [resultData, setResultData] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [winningType, setWinningType] = useState({ value: null, label: 'All' });
    const [scripId, setScripId] = useState({ value: null, label: 'All' });
    const [scripDataFilter, setScripDataFilter] = useState();
    const [gameIds, setGameIds] = useState([]);
    const [gameId, setGameId] = useState({ label: "All", value: 0 });
    const [total, setTotal] = useState(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(200);
    const [totalLength, setTotalLength] = useState(null);
    const [paginationData, setPaginationData] = useState({});
    const [slotOptions, setSlotOptions] = useState([])
    const [slotId, setSlotId] = useState({});
    //eslint-disable-next-line
    const [downloadLoader, setDownloadLoader] = useState(false);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        getUrl()
        getScripFilter();
        _getSlots();
    }, []);

    const getUrl = async () => {
        let url = await GetURLVar();
        setUserId(url.id);
    }

    useEffect(() => {
        if (userId) {
            resultsList()
        }
        //eslint-disable-next-line
    }, [userId, gameType, page, perPage, winningType, startDate, endDate, scripId, gameId, slotId?.value])

    // useEffect(() => {
    //     searchScrip();
    // }, [gameType])

    /* slot filter api-----*/
    const _getSlots = () => {
        const url = "/m2m/game-slots-filter"

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tempSlot = []
                tempSlot.push({ label: "All", value: null })
                response.data.data.map((item) => {
                    return (
                        tempSlot.push({
                            label: `${item.name}`,
                            value: item.slot_id
                        })
                    )
                })
                setSlotOptions(tempSlot)
                setSlotId(tempSlot[0])
            } else {
                setSlotOptions([])
            }
        })
    }

    /*------ booking list ------*/
    const resultsList = () => {
        setLoader(true);
        let url = "/report/results";

        let data = {
            "customer_id": Number(userId),
            "game_type": gameType?.value,
            "game_id": gameId?.value,
            "slot_id": slotId?.value,
            "start_date": startDate,
            "end_date": endDate,
            "scrip_id": scripId?.value,
            "winner_status": winningType?.value,
            "page_num": page,
            "page_size": perPage
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setResultData(response.data.data);
                setTotal(response.data.number_of_records);
                setLoader(false);
                let temp_options = [];
                temp_options.push({ label: "All", value: 0 })
                response.data.game_ids.map((item) => {
                    return (
                        temp_options.push({
                            label: `Game id ${item}`,
                            value: item,
                        })
                    )
                })
                setPaginationData({
                    rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                })
                let remainder = response.data.number_of_records % perPage
                let quotient = response.data.number_of_records / perPage
                if (Number(remainder) > 0) {
                    setTotalLength(parseInt(Number(quotient) + 1))

                } else {
                    setTotalLength(Number(quotient))
                }
                setGameIds(temp_options)
            } else {
                setResultData([]);
                setError(response.data.message);
                setLoader(false);
            }
        });
    }

    // /*------search scrips ------*/
    // const searchScrip = (query) => {
    //     setLoader(true);
    //     let url = "/user/search-booking";

    //     let data = {
    //         user_id: Number(userId),
    //         game_type: gameType?.value,
    //         query: query ? query : search
    //     }

    //     service(true, url, data).then((response) => {
    //         if (response.data.status_code === 200) {
    //             setResultData(response.data.data);
    //             setLoader(false);
    //         } else {
    //             setResultData([]);
    //             setLoader(false);
    //         }
    //     });

    // }

    /*-------- get filters for booking ----*/
    const getScripFilter = (temp) => {
        let url = '/scrip/scrip-filter';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tArray = [{ value: null, label: 'All' }];
                for (let index = 0; index < response.data.data.length; index++) {
                    const element = response.data.data[index];

                    tArray.push({ value: element.scrip_code, label: element.scrip_code })
                }
                setScripDataFilter(tArray);
            }
        })
    }
    const customDateSort = (a, b, selector, given) => {
        let format = given ? given : "DD MMM YYYY"
        if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
        else if (a[selector]) return -1;
        else if (b[selector]) return 1;
        else return 0;
    }

    const columns = [

        {
            name: 'Result date',
            selector: (row) =>
                <div>
                    {gameType?.value !== 3 ? row.date
                        :
                        row.result_date}</div>,
            sortable: true,
            left: true,
            minWidth: "140px",
            sortFunction: (a, b) => customDateSort(a, b, 'date'),
        },
        {
            name: 'Result time',
            selector: (row) =>
                <div>
                    {gameType?.value !== 3 ? row.time
                        :
                        row.result_time}</div>,
            sortable: false,
            left: true,
            minWidth: "120px",
        },
        {
            name: 'Game ID',
            selector: 'game_id',
            sortable: false,
            left: true,
            minWidth: "140px"
        },
        {
            name: 'Slot',
            selector: row => row.slot_time,
            sortable: false,
            left: true,
            minWidth: '180px',
            omit: gameType?.value === 1 || gameType?.value === 3 ? true : false

        },
        {
            name: 'Symbol',
            // selector: "scrip_code",
            sortable: true,
            left: true,
            minWidth: "130px",
            cell: row => gameType?.value === 1 ? row.scrip_code : row.scrip_name,
            omit: gameType?.value === 3 ? true : false
            // Cell: row => row.is_winner === true ? <span style={{ background: `green` }}>{row.scrip_name}</span> : <span style={{ background: "red" }}>jdsjk</span>
        },
        {
            name: 'Prev. Close',
            selector: (row) => <div>{row.previous_closing}</div>,
            sortable: false,
            left: true,
            minWidth: "120px",
            omit: gameType?.value === 3 ? true : false
        },
        {
            name: 'LTP',
            selector: (row) => <div>{row.today_closing}</div>,
            sortable: false,
            left: true,
            omit: gameType?.value === 3 ? true : false
        },
        {
            name: 'Change %',
            selector: 'change',
            sortable: true,
            left: true,
            cell: (row) => <div className={row.change > 0 ? 'e-green' : row.change < 0 ? 'e-red' : null}>{row.change}</div>,
            minWidth: '120px',
            omit: gameType?.value === 3 ? true : false
        },
        {
            name: 'Gainer Qty',
            selector: (row) => <div>{row.gainer_qty}</div>,
            sortable: false,
            left: true,
            minWidth: '150px',
            omit: gameType?.value === 3 ? true : false
        },
        {
            name: 'Loser Qty',
            selector: (row) => <div>{row.loser_qty}</div>,
            sortable: false,
            left: true,
            minWidth: '150px',
            omit: gameType?.value === 3 ? true : false
            // minWidth: "200px",
        },
        {
            name: 'Prize',
            selector: 'prize_amount',
            sortable: true,
            left: true,
            omit: gameType?.value === 3 ? true : false,
            minWidth: "160px",
            cell: row => <>
                {
                    row.prize_amount !== "-" ?
                        `₹${row.prize_amount}`
                        :
                        "-"
                }
            </>
        },
        {
            name: 'Booking amount',
            selector: row => "₹ " + row.booking_amount,
            sortable: true,
            left: true,
            omit: gameType?.value !== 3 ? true : false,
            minWidth: "160px",
        },
        {
            name: 'Average score',
            selector: row => row.average_score,
            sortable: false,
            left: true,
            omit: gameType?.value !== 3 ? true : false,
            minWidth: "160px",
        },
        {
            name: 'Participation fee',
            selector: row => "₹ " + row.participant_fee,
            sortable: true,
            left: true,
            omit: gameType?.value !== 3 ? true : false,
            minWidth: "220px",
        },
        {
            name: 'Entries closed',
            selector: 'entries_used',
            sortable: true,
            left: true,
            omit: gameType?.value !== 3 ? true : false,
            minWidth: "140px",
        },
        {
            name: 'Total entries',
            selector: 'entries_allowed',
            sortable: true,
            left: true,
            omit: gameType?.value !== 3 ? true : false,
            minWidth: "140px",
        },

        {
            name: 'Result date',
            selector: row => row['result_date'],
            sortable: false,
            left: true,
            minWidth: '160px',
            omit: gameType?.value !== 3 ? true : false
        },
        {
            name: 'Result time',
            selector: row => row['result_time'],
            sortable: false,
            left: true,
            minWidth: '160px',
            omit: gameType?.value !== 3 ? true : false
        },
        {
            name: 'P&L',
            selector: row => row['p&l'],
            sortable: false,
            left: true,
            omit: gameType?.value !== 3 ? true : false,
            cell: row => <>
                {
                    row['p&l'] !== "-" ?
                        `₹${row['p&l']}`
                        :
                        "-"
                }
            </>
        },
        {
            name: 'Reward',
            selector: row => row.reward_in_percentage + "%",
            sortable: false,
            left: true,
            omit: gameType?.value !== 3 ? true : false
        },
        {
            name: 'Winner',
            selector: 'is_winner',
            sortable: true,
            left: true,
            cell: (row) => <>{
                row.is_winner ?
                    <span className='e-green'>Won</span>
                    :
                    <span className='e-red'>Lost</span>
            }</>
        },

    ]


    const selectOptions = [
        // { label: 'All', value: null },
        { label: 'M2E', value: 1 },
        { label: 'M2M', value: 2 },
        { label: 'Skill race', value: 3 }
    ]

    const statusOption = [
        { vale: null, label: "All" },
        { value: true, label: 'Won' },
        { value: false, label: 'Lost' }
    ]

    const resetFilters = () => {
        setGameType({ value: 1, label: 'M2E' });
        setStartDate(null);
        setEndDate(null);
        setScripId(scripDataFilter[0])
        setWinningType({ value: null, label: 'All' });
        setGameId({ value: 0, label: "All" });
        setSlotId(slotOptions[0])
    }

    /* ----dwonload M2e report api *----*/
    const downloadM2eReport = () => {
        setDownloadLoader(true)
        let url = "/user/report/m2e-report";

        let data = {
            "user_id": Number(userId),
            "from_date": startDate,
            "to_date": endDate,
            "scrip_id": scripId?.value,
            "winner_status": winningType?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);

            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }
    /* ----dwonload M2m report api *----*/
    const downloadM2mReport = () => {
        setDownloadLoader(true)
        let url = "/user/report/m2m-report";

        let data = {
            "user_id": Number(userId),
            "from_date": startDate,
            "to_date": endDate,
            "scrip_id": scripId?.value,
            "winner_status": winningType?.value,
            "slot_id": slotId.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);

            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }

    /* ----dwonload Skillrace report api *----*/
    const downloadSkillraceReport = () => {
        setDownloadLoader(true)
        let url = "/user/report/skillrace-report";

        let data = {
            "user_id": Number(userId),
            "from_date": startDate,
            "to_date": endDate,
            "scrip_id": scripId?.value,
            // "winner_status": winningTypeSkillrace?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);

            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }

    const Export = ({ onExport }) => <PrimaryLink
        left={true}
        className={downloadLoader ? "e-disabled ps-0" : "ps-0"}
        name={
            downloadLoader ?
                "Downloading"
                :
                "Download"
        } handleClick={e => onExport(e.target.value)}>
        Export
    </PrimaryLink>;


    return (
        <div className='e-users-container'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className="row pt-4">
                            <div className="col-lg-6">
                                <h6 className="e-card-title ps-3">Game results</h6>
                            </div>
                            <div className='row px-4 mx-1'>
                                <div className='col-lg-12'>
                                    <div className='e-filter'>
                                        <label className='mb-2'>Game type</label>
                                        <SelectBox
                                            className="me-2 e-bookings-sort"
                                            options={selectOptions}
                                            value={gameType}
                                            selectchange={(temp) => { setGameType(temp); }}
                                            isSearchable={false}
                                            placeholder="Game  type"
                                        />
                                    </div>
                                    <div className='e-filter'>
                                        <label className='mb-2'>Scrip </label>
                                        <SelectBox
                                            className="me-2 e-bookings-sort"
                                            options={scripDataFilter}
                                            value={scripId}
                                            selectchange={(temp) => { setScripId(temp); }}
                                            isSearchable={true}
                                            placeholder="Game  type"
                                        />
                                    </div>
                                    {
                                        gameType?.value === 2 ?
                                            <div className='e-filter d-inline-block e-m2m-slot'>
                                                <label className='mb-2'>Slot</label>
                                                <SelectBox
                                                    className="me-2 e-bookings-sort"
                                                    options={slotOptions}
                                                    value={slotId}
                                                    selectchange={(temp) => { setSlotId(temp); }}
                                                    isSearchable={false}
                                                    placeholder="Select "
                                                />
                                            </div>
                                            :
                                            null
                                    }
                                    <div className='e-filter'>
                                        <label className='mb-2'>Winning status</label>
                                        <SelectBox
                                            className="me-2 e-bookings-sort"
                                            options={statusOption}
                                            defaultValue={statusOption[0]}
                                            value={winningType}
                                            selectchange={(temp) => { setWinningType(temp); }}
                                            isSearchable={false}
                                            placeholder="Winning  status"
                                        />
                                    </div>
                                    <div className='e-filter'>
                                        <label className='mb-2'>Game id</label>
                                        <SelectBox
                                            isSearchable={true}
                                            className="me-2 e-bookings-sort"
                                            defaultValue={gameIds[0]}
                                            options={gameIds}
                                            value={gameId}
                                            selectchange={(temp) => {
                                                setGameId(temp)
                                            }}
                                        />
                                    </div>
                                    <div className='mt-2 pt-4 e-filter'>
                                        <label className='mb-2'>Game result date</label>
                                        <Rangepicker
                                            placeholder={['From', 'To']}
                                            start={startDate}
                                            end={endDate}
                                            disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                            onDatesChange={(start, end) => {
                                                setStartDate(start);
                                                setEndDate(end);
                                            }}
                                        />
                                    </div>
                                    <div className='mt-2 pt-4 e-filter'>
                                        {
                                            startDate && endDate ?
                                                <PrimaryBtn name="Submit" className="e-result-filter-submit  mx-2" handleClick={() => resultsList()} />
                                                :
                                                <PrimaryBtn name="Submit" className="e-result-filter-submit mx-2" disabled={true} />
                                        }
                                    </div>
                                    <div className='mt-3 pt-4 e-filter px-0'>
                                        <PrimaryLink
                                            name="Clear all"
                                            className="px-0"
                                            handleClick={() => {
                                                resetFilters();
                                            }}
                                        />
                                    </div>
                                    <div className='mt-2 pt-4 e-filter px-0'>
                                        {
                                            resultData && resultData.length > 0 ?
                                                <Export onExport={() => {
                                                    if (gameType.value === 1) {
                                                        downloadM2eReport()
                                                    } else if (gameType.value === 2) {
                                                        downloadM2mReport()
                                                    } else {
                                                        downloadSkillraceReport()
                                                    }
                                                }} />
                                                :
                                                <PrimaryLink
                                                    name="Download"
                                                    left={true}
                                                    className="e-disabled e-download-booking"
                                                />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-12 e-booking-table pt-3 e-pagination">
                                {
                                    loader ?
                                        <Loader />
                                        :
                                        resultData.length > 0 ?
                                            <>
                                                <DataTable
                                                    pagination={true}
                                                    columns={columns}
                                                    data={resultData}
                                                    paginationTotalRows={total}
                                                    onChangeRowsPerPage={(p) => {
                                                        setPage(1)
                                                        setPerPage(p)
                                                    }}
                                                    paginationComponentOptions={paginationData}
                                                    size={perPage}
                                                    paginationIconNext={totalLength === page ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setPage(page + 1)} style={{ cursor: "pointer" }} />}
                                                    paginationIconPrevious={page === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPage(page - 1) }} />}
                                                // clearSelectedRows={downloadLoader ? true : false}
                                                // selectableRows={true}

                                                // selectRows={(row) => {
                                                //     handleChange(row)
                                                // }}
                                                />
                                                <div className='col-lg-12 text-end pe-3'>
                                                    <p>Page no : <span style={{ fontWeight: "600" }}>{page}</span></p>
                                                </div>
                                            </>
                                            :
                                            <EmptyNote img={Nodata} data="No results found" />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Results;
