/*
 *   File : privacy.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Privacy container
 *   Integrations : 04-08-2022
 *   Version : v1.0
 *   Created : 17-02-2022
 */
/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { Editor } from '../../components/input/index';
import { Toast } from '../../components/note';
import { PrimaryBtn, PrimaryLink } from '../../components/cta';
import { DisclaimerModal } from '../../components/modal/index'
import Loader from '../../components/loader/loader';

/* import services */
import service from '../../services/service';

const Disclaimer = (props) => {
    const [editorData, setEditorData] = useState("");
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        getDisclaimer();
    }, []);

    /*--------------get getDisclaimer -----------*/
    const getDisclaimer = () => {
        setLoader(true)
        let url = '/general/get-disclaimer';

        service(false, url).then((response) => {

            if (response.data.status_code === 200) {
                setLoader(false);
                setEditorData(response.data.data.text);
            }
            else {
                setError(response.data.message);
                setLoader(false);
            }
        });
    }

    /*---------add discalimer ----------*/
    const manageDisclaimer = () => {
        let url = '/general/manage-disclaimer';

        let data = {
            text: editorData
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                getDisclaimer();
            }
            else {
                setError(response.data.message);
            }

        });
    }
    return (
        <>
            <div className="row">
                <div className="col-lg-10 col-8">
                    <h6 className="e-card-title">Disclaimer</h6>
                </div>
                <div className="col-lg-2 col-4 text-end">
                    <PrimaryLink
                        name="View current"
                        toggle="modal"
                        target="#disclaimer" />
                </div>
            </div>
            <div className='row pt-4'>
                <div className='col-lg-12'>
                    {
                        loader ?
                            <Loader />
                            :
                            <Editor
                                editorChange={(temp) => {
                                    setEditorData(temp)
                                }}
                                value={editorData} />
                    }

                </div>
            </div>
            <div className='row pt-4 mt-3'>
                <div className='col-lg-1'>
                    {
                        editorData ?
                            <PrimaryBtn
                                name="Save"
                                className="e-editor-save"
                                handleClick={() => { manageDisclaimer() }} />
                            :
                            <PrimaryBtn
                                name="Save"
                                className="e-editor-save e-disabled"
                            />
                    }

                </div>
            </div>
            <DisclaimerModal data={editorData} title="Disclaimer" />
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </>

    )
}

export default Disclaimer;
