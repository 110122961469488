/*
 *   File : detail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Details
 *   Integrations : 27-07-2022
 *   Version : 1.0.0
 *   Created : 25-01-2022
 */

/* import packages */
import React, { useState, useEffect } from 'react';
import moment from 'moment';


/* import components */
import { Rangepicker } from '../../components/input/index';
import { PrimaryLink } from '../../components/cta/index';
import SelectBox from '../dropdown/selectBox';
import { Toast } from '../note';

/*Style Import*/
import styles from '../../styles/components/reportAccordion.module.scss';

/* import services */
import service from '../../services/service';


/* import assets */


const ReportAccordion = (props) => {
    const [id, setId] = useState(props?.data[0].id);
    const [dates, setDates] = useState({
        start: null,
        end: null
    });
    const [datesWallet, setDatesWallet] = useState({
        start: null,
        end: null
    });
    const [datesSummary, setDatesSummary] = useState({
        start: null,
        end: null
    });
    const [datesSummaryM2m, setDatesSummaryM2m] = useState({
        start: null,
        end: null
    });
    const [datesM2e, setDatesM2e] = useState({
        start: null,
        end: null
    });
    const [datesM2m, setDatesM2m] = useState({
        start: null,
        end: null
    });
    const [datesSkillrace, setDatesSkillrace] = useState({
        start: null,
        end: null
    });
    const [error, setError] = useState(null);
    const [scripId, setScripId] = useState({ value: null, label: 'All' });
    const [scripIdSummary, setScripIdSummary] = useState({ value: null, label: 'All' });
    const [scripIdSummaryM2m, setScripIdSummaryM2m] = useState({ value: null, label: 'All' });
    const [scripIdM2e, setScripIdM2e] = useState({ value: null, label: 'All' });
    const [scripIdM2m, setScripIdM2m] = useState({ value: null, label: 'All' });
    const [scripIdSkillrace, setScripIdSkillrace] = useState({ value: null, label: 'All' });
    const [scripDataFilter, setScripDataFilter] = useState();
    const [bookingType, setBookingType] = useState({ value: null, label: 'All' });
    const [tradeType, setTradeType] = useState({ value: null, label: 'All' });
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [gameType, setGameType] = useState({ label: "M2E", value: 1 });
    const [statusId, setStatusId] = useState({ label: "All", value: 0 });
    const [typeOptions, setTypeOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedType, setSelectedType] = useState({ label: "All", value: 0 });
    const [winningType, setWinningType] = useState({ label: "All", value: null });
    const [winningTypeM2m, setWinningTypeM2m] = useState({ label: "All", value: null });
    // const [winningTypeSkillrace, setWinningTypeSkillrace] = useState({ label: "All", value: 0 });
    const [success, setSuccess] = useState(null);
    const [customerData, setCustomerData] = useState([])
    const [userId, setUserId] = useState({ label: "All", value: 0 });
    const [slotOptions, setSlotOptions] = useState([]);
    const [slotId, setSlotId] = useState({});

    useEffect(() => {
        getScripFilter();
        filterData();
        getCustomersList();
        _getSlots();
    }, [])

    useEffect(() => {
        if (error != null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        if (success != null) {
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }
    }, [success])



    const selectOptions = [
        // { label: 'All', value: null },
        { label: 'M2E', value: 1 },
        { label: 'M2M', value: 2 },
        { label: 'Skill race', value: 3 }
    ]

    const bookingTypeOption = [
        { value: null, label: 'All' },
        { value: 1, label: 'Gainer' },
        { value: 2, label: 'Loser' },
    ]

    const tradeOption = [
        { value: null, label: 'All' },
        { value: 1, label: 'Open' },
        { value: 2, label: 'Won' },
        { value: 3, label: 'Lost' },
        { value: 4, label: 'Cancelled' },
    ]

    const skillraceTardeOption = [
        { value: null, label: 'All' },
        { value: 1, label: 'Open' },
        { value: 2, label: 'Completed' },
        { value: 3, label: 'Pending' },
    ]

    const winningOptions = [
        { vale: null, label: "All" },
        { value: true, label: 'Won' },
        { value: false, label: 'Lost' }
    ]

    /* slot filter api-----*/
    const _getSlots = () => {
        const url = "/m2m/game-slots-filter"

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tempSlot = []
                tempSlot.push({ label: "All", value: null })
                response.data.data.map((item) => {
                    return (
                        tempSlot.push({
                            label: `${item.name}`,
                            value: item.slot_id
                        })
                    )
                })
                setSlotOptions(tempSlot)
                setSlotId(tempSlot[0])
            } else {
                setSlotOptions([])
            }
        })
    }

    /*-------- get filters for scrips ----*/
    const getScripFilter = (temp) => {
        let url = '/scrip/scrip-filter';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tArray = [{ value: null, label: 'All' }];
                for (let index = 0; index < response.data.data.length; index++) {
                    const element = response.data.data[index];

                    tArray.push({ value: element.scrip_id, label: element.scrip_code })
                }
                setScripDataFilter(tArray);
            }
        })
    }

    /*------ filter data for transaction------*/
    const filterData = () => {
        let url = '/transaction/filter-data';

        service(false, url).then((response) => {
            if (response.status === 200) {
                let temp_type_options = []

                response.data.data.types.map((item) => (
                    temp_type_options.push({
                        value: item.id,
                        label: item.name
                    })
                ));
                setTypeOptions(temp_type_options);

                let temp_status_options = [];

                response.data.data.status.map((item) => (
                    temp_status_options.push({
                        value: item.id,
                        label: item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                    })
                ));
                setStatusOptions(temp_status_options);
            } else {
                setError(response.data.message)
            }
        });
    }

    /* ----dwonload booking report api *----*/
    const downloadBookings = () => {
        setDownloadLoader(true)
        let url = "/user/report/booking";

        let data = {
            "user_id": props.dashboard ? userId?.value : Number(props.id),
            "game_type": gameType?.value,
            "booking_type": bookingType?.value,
            "start_date": dates.start,
            "end_date": dates.end,
            "scrip_id": scripId?.value,
            "trade_status": tradeType?.value,
            "slot_id": slotId?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setSuccess("Download successful");
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }

    const getCustomersList = () => {
        var url = "/general/customer-list-filter";

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let temp_options = []
                temp_options.push({ label: "All", value: 0 })
                response.data.data.map((item) => {
                    return (
                        temp_options.push({
                            label: item.customer_name,
                            value: item.customer_id
                        })
                    )
                })
                setCustomerData(temp_options)
            }
        })
    }

    /* ----dwonload transaction report api *----*/
    const downloadTransactions = () => {
        setDownloadLoader(true)
        let url = "/user/report/wallet";

        let data = {
            "user_id": props.dashboard ? userId?.value : Number(props.id),
            "transaction_type": (selectedType?.value),
            "from_date": datesWallet.start,
            "to_date": datesWallet.end,
            "status": statusId?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setSuccess("Download successful");
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }

    /* ----dwonload M2E Booking summary report api *----*/
    const downloadSummaryReport = () => {
        setDownloadLoader(true)
        let url = "/user/report/m2e-booking ";

        let data = {
            "user_id": props.dashboard ? userId?.value : Number(props.id),
            "from_date": datesSummary.start,
            "to_date": datesSummary.end,
            "scrip_id": scripIdSummary?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setSuccess("Download successful");
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }

    /* ----dwonload M2M Booking summary report api *----*/
    const downloadM2MSummaryReport = () => {
        setDownloadLoader(true)
        let url = "/user/report/m2m-booking ";

        let data = {
            "user_id": props.dashboard ? userId?.value : Number(props.id),
            "from_date": datesSummary.start,
            "to_date": datesSummary.end,
            "scrip_id": scripIdSummaryM2m?.value,
            "slot_id": slotId?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setSuccess("Download successful");
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }

    /* ----dwonload M2E Booking summary report api *----*/
    const downloadSkillraceSummaryReport = () => {
        setDownloadLoader(true)
        let url = "/report/skill-race-booking-summary";

        let data = {
            "user_id": props.dashboard ? userId?.value : Number(props.id),
            "from_date": datesSummary.start,
            "to_date": datesSummary.end,
            "scrip_id": scripIdSummary?.value,
            "is_download": true
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setSuccess("Download successful");
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }
    /* ----dwonload M2e report api *----*/
    const downloadM2eReport = () => {
        setDownloadLoader(true)
        let url = "/user/report/m2e-report";

        let data = {
            "user_id": props.dashboard ? userId?.value : Number(props.id),
            "from_date": datesM2e.start,
            "to_date": datesM2e.end,
            "scrip_id": scripIdM2e?.value,
            "winner_status": winningType?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setSuccess("Download successful");
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }
    /* ----dwonload M2m report api *----*/
    const downloadM2mReport = () => {
        setDownloadLoader(true)
        let url = "/user/report/m2m-report";

        let data = {
            "user_id": props.dashboard ? userId?.value : Number(props.id),
            "from_date": datesM2m.start,
            "to_date": datesM2m.end,
            "scrip_id": scripIdM2m?.value,
            "winner_status": winningTypeM2m?.value,
            "slot_id": slotId.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setSuccess("Download successful");
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }

    /* ----dwonload Skillrace report api *----*/
    const downloadSkillraceReport = () => {
        setDownloadLoader(true)
        let url = "/user/report/skillrace-report";

        let data = {
            "user_id": props.dashboard ? userId?.value : Number(props.id),
            "from_date": datesSkillrace.start,
            "to_date": datesSkillrace.end,
            "scrip_id": scripIdSkillrace?.value,
            // "winner_status": winningTypeSkillrace?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setSuccess("Download successful");
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }

    const downloadUsers = () => {
        setDownloadLoader(true);

        let url = '/user/download';

        let data = JSON.stringify({
            user_ids: props.dashboard ? userId?.value : Number(props.id) === 0 ? [] : [props.dashboard ? userId?.value : Number(props.id)]
        });

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                if (response.data.status === true) {
                    console.log(response)
                    let a = document.createElement("a");
                    a.href = response.data.data.url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setDownloadLoader(false);
                    setSuccess("Download successful");
                } else {
                    setError(response.data.message);
                    setDownloadLoader(false);

                }
            }
            else {
                setError(response.data.message);
                setDownloadLoader(false);
            }
        })
    }


    /* ---- reset filters of bookings ----*/
    const resetFilters = () => {
        setGameType({ value: 1, label: 'M2E' });
        setDates({
            start: null,
            end: null
        })
        setScripId(scripDataFilter[0])
        setBookingType({ value: null, label: 'All' });
        setScripId({ value: null, label: 'All' });
        setTradeType({ value: null, label: 'All' });
        setSlotId(slotOptions[0])
    }

    /* ---- reset filters of wallet ----*/
    const resetFiltersWallet = () => {
        setStatusId({ label: "All", value: 0 })
        setSelectedType({ label: "All", value: 0 })
        setDatesWallet({
            start: null,
            end: null
        })
    }

    /* ---- reset filters of Booking summary ----*/
    const resetFiltersSummary = () => {
        setScripIdSummary({ value: null, label: 'All' });
        setDatesSummary({
            start: null,
            end: null
        })
        setUserId(customerData[0])
    }

    /* ---- reset filters of m2m Booking summary ----*/
    const resetFiltersSummaryM2m = () => {
        setScripIdSummaryM2m({ value: null, label: 'All' });
        setDatesSummaryM2m({
            start: null,
            end: null
        })
        setSlotId(slotOptions[0])
    }

    /* ---- reset filters of M2E report ----*/
    const resetFiltersM2e = () => {
        setScripIdM2e({ value: null, label: 'All' });
        setDatesM2e({
            start: null,
            end: null
        })
        setWinningType({ value: null, label: 'All' })
    }

    /* ---- reset filters of M2M report ----*/
    const resetFiltersM2m = () => {
        setScripIdM2m({ value: null, label: 'All' });
        setDatesM2m({
            start: null,
            end: null
        })
        setWinningTypeM2m({ value: null, label: 'All' });
        setSlotId(slotOptions[0])
    }

    /* ---- reset filters of skillrace report ----*/
    const resetFiltersSkillrace = () => {
        setScripIdM2m({ value: null, label: 'All' });
        setDatesM2m({
            start: null,
            end: null
        })
        setWinningTypeM2m({ value: null, label: 'All' })
    }

    return (
        <>
            <div className={`${styles.e_faq_accordion}`}>
                <div className="accordion" id="accordionDescription">
                    <div className="row mx-0">
                        {
                            props?.data?.map((item, key) => {
                                return (
                                    <div className={`${styles.e_accordion_sec} col-lg-12`}>
                                        <div className={`${styles.e_accordion_item} accordion-item`}>
                                            <h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription" + key} >
                                                <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`} onClick={() => setUserId({ label: "All", value: 0 })} >
                                                    <div className="row" onClick={() => { setId(item.id); setSlotId(slotOptions[0]); resetFiltersSummary(); }}>
                                                        {
                                                            item.id !== id ?
                                                                <div className="row" >
                                                                    <div className="col-lg-10" >
                                                                        <span className={`${styles.e_accordion_title}`}>{item.name}</span>

                                                                    </div>
                                                                    <div className="col-lg-2" >
                                                                        <span className={`${styles.e_accordion_icon}`} ></span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="row" onClick={() => { setId(null); resetFiltersSummary(); }}>
                                                        {
                                                            item.id === id ?
                                                                <div className="row" >
                                                                    <div className="col-lg-10 d-flex" >
                                                                        <span className={`${styles.e_accordion_title} pt-2`}>{item.name}</span>


                                                                    </div>
                                                                    <div className="col-lg-2" >
                                                                        <span className={`${styles.e_accordion_icon_minus}`} ></span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </button>
                                            </h2>
                                            {
                                                item.id === id ?
                                                    <div  >
                                                        <div className={`${styles.e_accordion_body} accordion-body`}>
                                                            <div className={`${styles.e_accordion_wrapper} row`}>
                                                                {/* <p className={`${styles.e_accordion_subtitle} row mb-1`}>Choose date</p> */}
                                                                {
                                                                    item.id === 1 ?
                                                                        <div className='col-lg-12 position-relative'>
                                                                            {
                                                                                props.dashboard ?
                                                                                    <div className='e-filter d-inline-block'>
                                                                                        <label className='mb-2'>Customer</label>
                                                                                        <SelectBox
                                                                                            className="me-2 e-bookings-sort"
                                                                                            isSearchable={true}
                                                                                            options={customerData}
                                                                                            value={userId}
                                                                                            selectchange={(temp) => setUserId(temp)} />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <div className='e-filter d-inline-block'>
                                                                                <label className='mb-2'>Game type</label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={selectOptions}
                                                                                    value={gameType}
                                                                                    selectchange={(temp) => { setGameType(temp); }}
                                                                                    isSearchable={false}
                                                                                    placeholder="Game  type"
                                                                                />
                                                                            </div>
                                                                            <div className='e-filter  d-inline-block'>
                                                                                <label className='mb-2'>Scrip </label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={scripDataFilter}
                                                                                    value={scripId}
                                                                                    selectchange={(temp) => { setScripId(temp); }}
                                                                                    isSearchable={true}
                                                                                    placeholder="Game  type"
                                                                                />
                                                                            </div>

                                                                            {
                                                                                gameType.value === 3 ?
                                                                                    null
                                                                                    :
                                                                                    <div className='e-filter  d-inline-block'>
                                                                                        <label className='mb-2'>Booking type</label>
                                                                                        <SelectBox
                                                                                            className="me-2 e-bookings-sort"
                                                                                            options={bookingTypeOption}
                                                                                            value={bookingType}
                                                                                            selectchange={(temp) => { setBookingType(temp); }}
                                                                                            isSearchable={false}
                                                                                            placeholder="Booking  type"
                                                                                        />
                                                                                    </div>
                                                                            }
                                                                            {
                                                                                gameType.value === 2 ?
                                                                                    <div className='e-filter d-inline-block e-m2m-slot'>
                                                                                        <label className='mb-2'>Slot</label>
                                                                                        <SelectBox
                                                                                            className="me-2 e-bookings-sort"
                                                                                            options={slotOptions}
                                                                                            value={slotId}
                                                                                            selectchange={(temp) => { setSlotId(temp); }}
                                                                                            isSearchable={false}
                                                                                            placeholder="Select "
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            <div className='e-filter  d-inline-block'>
                                                                                <label className='mb-2'>Trade  status</label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={gameType.value === 1 ? tradeOption : skillraceTardeOption}
                                                                                    value={tradeType}
                                                                                    selectchange={(temp) => { setTradeType(temp); }}
                                                                                    isSearchable={false}
                                                                                    placeholder="Winning  status"
                                                                                />
                                                                            </div>
                                                                            <div className='mt-4 pt-2 e-filter  d-inline-block'>
                                                                                <label className='mb-2'>Transactions from-till</label>
                                                                                <Rangepicker
                                                                                    placeholder={['From', 'To']}
                                                                                    start={dates.start}
                                                                                    end={dates.end}
                                                                                    disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                                    onDatesChange={(start, end) => {
                                                                                        setDates({
                                                                                            start: start,
                                                                                            end: end
                                                                                        });

                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            <div className='mt-4 pt-3 px-1 e-filter  d-inline-block'>
                                                                                <PrimaryLink
                                                                                    name="Clear all"
                                                                                    className='me-1'
                                                                                    handleClick={() => {
                                                                                        resetFilters()
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            <div className='mt-4 pt-3  e-filter  d-inline-block'>

                                                                                <PrimaryLink
                                                                                    className={downloadLoader ? "e-disabled" : ""}
                                                                                    name={downloadLoader ? "Downloading" : "Download"}
                                                                                    handleClick={() => downloadBookings()} />

                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        item.id === 2 ?
                                                                            <div className='col-lg-12 pt-4'>
                                                                                <div className='row gx-5'>
                                                                                    {
                                                                                        props.dashboard ?
                                                                                            <div className='col-lg-2 col-4'>
                                                                                                <label className='mb-2'>Customer</label>
                                                                                                <SelectBox
                                                                                                    className="e-sort e-month-sort"
                                                                                                    isSearchable={true}
                                                                                                    options={customerData}
                                                                                                    value={userId}
                                                                                                    selectchange={(temp) => setUserId(temp)} />
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                    <div className='col-lg-2 col-4'>
                                                                                        <label className='mb-2'>Type</label>
                                                                                        <SelectBox
                                                                                            isSearchable={false}
                                                                                            defaultValue={typeOptions[0]}
                                                                                            className="e-sort e-month-sort"
                                                                                            options={typeOptions}
                                                                                            value={selectedType}
                                                                                            selectchange={(temp) => {
                                                                                                setSelectedType(temp)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='col-lg-2 col-4'>
                                                                                        <label className='mb-2'>Status</label>
                                                                                        <SelectBox
                                                                                            isSearchable={false}
                                                                                            defaultValue={statusOptions[0]}
                                                                                            className="e-sort e-month-sort"
                                                                                            options={statusOptions}
                                                                                            value={statusId}
                                                                                            selectchange={(temp) => {
                                                                                                setStatusId(temp)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='col-lg-4 col-4' id="e-user-transaction-date">
                                                                                        <label className='mb-2'>Transactions from-till</label>
                                                                                        <Rangepicker
                                                                                            placeholder={['From', 'To']}
                                                                                            start={datesWallet.start}
                                                                                            end={datesWallet.end}
                                                                                            disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                                            onDatesChange={(start, end) => {
                                                                                                setDatesWallet({
                                                                                                    start: start,
                                                                                                    end: end
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                        <PrimaryLink
                                                                                            name="Clear all"
                                                                                            handleClick={() => {
                                                                                                resetFiltersWallet();
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                        <PrimaryLink
                                                                                            name={downloadLoader ? "Downloading" : "Download"}
                                                                                            handleClick={() => downloadTransactions()} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            item.id === 3 ?
                                                                                <div className='col-lg-12 pt-4'>
                                                                                    <div className='row gx-5'>
                                                                                        {
                                                                                            props.dashboard ?
                                                                                                <div className='col-lg-2 col-4'>
                                                                                                    <label className='mb-2'>Customer</label>
                                                                                                    <SelectBox
                                                                                                        className="e-sort e-month-sort"
                                                                                                        isSearchable={true}
                                                                                                        options={customerData}
                                                                                                        value={userId}
                                                                                                        selectchange={(temp) => setUserId(temp)} />
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        <div className='col-lg-2'>
                                                                                            <label className='mb-2'>Scrip </label>
                                                                                            <SelectBox
                                                                                                className="e-sort e-month-sort"
                                                                                                options={scripDataFilter}
                                                                                                value={scripIdSummary}
                                                                                                selectchange={(temp) => { setScripIdSummary(temp); }}
                                                                                                isSearchable={true}
                                                                                                placeholder="Game type"
                                                                                            />
                                                                                        </div>
                                                                                        <div className='col-lg-4 col-4' id="e-user-transaction-date">
                                                                                            <label className='mb-2'>Transactions from-till</label>
                                                                                            <Rangepicker
                                                                                                placeholder={['From', 'To']}
                                                                                                start={datesSummary.start}
                                                                                                end={datesSummary.end}
                                                                                                disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                                                onDatesChange={(start, end) => {
                                                                                                    setDatesSummary({
                                                                                                        start: start,
                                                                                                        end: end
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                            <PrimaryLink
                                                                                                name="Clear all"
                                                                                                handleClick={() => {
                                                                                                    resetFiltersSummary();
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                            <PrimaryLink
                                                                                                className={downloadLoader ? "e-disabled" : ""}
                                                                                                name={downloadLoader ? "Downloading" : "Download"}
                                                                                                handleClick={() => downloadSummaryReport()} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                item.id === 8 ?
                                                                                    <div className='col-lg-12 pt-4'>
                                                                                        <div className='row gx-5'>
                                                                                            {
                                                                                                props.dashboard ?
                                                                                                    <div className='col-lg-2 col-4'>
                                                                                                        <label className='mb-2'>Customer</label>
                                                                                                        <SelectBox
                                                                                                            className="e-sort e-month-sort"
                                                                                                            isSearchable={true}
                                                                                                            options={customerData}
                                                                                                            value={userId}
                                                                                                            selectchange={(temp) => setUserId(temp)} />
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            <div className='col-lg-2'>
                                                                                                <label className='mb-2'>Scrip </label>
                                                                                                <SelectBox
                                                                                                    className="e-sort e-month-sort"
                                                                                                    options={scripDataFilter}
                                                                                                    value={scripIdSummary}
                                                                                                    selectchange={(temp) => { setScripIdSummary(temp); }}
                                                                                                    isSearchable={true}
                                                                                                    placeholder="Game type"
                                                                                                />
                                                                                            </div>
                                                                                            <div className='col-lg-4 col-4' id="e-user-transaction-date">
                                                                                                <label className='mb-2'>Transactions from-till</label>
                                                                                                <Rangepicker
                                                                                                    placeholder={['From', 'To']}
                                                                                                    start={datesSummary.start}
                                                                                                    end={datesSummary.end}
                                                                                                    disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                                                    onDatesChange={(start, end) => {
                                                                                                        setDatesSummary({
                                                                                                            start: start,
                                                                                                            end: end
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                                <PrimaryLink
                                                                                                    name="Clear all"
                                                                                                    handleClick={() => {
                                                                                                        resetFiltersSummary();
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                                <PrimaryLink
                                                                                                    className={downloadLoader ? "e-disabled" : ""}
                                                                                                    name={downloadLoader ? "Downloading" : "Download"}
                                                                                                    handleClick={() => downloadSkillraceSummaryReport()} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    item.id === 4 ?
                                                                                        <div className='col-lg-12 pt-4'>
                                                                                            <div className='row gx-5'>
                                                                                                {
                                                                                                    props.dashboard ?
                                                                                                        <div className='col-lg-2 col-4'>
                                                                                                            <label className='mb-2'>Customer</label>
                                                                                                            <SelectBox
                                                                                                                className="e-sort e-month-sort"
                                                                                                                isSearchable={true}
                                                                                                                options={customerData}
                                                                                                                value={userId}
                                                                                                                selectchange={(temp) => setUserId(temp)} />
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                <div className='col-lg-2'>
                                                                                                    <label className='mb-2'>Scrip </label>
                                                                                                    <SelectBox
                                                                                                        className="e-sort e-month-sort"
                                                                                                        options={scripDataFilter}
                                                                                                        value={scripIdSummaryM2m}
                                                                                                        selectchange={(temp) => { setScripIdSummaryM2m(temp); }}
                                                                                                        isSearchable={true}
                                                                                                        placeholder="Game type"
                                                                                                    />
                                                                                                </div>

                                                                                                <div className='col-lg-2 e-m2m-slot'>
                                                                                                    <label className='mb-2'>Slot</label>
                                                                                                    <SelectBox
                                                                                                        className="e-sort e-month-sort"
                                                                                                        options={slotOptions}
                                                                                                        value={slotId}
                                                                                                        selectchange={(temp) => { setSlotId(temp); }}
                                                                                                        isSearchable={false}
                                                                                                        placeholder="Select "
                                                                                                    />
                                                                                                </div>

                                                                                                <div className='col-lg-4 col-4' id="e-user-transaction-date">
                                                                                                    <label className='mb-2'>Transactions from-till</label>
                                                                                                    <Rangepicker
                                                                                                        placeholder={['From', 'To']}
                                                                                                        start={datesSummaryM2m.start}
                                                                                                        end={datesSummaryM2m.end}
                                                                                                        disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                                                        onDatesChange={(start, end) => {
                                                                                                            setDatesSummaryM2m({
                                                                                                                start: start,
                                                                                                                end: end
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                                    <PrimaryLink
                                                                                                        name="Clear all"
                                                                                                        handleClick={() => {
                                                                                                            resetFiltersSummaryM2m();
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                                    <PrimaryLink
                                                                                                        className={downloadLoader ? "e-disabled" : ""}
                                                                                                        name={downloadLoader ? "Downloading" : "Download"}
                                                                                                        handleClick={() => downloadM2MSummaryReport()} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        item.id === 5 ?
                                                                                            <div className='col-lg-12 pt-4'>
                                                                                                <div className='row gx-5'>
                                                                                                    {
                                                                                                        props.dashboard ?
                                                                                                            <div className='col-lg-2 col-4'>
                                                                                                                <label className='mb-2'>Customer</label>
                                                                                                                <SelectBox
                                                                                                                    className="e-sort e-month-sort"
                                                                                                                    isSearchable={true}
                                                                                                                    options={customerData}
                                                                                                                    value={userId}
                                                                                                                    selectchange={(temp) => setUserId(temp)} />
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                    <div className='col-lg-2'>
                                                                                                        <label className='mb-2'>Scrip </label>
                                                                                                        <SelectBox
                                                                                                            className="e-sort e-month-sort"
                                                                                                            options={scripDataFilter}
                                                                                                            value={scripIdM2e}
                                                                                                            selectchange={(temp) => { setScripIdM2e(temp); }}
                                                                                                            isSearchable={true}
                                                                                                            placeholder="Game type"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='col-lg-2'>
                                                                                                        <label className='mb-2'>Winning status</label>
                                                                                                        <SelectBox
                                                                                                            className="e-sort e-month-sort"
                                                                                                            options={winningOptions}
                                                                                                            value={winningType}
                                                                                                            selectchange={(temp) => { setWinningType(temp); }}
                                                                                                            isSearchable={true}
                                                                                                            placeholder="Winning status"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='col-lg-4 col-4' id="e-user-transaction-date">
                                                                                                        <label className='mb-2'>Transactions from-till</label>
                                                                                                        <Rangepicker
                                                                                                            placeholder={['From', 'To']}
                                                                                                            start={datesM2e.start}
                                                                                                            end={datesM2e.end}
                                                                                                            disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                                                            onDatesChange={(start, end) => {
                                                                                                                setDatesM2e({
                                                                                                                    start: start,
                                                                                                                    end: end
                                                                                                                })
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                                        <PrimaryLink
                                                                                                            name="Clear all"
                                                                                                            handleClick={() => {
                                                                                                                resetFiltersM2e();
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                                        <PrimaryLink
                                                                                                            className={downloadLoader ? "e-disabled" : ""}
                                                                                                            name={downloadLoader ? "Downloading" : "Download"}
                                                                                                            handleClick={() => downloadM2eReport()} />
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            item.id === 6 ?
                                                                                                <div className='col-lg-12 pt-4 '>
                                                                                                    <div className=' position-relative'>
                                                                                                        {
                                                                                                            props.dashboard ?
                                                                                                                <div className='e-filter  d-inline-block'>
                                                                                                                    <label className='mb-2'>Customer</label>
                                                                                                                    <SelectBox
                                                                                                                        className="me-2 e-bookings-sort"
                                                                                                                        isSearchable={true}
                                                                                                                        options={customerData}
                                                                                                                        value={userId}
                                                                                                                        selectchange={(temp) => setUserId(temp)} />
                                                                                                                </div>
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                        <div className='e-filter  d-inline-block'>
                                                                                                            <label className='mb-2'>Scrip </label>
                                                                                                            <SelectBox
                                                                                                                className="me-2 e-bookings-sort"
                                                                                                                options={scripDataFilter}
                                                                                                                value={scripIdM2m}
                                                                                                                selectchange={(temp) => { setScripIdM2m(temp) }}
                                                                                                                isSearchable={true}
                                                                                                                placeholder="Game type"
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className='e-filter  d-inline-block e-m2m-slot'>
                                                                                                            <label className='mb-2'>Slot</label>
                                                                                                            <SelectBox
                                                                                                                className="me-2 e-bookings-sort"
                                                                                                                options={slotOptions}
                                                                                                                value={slotId}
                                                                                                                selectchange={(temp) => { setSlotId(temp); }}
                                                                                                                isSearchable={false}
                                                                                                                placeholder="Select "
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className='e-filter  d-inline-block'>
                                                                                                            <label className='mb-2'>Winning status</label>
                                                                                                            <SelectBox
                                                                                                                className="me-2 e-bookings-sort"
                                                                                                                options={winningOptions}
                                                                                                                value={winningTypeM2m}
                                                                                                                selectchange={(temp) => { setWinningTypeM2m(temp) }}
                                                                                                                isSearchable={true}
                                                                                                                placeholder="Status"
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className='e-filter  d-inline-block' id="e-user-transaction-date">
                                                                                                            <label className='mb-2'>Transactions from-till</label>
                                                                                                            <Rangepicker
                                                                                                                placeholder={['From', 'To']}
                                                                                                                start={datesM2m.start}
                                                                                                                end={datesM2m.end}
                                                                                                                disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                                                                onDatesChange={(start, end) => {
                                                                                                                    setDatesM2m({
                                                                                                                        start: start,
                                                                                                                        end: end
                                                                                                                    })
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className='e-filter  d-inline-block px-0 mt-3 pt-4'>
                                                                                                            <PrimaryLink
                                                                                                                name="Clear all"
                                                                                                                handleClick={() => {
                                                                                                                    resetFiltersM2m()
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className='e-filter  d-inline-block px-0 mt-3 pt-4 text-end'>
                                                                                                            <PrimaryLink
                                                                                                                className={downloadLoader ? "e-disabled" : ""}
                                                                                                                name={downloadLoader ? "Downloading" : "Download"}
                                                                                                                handleClick={() => {
                                                                                                                    downloadM2mReport()
                                                                                                                }} />
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                item.id === 7 ?
                                                                                                    <div className='col-lg-12 pt-4'>
                                                                                                        <div className='row gx-5'>
                                                                                                            {
                                                                                                                props.dashboard ?
                                                                                                                    <div className='col-lg-2 col-4'>
                                                                                                                        <label className='mb-2'>Customer</label>
                                                                                                                        <SelectBox
                                                                                                                            className="e-sort e-month-sort"
                                                                                                                            isSearchable={true}
                                                                                                                            options={customerData}
                                                                                                                            value={userId}
                                                                                                                            selectchange={(temp) => setUserId(temp)} />
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    null
                                                                                                            }
                                                                                                            <div className='col-lg-2'>
                                                                                                                <label className='mb-2'>Scrip </label>
                                                                                                                <SelectBox
                                                                                                                    className="e-sort e-month-sort"
                                                                                                                    options={scripDataFilter}
                                                                                                                    value={scripIdSkillrace}
                                                                                                                    selectchange={(temp) => { setScripIdSkillrace(temp) }}
                                                                                                                    isSearchable={true}
                                                                                                                    placeholder="Game type"
                                                                                                                />
                                                                                                            </div>
                                                                                                            {/* <div className='col-lg-2'>
                                                                                                            <label className='mb-2'>Winning status</label>
                                                                                                            <SelectBox
                                                                                                                className="me-2 e-bookings-sort"
                                                                                                                options={winningOptions}
                                                                                                                value={winningTypeSkillrace}
                                                                                                                selectchange={(temp) => { setWinningTypeSkillrace(temp) }}
                                                                                                                isSearchable={true}
                                                                                                                placeholder="Status"
                                                                                                            />
                                                                                                        </div> */}
                                                                                                            <div className='col-lg-4 col-4' id="e-user-transaction-date">
                                                                                                                <label className='mb-2'>Transactions from-till</label>
                                                                                                                <Rangepicker
                                                                                                                    placeholder={['From', 'To']}
                                                                                                                    start={datesSkillrace.start}
                                                                                                                    end={datesSkillrace.end}
                                                                                                                    disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                                                                    onDatesChange={(start, end) => {
                                                                                                                        setDatesSkillrace({
                                                                                                                            start: start,
                                                                                                                            end: end
                                                                                                                        })
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                                                <PrimaryLink
                                                                                                                    name="Clear all"
                                                                                                                    handleClick={() => {
                                                                                                                        resetFiltersSkillrace()
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className='col-lg-1 col-2 px-0 mt-3 pt-4'>
                                                                                                                <PrimaryLink
                                                                                                                    className={downloadLoader ? "e-disabled" : ""}
                                                                                                                    name={downloadLoader ? "Downloading" : "Download"}
                                                                                                                    handleClick={() => {
                                                                                                                        downloadSkillraceReport()
                                                                                                                    }} />
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className='row'>
                                                                                                        {
                                                                                                            props.dashboard ?
                                                                                                                <div className='col-lg-2 col-4'>
                                                                                                                    <label className='mb-2'>Customer</label>
                                                                                                                    <SelectBox
                                                                                                                        className="e-sort e-month-sort"
                                                                                                                        isSearchable={true}
                                                                                                                        options={customerData}
                                                                                                                        value={userId}
                                                                                                                        selectchange={(temp) => setUserId(temp)} />
                                                                                                                </div>
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                        <div className='col-lg-1 col-2 ps-3 mt-3 pt-4'>
                                                                                                            <PrimaryLink
                                                                                                                className={downloadLoader ? "e-disabled" : ""}
                                                                                                                name={downloadLoader ? "Downloading" : "Download"}
                                                                                                                handleClick={() => {
                                                                                                                    downloadUsers()
                                                                                                                }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
                {
                    error != null ?
                        <Toast
                            data={error}
                            type="fail"
                            format={1}
                        />
                        :
                        null
                }
                {
                    success != null ?
                        <Toast
                            data={success}
                            type="success"
                            format={1}
                        />
                        :
                        null
                }
            </div>

        </>
    );
}


export default ReportAccordion;