/*
 *   File : otherMenu.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Othe Menu
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-10-2021
 */

/*import packages*/


/*import components*/
import { PrimaryLink } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/notificationMenu.module.scss';


/*import assets*/

export default function NotificationMenu() {
  return (
    <>
      <span className={`${styles.e_notification_menu} dropdown`} >
        <div className={`${styles.e_notification_div} `}>
          <div className="d-flex justify-content-between">
            <span className={`${styles.e_title} `}>Notifications</span>
            <span><PrimaryLink name="Clear" /></span>
          </div>
          <span className={`${styles.e_span_border} my-3`}></span>
          <span className={`${styles.e_sub_title}`}>unread</span>
          <h6 className={`${styles.e_notification_title}`}>Joining request</h6>
          <p className={`${styles.e_notification_content} mb-1`}>Lorem Ipsum is simply dummy text of the printing</p>
          <h6 className={`${styles.e_notification_title}`}>Joining request</h6>
          <p className={`${styles.e_notification_content}`}>Lorem Ipsum is simply dummy text of the printing</p>
          <span className={`${styles.e_span_border} my-3`}></span>
          <span className={`${styles.e_sub_title}`}>OLD</span>
          <h6 className={`${styles.e_notification_old_title}`}>Old notification</h6>
          <p className={`${styles.e_notification_content}`}>Joining request</p>
          <h6 className={`${styles.e_notification_old_title}`}>Old notification</h6>
          <p className={`${styles.e_notification_content}`}>Joining request</p>

        </div>
      </span>

    </>
  )
}

