/*
 *   File : profileKyc.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Profile Kyc Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-11-2021
 */

/*import packages*/
import { useEffect, useState } from 'react';

/*import style*/
import styles from '../../styles/components/profileKyc.module.scss';

import { PrimaryBtn, PrimaryLink } from '../cta';

/*import assets*/
import Tick from '../../assets/images/card/tick.svg';
import Pending from '../../assets/images/users/pending.svg';
import { Modal } from 'bootstrap';
import { ManageKYC } from '../modal';



const ProfileKyc = (props) => {
    const [manage, setManage] = useState(false);

    useEffect(() => {
        if (manage === true) {
            var modal = new Modal(document.getElementById("kyc-data"), {});
            modal.show();
        }
    }, [manage]);

    const openImage = (url) => {
        window.open(url, "_blank")
    }

    //id =1: profile overview, id=2: kyc,id=3 : bank details
    return (
        <>
            {
                props.id !== 3 ?
                    <div className={`${styles.e_profileKyc_card} card`} id={props.id}>
                        <div className="row">
                            {
                                props.id !== 3 ?
                                    <div className="col-lg-12 col-12 text-start d-inline-flex ps-0">
                                        <h5>{props.title}</h5>
                                        {
                                            props.id === 2 ?
                                                props.data.kyc_status === true ?
                                                    <>
                                                        <span className={`${styles.e_verified} `}><img alt="" src={Tick} className="me-1" style={{ marginTop: "-2px" }} />{props.data.kyc?.status}</span>
                                                        < h5 className={`${styles.e_content} ms-auto`}>{props.data.kyc?.kyc_status_updated_at ? props.data.kyc?.kyc_status_updated_at : null}</h5>
                                                    </>
                                                    :
                                                    <>
                                                        <span className={`${styles.e_verified}`}><img alt="" src={Pending} className="me-1" width="14px" height="14px" style={{ marginTop: "-3px" }} />{props.data.kyc?.status}</span>
                                                        <h5 className={`${styles.e_content} ms-auto`}>{props.data.kyc?.kyc_status_updated_at ? props.data.kyc?.kyc_status_updated_at : null}</h5>
                                                    </>
                                                :

                                                props.data?.email_verified ?
                                                    <>
                                                        <span className={`${styles.e_verified}`}><img alt="" src={Tick} className="me-1" style={{ marginTop: "-2px" }} />Verified</span>


                                                    </>
                                                    :
                                                    <>
                                                        <span className={`${styles.e_verified}`}><img alt="" src={Pending} className="me-1" width="14px" height="14px" style={{ marginTop: "-3px" }} />Pending</span>
                                                    </>



                                        }

                                    </div>
                                    :
                                    null
                            }
                            {
                                props.id === 1 ?
                                    <div className={`${styles.e_content_wrap} mt-2 pt-1`}>
                                        <div className="row mt-3">
                                            <div className="col-lg-6 col-6 mb-3">
                                                <h5 className={`${styles.e_content_title}`}>Name</h5>
                                                <h5 className={`${styles.e_content}`}>{props.data.name}</h5>
                                            </div>
                                            <div className="col-lg-6 col-6 mb-3">
                                                <h5 className={`${styles.e_content_title}`}>Gender</h5>
                                                <h5 className={`${styles.e_content}`}>{props.data.gender ? props.data.gender : "--"}</h5>
                                            </div>
                                            <div className="col-lg-6 col-6 mb-3">
                                                <h5 className={`${styles.e_content_title}`}>DOB</h5>
                                                <h5 className={`${styles.e_content}`}>{props.data.dob ? props.data.dob : "--"}</h5>
                                            </div>
                                            <div className="col-lg-6 col-6 mb-3">
                                                <h5 className={`${styles.e_content_title}`}>Phone</h5>
                                                <h5 className={`${styles.e_content}`}>{props.data.mobile ? props.data.mobile : "--"}</h5>
                                            </div>
                                            <div className="col-lg-6 col-6">
                                                <h5 className={`${styles.e_content_title}`}>Email</h5>
                                                <h5 className={`${styles.e_content}`}>{props.data.email ? props.data.email : "--"}</h5>
                                            </div>
                                            <div className="col-lg-6 col-6">
                                                <h5 className={`${styles.e_content_title}`}>Address</h5>
                                                <h5 className={`${styles.e_content} ${styles.e_ellipse} d-flex`}>
                                                    {props.data.address
                                                        ?
                                                        <>{props.data.address.address_line_1},{props.data.address.address_line_2},{props.data.address.pincode}

                                                        </>
                                                        :
                                                        "--"
                                                    }
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    props.id === 2 && props.data.kyc ?
                                        <div className={`${styles.e_content_wrap} mt-2 pt-1`}>
                                            {Object.keys(props.data?.kyc)?.length > 0 ?
                                                <div className="row mt-3">

                                                    <div className="col-lg-6 col-6 mb-3">
                                                        <h5 className={`${styles.e_content_title}`}>PAN</h5>
                                                        <h5 className={`${styles.e_content}`}>{props.data.kyc.pan_number ? props.data.kyc.pan_number : "--"} {
                                                            props.data.kyc.pan_image !== "-" ?
                                                                <PrimaryLink name="View" className="ps-3" handleClick={() => openImage(props.data.kyc.pan_image)} />
                                                                :
                                                                null
                                                        }</h5>
                                                    </div>
                                                    <div className="col-lg-6 col-6 mb-3">
                                                        <h5 className={`${styles.e_content_title}`}>Aadhaar</h5>
                                                        <h5 className={`${styles.e_content}`}>{props.data.kyc.aadhar_number ? props.data.kyc.aadhar_number : "--"}
                                                            {
                                                                props.data.kyc.aadhar_image !== "-" ?
                                                                    <PrimaryLink name="View" className="ps-3" handleClick={() => openImage(props.data.kyc.aadhar_image)} />
                                                                    :
                                                                    null
                                                            }
                                                        </h5>
                                                    </div>

                                                    <div className="col-lg-6 col-6 mb-3">
                                                        <h5 className={`${styles.e_content_title}`}>Location</h5>
                                                        <h5 className={`${styles.e_content}`}>{props.data.kyc.location ? props.data.kyc.location : "--"}</h5>
                                                    </div>
                                                    <div className="col-lg-6 col-6 mb-3">
                                                        <h5 className={`${styles.e_content_title}`}>Country</h5>
                                                        <h5 className={`${styles.e_content}`}>{props.data.kyc.country ? props.data.kyc.country : "--"}</h5>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <h5 className={`${styles.e_content_title}`}>City</h5>
                                                        <h5 className={`${styles.e_content}`}>{props.data.kyc.city ? props.data.kyc.city : "--"}</h5>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <h5 className={`${styles.e_content_title}`}>Postal code</h5>
                                                        <h5 className={`${styles.e_content} ${styles.e_ellipse}`}>{props.data.address.pincode ? props.data.address.pincode : "--"}</h5>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <h5 className={`${styles.e_content_title}`}>Name on PAN</h5>
                                                        <h5 className={`${styles.e_content} ${styles.e_ellipse} mb-3`}>{props.data.kyc.pan_name ? props.data.kyc.pan_name : "--"}</h5>
                                                        <PrimaryBtn name="Verify" handleClick={() => setManage(true)} />
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <h5 className={`${styles.e_content_title}`}>KYC Remarks</h5>
                                                        <h5 className={`${styles.e_content} ${styles.e_ellipse}`}>{props.data.kyc.kyc_remarks ? props.data.kyc.kyc_remarks : "--"}</h5>
                                                    </div>
                                                    {/* <div className='col-lg-12'>

                                            </div> */}
                                                </div>
                                                :
                                                <div className='row mt-2'>
                                                    <div className='col-lg-12'>
                                                        KYC not completed
                                                    </div>
                                                    <div className='col-lg-12 mt-3 mb-0'>
                                                        <PrimaryBtn name="Verify" handleClick={() => setManage(true)} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        :

                                        null
                            }

                        </div>
                    </div>
                    :
                    props.data && props.data.bank_details && props.data.bank_details.length > 0 ?

                        props.data.bank_details.map((item) => {
                            return (

                                <div className='col-lg-6 mb-3'>
                                    <div className={`${styles.e_profileKyc_card} card`} id={props.id}>
                                        <div className="row">
                                            <div className='col-lg-12 col-12 text-start d-inline-flex ps-0'>
                                                <h5>Bank details</h5>
                                                {
                                                    item?.status === "VERIFIED" ?
                                                        <>
                                                            <span className={`${styles.e_verified}`}><img alt="" src={Tick} className="me-1" style={{ marginTop: "-2px" }} />Verified</span>
                                                            < h5 className={`${styles.e_content} ms-auto`}>{item?.verified_at ? item?.verified_at : null}
                                                                {item.is_default ? <span className='ps-3' style={{ color: "#9c1fe9" }}>Default</span> : null}</h5>

                                                        </>
                                                        :
                                                        <>
                                                            <span className={`${styles.e_verified}`}><img alt="" src={Pending} className="me-1" width="14px" height="14px" style={{ marginTop: "-3px" }} />Pending</span>
                                                        </>


                                                }
                                            </div>
                                            <div className={`${styles.e_content_wrap} mt-2 pt-1`}>
                                                <div className="row mt-3">
                                                    <div className="col-lg-6 col-6 mb-3">
                                                        <h5 className={`${styles.e_content_title}`}>Account holder name</h5>
                                                        <h5 className={`${styles.e_content}`}>{item.account_holder_name ? item.account_holder_name : "--"}</h5>
                                                    </div>
                                                    {/* <div className="col-lg-6 col-6 mb-3">
                                                    <h5 className={`${styles.e_content_title}`}>Type</h5>
                                                    <h5 className={`${styles.e_content}`}>{item.account_type ? item.account_type : "--"}</h5>
                                                </div> */}
                                                    <div className="col-lg-6 col-6 mb-3">
                                                        <h5 className={`${styles.e_content_title}`}>Account No.</h5>
                                                        <h5 className={`${styles.e_content}`}>{item.account_number ? item.account_number : "--"}</h5>
                                                    </div>
                                                    <div className="col-lg-6 col-6 mb-3">
                                                        <h5 className={`${styles.e_content_title}`}>IFSC</h5>
                                                        <h5 className={`${styles.e_content}`}>{item.ifsc_code ? item.ifsc_code : "--"}</h5>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <h5 className={`${styles.e_content_title}`}>Bank</h5>
                                                        <h5 className={`${styles.e_content}`}>{item.bankname ? item.bankname : "--"}</h5>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <h5 className={`${styles.e_content_title}`}>Branch</h5>
                                                        <h5 className={`${styles.e_content} ${styles.e_ellipse}`}>{item.branch ? item.branch : "--"}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                        :
                        <div className='col-lg-6 mb-3'>
                            <div className={`${styles.e_profileKyc_card} card`} id={props.id}>
                                <div className="row">
                                    <div className='col-lg-12 col-12 text-start d-inline-flex ps-0'>
                                        <h5>Bank details</h5>

                                        <span className={`${styles.e_verified}`}><img alt="" src={Pending} className="me-1" width="14px" height="14px" style={{ marginTop: "-3px" }} />Pending</span>

                                    </div>
                                    <div className={`${styles.e_content_wrap} mt-2 pt-1`}>
                                        <div className="row mt-3">
                                            <div className="col-lg-6 col-6 mb-3">
                                                <h5 className={`${styles.e_content_title}`}>Account holder name</h5>
                                                <h5 className={`${styles.e_content}`}>{"--"}</h5>
                                            </div>
                                            {/* <div className="col-lg-6 col-6 mb-3">
                                        <h5 className={`${styles.e_content_title}`}>Type</h5>
                                        <h5 className={`${styles.e_content}`}>{item.account_type ? item.account_type : "--"}</h5>
                                    </div> */}
                                            <div className="col-lg-6 col-6 mb-3">
                                                <h5 className={`${styles.e_content_title}`}>Account No.</h5>
                                                <h5 className={`${styles.e_content}`}>{"--"}</h5>
                                            </div>
                                            <div className="col-lg-6 col-6 mb-3">
                                                <h5 className={`${styles.e_content_title}`}>IFSC</h5>
                                                <h5 className={`${styles.e_content}`}>{"--"}</h5>
                                            </div>
                                            <div className="col-lg-6 col-6">
                                                <h5 className={`${styles.e_content_title}`}>Bank</h5>
                                                <h5 className={`${styles.e_content}`}>{"--"}</h5>
                                            </div>
                                            <div className="col-lg-6 col-6">
                                                <h5 className={`${styles.e_content_title}`}>Branch</h5>
                                                <h5 className={`${styles.e_content} ${styles.e_ellipse}`}>{"--"}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            }
            {
                manage ?
                    <ManageKYC
                        id={props.data.id}
                        Close={() => {
                            setManage(false);
                        }}
                        bank_details={props.data.bank_details}
                        Success={() => {
                            setManage(false);
                            props.Close();
                        }} />
                    :
                    null
            }
        </>
    )
}

export default ProfileKyc