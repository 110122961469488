/*
 *   File : innerHeader.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Inner Header
 *   Integrations :
 *   Version : v1.0
 *   Created : 07-02-2022
 */

/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/*import components*/
import { ChangePassword } from '../modal/index';
import { NotificationMenu } from '../navigation/index';

/*import styles*/
import styles from '../../styles/components/innerHeader.module.scss';

/*import assets*/
import Notification from '../../assets/images/navigation/notification.png';
import Menu from '../../assets/images/navigation/menu.svg';
import More from '../../assets/images/navigation/more.png';
import Password from '../../assets/images/navigation/password.png';
import Logout from '../../assets/images/navigation/logout.png';

/*import config */
import devconfig from '../../config/config';

export default function InnerHeader(props) {

  const [notificationDown, setNotificationDown] = useState(true);
  const [logoutdDown, setLogoutdDown] = useState(false);
  const [header, setHeader] = useState();
  const wrapperRef = useRef(null);
  const wrapRef = useRef(null);
  const current = useLocation();

  useEffect(() => {
    getCurrentLocation();
    //eslint-disable-next-line
  }, [current])

  const getCurrentLocation = () => {
    var temp = '';
    //eslint-disable-next-line
    devconfig.sidebar.map((item, key) => {
      if (item.link === current.pathname) {
        return (
          temp = item.header
        )
      }
    })
    setHeader(temp);
  }

  const closeMenu = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setLogoutdDown(false)
    }
  }
  const closeNotification = (event) => {
    if (wrapRef.current && !wrapRef.current.contains(event.target)) {
      setNotificationDown(true)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', closeMenu);
    return () => {
      document.removeEventListener('mouseup', closeMenu);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', closeNotification);
    return () => {
      document.removeEventListener('mouseup', closeNotification);
    };

  }, []);



  return (
    <>
      <div className={`${styles.e_inner_header_wrap}`} id="header" style={process.env.REACT_APP_MODE === "DEMO" ? { backgroundColor: "#1f1136" } : { backgroundColor: "#614f7f" }}>
        <div className="px-4">
          <div className="row pt-4 ">
            <div className="col-lg-6 ">
              <img src={Menu} className={`${styles.e_menu}`} alt="Cashyu Menu" onClick={() => props.collapse()} />
            </div>
            <div className="col-lg-6 text-end">
              <img src={Notification} alt="Notification" className={`${styles.e_notification}`} onClick={() => setNotificationDown(!notificationDown)} ref={wrapRef} />
              <span ref={wrapperRef} className={`${styles.e_drop_down_wrap} px-2`} onClick={() => setLogoutdDown(true)}>
                <img src={More} alt='' className={`${styles.e_header_more} ms-4`} />
              </span>
              {
                logoutdDown ?
                  <div>
                    <div className={`${styles.e_dropdown}`} ref={wrapperRef}>
                      {/* <Link to='/platform-settings'>
                      <span><img src={Settings} alt="" className="me-2" />Platform settings</span>
                      </Link> */}


                      <span data-bs-toggle="modal" data-bs-target="#changePassword"><img src={Password} alt="Change Password" className="me-2" />Change password</span>
                      <span className={`${styles.e_logout} mb-0`} onClick={() => { localStorage.clear(); window.location.href = "/" }}><img src={Logout} alt="Logout" className="me-2" />Logout</span>
                    </div>
                  </div>
                  :
                  null
              }
              {
                !notificationDown ?
                  <div>
                    <NotificationMenu />
                  </div>
                  :
                  null
              }
            </div>
          </div>
          {
            header ?
              <div className="row mt-5">
                <div className="col-lg-12">
                  <h3 className="ps-3 ms-2">{header}</h3>
                </div>
              </div>
              :
              null
          }
        </div>
      </div>
      <ChangePassword />
    </>
  )
}

