/*
 *   File : outerHeader.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Login Header
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-11-2021
 */

/*import packages*/
import { Link } from "react-router-dom";

/*import styles*/
import styles from '../../styles/components/outerHeader.module.scss';

/*import config*/
import devconfig from '../../config/config';


/*import components*/
import { TextBtn } from '../../components/cta/index';

const OuterHeader = (props) => {
  return (
    <div className={`row ${styles.e_outer_header}`}>
      <div className="col-lg-6 col-md-6 col-sm-6 col-4">
        <Link to="/">
          <img src={devconfig.outerHeader.OuterHeaderLogo} alt="Stock sports Logo" className={`${styles.e_logo}`} />
        </Link>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6 col-8 text-end">
        {
          props.type === 1 ?
            <Link to="/create-password">
              <TextBtn name="Set password" className={`${styles.e_btn}`} />
            </Link>
            :
            null
        }
      </div>
    </div>
  )
}

export default OuterHeader;

