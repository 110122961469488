
// import style 
import style from '../../styles/components/toggle.module.scss';


const Toggle = (props) => {
	return (
		<label className={`${style.e_switch} ${props.className}`} >
			<input type="checkbox" onChange={(e) => props.onChange(e)} className={props.className} disabled={props.disabled}  {...props} />
			<span className={`${style.e_slider} e-slider`}></span>
		</label>
	)
}

export default Toggle;