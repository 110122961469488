/*
 *   File : toast.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : toast
 *   Integrations :
 *   Version : v1.0
 *  Created : 29-10-2021
 */

import React from 'react';

/*import style*/
import styles from '../../styles/components/toast.module.scss';

/*import assets*/
import Close from '../../assets/images/note/close-black.svg';


function Toast(props) {
  return (
    <>
      {
        props.format === 1 ?
          <span className={props.type === "success" ? `ps-5 ${styles.e_success}` : `ps-5 ${styles.e_fail}`}>{props.data}</span>
          :
          <div className={`${styles.e_warning_card} card border-left border-top-0 border-bottom-0 border-end-0  pt-2`}>
            <div className="row py-2">
              <div className="col-lg-11">
                <h6>Warning</h6>
                <p>{props.message}</p>
              </div>
              <div className="col-lg-1 gx-0">
                <img src={Close} layout="fixed" onClick={() => { props.click() }} alt="" />
              </div>
            </div>
          </div>
      }
    </>
  );
}
export default Toast;
