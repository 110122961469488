/*
 *   File :  privacyModal.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description :  PrivacyModal Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

/*import styles*/
import styles from '../../styles/components/privacy.module.scss';


export default function PrivacyModal(props) {
  return (
    <div className={`${styles.e_privacy} modal fade`} id="privacy" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
        <div className="modal-content">
          <div className="modal-header">
            <h6>Privacy policy</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="container px-3">
            <div className="row">
              <div className="col-lg-12">
                {ReactHtmlParser(props.data)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
