/*
 *   File : detail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakages*/
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { Modal } from 'bootstrap';

/*import components*/
import { Tab } from '../../components/dataFormating/index';
import { UserDetail } from '../../components/card/index';
import Loader from '../../components/loader/loader';

/*import container*/
import Bookings from './bookings.js';
import Profile from './profile.js';
import Milestones from './milestones.js';
import Transactions from './transactions.js';
import Referrals from './referrals.js';
// import Voted from './voted.js';
import Reports from './reports.js';
import Leaderboard from './leaderboard.js';
import Results from './results';
import ReportDowload from './reportDownload';

/* import services */
import GetURLVar from '../../services/getUrl';
import service from '../../services/service';


const UserDetails = (props) => {
    const [tabIndex, setTabindex] = useState(1);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [userData, setUserData] = useState({});
    const [userId, setUserId] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    useEffect(() => {
        getUrl()
        //eslint-disable-next-line
    }, []);

    const getUrl = async () => {
        let url = await GetURLVar();
        getUserDetails(url.id, null, null);
        setUserId(url.id)
    }

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    /*------ user details ------*/
    const getUserDetails = (id, start, end) => {
        setLoader(true);
        let url = "/user/detail";

        let data = {
            user_id: Number(id),
            "from_date": start,
            "to_date": end
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setUserData(response.data.data);
                setLoader(false);
            } else {
                setUserData([]);
                setError(response.data.message);
                setLoader(false);
            }
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <span className="e-breadcrumb-link"><Link to="/users ">Users</Link>/ {userData.name}</span>
                </div>
            </div>
            {
                loader ?
                    <Loader />
                    :
                    <div className="row">
                        <div className="col-lg-12">
                            <UserDetail
                                data={userData}
                                userId={userId}
                                Close={() => {
                                    getUserDetails(userId, startDate, endDate)
                                }}
                                statusUpdate={() => {
                                    getUserDetails(userId, startDate, endDate)
                                }}
                            />

                        </div>
                        <div className="col-lg-12 mt-4 mb-3 e-ssp-tabs">
                            <Tab data={
                                [
                                    'Profile',
                                    'Bookings',
                                    'Transactions',
                                    'Results',
                                    'Booking summary',
                                    'Leaderboard',
                                    'Referrals',
                                    'Reports',
                                    'Milestones']
                            } setTab={(temp) => setTabindex(temp)} />
                        </div>
                        {
                            tabIndex === 1 ?
                                <Profile
                                    data={userData}
                                    Close={() => { getUserDetails(userId) }}
                                    start={startDate}
                                    end={endDate}
                                    onChange={(start, end) => {
                                        getUserDetails(userId, start, end);
                                        setStartDate(start);
                                        setEndDate(end)
                                    }} />
                                : tabIndex === 2 ?
                                    <Bookings />
                                    : tabIndex === 3 ?
                                        <Transactions name={userData.name} />

                                        //     : tabIndex === 4 ?
                                        //         <Transactions />
                                        //         : tabIndex === 5 ?
                                        //             <Referrals />
                                        //             : tabIndex === 6 ?
                                        //                 <Leaderboard />
                                        //                 : tabIndex === 7 ?
                                        //                     <Voted />
                                        //                     :
                                        //                     <Reports />
                                        :
                                        tabIndex === 4 ?
                                            <Results />
                                            :
                                            tabIndex === 5 ?
                                                <Reports />
                                                :
                                                tabIndex === 6 ?
                                                    <Leaderboard id={userId} />
                                                    :
                                                    tabIndex === 7 ?
                                                        <Referrals id={userId} />
                                                        : tabIndex === 8 ?
                                                            <ReportDowload />
                                                            :
                                                            <Milestones id={userId} />
                        }

                    </div>
            }

        </>
    )
}

export default UserDetails;
