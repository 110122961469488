// import packeges
import { useEffect, useState } from 'react';
import $ from 'jquery';

/* import components */
import { Input } from '../input';
import { PrimaryBtn, SecondaryBtn } from '../cta';
import SelectBox from '../dropdown/selectBox';
import { Toast } from '../note';

/* import services */
import service from '../../services/service';

// import components
// import 

const InviteAdmin = (props) => {

    const [name, setName] = useState("");
    const [type, setType] = useState(null);
    const [mail, setMail] = useState("");
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        var myModalEl = document.getElementById('invite-admin')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            setName("");
            setType(null);
            setMail("");
            // setAdded(false)
        })
    }, [])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000)
        }
    }, [error])

    const options = [
        // { value: null, label: "All" },
        { value: 1, label: "Super admin" },
        { value: 2, label: "Admin" },
        { value: 3, label: "Account" },
        { value: 4, label: "Staffuser" }
    ]

    //add admin 
    const _addAdmin = () => {
        setLoader(true);
        let url = "/auth/add-admin"

        let data = {
            email: mail,
            username: name,
            type: type
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false)
                props.update()
                $("#invite-admin").trigger("click")
            } else {
                setLoader(false);
                setError(response.data.message)
            }
        })
    }

    return (
        <>
            <div className={` modal fade e-add-admin`} id="invite-admin" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered`}>

                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Invite admin</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="container px-3">
                            <div className="row">
                                <div className='col-lg-6'>
                                    <label className="mb-1">User name</label>
                                    <Input placeholder="Name" id="name" value={name} valueChange={(temp) => setName(temp)} />
                                </div>

                                <div className='col-lg-6'>
                                    <label className="mb-1">Email</label>
                                    <Input placeholder="Email " type="email" value={mail} valueChange={(temp) => setMail(temp)} />
                                </div>
                                <div className='col-lg-6 mt-4'>
                                    <label className="mb-1">User type</label>
                                    <SelectBox placeholder="type" options={options} selectchange={(temp) => setType(temp?.value)} />
                                </div>
                            </div>
                            <div className="row pt-4 pb-2">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className="e-cancel"
                                            cancel="modal" />
                                        {
                                            name && mail && type ?
                                                <PrimaryBtn
                                                    name="Invite as admin"
                                                    handleClick={() => _addAdmin()}
                                                    className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"} />
                                                :
                                                <PrimaryBtn
                                                    name="Invite as admin"
                                                    className={"e-modal-submit e-disabled"} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </>
    )
}

export default InviteAdmin;