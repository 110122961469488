/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Skill race game scrips;
 *   Integrations :
 *   Version : v1.0
 *   Created : 22-02-2022
 */
/*import pakcages*/
import { useState } from 'react';

/*import components*/
import { DataTable } from '../../../components/dataFormating/index';

import { SingleDate } from '../../../components/input/index';

const Scrips = (props) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  const [click, setClick] = useState()

  const data = [
    { scrip: 'Apple Inc.', ltp: '295.96', change: '29.44', change_percent: '25', total_bookings: '4' },
    { scrip: 'Visa Inc.', ltp: '392.93', change: '23.44', change_percent: '12', total_bookings: '4' },
    { scrip: 'Tesla Inc ', ltp: '401.06', change: '18.04 ', change_percent: '8', total_bookings: '1' },
    { scrip: 'Chase & Co.', ltp: '265.36', change: '28.19', change_percent: '4', total_bookings: '2' },
    { scrip: 'JPMorgan', ltp: '265.36', change: '18.20', change_percent: '2', total_bookings: '5' },
    { scrip: 'NVIDIA Corporation ', ltp: '265.36', change: '23.00', change_percent: '8', total_bookings: '2' },
    { scrip: 'Alphabet Inc.', ltp: '265.36', change: '22.41', change_percent: '1', total_bookings: '3' },
    { scrip: 'Meta Platforms Inc.', ltp: '265.36', change: '28.40', change_percent: '2', total_bookings: '1' },
    { scrip: 'Meta Platforms Inc.', ltp: '265.36', change: '29.42', change_percent: '2', total_bookings: '1' },
    { scrip: 'Meta Platforms Inc.', ltp: '265.36', change: '15.44', change_percent: '2', total_bookings: '1' },
    { scrip: 'Meta Platforms Inc.', ltp: '265.36', change: '10.11', change_percent: '2', total_bookings: '1' },
    { scrip: 'Meta Platforms Inc.', ltp: '265.36', change: '34.21', change_percent: '2', total_bookings: '1' },
    { scrip: 'Meta Platforms Inc.', ltp: '265.36', close_price: '36.75', change_percent: '2', total_bookings: '1' },
  ]

  const columns = [
    {
      name: 'Scrip',
      selector: 'scrip',
      sortable: true,
      left: true,
      width: "200px",
    },
    {
      name: 'LTP',
      selector: 'ltp',
      sortable: true,
      left: true,
      width: "140px",
    },
    {
      name: 'Change',
      selector: 'change',
      sortable: true,
      left: true,
      width: "140px",
    },
    {
      name: 'Change %',
      selector: 'change_percent',
      sortable: true,
      left: true,
      width: "140px",

    },
    {
      name: 'Total bookings',
      selector: 'total_bookings',
      sortable: true,
      left: true,
      width: "180px",
    },

  ]


  return (
    <div className='e-users-container'>
      <div className="row">
        <div className="col-lg-12">
          <div className="card e-dashboard-card border-0">
            <div className='row pe-4'>
              <div className='col-lg-6'>
                <div className='d-flex'>
                  <span className='ps-4 ms-2 pt-3 mt-1 e-labels'>Show</span>
                  <div className="e-span-wrap mt-3 ms-3">
                    <span className={click === "1" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("1")}>
                      All scrips
                    </span>
                    <span className={click === "2" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("2")}>
                      Most qty top
                    </span>
                    <span className={click === "3" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("3")}>
                      Least qty top
                    </span>

                  </div>
                </div>
              </div>
              <div className='col-lg-3 offset-lg-3'>
                <div className='d-flex'>
                  <span className='ps-4 ms-2 pt-3 mt-1 e-labels me-3'>Date</span>
                  <SingleDate placeholder="Date" className="mt-2" />
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-lg-12 e-race-scrip-table pt-2">
                <DataTable pagination={true} columns={columns} data={data} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default Scrips;
