// import packeges
import { useEffect, useState } from 'react';
import $ from 'jquery';

/* import components */
import { Input } from '../input';
import { PrimaryBtn, SecondaryBtn } from '../cta';
import { Toast } from '../note';

/* import services */
import service from '../../services/service';

// import components
// import 

const EditName = (props) => {

    const [name, setName] = useState("");
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setName(props.data.name)
    }, [props.data, props.data.name])



    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000)
        }
    }, [error])



    //add admin 
    const _editName = () => {
        setLoader(true);
        let url = "/user/update-name"

        let data = {
            name: name,
            customer_id: props.data.id
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false)
                props.update()
                $("#btn-close").trigger("click")
            } else {
                setLoader(false);
                setError(response.data.message)
            }
        })
    }

    return (
        <>
            <div className={` modal fade e-add-admin`} id="edit-name" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered`}>

                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Update name</h6>
                            <button type="button" className="btn-close" id="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="container px-3">
                            <div className="row">
                                <div className='col-lg-6'>
                                    <label className="mb-1">User name</label>
                                    <Input placeholder="Name" id="name" value={name} valueChange={(temp) => setName(temp)} />
                                </div>
                            </div>
                            <div className="row pt-4 pb-2">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className="e-cancel"
                                            cancel="modal" />
                                        {
                                            name ?
                                                <PrimaryBtn
                                                    name="Update name"
                                                    handleClick={() => _editName()}
                                                    className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"} />
                                                :
                                                <PrimaryBtn
                                                    name="Update name"
                                                    className={"e-modal-submit e-disabled"} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </>
    )
}

export default EditName;