/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 12-04-2022
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakcages*/
import React, { useState, useEffect, } from 'react';
import moment from 'moment';

/*import components*/
import { DataTable, GameTabs } from '../../components/dataFormating/index';
import { Rangepicker, Search } from '../../components/input/index';
import { DownloadBtn } from '../../components/cta/index';
import SelectBox from '../../components/dropdown/selectBox';
import Loader from '../../components/loader/loader';
import { FilterBtn } from '../../components/cta/index';

/*import  assets*/
import Nodara from '../../assets/images/note/no-data.png';
import Next from '../../assets/images/dataFormating/next.svg';
import Prev from '../../assets/images/dataFormating/prev.svg';

/* import services */
import service from '../../services/service';
import { EmptyNote } from '../../components/note';

const Index = (props) => {
    // const [search, setSearch] = useState("");
    const [filterDown, setFilterDown] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    //eslint-disable-next-line
    const [check, setCheck] = useState([]);
    // const [timePeriod, setTimePeriod] = useState({ label: "Last one week", value: 1 });
    // const [gameOptions, setGameOptions] = useState([]);
    // const [timePeriodOptions, setTimePeriodOptions] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [gameIds, setGameIds] = useState([]);
    const [gameId, setGameId] = useState({ label: "All", value: 0 })
    const [downloadLoader, setDownloadLoader] = useState(false);
    // const [success, setSuccess] = useState(null);
    const [bookingType, setBookingType] = useState({ value: null, label: 'All' });
    const [winningType, setWinningType] = useState({ value: null, label: 'All' });
    //eslint-disable-next-line
    const [tradeType, setTradeType] = useState({ value: null, label: 'All' });
    const [scripId, setScripId] = useState({ value: null, label: 'All' });
    //eslint-disable-next-line
    const [scripIdDownload, setScripIdDownload] = useState({ value: null, label: 'All' });
    const [scripDataFilter, setScripDataFilter] = useState();
    //eslint-disable-next-line
    const [customerData, setCustomerData] = useState([])
    const [id, setId] = useState({ id: 0 });
    const [total, setTotal] = useState(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(200);
    const [totalLength, setTotalLength] = useState(null);
    const [search, setSearch] = useState("");
    const [paginationData, setPaginationData] = useState({})
    const [index, setIndex] = useState(1);
    const [slotOptions, setSlotOptions] = useState([])
    const [slotId, setSlotId] = useState({});
    const [btn_loader, set_btn_loader] = useState(false);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        // getFilters();
        getCustomersList();
        getScripFilter()
        _getSlots();
    }, []);

    useEffect(() => {
        if (downloadLoader === true) {
            setDownloadLoader(false)
        }
    }, [downloadLoader])

    useEffect(() => {
        resultsList();
        //eslint-disable-next-line
    }, [id, index, page, perPage, tradeType?.value, bookingType?.value, endDate, scripId, gameId?.value, winningType?.value, slotId?.value])

    // useEffect(() => {
    //     searchScrip();
    // }, [gameType])

    /* slot filter api-----*/
    const _getSlots = () => {
        const url = "/m2m/game-slots-filter"

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tempSlot = []
                tempSlot.push({ label: "All", value: null })
                response.data.data.map((item) => {
                    return (
                        tempSlot.push({
                            label: `${item.name}`,
                            value: item.slot_id
                        })
                    )
                })
                setSlotOptions(tempSlot)
                setSlotId(tempSlot[0])
            } else {
                setSlotOptions([])
            }
        })
    }

    /*------ booking list ------*/
    const resultsList = () => {
        setLoader(true);
        let url = "/report/results";

        let data = {
            "customer_id": Number(id.id),
            "game_type": index,
            "game_id": gameId?.value,
            "start_date": startDate,
            "end_date": endDate,
            "slot_id": slotId?.value,
            "scrip_id": scripId?.value,
            "winner_status": winningType?.value,
            "page_num": page,
            "page_size": perPage
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setResultData(response.data.data);
                setTotal(response.data.number_of_records);
                let remainder = response.data.number_of_records % perPage
                let quotient = response.data.number_of_records / perPage
                if (Number(remainder) > 0) {
                    setTotalLength(parseInt(Number(quotient) + 1))

                } else {
                    setTotalLength(Number(quotient))
                }
                setLoader(false);
                setPaginationData({
                    rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                })
                let temp_options = [];
                temp_options.push({ label: "All", value: 0 })
                response.data.game_ids.map((item) => {
                    return (
                        temp_options.push({
                            label: `Game id ${item}`,
                            value: item,
                        })
                    )
                })
                setGameIds(temp_options)
            } else {
                setResultData([]);
                setError(response.data.message);
                setLoader(false);
            }
        });
    }

    /*-------- get filters for booking ----*/
    const getScripFilter = (temp) => {
        let url = '/scrip/scrip-filter';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tArray = [{ value: null, label: 'All' }];
                for (let index = 0; index < response.data.data.length; index++) {
                    const element = response.data.data[index];

                    tArray.push({ value: element.scrip_id, label: element.scrip_code })
                }
                setScripDataFilter(tArray);
            }
        })
    }

    const customDateSort = (a, b, selector, given) => {
        let format = given ? given : "DD MMM YYYY"
        if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
        else if (a[selector]) return -1;
        else if (b[selector]) return 1;
        else return 0;
    }

    /* ----dwonload M2e report api *----*/
    const downloadM2eReport = () => {
        setDownloadLoader(true)
        set_btn_loader(true)
        let url = "/user/report/m2e-report";

        let data = {
            "user_id": Number(id.id),
            "from_date": startDate,
            "to_date": endDate,
            "scrip_id": scripId?.value,
            "winner_status": winningType?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setCheck([]);
                set_btn_loader(false)

            } else {
                setDownloadLoader(false);
                setError(response.data.message)
                set_btn_loader(false)

            }
        });
    }
    /* ----dwonload M2m report api *----*/
    const downloadM2mReport = () => {
        setDownloadLoader(true);
        set_btn_loader(true)
        let url = "/user/report/m2m-report";

        let data = {
            "user_id": Number(id.id),
            "from_date": startDate,
            "to_date": endDate,
            "scrip_id": scripId?.value,
            "winner_status": winningType?.value,
            "slot_id": slotId.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setCheck([]);
                set_btn_loader(false)

            } else {
                setDownloadLoader(false);
                setError(response.data.message);
                set_btn_loader(false)
            }
        });
    }

    /* ----dwonload Skillrace report api *----*/
    const downloadSkillraceReport = () => {
        setDownloadLoader(true)
        set_btn_loader(true)
        let url = "/user/report/skillrace-report";

        let data = {
            "user_id": Number(id.id),
            "from_date": startDate,
            "to_date": endDate,
            "scrip_id": scripId?.value,
            // "winner_status": winningTypeSkillrace?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                set_btn_loader(false)
                document.body.removeChild(a);
                setDownloadLoader(false);
                setCheck([]);

            } else {
                setDownloadLoader(false);
                setError(response.data.message);
                set_btn_loader(false)
            }
        });
    }

    const columns = [

        {
            name: 'Result date',
            selector: (row) =>
                <div>
                    {index !== 3 ? row.date
                        :
                        row.result_date}</div>,
            sortable: true,
            left: true,
            minWidth: "140px",
            sortFunction: (a, b) => customDateSort(a, b, 'date'),
        },
        {
            name: 'Result time',
            selector: (row) =>
                <div>
                    {index !== 3 ? row.time
                        :
                        row.result_time}</div>,
            sortable: false,
            left: true,
            minWidth: "120px",
        },
        {
            name: 'Game ID',
            selector: 'game_id',
            sortable: false,
            left: true,
            minWidth: "140px"
        },
        {
            name: 'Slot',
            selector: row => row.slot_time,
            sortable: false,
            left: true,
            minWidth: '180px',
            omit: index === 1 || index === 3 ? true : false

        },
        {
            name: 'Symbol',
            // selector: "scrip_code",
            sortable: true,
            left: true,
            minWidth: "130px",
            cell: row => index === 1 ? row.scrip_code : row.scrip_name,
            omit: index === 3 ? true : false
            // Cell: row => row.is_winner === true ? <span style={{ background: `green` }}>{row.scrip_name}</span> : <span style={{ background: "red" }}>jdsjk</span>
        },
        {
            name: 'Prev. Close',
            selector: (row) => <div>{row.previous_closing}</div>,
            sortable: false,
            left: true,
            minWidth: "120px",
            omit: index === 3 ? true : false
        },
        {
            name: 'LTP',
            selector: (row) => <div>{row.today_closing}</div>,
            sortable: false,
            left: true,
            omit: index === 3 ? true : false
        },
        {
            name: 'Change %',
            selector: 'change',
            sortable: true,
            left: true,
            cell: (row) => <div className={row.change > 0 ? 'e-green' : row.change < 0 ? 'e-red' : null}>{row.change}</div>,
            minWidth: '120px',
            omit: index === 3 ? true : false
        },
        {
            name: 'Gainer Qty',
            selector: (row) => <div>{row.gainer_qty}</div>,
            sortable: false,
            left: true,
            minWidth: '150px',
            omit: index === 3 ? true : false
        },
        {
            name: 'Loser Qty',
            selector: (row) => <div>{row.loser_qty}</div>,
            sortable: false,
            left: true,
            minWidth: '150px',
            omit: index === 3 ? true : false
            // minWidth: "200px",
        },
        {
            name: 'Prize',
            selector: row => row.prize_amount !== "-" ? parseFloat(row.prize_amount) : 0,
            sortable: true,
            left: true,
            omit: index === 3 ? true : false,
            minWidth: "160px",
            cell: row => row.prize_amount !== "-" ? "₹ " + row.prize_amount : "-",
        },
        {
            name: 'Booking amount',
            selector: row => "₹ " + row.booking_amount,
            sortable: true,
            left: true,
            omit: index !== 3 ? true : false,
            minWidth: "160px",
        },
        {
            name: 'Average score',
            selector: row => row.average_score,
            sortable: false,
            left: true,
            omit: index !== 3 ? true : false,
            minWidth: "160px",
        },
        {
            name: 'Participation fee',
            selector: row => "₹ " + row.participant_fee,
            sortable: true,
            left: true,
            omit: index !== 3 ? true : false,
            minWidth: "220px",
        },
        {
            name: 'Entries closed',
            selector: 'entries_used',
            sortable: true,
            left: true,
            omit: index !== 3 ? true : false,
            minWidth: "140px",
        },
        {
            name: 'Total entries',
            selector: 'entries_allowed',
            sortable: true,
            left: true,
            omit: index !== 3 ? true : false,
            minWidth: "140px",
        },
        {
            name: 'P&L',
            selector: row => row['p&l'],
            cell: row => `₹ ${row['p&l']}`,
            sortable: false,
            left: true,
            omit: index !== 3 ? true : false
        },
        {
            name: 'Reward',
            selector: row => row.reward_in_percentage + "%",
            sortable: false,
            left: true,
            omit: index !== 3 ? true : false
        },
        {
            name: 'Winner',
            selector: 'is_winner',
            sortable: true,
            left: true,
            cell: (row) => <>{
                row.is_winner ?
                    <span className='e-green'>Won</span>
                    :
                    <span className='e-red'>Lost</span>
            }</>
        },

    ]


    const handleChange = (row) => {
        setDownloadLoader(false)
        let selected = []
        for (let i = 0; i < row.selectedRows.length; i++) {
            selected.push(row.selectedRows[i]);
        }
        setCheck(selected);
    }

    const statusOption = [
        { label: 'All', value: null },
        { value: true, label: 'Won' },
        { value: false, label: 'Lost' }
    ]

    const resetFilters = () => {
        // setGameType({ value: 1, label: 'M2E' });
        setStartDate(null);
        setEndDate(null);
        setScripId(scripDataFilter[0])
        setBookingType({ value: null, label: 'All' });
        setWinningType({ value: null, label: 'All' });
        setId(0)
        setGameId(gameIds[0])
        setSlotId(slotOptions[0])
    }
    const getCustomersList = () => {
        var url = "/general/customer-list-filter";

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let temp_options = []
                temp_options.push({ label: "All", value: 0 })
                response.data.data.map((item) => {
                    return (
                        temp_options.push({
                            label: item.customer_name,
                            value: item.customer_id
                        })
                    )
                })
                setCustomerData(temp_options)
            }
        })
    }
    function convertArrayOfObjectsToCSV(data) {
        setDownloadLoader(true)
        // Empty array for storing the values
        var csvRows = [];

        // Headers is basically a keys of an
        // object which is id, name, and
        // profession

        if (index !== 3) {
            var titles = [
                'Result date',
                'Result time',
                'Game ID',
                'Symbol',
                'Prev. Close',
                'LTP',
                'Change',
                'Gainer Qty',
                'Loser Qty',
                'Price / unit',
                'Winner',
            ];
            if (index === 2) {
                titles.push("Slot time")
            }
        } else {
            //eslint-disable-next-line
            var titles = [
                'Result date',
                'Result time',
                'Game ID',
                'Booking amount',
                'Avg. score',
                'Participation fee',
                'Entries closed',
                'Total entries',
                'P&L',
                'Reward',
                'Winner',
            ];
        }

        const headers = titles
        // As for making csv format, headers
        // must be separated by comma and
        // pushing it into array
        csvRows.push(headers.join(','));

        // Pushing Object values into array
        // with comma separation
        for (let i = 0; i < data.length; i++) {
            if (index !== 3) {
                var values = [
                    data[i]['date'],
                    data[i]['time'],
                    data[i]['game_id'],
                    data[i]['scrip_code'],
                    data[i]['previous_closing'],
                    data[i]['today_closing'],
                    data[i]['change'],
                    data[i]['gainer_qty'],
                    data[i]['loser_qty'],
                    data[i]['prize_amount'],
                    data[i]['is_winner'],
                ]
                if (index === 2) {
                    values.push(data[i]['slot_time'])
                }
            } else {
                //eslint-disable-next-line
                var values = [
                    data[i]['result_date'],
                    data[i]['result_time'],
                    data[i]['game_id'],
                    data[i]['booking_amount'],
                    data[i]['average_score'],
                    data[i]['participant_fee'],
                    data[i]['entries_used'],
                    data[i]['entries_allowed'],
                    data[i]['p&l'],
                    data[i]['reward'],
                    data[i]['is_winner'],
                ]
            }
            csvRows.push(values.join(","))
        }
        // Returning the array joining with new line
        setCheck([])
        setDownloadLoader(false)
        return csvRows.join('\n')


    }

    function downloadCSV(data) {
        setDownloadLoader(true)
        // Creating a Blob for having a csv file format
        // and passing the data with type
        setCheck([])
        const blob = new Blob([data], { type: 'text/csv' });

        // Creating an object for downloading url
        const url = window.URL.createObjectURL(blob)

        // Creating an anchor(a) tag of HTML
        const a = document.createElement('a')

        // Passing the blob downloading url
        a.setAttribute('href', url)

        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute('Results', 'Results.csv');

        // Performing a download with click
        a.click()


    }

    // const Export = ({ onExport }) => <PrimaryLink onClick={e => onExport(e.target.value)}>Export</PrimaryLink>;
    // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(bookingData)} />, []);
    return (
        <>
            <div className='e-tab-position'>
                <GameTabs data={
                    ['M2E', 'M2M', 'Skillrace']
                }
                    index={index}
                    setTab={(temp) => {
                        setIndex(temp);
                        setPage(1);
                        setPerPage(200);
                        setSlotId(slotOptions[0]);
                        set_btn_loader(false);
                    }}
                    type={true} />
            </div>
            <div className='e-users-container e-pagination'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card e-dashboard-card border-0">
                            <div className="row pt-4">

                                <div className="col-lg-6 col-6">
                                    <h6 className="e-card-title ps-3">Results</h6>
                                </div>
                                <div className="col-lg-2 offset-lg-3 col-4 px-0 text-end">
                                    <DownloadBtn
                                        check={['check']}
                                        loaderClass={btn_loader ? "e-bounce" : ""}
                                        className={btn_loader ? "e-disabled" : ""}
                                        // name={check.length > 0}
                                        onClick={() => {
                                            if (check.length === 0 || check.length === resultData.length) {
                                                if (index === 1) {
                                                    downloadM2eReport()
                                                } else if (index === 2) {
                                                    downloadM2mReport()
                                                } else {
                                                    downloadSkillraceReport()
                                                }
                                            } else {
                                                const csvData = convertArrayOfObjectsToCSV(check)
                                                downloadCSV(csvData)
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-lg-1 col-1">
                                    <FilterBtn
                                        className={
                                            filterDown ?
                                                "e-filter-btn e-active"
                                                :
                                                "e-filter-btn"
                                        }
                                        handleClick={() => {
                                            setFilterDown(!filterDown)
                                            if (filterDown === true) {
                                                resetFilters()
                                            }
                                        }}
                                    />
                                </div>
                                {
                                    filterDown ?
                                        <div className='row pe-0'>
                                            <div className='col-lg-12 px-0'>
                                                <div className='e-filter-wraper pt-0 mt-3'>
                                                    <div className='col-lg-12 '>
                                                        {/* <div className='e-filter'>
                                                            <label className='mb-2'>Game type</label>
                                                            <SelectBox
                                                                className="me-2 e-bookings-sort"
                                                                options={selectOptions}
                                                                value={gameType}
                                                                selectchange={(temp) => { setGameType(temp); }}
                                                                isSearchable={false}
                                                                placeholder="Game  type"
                                                            />
                                                        </div> */}
                                                        <div className='e-filter'>
                                                            <label className='mb-2'>Scrip </label>
                                                            <SelectBox
                                                                className="me-2 e-bookings-sort"
                                                                options={scripDataFilter}
                                                                value={scripId}
                                                                selectchange={(temp) => { setScripId(temp); setScripIdDownload(temp) }}
                                                                isSearchable={true}
                                                                placeholder="Game  type"
                                                            />
                                                        </div>
                                                        {
                                                            index === 2 ?
                                                                <div className='e-filter d-inline-block e-m2m-slot'>
                                                                    <label className='mb-2'>Slot</label>
                                                                    <SelectBox
                                                                        className="me-2 e-bookings-sort"
                                                                        options={slotOptions}
                                                                        value={slotId}
                                                                        selectchange={(temp) => { setSlotId(temp); }}
                                                                        isSearchable={false}
                                                                        placeholder="Select "
                                                                    />
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <div className='e-filter'>
                                                            <label className='mb-2'>Winning status</label>
                                                            <SelectBox
                                                                className="me-2 e-bookings-sort"
                                                                options={statusOption}
                                                                value={winningType}
                                                                defaultValue={statusOption[0]}
                                                                selectchange={(temp) => { setWinningType(temp); }}
                                                                isSearchable={false}
                                                                placeholder="Winning  status"
                                                            />
                                                        </div>
                                                        <div className='e-filter  d-inline-block me-2 e-bookings-input'>
                                                            <label className='mb-2'>Customer</label>
                                                            {/* <SelectBox
                                                            isSearchable={true}
                                                            className="me-2 e-bookings-sort"
                                                            defaultValue={customerData[0]}
                                                            options={customerData}
                                                            selectchange={(temp) => {
                                                                setId(temp?.value)
                                                            }}
                                                        /> */}
                                                            <Search
                                                                value={id?.id === 0 ? {} : id}
                                                                search={search}
                                                                setSearch={(temp) => {
                                                                    setSearch(temp);
                                                                    if (temp === "") {
                                                                        setId({
                                                                            id: 0
                                                                        });
                                                                    }
                                                                }}
                                                                userDetail={(temp) => setId(temp)} />
                                                        </div>
                                                        <div className='e-filter  d-inline-block'>
                                                            <label className='mb-2'>Game id</label>
                                                            <SelectBox
                                                                isSearchable={true}
                                                                className="me-2 e-bookings-sort"
                                                                defaultValue={gameIds[0]}
                                                                options={gameIds}
                                                                selectchange={(temp) => {
                                                                    setGameId(temp)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='mt-2 pt-4 e-filter'>
                                                            <label className='mb-2'>Game result date</label>
                                                            <Rangepicker
                                                                placeholder={['From', 'To']}
                                                                start={startDate}
                                                                end={endDate}
                                                                disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                onDatesChange={(start, end) => {
                                                                    setStartDate(start);
                                                                    setEndDate(end);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                <div className="col-lg-12 e-booking-table pt-3">
                                    {
                                        loader ?
                                            <Loader />
                                            :
                                            resultData.length > 0 ?
                                                <>
                                                    <DataTable
                                                        pagination={true}
                                                        columns={columns}
                                                        data={resultData}
                                                        selectableRows={true}
                                                        paginationTotalRows={total}
                                                        paginationComponentOptions={paginationData}
                                                        selectRows={(row) => {
                                                            handleChange(row,);
                                                        }}
                                                        onChangeRowsPerPage={(p) => {
                                                            setPage(1)
                                                            setPerPage(p)
                                                        }}
                                                        size={perPage}
                                                        paginationIconNext={totalLength === page ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setPage(page + 1)} style={{ cursor: "pointer" }} />}
                                                        paginationIconPrevious={page === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPage(page - 1) }} />}
                                                        clearSelectedRows={downloadLoader}
                                                    />
                                                    <div className='col-lg-12 text-end pe-3'>
                                                        <p>Page no : <span style={{ fontWeight: "600" }}>{page}</span></p>
                                                    </div>
                                                </>
                                                :
                                                <EmptyNote img={Nodara} data="No data found" />
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index;
