/*
 *   File : changePassword.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-02-2022
 */

/*import packages*/
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

/*import assets*/

/*import components*/
import { OuterHeader, Footer } from '../../components/navigation/index';
import { Input } from '../../components/input/index';
import { PrimaryBtn, TextBtn } from '../../components/cta/index';
import GetURLVar from '../../services/getUrl';

const ChangePassword = (props) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState(true);
    const [verifyOtp, setVerifyOtp] = useState(null);
    const [url, setUrl] = useState("");

    useEffect(() => {
        getUrl();
    }, []);

    const getUrl = async () => {
        let url = await GetURLVar()
        if (url.change) {
            setUrl("?change=" + true)
        } else {
            setUrl("")
        }
    }


    return (
        <>
            <div className="container-fluild e-auth-wrapper">
                <OuterHeader type={2} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mt-2  pt-5 offset-lg-4">
                            <div className="e-form-wrap">
                                {
                                    otp ?
                                        <>
                                            <h4>Enter Email Address</h4>
                                            <div className="pt-3">
                                                <label className='pb-1'>Email</label>
                                                <Input
                                                    placeholder="Email"
                                                    value={email}
                                                    valueChange={(value) => {
                                                        setEmail(value)
                                                    }}
                                                    type="email"
                                                />
                                            </div>
                                            <div className="row mt-4 pt-2 mb-4">
                                                <div className="col-lg-12">
                                                    {
                                                        email ?
                                                            <PrimaryBtn
                                                                name="Send OTP"
                                                                handleClick={() => {
                                                                    setOtp(false)
                                                                }} />
                                                            :
                                                            <PrimaryBtn
                                                                name="Send OTP"
                                                                handleClick={() => {
                                                                    setOtp(false)
                                                                }}
                                                                className="e-disabled"
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <h4>OTP Verification</h4>
                                            <p className="py-3">Please enter the One Time Password send to the email:<span className="ms-2">{email}</span> </p>
                                            <div className="">
                                                <label className='pb-1'>Enter OTP</label>
                                                <Input
                                                    placeholder="OTP"
                                                    value={verifyOtp}
                                                    valueChange={(value) => {
                                                        setVerifyOtp(value)
                                                    }}
                                                    type="number"
                                                />
                                            </div>
                                            <div className="row mt-4 pt-2 mb-4">
                                                <div className="col-lg-12">

                                                    {
                                                        verifyOtp ?
                                                            <PrimaryBtn
                                                                name="Verify OTP"
                                                                handleClick={() => navigate('/create-password' + url)}
                                                            />
                                                            :
                                                            <PrimaryBtn
                                                                name="Verify OTP"
                                                                className="e-disabled" />
                                                    }

                                                </div>
                                            </div>
                                            <div className='row mt-4 pt-2'>
                                                <div className='col-lg-4 text-start'>
                                                    <TextBtn name="Resend OTP" />
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="e-auth-footer">
                    <Footer />
                </div>
            </div>

        </>
    )
}

export default ChangePassword;

// handleClick={()=>{setLogin(true)}}