/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';

/*import componets*/
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { AggresiveFund } from '../../components/card/index';
import { SearchInput } from '../../components/input/index';


/*import styles*/
import modalStyle from '../../styles/components/confirmation.module.scss';
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import Icici from '../../assets/images/card/icici.png';


export default function AddFund(props) {
  const [search, setSearch] = useState(null);
  const dummyData = [
    {
      organization: 'Test demo demo',
      year: '1Y',
      percentage: '7.11%',
      icon: Icici
    },
    {
      organization: 'ICICI Prudential Liquid Fund Direct Plan-Growth',
      year: '1Y',
      percentage: '7.11%',
      icon: Icici
    },
    {
      organization: 'ICICI Prudential Liquid Fund Direct Plan-Growth',
      year: '1Y',
      percentage: '7.11%',
      icon: Icici
    },
    {
      organization: 'ICICI Prudential Liquid Fund Direct Plan-Growth',
      year: '1Y',
      percentage: '7.11%',
      icon: Icici
    },
  ]

  const [funds, setFunds] = useState();
  const [searchResult, setSearchResult] = useState();
  useEffect(() => {
    setFunds(dummyData);
    setSearchResult(dummyData);
    //eslint-disable-next-line
  }, [])

  useEffect(() => { console.log(searchResult) }, [searchResult])
  const getSelected = () => {
    var temp = [];
    //eslint-disable-next-line
    funds.map((item, key) => {
      console.log(item.organization)
      if (item.organization.toUpperCase().includes(search.toUpperCase())) {
        return (
          temp.push(item)
        )
      }
    })
    setSearchResult(temp);
  }
  return (

    <div className={`${modalStyle.e_confirmation} modal fade`} id="addFund" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6>Add aggressive fund</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="container px-3">
            <div className="row">
              <div className="col-lg-12 position-relative mb-4">
                <SearchInput placeholder="Search for funds" value={search} valueChange={(value) => { setSearch(value) }} />
                {
                  search && search.length ?
                    <>
                      <span className={`${styles.e_search_submit} `} onClick={() => getSelected()}></span>
                      <button className={`${styles.e_search_close} pe-3`} onClick={() => setSearch("")} ></button>
                    </>
                    :
                    null
                }

              </div>
            </div>
            <div className="row">

              {
                searchResult && searchResult.map((item, key) => {
                  return (
                    <div className="col-lg-6 mb-3 pb-2" key={key}>
                      <AggresiveFund data={item} />
                    </div>
                  )
                })
              }

            </div>
            <div className="row pt-4 pb-2">
              <div className="col-lg-12">
                <div className="d-flex justify-content-end">
                  <SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" />
                  <PrimaryBtn name="Save Fund" className="e-modal-submit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
