/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 11-11-2021
 */
/*import pakcages*/
import { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';
import moment from 'moment';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { PrimaryLink } from '../../components/cta/index';
import { SearchInput, Rangepicker } from '../../components/input/index';
import { TransactionDetail, AddTransaction } from '../../components/modal/index';
import Loader from '../../components/loader/loader';
import SelectBox from '../../components/dropdown/selectBox';
import { EmptyNote, Toast } from '../../components/note';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/* import services */
import GetURLVar from '../../services/getUrl';
import service from '../../services/service';


/* import assets */
import Nodata from '../../assets/images/note/no-data.png';

const Transactions = (props) => {
    const [search, setSearch] = useState("");
    const [dates, setDates] = useState({ start: null, end: null });
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [transactionData, setTransactionData] = useState([]);
    const [userId, setUserId] = useState(null);
    const [typeOptions, setTypeOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [statusId, setStatusId] = useState({ label: "All", value: 0 })
    const [selectedType, setSelectedType] = useState({ label: "All", value: 0 });
    const [transactionDetails, seTransactionDetails] = useState({});
    const [success, setSuccess] = useState(null);
    const [page, setPage] = useState(1);
    const [totalTransactionAmount, setTotalTransactionAmount] = useState(null);
    const [total, setTotal] = useState(null);
    const [downloadLoader, setDownloadLoader] = useState(false);

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        filterData();
        getUrl();
        //eslint-disable-next-line
    }, []);

    const getUrl = async () => {
        let url = await GetURLVar();
        setUserId(url.id);
    }

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null)
            }, 2000);
        }
    }, [success]);

    useEffect(() => {
        if (userId) {
            getUserTransactions()
        }
        //eslint-disable-next-line
    }, [userId, dates, selectedType?.value, statusId, page]);


    /*------ filter data ------*/
    const filterData = () => {
        let url = '/transaction/filter-data';

        service(false, url).then((response) => {
            if (response.status === 200) {
                let temp_type_options = []

                response.data.data.types.map((item) => (
                    temp_type_options.push({
                        value: item.id,
                        label: item.name
                    })
                ));
                setTypeOptions(temp_type_options);

                let temp_status_options = [];

                response.data.data.status.map((item) => (
                    temp_status_options.push({
                        value: item.id,
                        label: item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                    })
                ));
                setStatusOptions(temp_status_options);
                console.log(temp_status_options)
            } else {
                setError(response.data.message)
            }
        });
    }

    /*------ user transactions ------*/
    const getUserTransactions = (query) => {
        setLoader(true);
        let url = "/report/wallet";

        let data = {
            "customer_id": Number(userId),
            "type": (selectedType?.value),
            "from_date": dates.start,
            "end_date": dates.end,
            "query": query ? query.value : search,
            "page_num": page,
            "status_id": Number(statusId?.value)

        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setTransactionData(response.data.data);
                setTotalTransactionAmount(response.data.total_data);
                setTotal(response.data.number_of_records)
                setLoader(false);
            } else {
                setTransactionData([]);
                setError(response.data.message);
                setLoader(false);
            }
        });
    }

    const customDateSort = (a, b, selector, given) => {
        let format = given ? given : "DD MMM YYYY"
        if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
        else if (a[selector]) return -1;
        else if (b[selector]) return 1;
        else return 0;
    }
    const columns = [

        {
            name: 'Date',
            selector: row =>
                <div className="d-inline-flex">
                    {/* <img width="24px"
                        className='e-img-style me-3'
                        src={row.is_credit ? Credit : Debit} /> */}
                    <span className='pt-1'> {row.transaction_date.slice(0, 12)}</span>
                    <span className='e-wallet-historty-time ms-3 pt-1'>{row.transaction_date.slice(12,)}</span>
                </div>,
            sortable: false,
            left: true,
            minWidth: "120px",
            sortFunction: (a, b) => customDateSort(a, b, 'transaction_date')
        },
        {
            name: 'TIme',
            selector: 'time',
            sortable: false,
            left: true,
        },
        {
            name: 'Transaction ID',
            selector: 'transaction_id',
            sortable: false,
            left: true,
            minWidth: "140px"
        },
        {
            name: 'Transaction detail',
            selector: 'message',
            sortable: false,
            left: true,
            minWidth: "260px",
            cell: (row) => { return <div>{row.message ? row.message : "--"}</div> }
        },
        {
            name: 'Modified by',
            selector: row => row.modified_by ? row.modified_by : "--",
            sortable: false,
            left: true,
            minWidth: "150px",
            omit: localStorage.getItem("type") === "1" ? false : true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: false,
            left: true
        },
        {
            name: 'Cr ₹',
            selector: row => Number(row.credited_amount),
            sortable: true,
            left: true,
            cell: row => row.credited_amount !== 0 && row.credited_amount ? row.credited_amount : "-",
        },
        {
            name: 'Dr ₹',
            selector: row => parseFloat(row.debited_amount),
            sortable: true,
            left: true,
            cell: row => row.debited_amount !== 0 && row.debited_amount ? row.debited_amount : "-",
        },
        {
            name: 'Balance ₹',
            selector: row => Number(row.running_balance),
            sortable: true,
            left: true,
            minWidth: '180px',
            cell: row => <>
                {
                    row.running_balance
                }
            </>,
        },
        {
            name: 'Remarks',
            selector: 'remark',
            cell: row => <>{row.remark}</>,
            sortable: false,
            left: true,
            minWidth: "220px"
        },
        {
            name: 'Reference Id',
            selector: row => row.reference_id,
            sortable: false,
            left: true,
            minWidth: "140px"
        }
    ];

    const modalOpen = (data) => {
        seTransactionDetails(data)
        var myModal = new Modal(document.getElementById("transactionDetail"), {});
        myModal.show();
    }

    const resetFilters = () => {
        setStatusId({ label: "All", value: 0 })
        setSelectedType({ label: "All", value: 0 })
        setDates({
            start: null,
            end: null
        });
        setPage(1);
        setSearch("")
    }

    // function convertArrayOfObjectsToCSV(data) {
    //     // Empty array for storing the values
    //     var csvRows = [];

    //     // Headers is basically a keys of an
    //     // object which is id, name, and
    //     // profession

    //     const titles = [
    //         'Date',
    //         'Time',
    //         'Transaction ID',
    //         'Transaction Detail',
    //         'Status',
    //         'Credit amount',
    //         'Debit amount',
    //         'Balance',
    //         'Reference ID',
    //     ];

    //     const headers = titles
    //     // As for making csv format, headers
    //     // must be separated by comma and
    //     // pushing it into array
    //     csvRows.push(headers.join(','));

    //     // Pushing Object values into array
    //     // with comma separation
    //     for (let i = 0; i < transactionData.length; i++) {
    //         var values = [
    //             data[i]['transaction_date'],
    //             data[i]['time'],
    //             data[i]['transaction_id'],
    //             data[i]['message'],
    //             data[i]['status'],
    //             data[i]['credited_amount'],
    //             data[i]['debited_amount'],
    //             data[i]['running_balance'],
    //             data[i]['reference_id'],
    //         ].join(",")
    //         csvRows.push(values)
    //     }
    //     // Returning the array joining with new line
    //     return csvRows.join('\n')
    // }

    // function downloadCSV(data) {
    //     // Creating a Blob for having a csv file format
    //     // and passing the data with type
    //     const blob = new Blob([data], { type: 'text/csv' });

    //     // Creating an object for downloading url
    //     const url = window.URL.createObjectURL(blob)

    //     // Creating an anchor(a) tag of HTML
    //     const a = document.createElement('a')

    //     // Passing the blob downloading url
    //     a.setAttribute('href', url)

    //     // Setting the anchor tag attribute for downloading
    //     // and passing the download file name
    //     a.setAttribute('transactions', 'transactions.csv');

    //     // Performing a download with click
    //     a.click()
    // }

    /* ----dwonload transaction report api *----*/
    const downloadTransactions = () => {
        setDownloadLoader(true)
        let url = "/user/report/wallet";

        let data = {
            "user_id": Number(userId),
            "transaction_type": (selectedType?.value),
            "from_date": dates.start,
            "to_date": dates.end,
            "status": Number(statusId?.value)
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }
    const Export = ({ onExport }) => <PrimaryLink
        name={downloadLoader ? "Downloading" : "Download"}
        className={downloadLoader ? "e-disabled ps-0" : "ps-0"}
        handleClick={e => onExport(e.target.value)}>Export</PrimaryLink>;
    return (
        <div className='e-users-container'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className="col-lg-6 pt-4 d-flex">
                            <h6 className="e-card-title ps-3 mb-0 mt-1">Payouts</h6>
                            <div className='e-filter px-3'>
                                <PrimaryLink
                                    name="+ Add Transaction"
                                    toggle="modal"
                                    target="#addTransaction"
                                />
                            </div>
                        </div>
                        <div className='row px-3 pt-4'>

                            <div className='col-lg-2 col-4'>
                                <label>Type</label>
                                <SelectBox
                                    isSearchable={false}
                                    defaultValue={typeOptions[0]}
                                    className="e-sort e-month-sort"
                                    options={typeOptions}
                                    value={selectedType}
                                    selectchange={(temp) => {
                                        setSelectedType(temp)
                                    }}
                                />
                            </div>
                            <div className='col-lg-2 col-4'>
                                <label>Status</label>
                                <SelectBox
                                    isSearchable={false}
                                    defaultValue={statusOptions[0]}
                                    className="e-sort e-month-sort"
                                    options={statusOptions}
                                    value={statusId}
                                    selectchange={(temp) => {
                                        setStatusId(temp)
                                    }}
                                />
                                {/* <SelectBox
                                    isSearchable={false}
                                    defaultValue={typeOptions[0]}
                                    className="e-sort e-month-sort"
                                    options={statusOptions}
                                    value={statusId}
                                    selectchange={(temp) => {
                                        setStatusId(temp)
                                    }}
                                /> */}
                            </div>


                            <div className='col-lg-3 col-4' id="e-user-transaction-date">
                                <label>Transactions from-till</label>
                                <Rangepicker
                                    placeholder={['From', 'To']}
                                    start={dates.start}
                                    end={dates.end}
                                    disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                    onDatesChange={(start, end) => {
                                        setDates({
                                            start: start,
                                            end: end
                                        })
                                    }}
                                />
                            </div>

                            <div className='col-lg-1 col-2 px-0 mt-4 pt-1'>
                                <PrimaryLink
                                    name="Clear all"
                                    handleClick={() => {
                                        resetFilters();
                                    }}
                                />
                            </div>
                            <div className='col-lg-1 col-2 px-0 mt-4 pt-1'>
                                {
                                    transactionData && transactionData.length ?
                                        <Export onExport={() => {
                                            // const csvData = convertArrayOfObjectsToCSV(transactionData)
                                            // downloadCSV(csvData)
                                            downloadTransactions()
                                        }} />
                                        :
                                        <PrimaryLink
                                            name="Download"
                                            className="e-disabled e-download-booking"
                                        />
                                }
                            </div>
                            <div className="col-lg-3 col-5 mt-2 e-user-transaction-search pt-2 position-relative">
                                <SearchInput
                                    placeholder="Search with id"
                                    value={search}
                                    valueChange={(value) => {
                                        setSearch(value);
                                        if (value === "") {
                                            getUserTransactions({ value: "" })
                                        }
                                    }}
                                    onSearch={(value) => {
                                        getUserTransactions({ value: value })
                                    }}
                                />
                                {
                                    search && search.length ?
                                        <>
                                            <span
                                                className={`${styles.e_search_submit} `}
                                                onClick={() => getUserTransactions({ value: search })} >
                                            </span>
                                            <button
                                                className={`${styles.e_search_close}`}
                                                onClick={() => {
                                                    setSearch("");
                                                    getUserTransactions({ value: "" });
                                                }} >
                                            </button>
                                        </>
                                        :
                                        null
                                }

                            </div>
                        </div>
                        {/* <div className="row pt-4">
                            <div className="col-lg-6">
                                <h6 className="e-card-title ps-4">Payouts</h6>
                            </div>
                            <div className="col-lg-2">
                                <DownloadBtn check={check}
                                    onClick={() => _downloadTransactionList()} />
                            </div>

                            <div className="col-lg-3 position-relative">
                                <SearchInput
                                    placeholder="Search with id"
                                    value={search}
                                    valueChange={(value) => {
                                        setSearch(value)
                                    }}
                                    onSearch={(value) => {
                                        getUserTransactions({ value: value })
                                    }}
                                />
                                {
                                    search && search.length ?
                                        <>
                                            <span
                                                className={`${styles.e_search_submit} `}
                                                onClick={() => setSearch(search)} >
                                            </span>
                                            <button
                                                className={`${styles.e_search_close} pe-3`}
                                                onClick={() => {
                                                    setSearch("");
                                                    getUserTransactions({ value: "" });
                                                }} >
                                            </button>
                                        </>
                                        :
                                        null
                                }

                            </div>
                            <div className="col-lg-1">
                                <FilterBtn
                                    className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
                                    handleClick={() => setFilterDown(!filterDown)} />
                            </div>
                            {
                                filterDown ?
                                    <div className='col-lg-12'>
                                        <div className="e-filter-wraper mt-3 ms-0">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <label className="mb-2">Transactions from - till</label>
                                                    <Rangepicker
                                                        start={dates.start}
                                                        end={dates.end}
                                                        disabledDate={disabledDate}
                                                        placeholder={["dd-mm-yyy", "dd-mm-yyy"]}
                                                        onDatesChange={(start, end) => {

                                                            setDates({
                                                                start: start,
                                                                end: end
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-lg-2">
                                                    <label className="mb-2">Status</label>
                                                    <SelectBox
                                                        isSearchable={false}
                                                        defaultValue={statusOptions[0]}
                                                        className="e-sort e-month-sort"
                                                        options={statusOptions}
                                                        selectchange={(temp) => {
                                                            setSelectedType(temp)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-lg-2">
                                                    <label className="mb-2">Type</label>
                                                    <SelectBox
                                                        isSearchable={false}
                                                        defaultValue={typeOptions[0]}
                                                        className="e-sort e-month-sort"
                                                        options={typeOptions}
                                                        selectchange={(temp) => {
                                                            setSelectedType(temp)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                           
                        </div> */}
                        {
                            loader ?
                                <Loader />
                                :
                                transactionData.length > 0 ?
                                    <>
                                        <div className="col-lg-12 e-booking-table mt-4">
                                            <DataTable
                                                pagination={true}
                                                columns={columns}
                                                data={transactionData}
                                                paginationTotalRows={total}
                                                // selectableRows={true}
                                                // clearSelectedRows={downloadLoader ? true : false}
                                                // selectRows={(row) => {
                                                //     handleChange(row)
                                                // }}
                                                // paginationIconNext={<img src={Next} alt="" width="28px" onClick={() => setPage(page + 1)} />}
                                                // paginationIconPrevious={<img src={Prev} alt="" width="28px" onClick={() => {
                                                //     if (page <= 1) {
                                                //         setPage(1)
                                                //     } else {
                                                //         setPage(page - 1)
                                                //     }
                                                // }} />}
                                                rowClick={(row) => modalOpen(row)} />
                                        </div>
                                        <div className="row px-3 pb-3">
                                            <div className='col-lg-6'>
                                                <h6 className='d-inline'>Total Cr:</h6>
                                                <p className='d-inline ms-2'>₹{totalTransactionAmount?.total_cr_amount}</p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <h6 className='d-inline'>Total Dr:</h6>
                                                <p className='d-inline ms-2'>₹{totalTransactionAmount?.total_dr_amount}</p>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <EmptyNote img={Nodata} data="No data found" />
                        }


                    </div>
                </div>
            </div>
            <AddTransaction
                customer={[]}
                type={typeOptions}
                id={userId}
                name={props.name}
                Success={() => { getUserTransactions() }} />
            <TransactionDetail data={transactionDetails} type={true} />
            {
                success !== null ?
                    <Toast
                        data={success}
                        type="success"
                        format={1}
                    />
                    :
                    null
            }
        </div >

    )
}

export default Transactions;