
/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : index for manage admins;
 *   Integrations : 23-08-2022
 *   Version : v1.0
 *   Created : 17-02-2022
 */
import { useEffect, useState } from 'react';

// import components
import Datatable from '../../components/dataFormating/dataTable';
import { PrimaryBtn } from '../../components/cta';
import { SearchInput } from '../../components/input';
import { InviteAdmin, SetPageAccess, Confirmation, EditAdmin } from '../../components/modal';
import Loader from '../../components/loader/loader';
import { EmptyNote } from '../../components/note';


/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

// import assets
import SelectBox from '../../components/dropdown/selectBox';
import Nodata from '../../assets/images/note/no-data.png';
import Edit from '../../assets/images/card/edits.svg';

/* import services */
import service from '../../services/service';

const Index = (props) => {

    const [search, setSearch] = useState("");
    const [selectedUser, setSelectedUser] = useState({});
    const [type, setType] = useState(null)
    const [list, setList] = useState([]);
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }, [])

    useEffect(() => {
        _getAdminList()
        //eslint-disable-next-line
    }, [type])

    /* get admins list */
    const _getAdminList = (query) => {
        setLoader(true);
        let url = "/auth/list-admins";

        let data = {
            query: query,
            type: type
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                setList(response.data.data);
            } else {
                setLoader(false);
                setList([]);
            }
        })
    }

    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            left: true,
        },
        {
            name: 'Added on',
            selector: 'created',
            sortable: true,
            left: true,
            maxWidth: '130px',
            cell: row => <>
                {
                    row.created
                }
            </>
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: false,
            left: true,
            cell: row => <>{row.email}</>,
            minWidth: "320px"
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: false,
            left: true,
        },
        {
            name: 'Active',
            selector: row => <>
                {
                    row.active ?
                        "Yes"
                        :
                        "No"
                }
            </>,
            sortable: false,
            left: true,
        },
        {
            name: 'Actions',
            selector: '',
            sortable: false,
            left: false,
            cell: row => <div className='d-flex justify-content-start'>
                {/* <img src={Admin} className="e-table-icons" data-bs-toggle="modal" data-bs-target="#set-page" onClick={() => setSelectedUser(row)} /> */}
                <img src={Edit} alt='' className="e-table-icons mx-0" data-bs-toggle="modal" data-bs-target="#edit-admin" onClick={() => setSelectedUser(row)} />
                {/* <img src={Delete} className="e-table-icons ms-1" data-bs-toggle="modal" data-bs-target="#confirmation" /> */}
            </div>
        }
    ]

    const options = [
        { value: null, label: "All" },
        { value: 1, label: "Super admin" },
        { value: 2, label: "Admin" },
        { value: 3, label: "Account" },
        { value: 4, label: "Staffuser" }
    ]
    return (
        <div className="e-users-container mb-5 pb-5">
            <div className="row">
                <div className='card border-0 e-manage-admin-card px-0' >
                    <div className='row px-2 pt-2 px-3 pt-4'>
                        <div className='col-lg-6 col-6 text-start'>
                            <h6 className="e-card-title ">All admins</h6>
                        </div>
                        <div className='col-lg-6 col-6 text-end'>
                            <PrimaryBtn
                                name="Invite admin"
                                toggle="modal"
                                target="#invite-admin"
                                className="e-invite-admin-btn" />
                        </div>
                        <div className='col-lg-3'>
                            <SelectBox
                                isSearchable={false}
                                options={options}
                                defaultValue={options[0]}
                                placeholder="Select type" selectchange={(temp) => {
                                    setType(temp?.value)
                                }} />
                        </div>
                        <div className='col-lg-3 col-5 position-relative'>
                            {/* <SearchInput placeholder="Search for admins" value={search} valueChange={(value) => { setSearch(value) }} /> */}
                            <SearchInput
                                placeholder="Search for admins"
                                value={search}
                                valueChange={(value) => {
                                    setSearch(value)
                                    if (value === "") {
                                        _getAdminList("")
                                    }
                                }}
                                onSearch={(value) => {
                                    _getAdminList(value)
                                }} />
                            {
                                search && search.length ?
                                    <>
                                        <span className={`${styles.e_search_submit} `} onClick={() => _getAdminList()} ></span>
                                        <button className={`${styles.e_search_close} pe-3`} onClick={() => {

                                            _getAdminList("")
                                            setSearch("")
                                        }} ></button>
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    {
                        loader ?
                            <Loader />
                            :
                            list.length > 0 ?
                                <Datatable columns={columns} data={list} pagination={true} />
                                :
                                <EmptyNote img={Nodata} data="No data found" />
                    }
                </div>
            </div>
            <EditAdmin
                details={selectedUser}
                update={() => { _getAdminList() }}
            />
            <InviteAdmin update={() => { _getAdminList() }} />
            <SetPageAccess details={selectedUser} />
            <Confirmation
                close={() => { }}
                title="Confirmation" content="Are you sure want to remove?"
                btn_name="Remove" />
        </div>
    )
}

export default Index;