/*
 *   File : gameCard.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Game Detail Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-02-2022
 */
/*import component */
import { PrimaryBtn } from '../cta';

/* import style */
import style from '../../styles/components/gameCard.module.scss';

/* import components */

const GameCard = (props) => {
  return (
    <div className={`${style.e_game_card} position-relative`} style={{ backgroundColor: props.data.background_color }} onClick={props.onClick}>
      <div className='row mb-3'>
        <div className='col-lg-12'>
          <h6 className='pt-1'>{props.data.game_name}</h6>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <img alt="" src={props.data.game_icon} className="" />
        </div>
        <div className='col-lg-6'>
          <PrimaryBtn name="Manage" type={1} className="mt-5" />
        </div>
      </div>
    </div>
  )
}

export default GameCard;