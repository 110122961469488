/*
 *   File : download.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Dowload Button
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*Import Packages*/
import React from 'react';
import Download from "../../assets/images/cta/download-btn-active.svg";

/*import style*/
import styles from '../../styles/components/download.module.scss';

/* import assets */


export default function DownloadBtn(props) {
	return (
		<>
			<button onClick={props.onClick} className={props.disabled ? `${styles.e_download_btn} ${props.className}` : props?.check?.length > 0 ? `${styles.e_download_btn_active} ${props.className}  ` : `${styles.e_download_btn} ${props.className} `}>
				<span>{props.name ? props.name : "Download report"}</span>
				<img src={Download} alt="" className={`${props.loaderClass}`} />
			</button>
		</>

	);
}
