/*
 *   File : detail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Details
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 25-01-2022
 */

/* import packages */
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment'

/* import components */
import { TextArea, Input, FileUpload, ColorPicker, Timepicker, CheckBox } from '../input/index';
import { PrimaryBtn, PrimaryLink } from '../cta/index';
import SelectBox from '../dropdown/selectBox';
import { Editor } from '../input/index';
import $ from 'jquery';


/*Style Import*/
import style from '../../styles/components/setPageAccess.module.scss';

/* import assets */
import { AddBlockRule, Confirmation } from '../modal';
import { Modal } from 'bootstrap';
import service from '../../services/service';
import Loader from '../loader/loader';
import M2Etable from './m2eTable';
import LoaderGif from '../../assets/images/loader/loader1.gif';
import Down from '../../assets/images/dataFormating/down.svg'

const SkillRaceSettingsAccordion = (props) => {
	const wrapperRef = useRef()
	const [totalEntries, setTotalEntries] = useState(null);
	const [entityAmount, setEntityAmount] = useState(null);
	const [stockList, setStockList] = useState({});
	const [gameStartTime, setGameStartTime] = useState("");
	const [gameEndTime, setGameEndTime] = useState("");
	const [maxBooking, setMaxBooking] = useState("");
	const [commissionPercent, setCommissionPercent] = useState("");
	const [type, setType] = useState("");
	const [title, setTitle] = useState("");
	let [skillraceBlockRuleData, setSkillraceBlockRuleData] = useState([]);
	const [skillLoader, setSkillLoader] = useState(false);
	const [skillRaceTableData, setSkillRaceTableData] = useState([]);
	const [imageUrl, setUploadImageUrl] = useState("");
	const [backgroundColor, setBackgroundColor] = useState("");
	//eslint-disable-next-line
	const [error, setError] = useState(null);
	const [loseAmount, setLoseAmount] = useState();
	//eslint-disable-next-line
	const [loseIn, setLoseIn] = useState(null);
	const [period, setPeriod] = useState(null);
	const [blockFor, setBlockFor] = useState(null);
	const [blockCode, setBlockCode] = useState(null);
	const [description, setDescription] = useState();
	const [url, setUrl] = useState();
	//eslint-disable-next-line
	const editorRef = useRef();
	const [editorData, setEditorData] = useState();
	//eslint-disable-next-line
	const [uploadImage, setUploadImage] = useState();
	const [updated, setUpdated] = useState(false);
	const [loader, setLoader] = useState(false);
	const [click, setClick] = useState(1);
	const [id, setId] = useState("");
	//eslint-disable-next-line
	const [ruleLoader, setRuleLoader] = useState(false);
	//eslint-disable-next-line
	const [selectDisable, setSelectDisable] = useState("");
	const [imageLoader, setImageLoader] = useState(false);
	const [show, setShow] = useState(null);
	const [activeWeb, setActiveWeb] = useState(false);
	const [activeMobile, setActiveMobile] = useState(false);
	const [mobileUrl, setMobileUrl] = useState(null);

	const options = [
		{
			value: 1, label: 'NSE 50',
		},
		{
			value: 2, label: 'NSE 100'
		},
	]

	useEffect(() => {
		getSkillRaceDetails();
	}, [])

	useEffect(() => {
		if (updated === true) {
			getTableInfo();
		}
	}, [updated]);

	const ruleOptions = [
		{ value: 1, label: "Days" },
		{ value: 2, label: "Month" },
		{ value: 3, label: "Quarter" },
		{ value: 4, label: "Year" },
	]

	const skillRaceRequest = {
		"total_entries": totalEntries,
		"unit_fee": entityAmount,
		"game_start_time": gameStartTime,
		"game_end_time": gameEndTime,
		"stock_list_from": stockList?.value,
		"max_booking_per_scrip": maxBooking,
		"commission_percentage": commissionPercent === "" ? 0 : commissionPercent
	}

	const getSkillRaceDetails = () => {
		setSkillLoader(true);
		let url = '/skillrace/detail';

		service(false, url).then((response) => {
			if (response.data.status_code === 200) {
				setSkillLoader(false);
				let basic_info = response.data.data.basic_info;
				setTotalEntries(basic_info.total_entries);
				setEntityAmount(basic_info.unit_fee);
				setStockList({ value: basic_info.stock_list_from, label: basic_info.stock_list_from === "1" ? 'NSE 50' : 'NSE 100' });
				setGameStartTime(moment(basic_info.game_start_time_in_ist, 'HH:mm A').format('h:mm a'));
				setGameEndTime(moment(basic_info.game_end_time_in_ist, 'HH:mm A').format('h:mm a'));
				setMaxBooking(basic_info.max_booking_per_scrip);
				setCommissionPercent(basic_info.commission_percentage);
				let blockrule = response.data.data.block_rules;
				setSkillraceBlockRuleData(blockrule);
				let settings = response.data.data.settings;
				setUrl(settings.video_url);
				setDescription(settings.about_two_line);
				setEditorData(settings.about);
				setUploadImageUrl(settings.image_url);
				setBackgroundColor(settings.background_color);
				setActiveWeb(settings.active_web);
				setActiveMobile(settings.active_mobile);
				setMobileUrl(settings.mobile_video_url);
			} else {
				setError(response.data.message);
				setSkillLoader(false);
			}
		});
	}

	/* customize table */
	const customizeTable = () => {
		setUpdated(false);
		setLoader(true);
		let url = "/skillrace/customize-table";

		let body = {
			table_data: skillRaceTableData
		};

		service(true, url, body).then((response) => {
			if (response.data.status_code === 200) {
				if (response.data.status === true) {
					// message.success("Table customized");
					setUpdated(true);
					setLoader(false);
				} else {
					setError(response.data.message);
					setUpdated(false);
					setLoader(false);
				}
			} else {
				setError(response.data.message);
				setUpdated(false);
				setLoader(false);
			}
		})
	}

	/* table data api */

	const getTableInfo = () => {
		let url = '/m2e/custom-table-info';
		service(false, url).then((response) => {
			if (response.data.status_code === 200) {
				setLoader(false);
			} else {
				setError(response.data.message)
			}
		})
	}

	const editRule = (data, key) => {
		setId(data.block_rule_id)
		document.getElementById(`amount${key}`).disabled = false;
		document.getElementById(`loseIn${key}`).disabled = false;
		setSelectDisable(`select${key}`)
		document.getElementById(`block${key}`).disabled = false;
		document.getElementById(`code${key}`).disabled = false;
		document.getElementById(`type${key}`).style.pointerEvents = "all"
	}

	/* update block rule */
	const updateBlockRule = (item) => {
		setRuleLoader(true)
		let url = "/skillrace/update-blockrule";

		let data = {
			"block_rule_id": item.block_rule_id,
			"loss_amount": item.loss_amount,
			"period": item.period,
			"period_type": item.period_type,
			"block_for_days": item.block_for_days,
			"block_code": item.block_code
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				// message.success("Block rule updated");
				setRuleLoader(false);
				getSkillRaceDetails();
				setId("");
				setSelectDisable("");
				setClick(1);
			} else {
				setError(response.data.message);
				setRuleLoader(false);
			}
		});
	}

	/* API - Create pre signed url in s3 */
	const preSignedUrl = (file) => {
		var date = new Date()
		setImageLoader(true);
		let url = "/app/presigned-url-upload";
		let data = {
			key: `skillrace/` + date.toISOString() + '/thumbnail.png',
			is_public: true
		};

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				setImageLoader(true);
				uploadDocument(response.data.data, file); // upload file in AWS s3
			} else {
				setError(response.data.message);
				setImageLoader(false);
			}
		});
	}

	/* Uploading to s3 */
	const uploadDocument = async (data, file) => {
		setImageLoader(true);
		var formdata = new FormData();
		formdata.append("key", data.upload_info.fields.key);
		formdata.append("x-amz-algorithm", data.upload_info.fields['x-amz-algorithm']);
		formdata.append("x-amz-credential", data.upload_info.fields['x-amz-credential']);
		formdata.append("x-amz-date", data.upload_info.fields['x-amz-date']);
		formdata.append("policy", data.upload_info.fields['policy']);
		formdata.append("x-amz-signature", data.upload_info.fields['x-amz-signature']);
		formdata.append("file", file, file.name);

		var requestOptions = {
			method: 'POST',
			body: formdata,
			redirect: 'follow'
		};

		fetch(data.upload_info.url, requestOptions)
			.then(response => response.text())
			.then(result => {
				setUploadImageUrl(data.view_info); // file got uploaded successfully
				setImageLoader(false);
			})
			.catch(error => {
				console.log('error', error); // show error toast here
				setImageLoader(false);
			});
	}


	/*update skill race settings */
	const updateSkillraceSettings = () => {
		var api_url = "/skillrace/update-settings";
		let data = {
			"background_color": backgroundColor,
			"image_url": imageUrl,
			"video_url": url,
			"about_two_line": description,
			"about": editorData,
			"active_web": activeWeb,
			"active_mobile": activeMobile,
			"mobile_video_url": mobileUrl,
		}
		service(true, api_url, data).then((response) => {
			if (response.data.status_code === 200) {
				getSkillRaceDetails();

			} else {
				setError(response.data.message);
			}
		})
	}


	return (
		<>
			<div className='e-game-settings-accordion e-manage-game-accordion e-add-milestone-wrap px-4'>
				{
					skillLoader ?
						<Loader />
						:
						<div class="accordion" id="accordionExample">
							<div class="accordion-item">
								<div onClick={() => setClick(1)}>
									{
										click !== 1 ?
											<h2 className="accordion-header" id="headingOne" >
												<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
													<div className='row'>
														<div className='col-lg-10'>
															Fee, commission & scrip settings
														</div>
														<div className='col-lg-2'>
															<span className='e-accordion-icon-plus'></span>
														</div>
													</div>
												</button>
											</h2>
											:
											null
									}
								</div>
								<div onClick={() => setClick(null)}>
									{
										click === 1 ?
											<h2 className="accordion-header" id="headingOne" >
												<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
													<div className='row'>
														<div className='col-lg-10'>
															Fee, commission & scrip settings
														</div>
														<div className='col-lg-2'>
															<span className='e-accordion-icon-minus'></span>
														</div>
													</div>
												</button>
											</h2>
											:
											null
									}
								</div>

								{
									click === 1 ?
										<div>
											<div class="accordion-body pt-0">

												<div className='row'>
													<div className='col-lg-4'>
														<label className="mb-1">Total entries</label>
														<Input
															placeholder="Total entries"
															value={parseInt(totalEntries)}
															valueChange={(temp) => {
																parseInt(setTotalEntries(temp))
															}}
															type="number"
														/>
													</div>
													<div className='col-lg-4'>
														<label className="mb-1">Max amount per entity</label>
														<Input
															placeholder="Max amount per entity"
															value={parseInt(entityAmount)}
															valueChange={(temp) => {
																parseInt(setEntityAmount(temp))
															}}
															type="number"
														/>

													</div>
													<div className='col-lg-4'>
														<label className="mb-1">Stock listing from</label>
														<SelectBox
															value={stockList}
															options={options}
															className="e-milestone-sort"
															selectchange={(temp) => {
																setStockList(temp)
															}}
														/>
													</div>
												</div>
												<div className='row pt-4'>
													<div className='col-lg-4'>
														<label className="mb-1">Game time</label>
														<Timepicker
															className="e-input-suffix"
															value={[gameStartTime, gameEndTime]}
															onChange={(time) => {
																setGameStartTime(time[0]);
																setGameEndTime(time[1]);
																console.log(time);
															}}
														/>
													</div>
													<div className='col-lg-4'>
														<label className="mb-1">Max booking per symbol</label>
														<Input
															placeholder="Max booking per symbol"
															value={maxBooking}
															valueChange={(temp) => {
																setMaxBooking(temp)
															}}
														/>
													</div>
													<div className='col-lg-4'>
														<label className="mb-1">Commission %</label>
														<Input
															placeholder="Commission"
															value={(commissionPercent)}
															valueChange={(temp) => {
																setCommissionPercent(temp)
															}}
														/>
													</div>

												</div>

												<div className='row pt-3 mt-4'>
													<div className='col-lg-2'>
														{
															totalEntries && entityAmount
																&& gameStartTime && gameEndTime &&
																stockList?.value && maxBooking && commissionPercent !== "" ?
																<PrimaryBtn
																	name="Save changes"
																	className="e-acc-btn"
																	handleClick={() => {
																		var modal = new Modal(document.getElementById("confirmation"), {});
																		modal.show();
																		setType("skillrace");
																		setTitle("Do yor really want to save changes made to Skill race settings?")
																	}} />
																:
																<PrimaryBtn
																	name="Save changes"
																	className="e-disabled e-acc-btn"
																/>
														}
													</div>
												</div>

											</div>
										</div>
										:
										null
								}
							</div>
							<div class="accordion-item">
								<div onClick={() => setClick(2)}>
									{
										click !== 2 ?
											<h2 className="accordion-header" id="headingTwo">
												<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">

													<div className='row'>
														<div className='col-lg-10'>
															Customize table
														</div>
														<div className='col-lg-2'>
															<span className='e-accordion-icon-plus'></span>
														</div>
													</div>
												</button>
											</h2>
											:
											null
									}
								</div>
								<div onClick={() => setClick(null)}>
									{
										click === 2 ?
											<h2 className="accordion-header" id="headingTwo">
												<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">

													<div className='row'>
														<div className='col-lg-10'>
															Customize table
														</div>
														<div className='col-lg-2'>
															<span className='e-accordion-icon-minus'></span>
														</div>
													</div>
												</button>
											</h2>
											:
											null
									}
								</div>
								{
									click === 2 ?
										<div >
											<div class="accordion-body pt-0">
												<div className='row'>
													<div className='col-lg-12'>
														<div className={`col-lg-12 ${style.e_card_wrap} border-0`}>
															<M2Etable
																tableData={(data) => {
																	setSkillRaceTableData(data);
																}}
																type="skillrace"
															/>
														</div>
													</div>
												</div>
												<div className='row pt-3'>
													<div className='col-lg-2'>
														<PrimaryBtn
															name="Save changes"
															className={
																loader ?
																	"e-acc-btn e-btn-loader"
																	:
																	"e-acc-btn"
															}
															handleClick={() => customizeTable()} />
													</div>
												</div>
											</div>
										</div>
										:
										null
								}
							</div>
							<div class="accordion-item">
								<div onClick={() => setClick(3)}>
									{
										click !== 3 ?
											<h2 className="accordion-header" id="headingThree">
												<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">

													<div className='row'>
														<div className='col-lg-10'>
															Block rules
														</div>
														<div className='col-lg-2'>
															<span className='e-accordion-icon-plus'></span>
														</div>
													</div>
												</button>
											</h2>
											:
											null
									}
								</div>
								<div onClick={() => setClick(null)}>
									{
										click === 3 ?
											<h2 className="accordion-header" id="headingThree">
												<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
													<div className='row'>
														<div className='col-lg-10'>
															Block rules <span><PrimaryLink name="Add new rule" className="ps-2" handleClick={() => {
																setClick(3);
																var modal = new Modal(document.getElementById("add-block-rule"), {})
																modal.show()
															}} /></span>
														</div>
														<div className='col-lg-2'>
															<span className='e-accordion-icon-minus'></span>
														</div>
													</div>
												</button>
											</h2>
											:
											null
									}
								</div>
								{
									click === 3 ?
										<div >
											<div class="accordion-body pt-0">
												{
													skillraceBlockRuleData.map((item, i) => (
														<div className='row pb-2'>
															<div className='col-lg-3'>
																<label className="mb-1">Lose amount</label>
																<Input
																	type="number"
																	disabled={true}
																	id={`amount${i}`}
																	placeholder="Lose amount"
																	suffix="₹"
																	defaultValue={item.loss_amount}
																	value={loseAmount}
																	valueChange={(temp) => {
																		// setLoseAmount(temp);
																		skillraceBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setLoseAmount(temp) : item.loss_amount);
																		let tempArr = [...skillraceBlockRuleData];
																		tempArr[i]['loss_amount'] = temp;
																		skillraceBlockRuleData = tempArr;
																		console.log("d", skillraceBlockRuleData);
																		setSkillraceBlockRuleData(skillraceBlockRuleData)

																	}} />
															</div>
															<div className='col-lg-3 e-add-block-in position-relative'>
																<label className="mb-1">in</label>
																<Input id={`loseIn${i}`}
																	suffixId={`type${i}`}
																	suffix={loseIn !== null ? loseIn : item.period_type === 1 ? <span onClick={() => { setShow(i) }}>Days <img src={Down} alt="" /></span> : item.period_type === 2 ? <span onClick={() => { setShow(i) }}>Month <img src={Down} alt="" /></span> : item.period_type === 3 ? <span onClick={() => { setShow(i) }}>Quarter <img src={Down} alt="" /></span> : <span onClick={() => { setShow(i) }}>Year <img src={Down} alt="" /></span>}
																	disabled={true} placeholder="days" value={period}
																	defaultValue={item.period}
																	valueChange={(temp) => {
																		skillraceBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setPeriod(temp) : item.period);
																		let tempArr = [...skillraceBlockRuleData];
																		tempArr[i]['period'] = temp;
																		skillraceBlockRuleData = tempArr;
																		// console.log("d", skillraceBlockRuleData);
																		setSkillraceBlockRuleData(skillraceBlockRuleData)
																	}}
																/>
																{
																	show === i ?
																		<div className="e-block-show" ref={wrapperRef}>
																			{
																				ruleOptions.map((ruleItem) => {
																					return (
																						<p className={ruleItem.value === item.period_type ? 'e-selected mb-2 mt-2' : 'mb-2 mt-2'}
																							onClick={() => {
																								skillraceBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setLoseAmount(ruleItem.value) : item.period_type);
																								let tempArr = [...skillraceBlockRuleData];
																								tempArr[i]['period_type'] = ruleItem.value;
																								skillraceBlockRuleData = tempArr;
																								console.log("d", skillraceBlockRuleData);
																								setSkillraceBlockRuleData(skillraceBlockRuleData)
																								setShow(null)
																							}}>{ruleItem.label}</p>
																					)
																				})
																			}

																		</div>
																		:
																		null
																}
															</div>
															<div className='col-lg-3 e-add-block-rule position-relative'>
																<label className="mb-1">Block for</label>
																<Input id={`block${i}`}
																	suffix={"Days"}
																	disabled={true} placeholder="days" value={blockFor}
																	defaultValue={item.block_for_days}
																	valueChange={(temp) => {
																		skillraceBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setBlockFor(temp) : item.block_for_days);
																		let tempArr = [...skillraceBlockRuleData];
																		tempArr[i]['block_for_days'] = parseInt(temp);
																		skillraceBlockRuleData = tempArr;
																		setSkillraceBlockRuleData(skillraceBlockRuleData)
																	}} />
															</div>
															<div className='col-lg-2 pe-0'>
																<label className="mb-1">Block code</label>
																<Input id={`code${i}`}
																	disabled={true}
																	placeholder="days"
																	value={blockCode}
																	defaultValue={item.block_code}
																	valueChange={(temp) => {
																		skillraceBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setBlockCode(temp) : item.block_for_days);
																		let tempArr = [...skillraceBlockRuleData];
																		tempArr[i]['block_code'] = temp;
																		skillraceBlockRuleData = tempArr;
																		setSkillraceBlockRuleData(skillraceBlockRuleData)
																	}} />
															</div>
															<div className='col-lg-1 ps-0'>
																<span className='d-inline-flex mt-4 pt-3'>
																	{
																		item.block_rule_id === id ?
																			<PrimaryLink
																				name="Save"
																				className="e-save-btn"
																				handleClick={() => {
																					updateBlockRule(item)
																				}}
																			/>
																			:
																			<PrimaryLink
																				name="Edit"
																				className="e-edit-btn"
																				handleClick={() => editRule(item, i)}
																			/>
																	}
																	<PrimaryLink
																		name="Delete"
																		className="e-delete-btn"
																		target="#confirmation"
																		toggle="modal"
																		handleClick={() => {
																			setId(item.block_rule_id);
																			setTitle("Confirmation");
																			setType("m2e");
																		}}
																	/>
																</span>
															</div>
														</div>
													))
												}

											</div>
										</div>
										:
										null
								}
							</div>
							<div class="accordion-item">
								<div onClick={() => setClick(4)}>
									{
										click !== 4 ?
											<h2 className="accordion-header" id="headingFour">
												<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">

													<div className='row'>
														<div className='col-lg-10'>
															Game image & color
														</div>
														<div className='col-lg-2'>
															<span className='e-accordion-icon-plus'></span>
														</div>
													</div>
												</button>
											</h2>
											:
											null
									}
								</div>
								<div onClick={() => setClick(null)}>
									{
										click === 4 ?
											<h2 className="accordion-header" id="headingFour">
												<button
													class="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseFour"
													aria-expanded="false"
													aria-controls="collapseFour"
												>
													<div className='row'>
														<div className='col-lg-10'>
															Game image & color
														</div>
														<div className='col-lg-2'>
															<span className='e-accordion-icon-minus'></span>
														</div>
													</div>
												</button>
											</h2>
											:
											null
									}
								</div>
								{
									click === 4 ?
										<div >
											<div class="accordion-body pt-0">

												<div className='row'>
													<div className='col-lg-4'>
														<div className='row pe-2'>
															<label className="mb-1">Choose image</label>
															<div className="e-uploader" onClick={() => { $('#e-upload').click() }}>
																{
																	uploadImage ?
																		<>
																			<p className='text-center mb-2'>Choose image</p>
																			<div className='text-center mb-0 setting-detail'>(Selected: {uploadImage})</div>
																		</>
																		:
																		<>
																			<p className='text-center mb-2'>Choose image</p>
																			<p className='text-center mb-0'>
																				<span>(500 X 500 png recommended)</span>
																			</p>
																		</>

																}

																<FileUpload
																	accept="application/pdf, image/*"
																	className="hidden"
																	id="e-upload"
																	upload={(temp) => {
																		preSignedUrl(temp)
																	}}
																/>
															</div>
														</div>
														<div className='row pt-5 pe-2'>
															<label className="mb-1">Choose background color</label>
															<ColorPicker
																color={(data) => {
																	setBackgroundColor(data.hex);
																}} />
														</div>
													</div>
													<div className='col-lg-4 ps-5'>
														<label className="mb-1">Image & bg color preview</label>
														<div className='e-preview-sec'>
															<div style={{ backgroundColor: backgroundColor }}
																className="e-preview-sub-sec">
																<img src={imageLoader ? LoaderGif : imageUrl} alt="" />
															</div>
														</div>
													</div>
												</div>
												<div className='row pt-4'>
													<div className='col-lg-2'>
														<CheckBox
															check={activeWeb}
															checkboxText="Show game in Web"
															onChange={(e) => setActiveWeb(e.target.checked)} />
													</div>
													<div className='col-lg-3'>
														<CheckBox
															check={activeMobile}
															checkboxText="Show game in Mobile"
															onChange={(e) => setActiveMobile(e.target.checked)} />
													</div>
												</div>
												<div className='row pt-3 pe-0'>
													<div className='col-lg-4 pe-0'>
														<label className="mb-1">Tutorial video link for Web</label>
														<Input
															placeholder="Url"
															value={url}
															valueChange={(temp) => setUrl(temp)} />
													</div>
												</div>
												<div className='row pt-3 pe-0'>
													<div className='col-lg-4 pe-0'>
														<label className="mb-1">Tutorial video link for Mobile</label>
														<Input
															placeholder="Url"
															value={mobileUrl}
															valueChange={(temp) => setMobileUrl(temp)} />
													</div>
												</div>
												<div className='row pt-3'>
													<div className='col-lg-12'>
														<label className="mb-1">Two liner about game</label>
														<TextArea
															placeholder="Description"
															value={description}
															valueChange={(temp) => setDescription(temp)}
														/>
													</div>
												</div>
												<div className='row e-accordion-editor pt-3'>
													<div className='col-lg-12'>
														<label className="mb-1">About game</label>
														<Editor
															editorChange={(temp) => {
																setEditorData(temp)
															}}
															value={editorData}
														/>
													</div>
												</div>

												<div className='row pt-3'>
													<div className='col-lg-2'>
														<PrimaryBtn
															name="Save changes"
															className="e-acc-btn"
															handleClick={() => updateSkillraceSettings()}
														/>
													</div>
												</div>
											</div>
										</div>
										:
										null
								}
							</div>
						</div>
				}
			</div>
			<Confirmation
				type={type}
				apiData={skillRaceRequest}
				title={title}
				block_rule_id={id}
				close={() => { }}
				update={() => {
					getSkillRaceDetails()
				}}
			/>
			<AddBlockRule type="skillrace" ruleAdded={() => getSkillRaceDetails()} />
		</>
	);
}


export default SkillRaceSettingsAccordion;