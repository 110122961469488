/*
 *   File : scripList.js
 *   Author : https://evoqueinnovativelab.com
 *   Description :  
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 23-11-2021
 */

/*import packages*/
import { useEffect, useState } from 'react';

/*import component */
import { Confirmation } from '../../components/modal';

/*import style*/
import styles from '../../styles/components/scripList.module.scss';


/*import assets*/
import Block from '../../assets/images/card/block.svg';
import Active from '../../assets/images/card/active.svg';
import Remove from '../../assets/images/card/remove.svg';


const ScripList = (props) => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [name, setName] = useState("");
    const [block, setblock] = useState(null);
    const [scripId, setScripId] = useState(null);
    const [scripName, setScripName] = useState("");

    useEffect(() => {
        setScripId(props.id)
    }, [props.id])
    useEffect(() => {
        props.scripId(scripId)
        //eslint-disable-next-line
    }, [scripId])

    useEffect(() => {
        props.scripName(scripName)
        //eslint-disable-next-line
    }, [scripName])

    const blockModalData = (id, block) => {
        if (block === true) {
            setTitle("Unblock?");
            setContent("Do you really want to unblock this symbol?");
        } else {
            setTitle("Block for 20 days?");
            setContent("Do you really want to block this symbol in 20 days?");
        }
        setName(block === false ? "Yes, block" : "Yes, unblock");
        setblock(true);
        setScripId(id);
    }

    const removeData = (id) => {
        setTitle("Remove this stock?");
        setContent("Do you really want to remove this symbol permanently?");
        setName("Yes, remove");
        setblock(false);
        setScripId(id);
    }




    return (
        <>
            {
                props.type ?
                    <>
                        {
                            props.list.map((item) => {
                                return (
                                    <div className='col-lg-4 pb-3'>
                                        <div className={`${styles.e_scrip_list_type}`} id={item.id}>
                                            <div className="row">
                                                <div className="col-lg-6 col-6 text-start d-inline-flex">
                                                    <h5 className='mb-0 pt-1'>{item.code}</h5>
                                                </div>
                                                <div className='col-lg-6 text-end'>
                                                    <span className={styles.e_add} onClick={() => {
                                                        setScripId(item.code);
                                                        setScripName(item.name)
                                                    }}>
                                                        {
                                                            item.code !== scripId ?
                                                                "+ Add"
                                                                :
                                                                null
                                                        }
                                                    </span>
                                                    <span className={styles.e_remove} onClick={() => {
                                                        setScripId(null);
                                                        setScripName(item.name)
                                                    }}>
                                                        {
                                                            item.code === scripId ?
                                                                "- remove"
                                                                :
                                                                null
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                    :
                    <>
                        {
                            props.list.map((item) => {
                                return (
                                    <div className='col-lg-4 pb-3'>
                                        <div className={`${styles.e_scrip_list} `} id={item.scrip_id}>
                                            <div className="row">
                                                <div className="col-lg-9 col-9 text-start d-inline-flex">
                                                    <h5 className='mb-0 pt-1'>{item.code}</h5>
                                                    {
                                                        item.is_listed ?
                                                            <span className={`${styles.e_verified} pt-1`}>Listed</span>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className='col-lg-3'>
                                                    <span className='d-flex mt-1 ps-3'>
                                                        {
                                                            item.is_blocked === true ?
                                                                <img src={Block} className="me-3"
                                                                    data-bs-toggle="modal"
                                                                    alt=""
                                                                    data-bs-target="#confirmation" onClick={() => {
                                                                        blockModalData(item.scrip_id, item.is_blocked)
                                                                    }} />
                                                                :
                                                                <img src={Active} className="me-3"
                                                                    data-bs-toggle="modal"
                                                                    alt=""
                                                                    data-bs-target="#confirmation" onClick={() => {
                                                                        blockModalData(item.scrip_id, item.is_blocked)
                                                                    }} />
                                                        }
                                                        <img src={Remove} className=""
                                                            alt=""
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#confirmation" onClick={() => {
                                                                removeData(item.scrip_id)
                                                            }} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
            }
            <Confirmation
                title={title}
                content={content}
                btn_name={name}
                status={block}
                id={scripId}
                update={() => props.update()}
                close={() => { }} />
            {/* <Confirmation title={title} type={true} content={content} btn_name={name} /> */}


        </>
    )
}

export default ScripList