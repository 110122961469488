/*
 *   File : dateRangepicker.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description :  dateRangepicker.js
 *   Integrations : 
 *   Version : v1.0
 */

/*Package importing*/
import React from 'react';
import 'react-dates/initialize';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

/*Components*/
// import CalendarIcon from '../../assets/images/calenderIcon.svg';

export default class DateRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initalDate: moment().format(),
      startDate: null,
      endDate: null,
    }
  }

  clearDateSelection = () => {
    this.setState({
      startDate: null,
      endDate: null
    });
  }

  onDatesChange = async ({ startDate, endDate }) => {
    this.setState({ startDate: startDate, endDate: endDate })
    if (startDate && endDate) {
      var tempStart = startDate.format("YYYY-MM-DD");
      var tempEnd = endDate.format("YYYY-MM-DD");
      this.props.dateChange(tempStart, tempEnd)
    } else {
      this.props.dateChange(null, null)
    }
  }

  render() {
    return (
      <DateRangePicker
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onDatesChange={this.onDatesChange.bind(this)}
        focusedInput={this.state.focused}
        onFocusChange={focusedInput => this.setState({ focused: focusedInput })}
        isOutsideRange={(day => isInclusivelyAfterDay(day, moment().add(2, 'days')))}
        small={true}
        displayFormat={this.props.format ? this.props.format : 'DD-MM-YYY'}
        showDefaultInputIcon
        inputIconPosition="after"
        showClearDates={true}
        startDatePlaceholderText="dd/mm/yyy"
        endDatePlaceholderText="dd/mm/yyy"
      />
    );
  }
}

