/*
 *   File : timepicker.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description :  timePicker.js
 *   Integrations : 
 *   Version : v1.0
 */

import { TimePicker } from "antd";
import moment from 'moment';

/* styles */
//eslint-disable-next-line
import 'antd/dist/antd.min.css';
import { useEffect, useState } from "react";

export default function Timepicker(props) {
	const [value, setValue] = useState({ startTime: null, endTime: null })
	useEffect(() => {
		console.log(props.value)
		if (props.value[0] !== "" && props.value[1] !== "") {
			setValue({
				startTime: moment(props.value[0], 'h:mm a'),
				endTime: moment(props.value[1], 'h:mm a')
			});
		} else {
			setValue({
				startTime: null,
				endTime: null
			})
		}
	}, [props.value])


	return (
		<div className="e-time-picker">
			<TimePicker.RangePicker
				value={[value.startTime, value.endTime]}
				onKeyDown={e => e.preventDefault()}
				use12Hours
				format="h:mm a"
				disabledHours={props.disabledHours}
				disabledMinutes={props.disabledMinutes}
				suffixIcon={props.suffixIcon}
				className={props.className}
				onChange={(time, timeString) => { props.onChange(timeString); }}
			/>
		</div>
	)
}
