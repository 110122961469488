/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : M2M game scrips;
 *   Integrations :
 *   Version : v1.0
 *   Created : 21-02-2022
 */
/*import pakcages*/
import { useState } from 'react';

/*import components*/
import { DataTable } from '../../../components/dataFormating/index';
import SelectBox from '../../../components/dropdown/selectBox';
import { SingleDate } from '../../../components/input/index';

const Scrips = (props) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  const [click, setClick] = useState()
  //eslint-disable-next-line
  const [selected, setSelected] = useState({});

  const options = [
    {
      value: 1, label: '9:30 - 10:30 am'
    },
    {
      value: 2, label: '10:30 - 11:30 am'
    },
    {
      value: 3, label: '11:30 - 12:30 pm'
    },
    {
      value: 4, label: '12:30 - 01:30 pm'
    },
  ]


  const data = [
    { scrip: 'Apple Inc.', prev_close: '295.96', close_price: '298.44', change: '25', performer_qty: '4', under_perfor_qty: '3', distrib_mount: '₹1,500', prize: '₹6,924' },
    { scrip: 'Visa Inc.', prev_close: '392.93', close_price: '298.44', change: '12', performer_qty: '4', under_perfor_qty: '5', distrib_mount: '₹6,500', prize: '₹6,924', },
    { scrip: 'Tesla Inc ', prev_close: '401.06', close_price: '298.44 ', change: '8', performer_qty: '1', under_perfor_qty: '4', distrib_mount: '₹1,000', prize: '₹6,924' },
    { scrip: 'Chase & Co.', prev_close: '265.36', close_price: '298.44', change: '4', performer_qty: '2', under_perfor_qty: '2', distrib_mount: '₹1,600', prize: '₹6,924' },
    { scrip: 'JPMorgan', prev_close: '265.36', close_price: '298.44', change: '2', performer_qty: '5', under_perfor_qty: '1', distrib_mount: '₹2,500', prize: '₹6,924' },
    { scrip: 'NVIDIA Corporation ', prev_close: '265.36', close_price: '298.44', change: '8', performer_qty: '2', under_perfor_qty: '1', distrib_mount: '₹4,500', prize: '₹6,924', },
    { scrip: 'Alphabet Inc.', prev_close: '265.36', close_price: '298.44', change: '1', performer_qty: '3', under_perfor_qty: '2', distrib_mount: '₹4,700', prize: '₹6,924' },
    { scrip: 'Meta Platforms Inc.', prev_close: '265.36', close_price: '298.44', change: '2', performer_qty: '1', under_perfor_qty: '1', distrib_mount: '₹4,000', prize: '₹6,924' },
    { scrip: 'Meta Platforms Inc.', prev_close: '265.36', close_price: '298.44', change: '2', performer_qty: '1', under_perfor_qty: '1', distrib_mount: '₹5,500', prize: '₹6,924' },
    { scrip: 'Meta Platforms Inc.', prev_close: '265.36', close_price: '298.44', change: '2', performer_qty: '1', under_perfor_qty: '1', distrib_mount: '₹7,500', prize: '₹6,924' },
    { scrip: 'Meta Platforms Inc.', prev_close: '265.36', close_price: '298.44', change: '2', performer_qty: '1', under_perfor_qty: '1', distrib_mount: '₹1,900', prize: '₹6,924' },
    { scrip: 'Meta Platforms Inc.', prev_close: '265.36', close_price: '298.44', change: '2', performer_qty: '1', under_perfor_qty: '1', distrib_mount: '₹3,500', prize: '₹6,924' },
    { scrip: 'Meta Platforms Inc.', prev_close: '265.36', close_price: '298.44', change: '2', performer_qty: '1', under_perfor_qty: '1', distrib_mount: '₹4,500', prize: '₹6,924' },
  ]

  const columns = [
    {
      name: 'Scrip',
      selector: 'scrip',
      sortable: true,
      left: true,
      minWidth: "180px",
    },
    {
      name: 'Prev close',
      selector: 'prev_close',
      sortable: true,
      left: true,
      // maxWidth: "160px",
    },
    {
      name: 'Close price',
      selector: 'close_price',
      sortable: true,
      left: true,
    },
    {
      name: 'Change %',
      selector: 'change',
      sortable: true,
      left: true,

    },
    {
      name: 'Performer qty',
      selector: 'performer_qty',
      sortable: true,
      left: true,
    },
    {
      name: 'Under perfor.qty',
      selector: 'under_perfor_qty',
      sortable: true,
      left: true,
      minWidth: "150px",
    },
    {
      name: 'Distrib. amount',
      selector: 'distrib_mount',
      sortable: true,
      left: true,
      // minWidth: "200px",
    },
    {
      name: 'Prize /unit',
      selector: 'prize',
      sortable: true,
      right: true,
    },

  ]


  return (
    <div className='e-users-container'>
      <div className="row">
        <div className="col-lg-12">
          <div className="card e-dashboard-card border-0">
            <div className='row pe-4'>
              <div className='col-lg-6'>
                <div className='d-flex'>
                  <span className='ps-4 ms-2 pt-3 mt-1 e-labels'>Show</span>
                  <div className="e-span-wrap mt-3 ms-3">
                    <span className={click === "1" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("1")}>
                      All scrips
                    </span>
                    <span className={click === "2" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("2")}>
                      Winners
                    </span>
                    <span className={click === "3" ? "e-profile-active-btn" : "e-profile-btn"} onClick={() => setClick("3")}>
                      Losers
                    </span>

                  </div>
                </div>
              </div>
              <div className='col-lg-5 offset-lg-1'>
                <div className='d-flex'>
                  <span className='ps-4 ms-2 pt-3 mt-1 e-labels me-3'>Date</span>
                  <SingleDate placeholder="Date" className="mt-2" />
                  <span className='ps-4 ms-2 pt-3 mt-1 e-labels me-3'>Slot</span>
                  <SelectBox options={options} placeholder="Select slot" className="e-sort mt-2" selectchange={(temp) => setSelected(temp)} />

                </div>


              </div>

            </div>
            <div className="row">
              <div className="col-lg-12 e-scrip-table pt-2">
                <DataTable pagination={true} columns={columns} data={data} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default Scrips;
