/*
 *   File : searchInput.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 15-11-2021
 */
/*import package*/
import { useState } from 'react';
import $ from 'jquery'

/*import style*/
import styles from '../../styles/components/searchInput.module.scss';

const SearchInput = (props) => {
  //eslint-disable-next-line
  const [value, setValue] = useState();

  const valueChange = (e) => {
    setValue(e.target.value);
    props.valueChange(e.target.value);
  }

  const keyPress = (e) => {

    if (e.keyCode === 13 && props.onSearch) {
      props.onSearch(e.target.value);
    }
  }

  $(`input`).on('keydown', function (e) {
    if (e.which === 32 && e.target.selectionStart <= 1) {
      return false;
    }
  });

  return (
    <div className={`${styles.e_input_wrap}`}>
      <input id={props.id}
        value={props.value}
        type={props.type}
        //eslint-disable-next-line
        className={`${styles.e_search_input_box}` + " " + props.className}
        onChange={(e) => { valueChange(e) }}
        placeholder={props.placeholder}
        onKeyDown={(e) => { keyPress(e) }}
        {...props} />
    </div>
  );
}
export default SearchInput;


