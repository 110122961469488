

/*import package*/
import React from 'react';

/*import styles*/
import styles from '../../styles/components/radioButton.module.scss';

const RadioButton = (props) => {
  const onClick = (e) => {
    props.onClick(e)
  }

  return (
    <div className={`${styles.e_radiobutton} ` + props.className}>
      <input id={props.id} onClick={(e) => { onClick(e) }} type="radio" name={props.name} checked={props.checked} />
      <label for={props.id} className={props.className}>{props.label}</label>
    </div>
  );
}

export default RadioButton;