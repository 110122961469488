/*
 *   File : bar.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : bar.js
 *   Integrations : 
 *   Version : v1.0
 */

/*Package importing*/
import React from 'react';
import Chart from "react-apexcharts";

/*import style*/
// import styles from '../../styles/components/bar.module.scss';

export default class GroupedBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: this.props.series_data,
    };
  }
  componentDidMount() {
    if (this.props.data) {
    }
  }

  render() {
    const options = {
      legend: { fontSize: '14px', fontFamily: 'Inter', fontWeight: "800", color: "#00244B" },
      //eslint-disable-next-line
      // fill: {
      //   colors: this.props.colors,
      // },
      colors: this.props.colors,
      chart: {
        type: 'bar',
        height: 350
      },
      //eslint-disable-next-line
      fill: {
        type: "gradient",
        gradient: {
          shade: 'light',
          shadeIntensity: 0.5,
          opacityFrom: 0.7,
          opacityTo: 0.9,
        }
      },
      plotOptions: {
        bar: {
          endingShape: 'rounded',
          // width: 0,
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1,
        colors: ['transparent']
      },
      xaxis: {
        categories: this.props.categories,
        allowDecimals: false,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        lines: {
          show: false
        },
        labels: {
          style: {
            colors: ['#61758A'],
            fontSize: '10px',
            fontFamily: 'Inter',
            fontWeight: 500,
            cssClass: 'apexcharts-xaxis-label',
          },
        },
      },
      yaxis: {
        allowDecimals: false,
        lines: {
          show: false
        },

        labels: {
          rotate: -90,
          style: {
            colors: ['#61758A'],
            fontSize: '10px',
            fontFamily: 'Inter',
            fontWeight: 500,
            cssClass: 'apexcharts-xaxis-label',
          },
        },
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: true,
        shared: false,
        y: {
          formatter: function (val) {
            return val
          }
        },
      },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false
          }
        },
      }
    }
    return (
      <Chart options={options} series={this.state.series} type="bar" height={350} />
    );
  }
}

