/*
 *   File : confirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Confirmation Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { SearchInput } from '../../components/input/index';
import { ScripList } from '../../components/card/index';

/*import styles*/
import styles from '../../styles/components/addScrip.module.scss';
import service from '../../services/service';
import { Toast } from '../note';


export default function AddScrip(props) {

    const [search, setSearch] = useState("");
    const [scripList, setScripList] = useState([]);
    const [error, setError] = useState(null);
    const [scripId, setScripId] = useState(null);
    const [scripName, setScripName] = useState("");

    useEffect(() => {
        if (search === "") {
            _getScripList()
        }
        //eslint-disable-next-line
    }, [search])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    /* ------ list of scrips api ----------*/
    const _getScripList = (query) => {
        let url = "/scrip/pool-search";

        let data = {
            query: query ? query.value : search
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setScripList(response.data.data)
            } else {
                setError(response.data.message);
            }
        });
    }

    /*------add scrip api --------*/
    const addScrip = () => {
        let url = "/scrip/add";

        let data = {
            scrip_code: scripId,
            scrip_name: scripName
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                $("#addScrip").trigger("click")
            } else {
                setError(response.data.message);
            }
        });
    }

    return (
        <div className={`${styles.e_add_scrip} modal fade`} id="addScrip" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div className='container'>
                            <div className='row'>
                                <div className='d-inline-flex'>
                                    <div className='col-lg-1 pt-2'>
                                        <span className='e-labels'>Add scrip</span>
                                    </div>
                                    <div className="col-lg-7 position-relative ps-3" >
                                        <SearchInput
                                            placeholder="Search in NSE 100"
                                            value={search}
                                            onSearch={(value) => _getScripList({ value: value })}
                                            valueChange={(value) => { setSearch(value) }} />
                                        {
                                            search && search.length ?
                                                <>
                                                    <span
                                                        className={`${styles.e_search_submit} `}
                                                        onClick={() => _getScripList()} >
                                                    </span>
                                                    <button
                                                        className={`${styles.e_search_close} pe-3`}
                                                        onClick={() => {
                                                            setSearch("");
                                                            // _getScripList({ value: "" })
                                                        }} >
                                                    </button>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className='col-lg-4 pt-3'>
                                        <div className='d-flex'>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => { setScripId(null) }} >
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container px-3">
                        <div className="row pt-3">
                            <div className="col-lg-12">
                                <div className={`${styles.e_wrapper} `}>
                                    <div className='row'>
                                        <ScripList
                                            list={scripList}
                                            type={true}
                                            id={scripId}
                                            scripId={(data) => setScripId(data)}
                                            scripName={(data) => setScripName(data)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className="e-cancel"
                                            cancel="modal"
                                            handleClick={() => { setScripId(null) }} />
                                        {
                                            scripId ?
                                                <PrimaryBtn
                                                    name="Save to list"
                                                    className="e-modal-submit"
                                                    handleClick={() => { addScrip() }} />
                                                :
                                                <PrimaryBtn
                                                    name="Save to list"
                                                    className="e-modal-submit e-disabled" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </div>
    )
}
