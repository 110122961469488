/*
 *   File : profile.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : profile
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-02-2022
*/

/*import packages*/
import moment from 'moment';
import { useEffect, useState } from 'react';

/*import components*/
import { ProfileKyc } from '../../components/card/index';
import { Rangepicker } from '../../components/input';
// import { EditUser } from '../../components/modal/index';

const Profile = (props) => {
    // const [editableData, setEditableData] = useState({});
    const [error, setError] = useState(null);
    // const [loader, setLoader] = useState(false);
    const [userData, setUserData] = useState({});


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        setUserData(props.data);
    }, [props.data])

    const disabledDate = (current) => {
        // Can not select days before today 
        return current && current > moment().endOf('day');
    }


    return (
        <>
            <div className="col-lg-6 mb-3">
                <ProfileKyc
                    data={userData}
                    id={1}
                    title="Profile overview"
                    Close={() => { }}
                />
            </div>
            <div className="col-lg-6 mb-3">
                <ProfileKyc
                    data={userData}
                    id={2}
                    title="KYC details"
                    Close={() => { props.Close() }}
                />
            </div>
            <div className="row mb-3">
                <ProfileKyc
                    data={userData}
                    id={3}
                    title="Bank details"
                    Close={() => { }}
                />
            </div>
            <div className='col-lg-12'>
                <div className='card e-detail-card mb-0'>
                    <div className='row mb-3'>
                        <div className='col-lg-3'>
                            <Rangepicker
                                start={props.start}
                                end={props.end}
                                disabledDate={disabledDate}
                                placeholder={["From", "To"]}
                                onDatesChange={(start, end) => {
                                    props.onChange(start, end)
                                }} />
                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-lg-3'>
                            <h6>Total Deposited</h6>
                            <p>₹{userData.total_payin}</p>
                        </div>
                        <div className='col-lg-3'>
                            <h6>Total withdraw</h6>
                            <p>₹{userData.total_payout}</p>
                        </div>
                        <div className='col-lg-3'>
                            <h6>Total bonus</h6>
                            <p>₹{userData.total_bonus}</p>
                        </div>
                        <div className='col-lg-3'>
                            <h6>Total referral</h6>
                            <p>{userData.total_referral_count}</p>
                        </div>
                        <div className='col-lg-3'>
                            <h6>Total referral bonus</h6>
                            <p>₹{userData.total_referral_bonus}</p>
                        </div>
                        <div className='col-lg-3'>
                            <h6>Total Referral Commission</h6>
                            <p>{userData.total_referral_commission}</p>
                        </div>
                        <div className='col-lg-3'>
                            <h6>Total Booking Amount</h6>
                            <p>₹{userData.total_booking_amount}</p>
                        </div>
                        <div className='col-lg-3'>
                            <h6>Total Rewards Amount</h6>
                            <p>₹{userData.total_reward}</p>
                        </div>
                        <div className='col-lg-3'>
                            <h6>Platform fee</h6>
                            <p>₹{userData.total_platform_fee}</p>
                        </div>
                        <div className='col-lg-2'>
                            <h6>P&L</h6>
                            <p>₹{userData["total_p&l"]}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <EditUser id="editDetails" data={editableData} /> */}
        </>
    )
}

export default Profile;