/*
 *   File : singleDate.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 16-12-2021
 */

/*import package*/
import { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import 'antd/dist/antd.min.css';
import moment from 'moment';

/*import style*/

// import Icon from '../../assets/images/input/calendar.svg';

const SingleDate = (props) => {
  const [value, setValue] = useState(null);

  const handleChange = (date, dateString) => {
    if (dateString) {
      var tempDate = moment(date).format('YYYY-MM-DD');
      props.onChange(tempDate);
    }
    else {
      props.onChange(null);
    }
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().startOf('day');
  }

  useEffect(() => {
    if (props.value !== null) {
      setValue(moment(props.value, dateFormatList[0]))
    } else {
      setValue(null)
    }
    //eslint-disable-next-line
  }, [props.value])

  const dateFormatList = ['YYYY-MM-DD', 'YY-MM-DD'];

  return (
    <>
      <DatePicker onChange={handleChange}
        value={value}
        format={dateFormatList}
        placeholder={props.placeholder}
        name={props.name}
        id={props.id}
        disabledDate={props.disabledDate ? props.disabledDate : disabledDate}
        onKeyDown={e => e.preventDefault()}
      />
    </>
  );
}

export default SingleDate;