/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for transaction container;
 *   Integrations : 16-03-2022
 *   Version : v1.0
 *   Created : 10-02-2022
 */
/*import pakcages*/
import { useState, useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';
import moment from 'moment';

/*import components*/
import { DataTable, GameTabs } from '../../components/dataFormating/index';
import { FilterBtn } from '../../components/cta/index';
import { SearchInput, Rangepicker, Search } from '../../components/input/index';
import { DownloadBtn, PrimaryLink } from '../../components/cta/index';
import { AddTransaction, Confirmation, TransactionDetail, UploadDocument } from '../../components/modal/index';
import SelectBox from '../../components/dropdown/selectBox';
import Loader from '../../components/loader/loader';
import { EmptyNote, Toast } from '../../components/note';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import Nodata from '../../assets/images/note/no-data.png';
import Next from '../../assets/images/dataFormating/next.svg';
import Prev from '../../assets/images/dataFormating/prev.svg';
/* import services */
import service from '../../services/service';

const Index = (props) => {
	let date = new Date()
	var today = moment(date).format("YYYY-MM-DD")
	const wrapperRef = useRef(null);
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState({ start: today, end: today });
	const [pdates, setPDates] = useState({ start: null, end: null });
	const [more, setMore] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState(0);
	const [pselectedStatus, setSelectedPStatus] = useState(1);
	const [selectedType, setSelectedType] = useState(0);
	const [statusOptions, setStatusOptions] = useState([]);
	const [typeOptions, setTypeOptions] = useState([]);
	const [search, setSearch] = useState("");
	const [check, setCheck] = useState([]);
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(false);
	const [transactionData, setTransactionData] = useState([]);
	const [transactionDetails, seTransactionDetails] = useState({});
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [success, setSuccess] = useState(null);
	const [customerData, setCustomerData] = useState([])
	const [id, setId] = useState({ id: 0 });
	const [tabIndex, setTabindex] = useState(1);
	const [total, setTotal] = useState(0);
	const [totalTransactionAmount, setTotalTransactionAmount] = useState(null);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(200);
	const [ppage, setpPage] = useState(1);
	const [perpPage, setPerpPage] = useState(200);
	const [totalLength, setTotalLength] = useState(null);
	const [transacId, setTransacId] = useState(null);
	const [searchuser, setSearchuser] = useState("");
	const [paginationData, setPaginationData] = useState({});
	const [exceptionPaginationData, setExceptionPaginationData] = useState({})
	const [paginationDataPayout, setPaginationDataPayout] = useState({})
	const [isException, setIsException] = useState(false)
	const [exceptionData, setExceptionData] = useState([]);
	const [btn_loader, set_btn_loader] = useState(false);


	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		_filterData();
		_getCustomersList();
	}, []);
	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null)
			}, 2000);
		}
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null)
			}, 2000);
		}
	}, [success]);

	useEffect(() => {
		// if (search === "") {
		if (tabIndex === 2) {
			_getPayoutList()
		} else if (tabIndex === 1) {
			_getTransactionsList();
		}
		// }
		//eslint-disable-next-line
	}, [dates, selectedStatus, selectedType, page, perPage, ppage, perpPage, pdates, pselectedStatus, id, tabIndex]);

	useEffect(() => {
		if (search === "") {
			if (tabIndex === 3) {
				_getExceptionList();
			}
		}
		//eslint-disable-next-line
	}, [id, tabIndex, search]);

	const _closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false)
		}
	}
	useEffect(() => {
		document.addEventListener('mouseup', _closeMenu);
		return () => {
			document.removeEventListener('mouseup', _closeMenu);
		};
	}, []);

	useEffect(() => {
		if (downloadLoader === true) {
			setDownloadLoader(false)
		}
	}, [downloadLoader])

	const customDateSort = (a, b, selector, given) => {
		let format = given ? given : "DD MMM YYYY"
		if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
		else if (a[selector]) return -1;
		else if (b[selector]) return 1;
		else return 0;
	}

	const openDetail = (row) => {
		window.open(`/user-detail?id=${row.user_id}`, "_blank");
	}

	const columns = [
		{
			name: 'Client ID',
			selector: 'user_id',
			sortable: true,
			left: true,
			minWidth: "120px",
			maxWidth: "120px",
			cell: row => row.client_id
		},
		{
			name: 'Name',
			selector: 'name',
			sortable: true,
			left: true,
			minWidth: "220px",
			cell: row => <div className='e-name-link' onClick={() => openDetail(row)}>{row.name}</div>,
		},
		{
			name: 'Transaction ID',
			selector: 'transaction_id',
			sortable: false,
			left: true,
			minWidth: "140px"
		},
		{
			name: 'Date',
			selector: row =>
				<div className="d-inline-flex">
					{/* <img width="24px"
                        className='e-img-style me-3'
                        src={row.is_credit ? Credit : Debit} /> */}
					<span className='pt-1'> {row.transaction_date?.slice(0, 12)}</span>
					<span className='e-wallet-historty-time ms-3 pt-1'>{row.transaction_date?.slice(12,)}</span>
				</div>,
			sortable: false,
			left: true,
			minWidth: "140px",
			omit: tabIndex === 3 ? true : false,
			sortFunction: (a, b) => customDateSort(a, b, 'transaction_date'),
		},
		{
			name: 'Time',
			selector: 'time',
			sortable: false,
			left: true,
		},
		{
			name: 'Transaction detail',
			selector: 'message',
			sortable: false,
			left: true,
			minWidth: "260px",
			cell: (row) => { return <div>{row.message ? row.message : "--"}</div> }
		},
		{
			name: 'Modified by',
			selector: row => row.modified_by ? row.modified_by : "--",
			sortable: false,
			left: true,
			minWidth: "150px",
			omit: localStorage.getItem("type") === "1" ? false : true
		},
		{
			name: 'Status',
			selector: row => row.status,
			sortable: false,
			left: true,
			minWidth: "150px",
		},
		{
			name: 'Cr ₹',
			selector: row => row.credited_amount,
			sortable: false,
			left: true
		},
		{
			name: 'Dr ₹',
			selector: row => row.debited_amount,
			sortable: false,
			left: true
		},
		{
			name: 'Balance ₹',
			selector: 'running_balance',
			cell: row => <>
				{
					row.running_balance
				}
			</>,
			sortable: false,
			left: true,
			minWidth: '180px'
		},
		{
			name: 'Remarks',
			selector: 'remark',
			cell: row => <>{row.remark}</>,
			sortable: false,
			left: true,
			minWidth: "220px"
		},
		{
			name: 'Reference ID',
			selector: row => row.reference_id,
			sortable: false,
			left: true,
			minWidth: "200px"
		},
		{
			name: '',
			omit: tabIndex === 1 ? true : false,
			cell: (row) => <div className='d-flex'>
				<span className="e-more-users" style={{ position: "inherit" }} onClick={() => { setTransacId(row.transaction_id); setMore(true) }} ref={wrapperRef}></span>
				{
					more && row.transaction_id === transacId ?
						<div className="e-card-user" ref={wrapperRef} style={{ height: "58px" }}>
							<span onClick={() => cancelPay()}>Cancel payout</span>
						</div>
						:
						null
				}
			</div >
		}
	];

	const exceptionColumns = [
		{
			name: 'Client ID',
			selector: 'user_id',
			sortable: true,
			left: true,
			minWidth: "120px",
			maxWidth: "120px",
			cell: row => row.client_id
		},
		{
			name: 'Name',
			selector: 'name',
			sortable: true,
			left: true,
			minWidth: "220px",
			cell: row => <div className='e-name-link' onClick={() => openDetail(row)}> {row.name}</ div>,
		},
		{
			name: 'Mobile',
			selector: 'mobile',
			cell: row => <>
				{
					row.mobile
				}
			</>,
			sortable: false,
			left: true,
			minWidth: '180px'
		}, {
			name: 'Email',
			selector: 'email',
			cell: row => <>
				{
					row.email
				}
			</>,
			sortable: false,
			left: true,
			minWidth: '180px'
		},


		{
			name: 'Balance ₹',
			selector: 'wallet_balance',
			cell: row => <>
				{
					row.wallet_balance
				}
			</>,
			sortable: false,
			left: true,
			minWidth: '180px'
		},
		{
			name: '',
			omit: tabIndex === 1 ? true : false,
			cell: (row) => <div className='d-flex'>
				<span className="e-more-users" style={{ position: "inherit" }} onClick={() => { setTransacId(row.user_id); setMore(true) }} ref={wrapperRef}></span>
				{
					more && row.user_id === transacId ?
						<div className="e-card-user" ref={wrapperRef} style={{ height: "58px" }}>
							<span data-bs-target="#confirmation" data-bs-toggle="modal">Delete</span>
						</div>
						:
						null
				}
			</div >
		}
	];
	const _handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]);
		}
		setCheck(selected);
		setDownloadLoader(false)
		// setCheck(row.selectedRows);
	}

	const _modalOpen = (row) => {
		console.log(row)
		seTransactionDetails(row)
		var myModal = new Modal(document.getElementById("transactionDetail"), {});
		myModal.show();
	}

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > moment().endOf('day');
	}

	/*------ filter data ------*/
	const _filterData = () => {
		let url = '/transaction/filter-data';

		service(false, url).then((response) => {
			if (response.data.status_code === 200) {
				let temp_status_options = [];
				let temp_type_options = [];
				response.data.data.status.map((item) => (
					temp_status_options.push({
						value: item.id,
						label: item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
					})

				));
				setStatusOptions(temp_status_options);

				response.data.data.types.map((item) => (
					temp_type_options.push({
						value: item.id,
						label: item.name
					})
				));
				setTypeOptions(temp_type_options);
			} else {
				setError(response.data.message)
			}
		});
	}

	/*--------- transaction list -----------*/
	const _getTransactionsList = (query) => {
		setLoader(true)
		let url = '/transaction/list';

		let data = JSON.stringify({
			"status_id": Number(selectedStatus),
			"type": selectedType,
			"page_num": page,
			"query": query ? query.value : search,
			"from_date": dates.start,
			"end_date": dates.end,
			"customer_id": id?.id,
			"page_size": perPage
		});

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				if (response.data.status === true) {
					setTransactionData(response.data.data);
					let remainder = response.data.number_of_records % perPage
					let quotient = response.data.number_of_records / perPage
					if (Number(remainder) > 0) {
						setTotalLength(parseInt(Number(quotient) + 1))

					} else {
						setTotalLength(Number(quotient))
					}
					setTotal(response.data.number_of_records);
					setTotalTransactionAmount(response.data.total_data);
					setLoader(false);
					setCheck([]);
					setPaginationData({
						rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
					})
				} else {
					setTransactionData([]);
					setLoader(false);
					setCheck([]);
				}
			}
			else {
				setLoader(false);
				setTransactionData([]);
				setCheck([]);
			}
		})
	}

	/*--------- transaction list -----------*/
	const _getExceptionList = (is_download) => {
		setLoader(true)
		set_btn_loader(true);
		let url = '/reports/wallet-exceptions/list';

		let data = JSON.stringify({
			"customer_id": id?.id === 0 ? null : id?.id,
			"is_download": is_download ? is_download : false
		});

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				if (response.data.status === true) {
					if (is_download) {

						let a = document.createElement("a");
						a.href = response.data.data.url;
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
					} else {
						setExceptionData(response.data.data);
						setExceptionPaginationData({
							rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
						})

					}
					setLoader(false);
					set_btn_loader(false)

				} else {
					setExceptionData([]);
					setLoader(false);
					set_btn_loader(false)
				}
			}
			else {
				setLoader(false);
				setExceptionData([]);
				set_btn_loader(false)
			}
		})
	}
	/*--------- transaction list -----------*/
	const _getPayoutList = (query) => {
		setLoader(true)
		let url = '/transaction/list';

		let data = JSON.stringify({
			"status_id": Number(pselectedStatus),
			"type": 2,
			"page_num": ppage,
			"query": query ? query.value : search,
			"from_date": pdates.start,
			"end_date": pdates.end,
			"customer_id": id?.id,
			"page_size": perpPage
		});

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				if (response.data.status === true) {

					setTransactionData(response.data.data);
					setTotal(response.data.number_of_records);
					setTotalTransactionAmount(response.data.total_data);
					let remainder = response.data.number_of_records % perpPage
					let quotient = response.data.number_of_records / perpPage
					if (Number(remainder) > 0) {
						setTotalLength(parseInt(Number(quotient) + 1))

					} else {
						setTotalLength(Number(quotient))
					}
					setLoader(false);
					setCheck([]);
					setPaginationDataPayout({
						rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
					})
				} else {
					setTransactionData([]);
					setLoader(false);
					setCheck([]);
				}
			}
			else {
				setLoader(false);
				setTransactionData([]);
				setCheck([]);
			}
		})
	}

	const _getCustomersList = () => {
		var url = "/general/customer-list-filter";

		service(false, url).then((response) => {
			if (response.data.status_code === 200) {
				let temp_options = []
				temp_options.push({ label: "All", value: 0 })
				response.data.data.map((item) => {
					return (
						temp_options.push({
							label: item.customer_name,
							value: item.customer_id
						})
					)
				})
				setCustomerData(temp_options)
			}
		})
	}

	const requestDownload = () => {
		var url = "/general/payout-requests-download";

		service(false, url).then((response) => {
			if (response.data.status_code === 200) {
				let a = document.createElement("a");
				a.href = response.data.data.url;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				setCheck([]);
				setDownloadLoader(false);
				setSearch("");
				setSuccess("Download successful");
			}
		})
	}

	/* cancel payout */
	const cancelPay = () => {
		let url = "/general/cancel-payout";

		let data = {
			transaction_id: transacId
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				_getPayoutList();

			}
		});
	}


	function convertArrayOfObjectsToCSV(data) {
		setDownloadLoader(true)
		// Empty array for storing the values
		var csvRows = [];

		// Headers is basically a keys of an
		// object which is id, name, and
		// profession

		const titles = [
			'Sl No',
			'Name',
			'Customer ID',
			'Date Time',
			'Transaction ID',
			'Transaction Detail',
			'Status',
			'CR',
			'DR',
			'Balance',
			'Remarks',
			'Reference ID',
		];

		const headers = titles
		// As for making csv format, headers
		// must be separated by comma and
		// pushing it into array
		csvRows.push(headers.join(','));

		// Pushing Object values into array
		// with comma separation
		for (let i = 0; i < data.length; i++) {
			var values = [
				i + 1,
				data[i]['name'],
				data[i]['client_id'],
				`${data[i]['transaction_date']} ${data[i]['time']}`,
				data[i]['transaction_id'],
				data[i]['message'],
				data[i]['status'],
				data[i]['credited_amount'],
				data[i]['debited_amount'],
				data[i]['running_balance'],
				data[i]['remarks'],
				data[i]['reference_id'],
			].join(",")
			csvRows.push(values)
		}
		// Returning the array joining with new line
		setCheck([])
		return csvRows.join('\n')


	}

	function downloadCSV(data) {
		setDownloadLoader(true)
		// Creating a Blob for having a csv file format
		// and passing the data with type
		setCheck([])
		const blob = new Blob([data], { type: 'text/csv' });

		// Creating an object for downloading url
		const url = window.URL.createObjectURL(blob)

		// Creating an anchor(a) tag of HTML
		const a = document.createElement('a')

		// Passing the blob downloading url
		a.setAttribute('href', url)

		// Setting the anchor tag attribute for downloading
		// and passing the download file name
		a.setAttribute('Transactions', 'Transactions.csv');

		// Performing a download with click
		a.click()
	}


	/* ----dwonload transaction report api *----*/
	const downloadTransactions = () => {
		setDownloadLoader(true);
		set_btn_loader(true);
		let url = "/user/report/wallet";

		let data = {
			"user_id": id?.id,
			"transaction_type": (selectedType),
			"from_date": dates.start,
			"to_date": dates.end,
			"status": tabIndex === 2 ? Number(pselectedStatus) : Number(selectedStatus)
		}

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				let a = document.createElement("a");
				a.href = response.data.data.url;
				document.body.appendChild(a);
				a.click();
				setCheck([])
				document.body.removeChild(a);
				setDownloadLoader(false);
				set_btn_loader(false);
			} else {
				setDownloadLoader(false);
				set_btn_loader(false);
				setError(response.data.message)
			}
		});
	}

	return (
		<>
			<div className='e-users-container e-users-list e-pagination'>
				<div className="row">
					<div className="col-lg-12">
						<div className="card e-dashboard-card border-0">
							<div className="row pt-4">
								<div className="col-lg-4 col-12 pt-2 mt-1 ps-4">
									<GameTabs
										index={tabIndex}
										data={[
											'Transactions',
											'Payouts',
											'Wallet exception'
										]}
										setTab={(temp) => {
											setTabindex(temp);
											setId({ id: 0 });
											setFilterDown(false);
											setPage(1);
											setpPage(1)
											setSearch("");
											setSelectedPStatus(1);
											setTotalLength(null);
											setSelectedStatus(0)
											setSelectedType(0);
											setPerPage(200);
											setPerpPage(200);
											set_btn_loader(false);
										}} />
								</div>
								<div className={tabIndex === 2 ? 'col-lg-11 col-11 pe-0 text-end e-responsive-align' : 'col-lg-7 col-11 pe-0 text-end e-responsive-align'}>

									{
										tabIndex === 2 ?
											<>
												<div className='e-filter pe-3'>
													<PrimaryLink
														name="Download request"
														handleClick={() => {
															requestDownload()
														}}
													/>
												</div>
												<div className='e-filter pe-3'>
													<PrimaryLink
														name="Upload response"
														target="#file-upload"
														toggle="modal"
													/>
												</div>
											</>
											:
											tabIndex === 3 ?
												<div className='e-filter pe-3'>
													<PrimaryLink
														name="+ Add exception"
														toggle="modal"
														target="#addTransaction"
														handleClick={() => {
															setIsException(true)
														}}
													/>
												</div>
												:
												<div className='e-filter pe-3'>
													<PrimaryLink
														name="+ Add Transaction"
														toggle="modal"
														target="#addTransaction"
														handleClick={() => {
															setIsException(false)
														}}
													/>
												</div>
									}
									<div className='e-filter pe-3 '>
										<DownloadBtn
											name={'Download report'}
											className={btn_loader ? "e-disabled" : ""}
											loaderClass={btn_loader ? "e-bounce" : ""}
											check={tabIndex === 3 ? ['a'] : ['check']}
											onClick={() => {
												if (tabIndex === 3) {
													_getExceptionList(true)
												} else {
													if (transactionData.length === check.length || check.length === 0) {
														downloadTransactions()
													} else {
														const csvData = convertArrayOfObjectsToCSV(check)
														downloadCSV(csvData)
													}
												}
											}}
										/>
									</div>

									{
										tabIndex === 3 ?
											null
											:
											<div className="position-relative e-filter pe-3">
												<SearchInput
													placeholder="Name/ client id/ trans. id"
													value={search}
													onSearch={(value) => {
														if (tabIndex === 1) {
															_getTransactionsList({ value: value })
														} else {
															_getPayoutList({ value: value })
														}

													}}
													valueChange={(value) => {
														setSearch(value)
														if (value === "") {
															if (tabIndex === 1) {
																_getTransactionsList({ value: "" })
															} else {
																_getPayoutList({ value: "" })
															}
														}
													}}
												/>
												{
													search && search.length ?
														<>
															<span className={`${styles.e_search_submit} `} onClick={() => _getTransactionsList()} ></span>
															<button className={`${styles.e_search_close} pe-3`} onClick={() => {
																setSearch("");
																if (tabIndex === 1) {
																	_getTransactionsList({ value: "" })
																} else {
																	_getPayoutList({ value: "" })
																}
															}} ></button>
														</>
														:
														null
												}

											</div>
									}

								</div>
								<div className='col-lg-1 col-1 ps-0'>
									<FilterBtn
										className={
											filterDown ?
												"e-filter-btn e-active"
												:
												"e-filter-btn"
										}
										handleClick={() => {
											setFilterDown(!filterDown)
											if (filterDown === true) {
												setDates({
													start: today,
													end: today
												})
												setSelectedStatus(0)
												setPDates({
													start: null,
													end: null
												})
												setSelectedPStatus(1)
												setSelectedType(0)
												setPage(1)
											}
										}}
									/>
								</div>
								{/* <div className="col-lg-2 px-0">
									<DownloadBtn
										check={check}
										onClick={() => {
											const csvData = convertArrayOfObjectsToCSV(check)
											downloadCSV(csvData)
										}}
									/>
								</div> */}

								{/* <div className="col-lg-3 position-relative">
									<SearchInput
										placeholder="Name/ client id/ trans. id"
										value={search}
										onSearch={(value) => {
											_getTransactionsList({ value: value })
										}}
										valueChange={(value) => {
											setSearch(value)
										}}
									/>
									{
										search && search.length ?
											<>
												<span className={`${styles.e_search_submit} `} onClick={() => _getTransactionsList()} ></span>
												<button className={`${styles.e_search_close} pe-3`} onClick={() => {
													setSearch("");
													_getTransactionsList({ value: "" })
												}} ></button>
											</>
											:
											null
									}

								</div> */}
								{/* <div className="col-lg-1">
									<FilterBtn
										className={
											filterDown ?
												"e-filter-btn e-active"
												:
												"e-filter-btn"
										}
										handleClick={() => {
											setFilterDown(!filterDown)
											if (filterDown === true) {
												setDates({
													start: today,
													end: today
												})
												setSelectedStatus(0)
												setSelectedType(0)
												setPage(1)
											}
										}}
									/>
								</div> */}
								{
									filterDown ?
										<div className='row pe-0'>
											<div className='col-lg-12 px-0'>
												<div className="e-filter-wraper mt-3">
													<div className="row">
														{
															tabIndex === 3 ?
																null
																:
																<>
																	<div className="col-lg-3">
																		<label className="mb-2">Transactions from - till</label>
																		<Rangepicker
																			start={tabIndex === 1 ? dates.start : pdates.start}
																			end={tabIndex === 1 ? dates.end : pdates.end}
																			disabledDate={disabledDate}
																			placeholder={["From", "To"]}
																			onDatesChange={(start, end) => {
																				if (tabIndex === 1) {
																					setDates({
																						start: start,
																						end: end
																					});
																				} else {
																					setPDates({
																						start: start,
																						end: end
																					});
																				}
																			}}
																		/>
																	</div>
																	<div className="col-lg-2 ">
																		<label className="mb-2">Status </label>
																		<SelectBox
																			isSearchable={false}
																			options={statusOptions}
																			defaultValue={tabIndex === 1 ? statusOptions[0] : statusOptions[1]}
																			selectchange={(temp) => {
																				if (tabIndex === 1) {
																					setSelectedStatus(temp?.value)
																				} else {
																					setSelectedPStatus(temp?.value)
																				}
																			}}
																		/>
																	</div>
																	{
																		tabIndex === 1 ?
																			<div className="col-lg-2">
																				<label className="mb-2">Type</label>
																				<SelectBox
																					isSearchable={false}
																					defaultValue={typeOptions[0]}
																					options={typeOptions}
																					selectchange={(temp) => {
																						setSelectedType(temp?.value)
																					}}
																				/>
																			</div>
																			:
																			null
																	}
																</>
														}

														<div className="col-lg-2 e-bookings-input">
															<label className="mb-2">Customer</label>
															{/* <SelectBox
																isSearchable={true}
																defaultValue={customerData[0]}
																options={customerData}
																selectchange={(temp) => {
																	setId(temp?.value)
																}}
															/> */}
															<Search
																value={id?.id === 0 ? {} : id}
																search={searchuser}
																setSearch={(temp) => {
																	setSearchuser(temp);
																	if (temp === "") {
																		setId({
																			id: 0
																		});
																	}
																}}
																userDetail={(temp) => setId(temp)} />
														</div>
													</div>
												</div>
											</div>
										</div>
										:
										null
								}
								<div className="col-lg-12 e-booking-table pt-2">
									{
										loader ?
											<Loader />
											:
											tabIndex === 3 ?
												exceptionData.length > 0 ?
													<DataTable
														data={exceptionData}
														pagination={true}
														columns={exceptionColumns}
														paginationComponentOptions={exceptionPaginationData}
														selectableRows={false} />
													:
													<EmptyNote img={Nodata} data="No data found" />
												:
												transactionData.length > 0 ?
													<>
														<DataTable
															pagination={true}
															columns={columns}
															data={transactionData}
															selectableRows={true}
															size={tabIndex === 1 ? perPage : perpPage}
															onChangeRowsPerPage={(p) => {
																if (tabIndex === 1) {
																	setPage(1)
																	setPerPage(p)
																} else {
																	setpPage(1)
																	setPerpPage(p)
																}
															}}
															selectRows={(row) => {
																_handleChange(row);
															}}
															paginationComponentOptions={tabIndex === 1 ? paginationData : paginationDataPayout}
															paginationIconNext={
																tabIndex === 1 ?
																	totalLength === page ?
																		<img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} />
																		:
																		<img src={Next} alt="" width="26px" onClick={() => {

																			setPage(page + 1)

																		}} style={{ cursor: "pointer" }} />
																	:
																	totalLength === ppage ?
																		<img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} />
																		:
																		<img src={Next} alt="" width="26px" onClick={() => {
																			setpPage(ppage + 1)
																		}} style={{ cursor: "pointer" }} />}
															paginationIconPrevious={
																tabIndex === 1 ?
																	page === 1 ?
																		<img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} />
																		:
																		<img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPage(page - 1) }} />
																	:
																	ppage === 1 ?
																		<img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} />
																		:
																		<img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setpPage(ppage - 1) }} />
															}
															paginationTotalRows={total}
															clearSelectedRows={downloadLoader}
															rowClick={(row) => _modalOpen(row)}
														/>
														<div className='col-lg-12 text-end pe-3'>
															{
																tabIndex === 1 ?
																	<p>Page no : <span style={{ fontWeight: "600" }}>{page}</span></p>
																	:
																	<p>Page no : <span style={{ fontWeight: "600" }}>{ppage}</span></p>
															}
														</div>
														{
															tabIndex === 1 ?
																<div className="row px-3 pb-3">
																	<div className='col-lg-6'>
																		<h6 className='d-inline'>Total Cr:</h6>
																		<p className='d-inline ms-2'>₹{totalTransactionAmount?.total_cr_amount}</p>
																	</div>
																	<div className='col-lg-6'>
																		<h6 className='d-inline'>Total Dr:</h6>
																		<p className='d-inline ms-2'>₹{totalTransactionAmount?.total_dr_amount}</p>
																	</div>
																</div>
																:
																<div className="row px-3 pb-3">
																	<div className='col-lg-4'>
																		<h6 className='d-inline'>Total Cr:</h6>
																		<p className='d-inline ms-2'>₹{totalTransactionAmount?.total_cr_amount}</p>
																	</div>
																	<div className='col-lg-4'>
																		<h6 className='d-inline'>Total Dr:</h6>
																		<p className='d-inline ms-2'>₹{totalTransactionAmount?.total_dr_amount}</p>
																	</div>
																	<div className='col-lg-4'>
																		<h6 className='d-inline'>Total pending amount:</h6>
																		<p className='d-inline ms-2'>₹{totalTransactionAmount?.total_pending_amount}</p>
																	</div>
																</div>
														}
													</>
													:
													<EmptyNote img={Nodata} data="No data found" />
									}
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<TransactionDetail data={transactionDetails} type={false} />
			<AddTransaction
				customer={customerData}
				isException={isException}
				type={typeOptions}
				Success={() => {
					if (isException) {
						_getExceptionList()
					} else {
						_getTransactionsList()
					}
				}} />

			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
			{
				success !== null ?
					<Toast
						data={success}
						type="success"
						format={1}
					/>
					:
					null
			}
			<UploadDocument />
			<Confirmation
				exceptionId={transacId}
				title="Are you sure you want to delete exception?"
				update={() => {
					_getExceptionList()
				}} />
		</>
	);
}

export default Index;
