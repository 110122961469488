/*
 *   File : fileUpload.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Textarea
 *   Integrations :
 *   Version : 1.0
 *   Created : 14-01-2022
 */

/*import packages*/
import React from 'react';
import $ from 'jquery'

/*import Services */
// import UploadImageFile from './s3Uploader.js';



const FileUpload = (props) => {

  // const uploadDocument=(e)=>{
  //   props.upload(e.target.files[0])
  //   props.uploadDocument(e.target.files[0].name)
  // }

  // const uploadDocument = async (e) => {

  //   if (e.target.files.length) {
  //     var file = e.target.files[0];
  //     var tempFileName = file.name;
  //     var fileReader = new FileReader();
  //     var tempKey = props.tempKey
  //     tempKey = tempKey + "/" + new Date().getTime()
  //     if (e.target.files != null) {
  //       if (props.imageSelected) {
  //         props.imageSelected();
  //       }
  //       var fileType = tempFileName.split("/").pop();
  //       var response = await UploadImageFile(file);
  //       // console.log(response);
  //       var data = JSON.stringify({
  //         'file': file,
  //         'fileName': tempFileName,
  //         'key': tempKey
  //       });
  //       if (response) {
  //         console.log("res::", response)
  //         await setFile(response.cdn_url);
  //         // props.uploadSucess(response.cdn_url, JSON.parse(data));
  //       }
  //       else {
  //         await setFile({ apiLoading: false });
  //       }
  //       // props.uploadStatus(false);
  //     }
  //   }
  // }
  $('input[type="file"]').click(function (e) {
    e.target.value = null
  });
  return (
    <input
      id={props.id}
      className={props.className}
      type="file"
      key={props.key}
      accept={props.accept}
      onChange={(e) => props.upload(e.target.files[0])}
      {...props}
    />
  );
}

export default FileUpload;
