/*
 *   File : createPassword.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-02-2022
 */

/*import packages*/
import { useState, useEffect } from 'react';


/*import assets*/

/*import components*/
import { OuterHeader, Footer } from '../../components/navigation/index';
import { Input } from '../../components/input/index';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';
import GetURLVar from '../../services/getUrl';
import service from '../../services/service';
import { Toast } from '../../components/note';

/*import container*/


const CreatePassword = (props) => {
    const [password, setPassword] = useState("");
    const [cpassword, setCpassword] = useState("");
    //eslint-disable-next-line
    const [url, setUrl] = useState("");
    const [mail, setMail] = useState("");
    const [otp, setOtp] = useState("");
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        getUrl()
    }, [])

    const getUrl = async () => {
        let url = await GetURLVar();
        setUrl(url.change)
    }
    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000)
        }
    }, [error])

    /*---- validation for password eg: Admin@12 ----*/
    const _validate = () => {
        var pattern = new RegExp(/^(?=.*\d)(?=.*?[a-zA-Z])(?=.*?[\W_]).{8,100}$/);
        if (!pattern.test(password)) {
            setError("Password should contain one alphabet, one special character and one digit with minimum 8 characters");
        } else if (password !== cpassword) {
            setError("Password and confirm password should be same")
        } else {
            _setPassword();
        }
    }

    //set Password 
    const _setPassword = () => {
        setLoader(true);
        let url = "/auth/set-password"

        let data = {
            email: mail,
            otp: otp,
            password: password
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false)
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            } else {
                setLoader(false);
                setError(response.data.message)
            }
        })
    }
    return (
        <>
            <div className="container-fluild e-auth-wrapper">
                <OuterHeader type={2} />
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-6 mt-2  pt-5 offset-lg-3 px-0">
                            <div className="e-form-wrap">
                                <h4>Set password</h4>
                                <div className="row">
                                    <div className='col-lg-6'>
                                        <label className="mb-1">Email</label>
                                        <Input placeholder="Email" type="email" value={mail} valueChange={(temp) => setMail(temp)} />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className="mb-1">OTP</label>
                                        <Input placeholder="Name" value={otp} valueChange={(temp) => setOtp(temp)} />
                                    </div>
                                    <div className="col-lg-6 pt-4">
                                        <label className='pb-1'>Password</label>
                                        <Input
                                            placeholder="password"
                                            value={password}
                                            valueChange={(value) => {
                                                setPassword(value)
                                            }}
                                            type="password"
                                        />
                                    </div>
                                    <div className=" col-lg-6 pt-4">
                                        <label>Confirm password</label>
                                        <Input
                                            placeholder="password"
                                            value={cpassword}
                                            valueChange={(value) => {
                                                setCpassword(value)
                                            }}
                                            type="password"
                                        />
                                    </div>
                                </div>

                                <div className="row pt-4 pb-2">
                                    <div className="col-lg-12">
                                        <div className="d-flex justify-content-end">
                                            <SecondaryBtn
                                                name="Cancel"
                                                className="e-cancel"
                                                cancel="modal" />
                                            {
                                                password && otp && mail ?
                                                    <PrimaryBtn
                                                        name="Set password"
                                                        handleClick={() => _validate()}
                                                        className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"} />
                                                    :
                                                    <PrimaryBtn
                                                        name="Set password"
                                                        className={"e-modal-submit e-disabled"} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        error !== null ?
                            <Toast data={error}
                                type="fail"
                                format={1}
                            />
                            :
                            null
                    }
                </div>
                <div className="e-auth-footer pt-5">
                    <Footer />
                </div>
            </div>

        </>
    )
}

export default CreatePassword;

// handleClick={()=>{setLogin(true)}}