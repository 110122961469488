/*
 *   File : textbtn.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Link Text button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-11-2021
 */

/*Style Import*/
import styles from '../../styles/components/textBtn.module.scss';

const TextBtn = (props) => {
  return (
    <button
      id={props.id}
      className={`${styles.e_text_btn} ${props.className}`}
      data-bs-dismiss={props.cancel}
      data-bs-toggle={props.toggle}
      data-bs-target={props.target}
      onClick={props.handleClick}
    >
      {
        props.left ?
          <img src={props.image} alt="" />
          :
          null
      }
      {props.name}
      {
        props.right ?
          <img src={props.image} alt="" />
          :
          null
      }

    </button>
  );
}


export default TextBtn;
