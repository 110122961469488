/*
 *   File : confirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Confirmation Modal
 *   Integrations : 03-03-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import components*/
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/confirmation.module.scss';

/* import services */
import service from '../../services/service';

export default function Update(props) {

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [id, setId] = useState(null);


    useEffect(() => {
        setId(props.id)
    }, [props.id]);

    useEffect(() => {
        setTimeout(() => {
            if (error !== null) {
                setError(null);
                $("#update").trigger('click');
            }
        }, 3000);
    }, [error]);



    useEffect(() => {
        var myModalEl = document.getElementById('update')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            props.close()
        })

        //eslint-disable-next-line
    }, [])

    const statusUpdate = () => {
        setLoader(true)
        let url = '/general/customer-user-status-manager';
        let data = JSON.stringify({
            user_id: id
        })
        service(true, url, data).then((response) => {

            if (response.data.status_code === 200) {
                setLoader(false);
                $("#update").trigger("click");
                props.update(response.data.data.user_status)
                // props.statusUpdate()
            } else {
                setError(response.message);
            }
        })
    }


    return (
        <div className={`${styles.e_confirmation} modal fade`} id="update" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>{props.title}</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.close()}></button>
                    </div>
                    <div className="container px-3">
                        {/* <div className="row">
							<div className="col-lg-12 text-start">
								<p>{props.content}</p>
							</div>
						</div> */}
                        <div className="row pt-4 pb-2">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-end">
                                    <SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" handleClick={() => props.close()} />
                                    <PrimaryBtn
                                        name={props.btn_name ? props.btn_name : "Save settings"}
                                        className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                        handleClick={() => {
                                            statusUpdate()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast
                        data={error}
                        format={1}
                        type="fail"
                    />
                    :
                    null
            }
        </div>
    )
}
