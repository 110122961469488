// import packeges
import { useState, useEffect } from 'react';

// import style 
import style from '../../styles/components/setPageAccess.module.scss';

// import components
import { PrimaryBtn, SecondaryBtn } from '../cta';

// import assets
import HideIcon from '../../assets/images/admins/hide.png';

// import  service
import devconfig from '../../config/config';


const SetPageAAccess = (props) => {

  const [name, setName] = useState();

  useEffect(() => {
    setName(props.details.name);
  }, [props.details])

  return (
    <div className={` modal fade`} id="set-page" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className={`modal-dialog modal-dialog-centered`}>
        <div className="modal-content">
          <div className="modal-header">
            <h6>Accessible page for {name}</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="container px-3">
            <div className="row">
              <div className="col-lg-12">
                <div className={`${style.e_card_wrapper} px-3`}>
                  {
                    devconfig.sidebar.map((item, key) => {
                      return (
                        <span className={`${style.e_page_name} me-3 mt-3`}>{item.name} <img src={HideIcon} alt="" /></span>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="row pt-4 pb-2">
              <div className="col-lg-12">
                <div className="d-flex justify-content-end">
                  <SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" />
                  <PrimaryBtn name="Save access" className="e-modal-submit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetPageAAccess;