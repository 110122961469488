/*
 *   File : footer.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Footer
 *   Integrations :
 *   Version : v1.0
 *   Created : 07-02-2022
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import $ from 'jquery'

/*import style*/
import styles from '../../styles/components/sidebar.module.scss';

/*import config*/
import devconfig from '../../config/config';

/*import assets*/
import Logo from '../../assets/images/navigation/sidebar_logo.svg';
import Favlogo from '../../assets/images/sidebar/mobile-logo.svg'

export default function Sidebar(props) {
    const [location, setLocation] = useState("/");
    const current = useLocation();

    useEffect(() => {
        setLocation(current)
    }, [current])

    useEffect(() => {
        if (current.pathname === "/M2E-game" || current.pathname === "/M2M-game" || current.pathname === "/skill-race-game") {
            setLocation({ pathname: "/manage-games" })
        } else if (current.pathname === "/user-detail") {
            if (localStorage.getItem("type") === "3" || localStorage.getItem("type") === "4") {
                setLocation({ pathname: "/" })
            } else {
                setLocation({ pathname: "/users" })
            }
        } else if (current.pathname === "/add-milestone") {
            setLocation({ pathname: "/milestones" })
        }
    }, [current])

    if ($(".fade").hasClass('modal-backdrop')) {
        $("body").removeAttr("style");
        $(".fade").removeClass("modal-backdrop");
        $("body").removeAttr("class")
    }

    return (
        <>
            <div className={props.is_menu_collapse || props.width <= 1024 ? `${styles.e_sidebar_collapsed} ${styles.e_sidebar}` : `${styles.e_sidebar}`}>
                {
                    !props.is_menu_collapse && props.width > 1024 ?
                        <div className={`${styles.e_sidebar_list_wrap} position-relative`}>
                            <div className={`${styles.e_logo_wrapper} mb-4`}>
                                <Link to="/"><img src={Logo} className={`${styles.e_logo}`} alt='' /></Link>
                            </div>
                            <div className={`${styles.e_sidebar_list}`}>
                                {
                                    devconfig.sidebar.map((item, key) => {
                                        return (
                                            <Link to={item.link}>
                                                <div className={item.link === location.pathname ? `${styles.e_sidebar_item_active}` : `${styles.e_sidebar_item}`}>
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#">
                                                        <img src={location.pathname === item.link ? item.activeIcon : item.icon} alt="" />
                                                        <span>{item.name}</span>
                                                    </a>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        :
                        <div>
                            <div className={`text-center mb-4 mt-4 mb-4`}>
                                <Link to="/"><img src={Favlogo} alt="" width="24px" /></Link>
                            </div>
                            <div className={`${styles.e_sidebar_list}`}>
                                {
                                    devconfig.sidebar.map((item, key) => {
                                        return (
                                            <Link to={item.link}>
                                                <div className={item.link === location.pathname ? `${styles.e_sidebar_item_collapse_active}` : `${styles.e_sidebar_collapse_item}`}>
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#">
                                                        <img src={location.pathname === item.link ? item.collapseActiveIcon : item.icon} alt="" style={location.pathname === item.link ? { paddingLeft: item.paddingLeft } : { paddingLeft: item.paddingLeftInactive }} />
                                                    </a>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
            </div>
        </>
    )
}

