/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Funds;
 *   Integrations :
 *   Version : v1.0
 *   Created : 11-11-2021
 */

/*import packages*/
import { useState } from 'react';
import { Modal } from 'bootstrap'


/*import components*/
import { Funds } from '../../components/card/index';
import { EditFund } from '../../components/modal/index';

/*import assets*/
import DSP from '../../assets/images/card/dsp.png';
import Icici from '../../assets/images/card/icici.png';
import { PieChart } from '../../components/chart/index';
import SelectBox from '../../components/dropdown/selectBox';

const Index = (props) => {
	//eslint-disable-next-line
	const [selected, setSelected] = useState();
	const [editableData, setEditableData] = useState();

	const options = [
		{
			value: 1, label: 'This week',
		},
		{
			value: 2, label: 'This month'
		},
		{
			value: 3, label: 'This year'
		},
	]

	const dummyData = [
		{
			title: 'Defensive',
			risk: 'low',
			icon: Icici,
			organization: 'ICICI Prudential Liquid Fund Direct Plan-Growth',
			returns: [
				{
					year: '1Y',
					percentage: '7.11%',
				},
				{
					year: '2Y',
					percentage: '9.85%',
				},
				{
					year: '3Y',
					percentage: '10.41%'
				}
			]
		},
		{
			title: 'Moderate',
			risk: 'medium',
			icon: DSP,
			organization: 'DSP Small Cap Fund',
			returns: [
				{
					year: '1Y',
					percentage: '8.25%',
				},
				{
					year: '2Y',
					percentage: '12.45%',
				},
				{
					year: '3Y',
					percentage: '16.41%'
				}
			]
		},
		{
			title: 'Aggressive',
			risk: 'high',
			icon: null,
			organization: null,
			// returns: []
		}
	]

	const setData = (temp) => {
		setEditableData(temp);
		var myModal = new Modal(document.getElementById("editFund"), {});
		myModal.show();
	}
	window.scrollTo({ top: 0, behavior: 'smooth' });

	return (
		<div className="row">
			{
				dummyData.map((item, key) => {
					return (
						<div className="col-lg-4" key={key}>
							<Funds data={item} id={key} Cliked={(temp) => setData(temp)} />
						</div>
					)
				})
			}
			<div className="col-lg-6 mt-4">
				<div className="card e-dashboard-card e-round-chart-container border-0 p-3">
					<div className="d-flex justify-content-between">
						<h6 className="e-card-title">Total investment</h6>
						<SelectBox options={options} defaultValue={options[0]} className="e-dashboard-card-sort" selectchange={(temp) => setSelected(temp)} />
					</div>
					<div className="col-lg-12 position-relative">
						<PieChart data={[300, 500, 100]} colors={['#FFB61D', '#EF4A3C', '#28C54F']} labels={['Gold 100k', 'Mutual funds 500k', 'Low']} />
						<div className="position-absolute e-piechart-label">
							<h2>100<span>k</span></h2>
							<p><div className="rounded-circle me-2" style={{ backgroundColor: '#FFB61D', width: '9px', height: '10px', display: 'inline-block' }}></div>Defensive</p>
							<h2>150<span>k</span></h2>
							<p><div className="rounded-circle me-2" style={{ backgroundColor: '#28C54F', width: '9px', height: '10px', display: 'inline-block' }}></div>Modarete</p>
							<h2>10<span>k</span></h2>
							<p><div className="rounded-circle me-2" style={{ backgroundColor: '#EF4A3C', width: '9px', height: '10px', display: 'inline-block' }}></div>High</p>
						</div>
					</div>
				</div>
			</div>
			<EditFund id="editFund" data={editableData} />
		</div>
	)
}

export default Index;