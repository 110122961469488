/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React from 'react';

/*import componets*/
import { Badge } from '../../components/badge/index';

/*import styles*/
import styles from '../../styles/components/transactionDetail.module.scss';


export default function TransactionDetail(props) {

  return (
    <div className={`${styles.e_transaction_detail} modal fade`} id="transactionDetail" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className={`${styles.e_modal_content} modal-content`}>
          <div className="modal-header px-4 pt-4">
            <h6>Transaction detail</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          {/* <div className={`${styles.e_transaction_content}`}>
            <div className="container-fluid px-0">
              <div className="row p-4">
                <div className="col-lg-10 col-10 d-inline-flex">
                  <span className={`${styles.e_icon}`}>R</span>
                  <span className={`${styles.e_span} ms-3 pt-1`}>
                    <h6>Ralph Edwards</h6>
                    <p className={`${styles.e_time_date} pt-1 mb-0`}>12 Jun 2020 5.00 PM</p>
                  </span>
                </div>
                <div className="col-lg-2">
                  <Badge type="success" text="success" className={`${styles.e_badge}`}/>
                </div>
              </div>
            </div>
          </div> */}
          <div className="container px-4">
            <div className="row">
              <div className={`col-lg-3 ${styles.e_details} mb-2 pe-0`}>
                <h6>Date</h6>
                <p className='pt-1 mb-0'>{props.data?.transaction_date}</p>
                <p className='pt-0 mt-0'>{props.data.time}</p>
              </div>
              <div className={`col-lg-3 ${styles.e_details} mb-2`}>
                <h6>Status</h6>
                <Badge type={props.data?.status} text={props.data?.status} className={`${styles.e_badge}`} status={props.type} />
              </div>
              <div className={`col-lg-3 ${styles.e_details} mb-2`}>
                <h6>Transaction ID</h6>
                <p className='pt-1'>{props.data?.transaction_id}</p>
              </div>
              <div className={`col-lg-3 ${styles.e_details} mb-2`}>
                <h6>Amount</h6>
                <p className='pt-1'>₹ {props.data.amount}</p>
              </div>
              <div className={`col-lg-3 ${styles.e_details} mb-2`}>
                <h6>Type</h6>
                <p className='pt-1'>{props.data?.type}</p>
              </div>
              <div className={`col-lg-3 ${styles.e_details} mb-2`}>
                <h6>Reference ID</h6>
                <p className='pt-1'>{props.data?.reference_id}</p>
              </div>
              <div className={`col-lg-6 ${styles.e_details} mb-2`}>
                <h6>Remarks</h6>
                <p className='pt-1'>{props.data?.remark}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
