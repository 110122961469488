/*
 *   File : detail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Details
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 25-01-2022
 */

/* import packages */
import React, { useState, } from 'react';


/* import components */
// import { DateRangePicker } from '../../components/input/index';
import { PrimaryLink } from '../../components/cta/index';
import { Confirmation, AddFaq } from '../../components/modal/index';

/*Style Import*/
import styles from '../../styles/components/faqAccordion.module.scss';

/* import assets */
import Edit from '../../assets/images/manage-content/edit.svg';
import Delete from '../../assets/images/manage-content/delete.svg';

const FaqAccordion = (props) => {
    const [id, setId] = useState(null);
    const [edit, setEdit] = useState({});
    const [faqId, setFaqId] = useState("")

    const editItem = (type, value) => {
        let data = edit;
        data[type] = value;
        setEdit({ ...data });
        console.log(type, value, data);
    }

    return (
        <>
            <div className={`${styles.e_faq_accordion}`}>
                <div className="accordion" id="accordionDescription">
                    <div className="row">
                        <div className="col-lg-6">
                            {
                                //eslint-disable-next-line
                                props.data.map((item, key) => {
                                    if (key % 2 === 0) {
                                        return (
                                            <div >

                                                <div className={`${styles.e_accordion_item} accordion-item`}>
                                                    <h2 className={`${styles.e_faq_accordion_header}`} id={"collapseDescription" + key}>
                                                        <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`}>
                                                            <div className="row" onClick={() => { setId(item.faq_id); }}>
                                                                {
                                                                    item.faq_id !== id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.question[props.selected]}</span>

                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            <div className="row" onClick={() => { setId(null); }}>
                                                                {
                                                                    item.faq_id === id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.question[props.selected]}</span>

                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon_minus}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    {
                                                        item.faq_id === id ?

                                                            <div className={`${styles.e_accordion_body} accordion-body mt-3`}>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className={`${styles.e_accordion_wrapper}`}>
                                                                            <span className={`${styles.e_accordion_content}`}>{item.answer[props.selected]}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12 text-start">
                                                                        <PrimaryLink left={true} image={Edit} name="Edit" className={`${styles.e_edit}`} toggle="modal" target="#editFaq" handleClick={() => { setEdit({ ...item }) }} />
                                                                        <PrimaryLink left={true} image={Delete} name="Delete" className={`${styles.e_delete} ms-3`} toggle="modal" target="#confirmation" handleClick={() => { setFaqId(item.faq_id) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className="col-lg-6">
                            {
                                //eslint-disable-next-line
                                props.data.map((item, key) => {
                                    if (key % 2 !== 0) {
                                        return (
                                            <div >

                                                <div className={`${styles.e_accordion_item} accordion-item`}>
                                                    <h2 className={`${styles.e_faq_accordion_header}`} id={"collapseDescription" + key}>
                                                        <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`}>
                                                            <div className="row" onClick={() => { setId(item.faq_id); }}>
                                                                {
                                                                    item.faq_id !== id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.question[props.selected]}</span>

                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            <div className="row" onClick={() => { setId(null); }}>
                                                                {
                                                                    item.faq_id === id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.question[props.selected]}</span>

                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon_minus}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    {
                                                        item.faq_id === id ?

                                                            <div className={`${styles.e_accordion_body} accordion-body mt-3`}>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className={`${styles.e_accordion_wrapper}`}>
                                                                            <span className={`${styles.e_accordion_content}`}>{item.answer[props.selected]}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12 text-start">
                                                                        <PrimaryLink left={true} image={Edit} name="Edit" className={`${styles.e_edit}`} toggle="modal" target="#editFaq" handleClick={() => { setEdit({ ...item }) }} />
                                                                        <PrimaryLink left={true} image={Delete} name="Delete" className={`${styles.e_delete} ms-3`} toggle="modal" target="#confirmation" handleClick={() => { setFaqId(item.faq_id) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
                <AddFaq
                    edit={true}
                    data={edit}
                    setData={editItem}
                    update={() => props.update()}
                />
                <Confirmation
                    faqId={faqId}
                    title="Delete Confirmation"
                    content="Are you sure want to delete this faq?"
                    btn_name="Yes, delete"
                    update={() => props.update()}
                    close={() => { }}
                />
            </div>
        </>
    );
}


export default FaqAccordion;