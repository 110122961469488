
import { useEffect, useState } from "react";

import { Input } from "../../components/input";
import { PrimaryBtn } from "../../components/cta";
import Loader from '../../components/loader/loader';
import { Toast } from "../../components/note";

import service from "../../services/service";


const ReferralSettings = () => {
    const [loader, setLoader] = useState(true);
    const [bookingBonus, setBookingBonus] = useState();
    const [rewardPercent, setRewardPercent] = useState();
    const [userBookingBonus, setUserBookingBonus] = useState();
    const [userRewardPercent, setUserRewardPercent] = useState();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        getReferralSettings();
    }, [])

    const getReferralSettings = () => {
        let url = '/settings/get-referral-config';
        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                setBookingBonus(response.data.data.first_booking_bonus_influencer);
                setRewardPercent(response.data.data.rewards_percentage_influencer);
                setUserBookingBonus(response.data.data.first_booking_bonus);
                setUserRewardPercent(response.data.data.rewards_percentage);

            }
            else {
                setLoader(false);
            }
        })
    }

    const updateSettings = () => {
        let url = '/settings/update-referral-config';
        let data = JSON.stringify({
            "first_booking_bonus": userBookingBonus,
            "first_booking_bonus_influencer": bookingBonus,
            "rewards_percentage": userRewardPercent,
            "rewards_percentage_influencer": rewardPercent
        })
        service(true, url, data).then((response) => {
            if (!response.data.status_code === 200) {
                setError(response.data.message);
            } else {
                setSuccess("Referral settings updated")
            }
        })
        setTimeout(() => { setError(null); setSuccess(null) }, 3000)
    }

    return (
        <>
            {
                loader ?
                    <Loader />
                    :

                    <div className='e-tax-container p-3'>
                        <div className="row ">
                            <div className='col-lg-12'>
                                <h6>Influencer reward</h6>
                            </div>
                        </div>
                        <div className='row pt-1'>
                            <div className='col-lg-6'>
                                <label className="mb-1">Referral bonus</label>
                                <Input
                                    placeholder="Bonus amount"
                                    suffix="₹"
                                    value={bookingBonus}
                                    type="number"
                                    valueChange={(temp) => {
                                        setBookingBonus(temp)
                                    }}
                                />
                            </div>
                            <div className='col-lg-6'>
                                <label className="mb-1">Brokerage commission</label>
                                <Input
                                    placeholder="percentage amount"
                                    suffix="%"
                                    value={rewardPercent}
                                    type="number"
                                    valueChange={(temp) => {
                                        setRewardPercent(temp)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className='col-lg-12'>
                                <h6>User reward</h6>
                            </div>
                        </div>
                        <div className='row pt-1'>
                            <div className='col-lg-6'>
                                <label className="mb-1">Referral bonus</label>
                                <Input
                                    placeholder="Bonus amount"
                                    suffix="₹"
                                    value={userBookingBonus}
                                    type="number"
                                    valueChange={(temp) => {
                                        setUserBookingBonus(temp)
                                    }}
                                />
                            </div>
                            <div className='col-lg-6'>
                                <label className="mb-1">Brokerage commission</label>
                                <Input
                                    placeholder="percentage amount"
                                    suffix="%"
                                    value={userRewardPercent}
                                    type="number"
                                    valueChange={(temp) => {
                                        setUserRewardPercent(temp)
                                    }}
                                />
                            </div>
                            <div className="col-lg-2">
                                {
                                    bookingBonus &&
                                        rewardPercent &&
                                        userBookingBonus &&
                                        userRewardPercent ?
                                        <PrimaryBtn
                                            name="Update & save"
                                            className=""
                                            handleClick={() => updateSettings()}
                                        />
                                        :
                                        <PrimaryBtn
                                            name="Update & save"
                                            className="e-disabled"
                                        />

                                }

                            </div>
                        </div>
                        {
                            error ?
                                <Toast data={error} type="fail" />
                                :
                                null
                        }
                        {
                            success ?
                                <Toast data={success} type="success" format={1} />
                                :
                                null
                        }
                    </div>
            }
        </>
    )
}

export default ReferralSettings