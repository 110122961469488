
/*import package*/
import React, { Component } from 'react';
import Chart from "react-apexcharts";

/*import style*/
// import styles from '../../styles/components/area.module.scss';

class LineChart extends Component {
  constructor(props) {
    super(props);
    console.log("this.props.data: ", this.props.data)

    this.state = {

      series: this.props.series_data,

    };
  }

  componentDidMount() {

  }
  render() {
    const options = {
      chart: {
        type: 'area',
      },
      colors: this.props.colors,
      stroke: {
        width: [1],
      },
      xaxis: {
        categories: this.props.categories,
        labels: {
          style: {
            colors: ['#6D6D6D'],
            fontSize: '10px',
            fontFamily: 'Inter',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label',
          },
        },
      },
      yaxis: {
        // categories:  ['Jun ‘20','Jul','Aug','Sep','Nov','Dec','Jan ‘21','Feb','Mar','Apr','May','Jun','Jul'],
        labels: {
          formatter: (idx) => { return Math.abs(idx) > 0 ? this.props.currency + Math.sign(idx) * ((Math.abs(idx) / 1000).toFixed(1)) + 'k' : Math.sign(idx) * Math.abs(idx) },
          style: {
            colors: ['#6D6D6D'],
            fontSize: '10px',
            fontFamily: 'Inter',
            fontWeight: 400,
            cssClass: 'apexcharts-yaxis-label',
          },
        },
      },
      grid: {
        show: true,
        borderColor: '#6d6d6d59',
        strokeDashArray: 2,
        position: 'back',
        // column: {
        //   colors: '#6D6D6D',
        //   opacity: 0.7,
        // },  

      },

      fill: {

        type: 'gradient',
        gradient: {
          // type: "diagonal1",
          // shadeIntensity: 0,
          // opacityFrom: 0.1,
          // opacityTo: 0.5,
          // stops: [0, 90, 100]
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.1,
          opacityTo: 0.3,
          stops: [0, 0, 60, 200]
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false
      },
      markers: {
        size: 0
      },
      dataLabels: {
        enabled: false,
      },

      tooltip: {
        enabled: true,
        intersect: false,
        inverseOrder: false,
        followCursor: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          }
        }
      }
    }
    return (
      <Chart options={options} series={this.state.series} type="line" height={350} width={342} />
    );
  }
}


export default LineChart;
