/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 12-04-2022
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakcages*/
import React, { useState, useEffect } from 'react';
import moment from 'moment';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { PrimaryBtn, PrimaryLink } from '../../components/cta/index';
import { Rangepicker } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import Loader from '../../components/loader/loader'

/*import styles*/

/* import services */
import GetURLVar from '../../services/getUrl';
import service from '../../services/service';

/* import assets */
import Nodata from '../../assets/images/note/no-data.png'
import { EmptyNote } from '../../components/note';
import Next from '../../assets/images/dataFormating/next.svg';
import Prev from '../../assets/images/dataFormating/prev.svg';

const Bookings = (props) => {

    // const [search, setSearch] = useState("");
    // const [filterDown, setFilterDown] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [userId, setUserId] = useState(null)
    const [gameType, setGameType] = useState({ label: "M2E", value: 1 });
    // const [gameOptions, setGameOptions] = useState([]);
    // const [timePeriodOptions, setTimePeriodOptions] = useState([]);
    const [bookingData, setBookingData] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    // const [downloadLoader, setDownloadLoader] = useState(false);
    // const [success, setSuccess] = useState(null);
    const [bookingType, setBookingType] = useState({ value: null, label: 'All' });
    const [winningType, setWinningType] = useState({ value: null, label: 'All' });
    const [tradeType, setTradeType] = useState({ value: null, label: 'All' });
    const [scripId, setScripId] = useState({ value: null, label: 'All' });
    const [scripDataFilter, setScripDataFilter] = useState();
    const [totals, setTotals] = useState();
    const [slotOptions, setSlotOptions] = useState([])
    const [slotId, setSlotId] = useState({});
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(200);
    const [totalLength, setTotalLength] = useState(null);
    const [paginationData, setPaginationData] = useState({});
    const [total, setTotal] = useState(0);
    const [downloadLoader, setDownloadLoader] = useState(false)

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });


        getScripFilter();
        _getSlots();
        getUrl();
        // getFilters();
    }, []);


    const getUrl = async () => {
        let url = await GetURLVar();
        setUserId(url.id)
    }

    useEffect(() => {
        if (userId) {
            bookingList()
        }
        //eslint-disable-next-line
    }, [userId, gameType, tradeType, bookingType, endDate, scripId, winningType, slotId, page, perPage])

    // useEffect(() => {
    //     searchScrip();
    // }, [gameType])

    const _getSlots = () => {
        const url = "/m2m/game-slots-filter"

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tempSlot = []
                tempSlot.push({ label: "All", value: null })
                response.data.data.map((item) => {
                    return (
                        tempSlot.push({
                            label: `${item.name}`,
                            value: item.slot_id
                        })
                    )
                })
                setSlotOptions(tempSlot)
                setSlotId(tempSlot[0])
            } else {
                setSlotOptions([])
            }
        })
    }

    /*------ booking list ------*/
    const bookingList = () => {
        setLoader(true);
        let url = "/report/bookings";

        let data = {
            "customer_id": Number(userId),
            "game_type": gameType?.value,
            "booking_type": bookingType?.value,
            "from_date": startDate,
            "slot_id": slotId?.value,
            "to_date": endDate,
            "scrip_id": scripId?.value,
            "trade_status": tradeType?.value,
            "winning_status": winningType?.value,
            "page_num": page,
            "page_size": perPage
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setBookingData(response.data.data);
                let remainder = response.data.number_of_records % perPage
                let quotient = response.data.number_of_records / perPage
                if (Number(remainder) > 0) {
                    setTotalLength(parseInt(Number(quotient) + 1))

                } else {
                    setTotalLength(Number(quotient))
                }
                setPaginationData({
                    rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                })
                setTotal(response.data.number_of_records);
                setTotals(response.data.total_data);
                setLoader(false);
            } else {
                setBookingData([]);
                setError(response.data.message);
                setLoader(false);
            }
        });
    }
    /*-------- get filters for booking ----*/
    const getScripFilter = (temp) => {
        let url = '/scrip/scrip-filter';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tArray = [{ value: null, label: 'All' }];
                for (let index = 0; index < response.data.data.length; index++) {
                    const element = response.data.data[index];

                    tArray.push({ value: element.scrip_id, label: element.scrip_code })
                }
                setScripDataFilter(tArray);
            }
        })
    }

    const customDateSort = (a, b, selector, given) => {
        let format = given ? given : "DD MMM YYYY"
        if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
        else if (a[selector]) return -1;
        else if (b[selector]) return 1;
        else return 0;
    }

    const columns = [
        {
            name: 'Sno',
            selector: (row, rowIndex) => { return (<div>{rowIndex + 1}</div>) },
            sortable: false,
            left: true,
            width: '60px'
        },
        {
            name: 'Date',
            sortable: true,
            left: true,
            minWidth: "130px",
            cell: row => <div className="d-flex flex-column"> {row.date} </div>,
            sortFunction: (a, b) => customDateSort(a, b, 'date')
        },
        {
            name: 'Time',
            sortable: false,
            left: true,
            maxWidth: "140px",
            cell: row => <div className="d-flex flex-column"> {row.time} </div>
        },
        {
            name: 'Symbol',
            selector: 'scrip_code',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row =>
                <div >
                    {row.scrip_code}
                </div>
        },
        {
            name: 'Game ID',
            selector: row => row.game_id,
            sortable: false,
            left: true,
            minWidth: '140px',

        },
        {
            name: 'Slot',
            selector: row => row.slot_time,
            sortable: false,
            left: true,
            minWidth: '180px',
            omit: gameType?.value === 1 || gameType?.value === 3 ? true : false

        },

        {
            name: 'Prev. Close',
            selector: row => row.previous_close,
            sortable: true,
            left: true,
            minWidth: '160px',
        },
        {
            name: 'Closing price',
            selector: row => row.closing,
            sortable: true,
            left: true,
            minWidth: '150px',
        },
        {
            name: 'Change %',
            selector: row => row.closing_change,
            sortable: true,
            left: true,
            minWidth: '150px',
        },
        {
            name: 'Score',
            selector: row => row.score_points,
            sortable: true,
            left: true,
            minWidth: '130px',
        },
        {
            name: 'Gainer Qty',
            selector: row => Number(row.gainer_qty),
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => row.gainer_qty !== 0 && row.gainer_qty ? row.gainer_qty : "-",
        },
        {
            name: 'Loser Qty',
            selector: row => Number(row.looser_qty),
            sortable: true,
            left: true,
            cell: row => row.looser_qty !== 0 && row.looser_qty ? row.looser_qty : "-",
            minWidth: "150px"
        },
        {
            name: 'Booking amount',
            selector: row => Number(row.amount),
            sortable: true,
            left: true,
            minWidth: "170px",
            cell: row => <div> {row.amount !== "-" && row.amount ? `₹ ${row.amount}` : "-"}</div>,
        },
        {
            name: 'Trade status',
            selector: row => row.trade_status,
            sortable: false,
            left: true,
            minWidth: '150px'
        },
        {
            name: 'Result date time',
            cell: row => <>{row.result_datetime}</>,
            sortable: false,
            left: true,
            minWidth: "160px",
        },
        {
            name: 'Reward',
            // selector: 'amount',
            sortable: false,
            left: true,
            // width: "100px",
            cell: row => <div> {row.reward !== "-" ? `₹ ${row.reward}` : row.reward}</div>
        },
        {
            name: 'TDS',
            selector: row => Number(row.tds),
            sortable: true,
            left: true,
            cell: row => row.tds !== 0 && row.tds ? row.tds : row.tds,
        },
        {
            name: 'Platform fees',
            selector: row => parseFloat(row.platform_fees),
            cell: row => `₹ ${parseFloat(row.platform_fees)}`,
            sortable: false,
            left: true,
            minWidth: '150px'
        },
        {
            name: 'Cancel date time',
            selector: row => row.cancelled_at,
            sortable: false,
            left: true,
            minWidth: "180px",
        },
    ]

    const skillrace_columns = [
        {
            name: 'Sno',
            selector: (row, rowIndex) => { return (<div>{rowIndex + 1}</div>) },
            sortable: false,
            left: true,
            width: '60px'
        },
        {
            name: 'Date',
            // selector: row => <div className="d-flex flex-column"> {row.transaction_date.slice(0, 12)} <span className='e-wallet-historty-time pt-2'>{row.transaction_date.slice(12,)}</span> </div>,
            sortable: false,
            left: true,
            minWidth: "140px",
            cell: row => <div className="d-flex flex-column"> {row.date} <span className='e-wallet-historty-time pt-2'>{row.time}</span> </div>
        },
        {
            name: 'Symbol',
            // selector: 'scrip',
            sortable: false,
            left: true,
            minWidth: "180px",
            cell: row =>
                <div className="d-flex flex-column">
                    {row.scrip_code}
                </div>
        },
        {
            name: 'Game ID',
            selector: row => row.game_id,
            sortable: false,
            left: true,
            minWidth: '150px',

        },
        {
            name: ' Quantity',
            selector: 'quantity',
            sortable: false,
            left: true,
            cell: row => <div>{row.quantity}</div>
        },
        {
            name: ' Buy price',
            selector: row => row.buy_price,
            sortable: false,
            left: true,
            minWidth: '150px'
        },
        {
            name: ' Buy time',
            selector: row => row.buy_placed_time,
            sortable: false,
            left: true,
        },
        {
            name: ' Sell price',
            selector: row => row.sell_price,
            sortable: false,
            left: true,
            minWidth: '150px'
        },
        {
            name: ' Sell time',
            selector: row => row.sell_placed_time,
            sortable: false,
            left: true,
        },
        {
            name: 'Returns %',
            selector: row => row.returns_percentage,
            sortable: false,
            left: true,
            minWidth: '150px'
        },
        {
            name: 'Trigger price',
            selector: row => "₹" + row.trigger_price,
            sortable: false,
            left: true,
            minWidth: '160px'
        },
        {
            name: 'SL',
            selector: row => row.stop_loss,
            sortable: false,
            left: true,
        },
        {
            name: 'Type',
            selector: row => row.order_type,
            sortable: false,
            left: true,
            minWidth: '160px'
        },
        {
            name: 'Result date',
            selector: row => row.result_date,
            sortable: false,
            left: true,
            minWidth: '160px'
        },
        {
            name: 'Result time',
            selector: row => row.result_time,
            sortable: false,
            left: true,
            minWidth: '160px'
        },

    ]

    const selectOptions = [
        // { label: 'All', value: null },
        { label: 'M2E', value: 1 },
        { label: 'M2M', value: 2 },
        { label: 'Skill race', value: 3 }
    ]

    const bookingTypeOption = [
        { value: null, label: 'All' },
        { value: 1, label: 'Gainer' },
        { value: 2, label: 'Loser' },
    ]
    const skillraceTardeOption = [
        { value: null, label: 'All' },
        { value: 1, label: 'Open' },
        { value: 2, label: 'Completed' },
        { value: 3, label: 'Pending' },
    ]

    const statusOption = [
        { value: null, label: 'All' },
        { value: true, label: 'Won' },
        { value: false, label: 'Lost' }
    ]
    const tradeOption = [
        { value: null, label: 'All' },
        { value: 1, label: 'Open' },
        { value: 2, label: 'Won' },
        { value: 3, label: 'Lost' },
        { value: 4, label: 'Cancelled' },
    ]

    const resetFilters = () => {
        setGameType({ value: 1, label: 'M2E' });
        setStartDate(null);
        setEndDate(null);
        setScripId(scripDataFilter[0])
        setBookingType({ value: null, label: 'All' });
        setWinningType({ value: null, label: 'All' });
        setScripId({ value: null, label: 'All' });
        setTradeType({ value: null, label: 'All' });
        setSlotId(slotOptions[0])
    }

    // function convertArrayOfObjectsToCSV(data) {
    //     console.log('totals,', totals)
    //     // Empty array for storing the values
    //     var csvRows = [];

    //     // Headers is basically a keys of an
    //     // object which is id, name, and
    //     // profession
    //     const titles = [
    //         'Date',
    //         'Time',
    //         'Game ID',
    //         'Symbol',
    //         'Gainer Qty',
    //         'Loser Qty',
    //         'Booking amount',
    //         'Trade status',
    //         'Result date time',
    //         'Reward',
    //         'TDS',
    //         'Platform fees',
    //         'Cancel date time'

    //     ];

    //     var headers = titles
    //     // As for making csv format, headers
    //     // must be separated by comma and
    //     // pushing it into array
    //     csvRows.push(headers.join(','));

    //     // Pushing Object values into array
    //     // with comma separation

    //     for (let i = 0; i < bookingData.length; i++) {
    //         var values = [
    //             data[i]['date'],
    //             data[i]['time'],
    //             data[i]['game_id'],
    //             data[i]['scrip_code'],
    //             data[i]['gainer_qty'],
    //             data[i]['looser_qty'],
    //             data[i]['amount'],
    //             data[i]['trade_status'],
    //             data[i]['result_datetime'],
    //             data[i]['reward'],
    //             data[i]['tds'],
    //             data[i]['platform_fees'],
    //             data[i]['cancelled_at']
    //         ].join(",")
    //         csvRows.push(values)
    //     }



    //     // Returning the array joining with new line
    //     console.log(csvRows)
    //     return csvRows.join('\n')
    // }

    // function downloadCSV(data) {
    //     // Creating a Blob for having a csv file format
    //     // and passing the data with type
    //     const blob = new Blob([data], { type: 'text/csv' });

    //     // Creating an object for downloading url,
    //     const url = window.URL.createObjectURL(blob)

    //     // Creating an anchor(a) tag of HTML
    //     const a = document.createElement('a')

    //     // Passing the blob downloading url
    //     a.setAttribute('href', url)

    //     // Setting the anchor tag attribute for downloading
    //     // and passing the download file name
    //     a.setAttribute('bookings', 'bookings.csv');

    //     // Performing a download with click
    //     a.click()
    // }
    const Export = ({ onExport }) =>
        <PrimaryLink
            name={downloadLoader ? "Downloading" : "Download"}
            className={downloadLoader ? "e-disabled ps-0" : "ps-0"}
            handleClick={e => onExport(e.target.value)}>Export</PrimaryLink>;
    // const Export = ({ onExport }) => <PrimaryLink onClick={e => onExport(e.target.value)}>Export</PrimaryLink>;
    // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(bookingData)} />, []);

    const downloadBookings = () => {
        setDownloadLoader(true)
        let url = "/user/report/booking";

        let data = {
            "user_id": Number(userId),
            "game_type": gameType.value,
            "booking_type": bookingType?.value,
            "start_date": startDate,
            "end_date": endDate,
            "scrip_id": scripId?.value,
            "trade_status": tradeType?.value,
            "slot_id": slotId?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setDownloadLoader(false);
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }
    return (
        <div className='e-users-container'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className="row pt-4">
                            <div className="col-lg-6">
                                <h6 className="e-card-title ps-3 mb-0">Bookings</h6>
                            </div>
                            <div className='col-lg-12 position-relative px-4 mx-1'>
                                <div className='e-filter d-inline-block'>
                                    <label className='mb-2'>Game type</label>
                                    <SelectBox
                                        className="me-2 e-bookings-sort"
                                        options={selectOptions}
                                        value={gameType}
                                        selectchange={(temp) => { setGameType(temp); setPage(1); setPerPage(200) }}
                                        isSearchable={false}
                                        placeholder="Game  type"
                                    />
                                </div>
                                <div className='e-filter  d-inline-block'>
                                    <label className='mb-2'>Scrip </label>
                                    <SelectBox
                                        className="me-2 e-bookings-sort"
                                        options={scripDataFilter}
                                        value={scripId}
                                        selectchange={(temp) => { setScripId(temp); }}
                                        isSearchable={true}
                                        placeholder="Game type"
                                    />
                                </div>

                                {
                                    gameType.value === 3 ?
                                        <div className='e-filter  d-inline-block'>
                                            <label className='mb-2'>Winning  status</label>
                                            <SelectBox
                                                className="me-2 e-bookings-sort"
                                                options={statusOption}
                                                value={winningType}
                                                selectchange={(temp) => { setWinningType(temp); }}
                                                isSearchable={false}
                                                placeholder="Winning  status"
                                            />
                                        </div>
                                        :
                                        <div className='e-filter  d-inline-block'>
                                            <label className='mb-2'>Booking type</label>
                                            <SelectBox
                                                className="me-2 e-bookings-sort"
                                                options={bookingTypeOption}
                                                value={bookingType}
                                                selectchange={(temp) => { setBookingType(temp); }}
                                                isSearchable={false}
                                                placeholder="Booking  type"
                                            />
                                        </div>
                                }
                                {
                                    gameType?.value === 2 ?
                                        <div className='e-filter d-inline-block e-m2m-slot'>
                                            <label className='mb-2'>Slot</label>
                                            <SelectBox
                                                className="me-2 e-bookings-sort"
                                                options={slotOptions}
                                                value={slotId}
                                                selectchange={(temp) => { setSlotId(temp); }}
                                                isSearchable={false}
                                                placeholder="Select "
                                            />
                                        </div>
                                        :
                                        null
                                }

                                <div className='e-filter  d-inline-block'>
                                    <label className='mb-2'>Trade  status</label>
                                    <SelectBox
                                        className="me-2 e-bookings-sort"
                                        options={gameType.value === 3 ? skillraceTardeOption : tradeOption}
                                        value={tradeType}
                                        selectchange={(temp) => { setTradeType(temp); }}
                                        isSearchable={false}
                                        placeholder="Winning  status"
                                    />
                                </div>
                                <div className='mt-4 pt-2 e-filter  d-inline-block'>
                                    <label className='mb-2'>Transactions from-till</label>
                                    <Rangepicker
                                        placeholder={['From', 'To']}
                                        start={startDate}
                                        end={endDate}
                                        disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                        onDatesChange={(start, end) => {
                                            setStartDate(start);
                                            setEndDate(end);
                                        }}
                                    />
                                </div>
                                <div className='mt-4 pt-2 e-filter  d-inline-block'>
                                    {
                                        startDate && endDate ?
                                            <PrimaryBtn name="Submit" className="e-result-filter-submit   mx-2" handleClick={() => bookingList(gameType.value, scripId.value, bookingType.value, winningType.value, tradeType.value, startDate, endDate)} />
                                            :
                                            <PrimaryBtn name="Submit" className="e-result-filter-submit mx-2" disabled={true} />
                                    }
                                </div>
                                <div className='mt-4 pt-3  e-filter  d-inline-block'>
                                    <PrimaryLink
                                        name="Clear all"
                                        className='me-2'
                                        handleClick={() => {
                                            resetFilters()
                                        }}
                                    />
                                </div>
                                {
                                    bookingData && bookingData.length ?
                                        // <PrimaryLink
                                        //     left={true}
                                        //     name="Download"
                                        //     width="20px"
                                        //     handleClick={() => downloadCSV()}
                                        //     className="e-download-booking"
                                        // />

                                        <Export onExport={() => {
                                            downloadBookings()
                                        }} />
                                        :
                                        <PrimaryLink
                                            left={true}
                                            name="Download"
                                            width="20px"

                                            className="e-disabled e-download-booking"
                                        />
                                }
                            </div>


                            {
                                loader ?
                                    <Loader />
                                    :
                                    bookingData.length > 0 ?
                                        <>
                                            <div className="col-lg-12 e-booking-table pt-3">
                                                <DataTable
                                                    pagination={true}
                                                    columns={gameType?.value !== 3 ? columns : skillrace_columns}
                                                    data={bookingData}
                                                    size={perPage}
                                                    onChangeRowsPerPage={(p) => {
                                                        setPage(1)
                                                        setPerPage(p)
                                                    }}
                                                    paginationComponentOptions={paginationData}
                                                    paginationTotalRows={total}
                                                    paginationIconNext={totalLength === page ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setPage(page + 1)} style={{ cursor: "pointer" }} />}
                                                    paginationIconPrevious={page === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPage(page - 1) }} />}
                                                // actions={actionsMemo}
                                                // clearSelectedRows={downloadLoader ? true : false}
                                                // selectableRows={true}

                                                // selectRows={(row) => {
                                                //     handleChange(row)
                                                // }}
                                                />
                                            </div>
                                            <div className='col-lg-12 text-end pe-3'>
                                                <p>Page no : <span style={{ fontWeight: "600" }}>{page}</span></p>
                                            </div>
                                            <div className='row px-4 pt-3 pb-3'>
                                                {
                                                    gameType?.value !== 3 ?
                                                        <div className='row px-4 pb-3 pt-4'>
                                                            <div className='col-lg-2'>
                                                                <h6>Total booking amount</h6>
                                                                <p>₹{totals?.total_booking_amount}</p>
                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <h6>Total gainer qty</h6>
                                                                <p>{totals?.total_gainer_qty}</p>
                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <h6>Total loser qty</h6>
                                                                <p>{totals?.total_looser_qty}</p>
                                                            </div>
                                                            <div className='col-lg-1 px-0'>
                                                                <h6>Total p&l </h6>
                                                                <p>{totals?.total_p_l}</p>
                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <h6>Total platform fees </h6>
                                                                <p>₹{totals?.total_platform_fees}</p>
                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <h6>Total rewards</h6>
                                                                <p>₹{totals?.total_reward}</p>
                                                            </div>
                                                            <div className='col-lg-1'>
                                                                <h6>Total tds</h6>
                                                                <p>{totals?.total_tds}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='row px-4 pb-3 pt-4'>
                                                            <div className='col-lg-6'>
                                                                <h6>Total booking amount</h6>
                                                                <p>₹{totals?.total_booking_amount}</p>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <h6>Total qty</h6>
                                                                <p>{totals?.total_quantity}</p>
                                                            </div>

                                                        </div>
                                                }
                                            </div>
                                        </>
                                        :
                                        <EmptyNote img={Nodata} data="No data found" />
                            }

                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Bookings;
