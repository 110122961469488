/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Leaderboard;
 *   Integrations :19-04-2022
 *   Version : v1.0
 *   Created : 19-04-2022
 */
/*import pakcages*/
import { useState, } from 'react';

/*import components*/
import { GameTabs } from '../../components/dataFormating/index';

/*import containers */
import GlobalToper from './global-toper.js';
import M2EToper from './m2eToper';
import M2MToper from './m2mToper';
import SkillraceToper from './skillraceToper';

/*import assets*/

const Index = (props) => {

  window.scrollTo({ top: 0, behavior: 'smooth' });
  const [tabIndex, setTabindex] = useState(1);


  return (
    <div className='e-users-container e-leaderboard'>
      <div className="row">
        <div className="col-lg-12">
          <div className="card e-dashboard-card border-0">
            <div className="row">
              <div className="col-lg-12 pt-4 ps-4 ms-3 mt-1">
                <GameTabs
                  index={tabIndex}
                  data={[
                    'Global topper',
                    'M2E topper',
                    'M2M topper',
                    'Skill race topper'
                  ]}
                  setTab={(temp) => setTabindex(temp)} />
              </div>
              {
                tabIndex === 1 ?
                  <div className='mt-4'>
                    <GlobalToper tabIndex={tabIndex} />
                  </div>

                  : tabIndex === 2 ?
                    <div className='mt-4'>
                      <M2EToper tabIndex={tabIndex} />
                    </div>
                    : tabIndex === 3 ?
                      <div className='mt-4'>
                        <M2MToper tabIndex={tabIndex} />
                      </div>
                      :
                      <div className='mt-4'>
                        <SkillraceToper tabIndex={tabIndex} />
                      </div>
              }
            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default Index;
