/*
 *   File : fileupload.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import styles from '../../styles/components/addFaq.module.scss';
import { PrimaryBtn, SecondaryBtn } from '../cta';
import { FileUpload } from '../input';
import $ from 'jquery'
import service from '../../services/service';
import { Toast } from '../note';


export default function UploadDocument(props) {

    const [uploadImage, setUploadImage] = useState("");
    const [imageLoader, setImageLoader] = useState(false);
    const [error, setError] = useState(null);
    const [imageUrl, setUploadImageUrl] = useState("");
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000);
        }
    }, [error])

    const preSignedUrl = (file) => {
        // var date = new Date()
        setImageLoader(true);
        let url = "/app/presigned-url-upload";
        let data = {
            key: `Payout_Bank_Slip/` + file.name
        };

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setImageLoader(true);
                uploadDocument(response.data.data, file); // upload file in AWS s3
            } else {
                setError(response.data.message);
                setImageLoader(false);
            }
        });
    }

    /* Uploading to s3 */
    const uploadDocument = async (data, file) => {
        setImageLoader(true);
        var formdata = new FormData();
        formdata.append("key", data.upload_info.fields.key);
        formdata.append("x-amz-algorithm", data.upload_info.fields['x-amz-algorithm']);
        formdata.append("x-amz-credential", data.upload_info.fields['x-amz-credential']);
        formdata.append("x-amz-date", data.upload_info.fields['x-amz-date']);
        formdata.append("policy", data.upload_info.fields['policy']);
        formdata.append("x-amz-signature", data.upload_info.fields['x-amz-signature']);
        formdata.append("file", file, file.name);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(data.upload_info.url, requestOptions)
            .then(response => { response.text(); console.log(response.text()) })
            .then(res => {
                setUploadImageUrl(data.view_info); // file got uploaded successfully
                setImageLoader(false);
                console.log(res)

            })
            .catch(error => {
                console.log('error', error); // show error toast here
                setImageLoader(false);
            });
    }

    const uplaodFile = () => {
        setLoader(true)
        var url = "/general/payout-requests-upload";

        var data = {
            url: imageUrl
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false)
                window.location.reload()
            } else {
                setLoader(false)
                setError(response.data.message)
            }
        })
    }



    return (
        <>
            <div className={`${styles.e_add_faq} modal fade e-game-settings-accordion e-manage-game-accordion e-add-milestone-wrap`} id='file-upload' tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Upload file</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setUploadImage("")}></button>
                        </div>
                        <div className="container px-3">
                            <div className="row">
                                <div className='col-lg-6'>
                                    <label className="mb-2 ps-2 ms-1">Choose file</label>
                                    <div className="e-uploader" onClick={() => { $('#e-upload').click() }}>
                                        {
                                            uploadImage ?
                                                <>
                                                    <p className='text-center mb-0'>Choose file</p>
                                                    <div className='text-center mb-0 setting-detail mt-2'>(Selected: {uploadImage})</div>
                                                </>
                                                :
                                                <>
                                                    <p className={imageLoader ? 'e-btn-loader text-center mb-0' : 'text-center mb-0'}>Choose file</p>
                                                </>
                                        }

                                        <FileUpload
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            className="hidden"
                                            id="e-upload"
                                            key="e-upload-image"
                                            upload={(temp) => {
                                                preSignedUrl(temp);
                                                setUploadImage(temp.name)
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 mt-4 text-end'>
                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className="e-cancel"
                                            cancel="modal"
                                            handleClick={() => setUploadImage("")} />
                                        <PrimaryBtn
                                            name="Submit"
                                            className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                            handleClick={() => {
                                                uplaodFile()
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </>
    )
}
