/*
 *   File : addTransaction.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add transaction Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input, RadioButton, Search } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';
import SelectBox from '../dropdown/selectBox';
import service from '../../services/service';
import { Toast } from '../note';


export default function AddTransaction(props) {
    //eslint-disable-next-line
    const wrapperRef = useRef(null);
    const [type, setType] = useState(1);
    const [selectedType, setSelectedType] = useState({})
    const [userId, setUserId] = useState({});
    const [amount, setAmount] = useState('');
    const [remarks, setRemarks] = useState("");
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [typeOptions, setTypeOptions] = useState([]);
    const [search, setSearch] = useState("");


    useEffect(() => {
        if (props.id) {
            setUserId({
                id: Number(props.id)
            })
        }
    }, [props.id]);

    // useEffect(() => {
    //     let temp_customer = []
    //     props.customer.map((item, key) => {
    //         if (key != 0) {
    //             return (
    //                 temp_customer.push({
    //                     label: `${item.label} (SS${item.value})`,
    //                     value: item.value
    //                 })

    //             )
    //         }
    //     })
    //     setCustomerData(temp_customer)
    // }, [props.customer]);

    useEffect(() => {
        let temp_type = []
        //eslint-disable-next-line
        props.type.map((item, key) => {
            if (key !== 0) {
                return (
                    temp_type.push({
                        label: `${item.label}`,
                        value: item.value
                    })

                )
            }
        })
        setTypeOptions(temp_type)
    }, [props.type]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    /* update transaction list api */
    const addNewTransaction = () => {
        setLoader(true)
        let url = "/transaction/update";

        let data = {
            "type": selectedType?.value,
            "user_id": userId?.id,
            "is_credit": type === 1 ? true : false,
            "amount": amount,
            "remark": remarks
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                $("#addTransaction").trigger("click");
                props.Success()
                clear()
            } else {
                setLoader(false);
                setError(response.data.message);
            }
        })
    }

    /* update transaction list api */
    const addNewException = () => {
        setLoader(true)
        let url = "/reports/wallet-exceptions/add";

        let data = {
            "customer_id": userId?.id,
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                $("#addTransaction").trigger("click");
                props.Success()
                clear()
            } else {
                setLoader(false);
                setError(response.data.message);
            }
        })
    }

    const clear = () => {
        setAmount('');
        setSelectedType({});
        setRemarks('');
        if (props.id === undefined) {
            setUserId({});
            setSearch("");
        }
        setType(1)
    }


    return (
        <>
            <div className={`${styles.e_add_faq} modal fade e-add-block-rule`} id="addTransaction" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Add Transaction</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { clear() }}></button>
                        </div>
                        <div className="container px-3">
                            <div className="row">
                                {
                                    props.id && props.name ?
                                        <div className="col-lg-6">
                                            <label className="mb-2">Customer name</label>
                                            <SelectBox
                                                isDisabled={true}
                                                isSearchable={true}
                                                defaultValue={{ label: `${props.name} (${props.id})`, value: props.id }}
                                                // options={customerData}
                                                value={{ label: `${props.name} (${props.id})`, value: props.id }}
                                                placeholder="Select customer"
                                            />
                                        </div>
                                        :
                                        <div className="col-lg-6">
                                            <label className="mb-2">Customer name</label>
                                            <Search
                                                value={userId}
                                                search={search}
                                                setSearch={(temp) => { setSearch(temp); setUserId({}) }}
                                                userDetail={(temp) => setUserId(temp)} />

                                        </div>
                                }
                                {
                                    props.isException ?
                                        null
                                        :
                                        <>
                                            <div className='col-lg-6'>
                                                <label className="mb-2">Type</label>
                                                <SelectBox
                                                    isSearchable={false}
                                                    defaultValue={typeOptions[0]}
                                                    options={typeOptions}
                                                    value={Object.keys(selectedType).length > 0 ? selectedType : null}
                                                    placeholder="Select type"
                                                    selectchange={(temp) => {
                                                        setSelectedType(temp)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-6 mt-2">
                                                <label className="mb-2">Amount</label>
                                                <Input
                                                    placeholder="Amount"
                                                    value={amount}
                                                    valueChange={(value) => {
                                                        setAmount(value)
                                                    }}
                                                    type="number"
                                                />
                                            </div>
                                            <div className="col-lg-6 mt-2">
                                                <label className="mb-2">Remarks</label>
                                                <Input
                                                    placeholder="Remarks"
                                                    value={remarks}
                                                    id="remarks"
                                                    valueChange={(value) => {
                                                        setRemarks(value)
                                                    }}
                                                    type="text"
                                                />
                                            </div>
                                        </>
                                }

                            </div>
                            <div className="row pt-3 mt-2  pb-2">
                                {
                                    props.isException ?
                                        <div className="col-lg-12 pt-3 mt-2 text-end">
                                            <label></label>
                                            {
                                                userId?.id ?
                                                    <PrimaryBtn
                                                        name="Save"
                                                        className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                        handleClick={() => {
                                                            addNewException()
                                                        }}
                                                    />
                                                    :
                                                    <PrimaryBtn
                                                        name="Save"
                                                        className={`${styles.e_disabled} e-modal-submit `}
                                                    />
                                            }
                                        </div>
                                        :
                                        <>
                                            <div className="col-lg-6 pt-3">
                                                {/* <label>Transaction type</label> */}
                                                <div className='d-flex'>
                                                    <RadioButton className="my-1" checked={Number(type) === 1 ? true : false} label="Credit" id={1} onClick={(e) => setType(e.target.id)} />
                                                    <RadioButton className="my-1" checked={Number(type) === 2 ? true : false} label="Debit" id={2} onClick={(e) => setType(e.target.id)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 pt-3 mt-2 text-end">
                                                <label></label>
                                                {
                                                    userId?.id && amount && remarks && selectedType ?
                                                        <PrimaryBtn
                                                            name="Save"
                                                            className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                            handleClick={() => {
                                                                addNewTransaction()
                                                            }}
                                                        />
                                                        :
                                                        <PrimaryBtn
                                                            name="Save"
                                                            className={`${styles.e_disabled} e-modal-submit `}
                                                        />
                                                }
                                            </div>
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                    />
                    :
                    null
            }
        </>
    )
}
