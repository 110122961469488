/*
 *   File : add-milestone.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : m2e container;
 *   Integrations : 11-04-2022
 *   Version : v1.0
 *   Created : 16-02-2022
 */
/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { GameTabs } from '../../components/dataFormating/index';
import { ScripList } from '../../components/card/index';
import { SearchInput } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';
import { AddScrip, UpdateScripType } from '../../components/modal/index';
import Loader from '../../components/loader/loader';
import { EmptyNote, Toast } from '../../components/note';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import container */
// import ListedScrips from './listed-scrips.js';
import ScripVoting from './scrip-voting.js';

/* import services */
import service from '../../services/service';

/* import assets */
import Nodata from '../../assets/images/note/no-data.png';
import { Modal } from 'bootstrap';

const AllScrips = (props) => {

    const [tabIndex, setTabindex] = useState(1);
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(false);
    const [scripList, setScripList] = useState([]);
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        if (search === "") {
            listScrips(); //list all available scrips api
        }
        //eslint-disable-next-line
    }, [search])

    useEffect(() => {
        if (show) {
            const modal = new Modal(document.getElementById("updateScripType"), {})
            modal.show()
        }
    }, [show])

    useEffect(() => {
        setTimeout(() => {
            if (error !== null) {
                setError(null)
            }
        }, 3000);
    }, [error])

    /*----- list scrips api ----*/
    const listScrips = () => {
        setLoader(true)
        let url = '/scrip/list';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setScripList(response.data.data);
                setLoader(false);
            }
            else {
                setLoader(false);
                setScripList([]);
                setError(response.data.message);
            }
        });
    }

    /*------search scrips -------*/
    const searchScrips = (value) => {
        setLoader(true)
        let url = '/scrip/search';

        let data = {
            query: value ? value : search
        }

        service(true, url, data).then((response) => {

            if (response.data.status_code === 200) {
                setScripList(response.data.data);
                setLoader(false);
            }
            else {
                setScripList([]);
                setLoader(false);
                setError(response.data.message);
            }
        });
    }

    return (
        <>
            <div className='e-tab-position'>
                <GameTabs data={['All scrips', 'Scrip voting']} index={tabIndex} setTab={(temp) => setTabindex(temp)} type={true} />
            </div>
            <div className='e-users-container pt-3'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card e-dashboard-card pt-2 border-0">
                            {
                                tabIndex === 1 ?
                                    <div className='e-milestone-wrap'>
                                        <div className="row">
                                            <div className="col-lg-10 col-9">
                                                <span className='pt-3 mt-1 e-labels me-3'>Most active {scripList.length} Indian Equity</span>
                                            </div>
                                            {/* <div className='col-lg-2 text-end col-3'>
                                                <span className='e-work-link pt-3 mt-1'>How it works?</span>
                                            </div> */}
                                        </div>
                                        <div className="row pt-3 mt-2">
                                            <div className="col-lg-3 col-5 position-relative ">
                                                <SearchInput
                                                    placeholder="Search for scrips"
                                                    value={search}
                                                    valueChange={(value) => { setSearch(value) }}
                                                    onSearch={(value) => {
                                                        setSearch(value);
                                                        searchScrips(value);
                                                    }}
                                                />
                                                {
                                                    search && search.length ?
                                                        <>
                                                            <span
                                                                className={`${styles.e_search_submit} `}
                                                                onClick={() => {
                                                                    searchScrips();
                                                                }} >
                                                            </span>
                                                            <button
                                                                className={`${styles.e_search_close} pe-3`}
                                                                onClick={() => {
                                                                    setSearch("");
                                                                    // listScrips();
                                                                }} >
                                                            </button>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className='col-lg-9 col-7 text-end'>
                                                <PrimaryBtn
                                                    name="Update game scrips"
                                                    className="e-add-scrip-btn"
                                                    handleClick={() => {
                                                        setShow(true)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='row pt-3 mt-2 text-center'>
                                            {
                                                loader ?
                                                    <Loader />
                                                    :
                                                    // scripList.map((item, key) => {
                                                    //     return (
                                                    // <div className="col-lg-12 " >
                                                    scripList.length > 0 ?
                                                        <ScripList
                                                            list={scripList}
                                                            update={() => listScrips()}
                                                            scripId={() => { }}
                                                            scripName={() => { }} />
                                                        :
                                                        <EmptyNote img={Nodata} data="No scrips found" />
                                                // </div>
                                                //     )
                                                // })
                                            }
                                        </div>
                                    </div>

                                    // : tabIndex === 2 ?
                                    //     <ListedScrips />

                                    :
                                    <ScripVoting />
                            }
                        </div>
                    </div>
                </div>

                {
                    error !== null ?
                        <Toast
                            data={error}
                            type="fail"
                            format={1}
                        />
                        :
                        null
                }

            </div>
            <AddScrip />
            {
                show ?
                    <UpdateScripType update={() => {
                        listScrips();
                    }}
                        closeModal={() => {
                            setShow(false)
                        }} />
                    :
                    null
            }

        </>

    )
}

export default AllScrips;
