/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 12-04-2022
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakcages*/
import React, { useState, useEffect } from 'react';
import moment from 'moment';

/*import components*/
import { DataTable, GameTabs } from '../../components/dataFormating/index';
import { Rangepicker, Search } from '../../components/input/index';
import { DownloadBtn } from '../../components/cta/index';
import SelectBox from '../../components/dropdown/selectBox';
import Loader from '../../components/loader/loader';
import { FilterBtn } from '../../components/cta/index';

/*import styles*/

/* import assets */
import Nodata from '../../assets/images/note/no-data.png'
import Next from '../../assets/images/dataFormating/next.svg';
import Prev from '../../assets/images/dataFormating/prev.svg';

/* import services */
import service from '../../services/service';
import { EmptyNote } from '../../components/note';

const Index = (props) => {
    // const [search, setSearch] = useState("");
    const [filterDown, setFilterDown] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [check, setCheck] = useState([]);
    //eslint-disable-next-line
    const [gameType, setGameType] = useState({ label: "M2E", value: 1 });
    // const [timePeriod, setTimePeriod] = useState({ label: "Last one week", value: 1 });
    // const [gameOptions, setGameOptions] = useState([]);
    // const [timePeriodOptions, setTimePeriodOptions] = useState([]);
    const [bookingData, setBookingData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [tabIndex, setTabindex] = useState(1)
    const [bookingType, setBookingType] = useState({ value: null, label: 'All' });
    const [winningType, setWinningType] = useState({ value: null, label: 'All' });
    const [tradeType, setTradeType] = useState({ value: null, label: 'All' });
    const [scripId, setScripId] = useState({ value: null, label: 'All' });
    const [scripIdSummary, setScripIdSummary] = useState({ value: null, label: 'All' });
    const [summaryStartDate, setSummaryStartDate] = useState(null);
    const [summaryEndDate, setSummaryEndDate] = useState(null);
    const [totalBookingStartDate, setTotalBookingStartDate] = useState(null);
    const [totalBookingEndDate, setTotalBookingEndDate] = useState(null);
    const [scripDataFilter, setScripDataFilter] = useState();
    //eslint-disable-next-line
    const [customerData, setCustomerData] = useState([])
    const [id, setId] = useState({ id: 0 });
    const [userId, setUserId] = useState({ id: 0 });
    const [gameId, setGameId] = useState({ label: "All", value: 0 });
    const [totalBookingData, setTotalBookingData] = useState([]);
    const [gameIds, setGameIds] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalGainerLoser, setTotalGainerLoser] = useState(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(200);
    const [spage, setSPage] = useState(1);
    const [perSPage, setPerSPage] = useState(200);
    const [totalLength, setTotalLength] = useState(null);
    const [totals, setTotals] = useState({});
    const [search, setSearch] = useState("");
    const [index, setIndex] = useState(1);
    const [paginationData, setPaginationData] = useState({})
    const [paginationDataSummary, setPaginationDataSummary] = useState({})
    const [slotOptions, setSlotOptions] = useState([])
    const [slotId, setSlotId] = useState({});
    const [btn_loader, set_btn_loader] = useState(false);


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        // getFilters();
        getCustomersList();
        getScripFilter();
        _getSlots();
    }, []);

    useEffect(() => {
        if (tabIndex === 1) {
            bookingList()
        }
        //eslint-disable-next-line
    }, [id, page, perPage, index, tradeType?.value, bookingType?.value, startDate, endDate, scripId, tabIndex, winningType, slotId]);

    useEffect(() => {
        if (tabIndex === 2) {
            getSummary();
        }
        //eslint-disable-next-line
    }, [scripIdSummary, index, spage, perSPage, summaryEndDate, summaryStartDate, userId, tabIndex, slotId?.value])

    useEffect(() => {
        if (tabIndex === 3) {
            getTotalBookings();
        }
        //eslint-disable-next-line
    }, [index, gameId, tabIndex, totalBookingStartDate, totalBookingEndDate])



    const _getSlots = () => {
        const url = "/m2m/game-slots-filter"

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tempSlot = []
                tempSlot.push({ label: "All", value: null })
                response.data.data.map((item) => {
                    return (
                        tempSlot.push({
                            label: `${item.name}`,
                            value: item.slot_id
                        })
                    )
                })
                setSlotOptions(tempSlot)
                setSlotId(tempSlot[0])
            } else {
                setSlotOptions([])
            }
        })
    }

    /*------ booking list ------*/
    const bookingList = () => {
        setLoader(true);
        let url = "/report/bookings";

        let data = {
            "customer_id": Number(id?.id),
            "game_type": index,
            "booking_type": bookingType?.value,
            "from_date": startDate,
            "to_date": endDate,
            "slot_id": slotId?.value,
            "scrip_id": scripId?.value,
            "trade_status": tradeType?.value,
            "winning_status": winningType?.value,
            "page_num": page,
            "page_size": perPage
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setBookingData(response.data.data);
                let remainder = response.data.number_of_records % perPage
                let quotient = response.data.number_of_records / perPage
                if (Number(remainder) > 0) {
                    setTotalLength(parseInt(Number(quotient) + 1))

                } else {
                    setTotalLength(Number(quotient))
                }
                setPaginationData({
                    rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                })
                setTotal(response.data.number_of_records);
                setTotalGainerLoser(response.data.total_data);
                setLoader(false);
            } else {
                setBookingData([]);
                setError(response.data.message);
                setTotalGainerLoser(null);
                setLoader(false);
            }
        });
    }

    /*------- get booking summary api ---------*/
    const getSummary = () => {
        setLoader(true)
        if (index === 1) {
            var url = "/report/m2e-booking-summary";
            //eslint-disable-next-line
            var data = {
                "from_date": summaryStartDate,
                "to_date": summaryEndDate,
                "scrip_id": scripIdSummary?.value,
                "customer_id": userId?.id,
                "page_num": spage,
                "page_size": perSPage
            }
        } else if (index === 2) {
            //eslint-disable-next-line
            var url = "/report/m2m-booking-summary";
            //eslint-disable-next-line
            var data = {
                "from_date": summaryStartDate,
                "to_date": summaryEndDate,
                "scrip_id": scripIdSummary?.value,
                "customer_id": userId?.id,
                "page_num": spage,
                "page_size": perSPage,
                "slot_id": slotId.value
            }
        } else {
            //eslint-disable-next-line
            var url = "/report/skill-race-booking-summary";
            //eslint-disable-next-line
            var data = {
                "from_date": summaryStartDate,
                "to_date": summaryEndDate,
                "scrip_id": scripIdSummary?.value,
                "customer_id": userId?.id,
                "page_num": spage,
                "page_size": perSPage,
                // "slot_id": slotId.value
            }
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                setTotal(response.data.number_of_records);
                let remainder = response.data.number_of_records % perSPage
                let quotient = response.data.number_of_records / perSPage
                if (Number(remainder) > 0) {
                    setTotalLength(parseInt(Number(quotient) + 1))

                } else {
                    setTotalLength(Number(quotient))
                }
                setTotals(response.data.total_data);
                // setTotalLength(response.data.number_of_records / perSPage)
                setSummaryData(response.data.data);
                setPaginationDataSummary({
                    rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                })
            } else {
                setLoader(false);
                setSummaryData([]);
            }
        })
    }

    /* --------- get total bookings ---------*/
    const getTotalBookings = (is_download) => {
        setLoader(true);
        set_btn_loader(true);
        var url = "/report/user-bookings";
        var data = {
            "game_type_id": index,
            "game_id": gameId?.value,
            "start_date": totalBookingStartDate,
            "end_date": totalBookingEndDate,
            "is_download": is_download ? is_download : false
        }
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                set_btn_loader(false);
                if (is_download) {
                    let a = document.createElement("a");
                    a.href = response.data.data.url;
                    document.body.appendChild(a);
                    a.click();
                    setCheck([])
                    document.body.removeChild(a);
                    setLoader(false)
                } else {
                    setTotalBookingData(response.data.data);
                    setTotalGainerLoser(response.data.total_data);
                    setLoader(false);
                }
                // response.data.game
            } else {
                set_btn_loader(false);
                setLoader(false);
                setTotalBookingData([]);
                setTotalGainerLoser(null)
            }
            let temp_options = []
            temp_options.push({ label: "All", value: 0 })
            for (const element of response.data.game_ids) {
                temp_options.push({ label: `Game id ${element}`, value: element })
            }
            setGameIds(temp_options)
        })
    }

    /*-------- get filters for booking ----*/
    const getScripFilter = (temp) => {
        let url = '/scrip/scrip-filter';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {

                let tArray = [{ value: null, label: 'All' }];
                for (let index = 0; index < response.data.data.length; index++) {
                    const element = response.data.data[index];
                    console.log(element)
                    tArray.push({ value: element.scrip_id, label: element.scrip_code })
                }
                setScripDataFilter(tArray);
            }
        })
    }


    const customDateSort = (a, b, selector, given) => {
        let format = given ? given : "DD MMM YYYY"
        if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
        else if (a[selector]) return -1;
        else if (b[selector]) return 1;
        else return 0;
    }

    const columns = [
        {
            name: 'Client ID',
            selector: "user_id",
            sortable: true,
            left: true,
            minWidth: "130px",
            cell: row => row.client_id
        },
        {
            name: 'Name',
            selector: "customer_name",
            sortable: true,
            left: true,
            minWidth: "220px",
            cell: row => <div className='e-name-link' onClick={() => openDetail(row)}>{row.customer_name}</div>
        },
        {
            name: 'Date',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row => <div className="d-flex flex-column"> {row.date} </div>,
            sortFunction: (a, b) => customDateSort(a, b, 'date'),
        },
        {
            name: 'Time',
            selector: 'time',
            sortable: false,
            left: true,
            maxWidth: "140px",
            cell: row => <div className="d-flex flex-column"> {row.time} </div>,
        },
        {
            name: 'Symbol',
            selector: 'scrip_code',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row =>
                <div>
                    {row.scrip_code}
                </div>
        },
        {
            name: 'Game ID',
            selector: row => row.game_id,
            sortable: false,
            left: true,
            minWidth: '150px',

        },
        {
            name: 'Slot',
            selector: row => row.slot_time,
            sortable: false,
            left: true,
            minWidth: '180px',
            omit: index === 1 || index === 3 ? true : false

        },
        {
            name: 'Prev. Close',
            selector: row => row.previous_close,
            sortable: true,
            left: true,
            minWidth: '160px',
        },
        {
            name: 'Closing price',
            selector: row => row.closing,
            sortable: true,
            left: true,
            minWidth: '150px',
        },
        {
            name: 'Change %',
            selector: row => row.closing_change,
            sortable: true,
            left: true,
            minWidth: '130px',
        },
        {
            name: 'Score',
            selector: row => row.score_points,
            sortable: true,
            left: true,
            minWidth: '120px',
        },
        {
            name: 'Gainer Qty',
            selector: row => Number(row.gainer_qty),
            sortable: true,
            left: true,
            minWidth: "150px",
            cell: row => row.gainer_qty !== 0 && row.gainer_qty ? row.gainer_qty : "-",
            omit: index === 3 ? true : false
        },
        {
            name: 'Loser Qty',
            selector: row => Number(row.looser_qty),
            sortable: true,
            left: true,
            minWidth: "150px",
            cell: row => row.looser_qty !== 0 && row.looser_qty ? row.looser_qty : "-",
            omit: index === 3 ? true : false
        },
        {
            name: 'Booking amount',
            selector: row => Number(row.amount),
            sortable: true,
            left: true,
            minWidth: "180px",
            cell: row => <div> {row.amount !== "-" && row.amount ? `₹ ${row.amount}` : "-"}</div>,
            omit: index === 3 ? true : false
        },
        {
            name: 'Trade status',
            selector: row => row.trade_status,
            sortable: false,
            left: true,
            minWidth: '120px',
            omit: index === 3 ? true : false
        },
        {
            name: 'Result date time',
            selector: row => row.result_datetime,
            sortable: false,
            left: true,
            minWidth: "180px",
            omit: index === 3 ? true : false
        },
        // {
        // 	name: 'WInning status',
        // 	selector: (row) => { return( <div>{row.is_winner === 1 ? 'Won' : 'Lost' }</div> )},
        // 	sortable: false,
        // 	left: true
        // },
        {
            name: 'Reward',
            // selector: 'amount',
            sortable: false,
            left: true,
            // width: "100px",
            cell: row => <div> {row.reward !== "-" ? `₹ ${row.reward}` : row.reward}</div>
        },

        // {
        // 	name: 'P/L Amount',
        // 	selector :  'p&l_amount',
        // 	sortable: false,
        // 	left: true,
        // },
        {
            name: 'TDS',
            selector: row => Number(row.tds),
            sortable: true,
            left: true,
            cell: row => row.tds !== 0 && row.tds ? row.tds : row.tds,
            omit: index === 3 ? true : false
        },
        {
            name: 'Platform fees',
            selector: row => parseFloat(row.platform_fees),
            cell: row => `₹ ${parseFloat(row.platform_fees)}`,
            sortable: true,
            left: true,
            minWidth: '160px',
            omit: index === 3 ? true : false
        },
        {
            name: 'Cancel date time',
            selector: row => row.cancelled_at,
            sortable: false,
            left: true,
            minWidth: "180px",
            omit: index === 3 ? true : false
        },
        // {
        //   name: 'Status',
        //   selector: 'status',
        //   sortable: false,
        //   left: true,
        //   width: "100px",
        //   cell: row => <div  className={row.status === "Pending" ? "e-status-pending" : row.status === "Success" ? "e-status-success": "e-status-failed"}>{row.status}</div>,
        // },
        // {
        //   name: 'Profit booking & amount',
        //   selector: 'profit',
        //   sortable: false,
        //   left: true,
        //   cell: row => <div className="d-flex flex-column"> {row.profit} <span className='e-wallet-historty-time text-uppercase'>{row.booking}</span> </div>
        // },
    ]

    const skillrace_columns = [
        {
            name: 'Client ID',
            selector: "user_id",
            sortable: true,
            left: true,
            minWidth: "130px",
            cell: row => row.client_id
        },
        {
            name: 'Name',
            selector: "customer_name",
            sortable: true,
            left: true,
            minWidth: "220px",
            cell: row => <div className='e-name-link' onClick={() => openDetail(row)}>{row.customer_name}</div>
        },
        {
            name: 'Date',
            // selector: row => <div className="d-flex flex-column"> {row.transaction_date.slice(0, 12)} <span className='e-wallet-historty-time pt-2'>{row.transaction_date.slice(12,)}</span> </div>,
            sortable: true,
            left: true,
            minWidth: "140px",
            sort: (a, b) => customDateSort(a, b, 'date'),
            cell: row => <div className="d-flex flex-column"> {row.date} <span className='e-wallet-historty-time pt-2'>{row.time}</span> </div>
        },
        {
            name: 'Symbol',
            // selector: 'scrip',
            sortable: false,
            left: true,
            minWidth: "180px",
            cell: row =>
                <div className="d-flex flex-column">
                    {row.scrip_code}
                </div>
        },
        {
            name: 'Game ID',
            selector: row => row.game_id,
            sortable: false,
            left: true,
            minWidth: '150px',

        },
        {
            name: ' Quantity',
            selector: 'quantity',
            sortable: false,
            left: true,
            cell: row => <div>{row.quantity}</div>
        },
        {
            name: ' Buy price',
            selector: row => row.buy_price,
            sortable: false,
            left: true,
            minWidth: "160px",
        },
        {
            name: ' Buy time',
            selector: row => row.buy_placed_time,
            sortable: false,
            left: true,
            minWidth: "160px",
        },
        {
            name: 'Sell price',
            selector: row => row.sell_price,
            sortable: false,
            left: true,
            minWidth: "160px",
        },
        {
            name: 'Sell time',
            selector: row => row.sell_placed_time,
            sortable: false,
            left: true,
        },
        {
            name: 'Returns %',
            selector: row => row.returns_percentage,
            sortable: false,
            left: true,
            minWidth: '130px'
        },
        {
            name: 'Trigger price',
            selector: row => "₹" + row.trigger_price,
            sortable: false,
            left: true,
            minWidth: '160px'
        },
        {
            name: 'SL',
            selector: row => row.stop_loss,
            sortable: false,
            left: true,
        },
        {
            name: 'Type',
            selector: row => row.order_type,
            sortable: false,
            left: true,
            minWidth: '160px'
        },
        {
            name: 'Result date',
            selector: row => row.result_date,
            sortable: false,
            left: true,
            minWidth: '160px'
        },
        {
            name: 'Result time',
            selector: row => row.result_time,
            sortable: false,
            left: true,
            minWidth: '160px'
        },

    ]

    const summaryColumns = [
        // {
        //     name: 'Client ID',
        //     selector: 'client_id',
        //     sortable: false,
        //     left: true,
        //     minWidth: "130px"
        // },
        // {
        //     name: 'Name',
        //     selector: 'client_name',
        //     sortable: false,
        //     left: true,
        //     minWidth: "140px",
        //     cell: row => <div className='e-name-link' onClick={() => navigate(`/user-detail?id=${row.user_id}`)}>{row.client_name}</div>
        // },
        {
            name: 'Scrip name',
            selector: 'scrip_code',
            sortable: true,
            left: true,
            minWidth: '160px'
        },

        {
            name: 'Gainer Qty',
            selector: row => Number(row.gainer_qty),
            sortable: true,
            left: true,
            minWidth: "160px",
        },
        {
            name: 'Loser Qty',
            selector: row => Number(row.loser_qty),
            sortable: true,
            left: true,
            minWidth: "160px",
        },
        {
            name: 'Booking amount',
            selector: row => Number(row.booking_amount),
            sortable: true,
            left: true,
            minWidth: "180px",
            cell: (row) => { return <div>{row.booking_amount ? `₹ ${row.booking_amount}` : "--"}</div> }
        },
        {
            name: 'Winning amount',
            selector: row => parseFloat(row.wining_amount),
            sortable: true,
            left: true,
            minWidth: "180px",
            cell: (row) => { return <div>{row.wining_amount ? `₹ ${row.wining_amount}` : "--"}</div> }
        },
        {
            name: 'P&L',
            selector: "p&l",
            sortable: false,
            left: true,
            minWidth: '120px',
            cell: (row) => <>
                {
                    row["p&l"] < 0 ?
                        <span style={{ color: "red" }}>
                            ₹ {row["p&l"]}
                        </span>
                        :
                        row["p&l"] !== "-" ?
                            <span style={{ color: "green" }}>
                                ₹ +{row["p&l"]}

                            </span>
                            :
                            "-"
                }
            </>
        },
        {
            name: 'Win Qty',
            selector: row => Number(row.win_qty),
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Lose Qty',
            selector: row => Number(row.lose_qty),
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Platform fee',
            selector: row => parseFloat(row.platform_fees),
            cell: row => `₹ ${parseFloat(row.platform_fees)}`,
            sortable: true,
            left: true,
            minWidth: '140px'
        },
        {
            name: 'TDS',
            selector: row => row.tds,
            sortable: false,
            left: true
        },

    ];

    const skillraceSummaryColumns = [
        // {
        //     name: 'Client ID',
        //     selector: 'client_id',
        //     sortable: false,
        //     left: true,
        //     minWidth: "130px"
        // },
        // {
        //     name: 'Name',
        //     selector: 'client_name',
        //     sortable: false,
        //     left: true,
        //     minWidth: "140px",
        //     cell: row => <div className='e-name-link' onClick={() => navigate(`/user-detail?id=${row.user_id}`)}>{row.client_name}</div>
        // },
        {
            name: 'Scrip name',
            selector: 'scrip_code',
            sortable: true,
            left: true,
            minWidth: '160px'
        },

        {
            name: 'Bookings',
            selector: row => Number(row.no_of_bookings),
            sortable: true,
            left: true,
            minWidth: "160px",
        },
        {
            name: 'Positive entries',
            selector: row => Number(row.number_of_positive_entry),
            sortable: true,
            left: true,
            minWidth: "180px",
            cell: (row) => { return <div>{row.number_of_positive_entry ? row.number_of_positive_entry : "--"}</div> }
        },
        {
            name: 'Negative entries',
            selector: row => parseFloat(row.number_of_negative_entry),
            sortable: true,
            left: true,
            minWidth: "180px",
        },
        {
            name: 'Booking amount',
            selector: row => Number(row.booking_amount),
            cell: row => "₹ " + Number(row.booking_amount),
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Buy entries',
            selector: row => Number(row.number_of_buy_entry),
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Sell entries',
            selector: row => parseFloat(row.number_of_sell_entry),
            sortable: true,
            left: true,
            minWidth: '140px'
        },

    ];

    const bookingColumns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            left: true,
            cell: row => <div className='e-name-link' onClick={() => openDetail(row)}>{row.name}</div>
        },
        {
            name: 'Gainer',
            selector: row => Number(row.gainer),
            sortable: true,
            left: true,
        },
        {
            name: 'Loser',
            selector: row => Number(row.loser),
            sortable: true,
            left: true,
        },
    ]

    const bookingSkillraceColumns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            left: true,
            cell: row => <div className='e-name-link' onClick={() => openDetail(row)}>{row.customer_name}</div>
        },
        {
            name: 'Client ID',
            selector: 'customer_id',
            sortable: true,
            left: true,
            minWidth: '100px',
            maxWidth: '100px',
        },
        {
            name: 'Joining date',
            selector: row => (row.joined_at),
            sortable: true,
            left: true,
            sortFunction: (a, b) => customDateSort(a, b, 'joined_at')
        },
        {
            name: 'Entries',
            selector: 'entries',
            sortable: false,
            left: true,
        },
        {
            name: 'Avg score',
            selector: row => (row.avg_score),
            sortable: true,
            left: true,
        },
        {
            name: 'Exited',
            cell: row => <>
                {
                    row.exited ?
                        "Yes"
                        :
                        "No"
                }
            </>
        }

    ]

    const handleChange = (row) => {
        let selected = []
        for (let i = 0; i < row.selectedRows.length; i++) {
            selected.push(row.selectedRows[i]);
        }
        setCheck(selected);
        setDownloadLoader(false)
    }

    const bookingTypeOption = [
        { value: null, label: 'All' },
        { value: 1, label: 'Gainer' },
        { value: 2, label: 'Loser' },
    ]

    const skillraceTardeOption = [
        { value: null, label: 'All' },
        { value: 1, label: 'Open' },
        { value: 2, label: 'Completed' },
        { value: 3, label: 'Pending' },
    ]

    const statusOption = [
        { value: null, label: 'All' },
        { value: true, label: 'Won' },
        { value: false, label: 'Lost' }
    ]

    const tradeOption = [
        { value: null, label: 'All' },
        { value: 1, label: 'Open' },
        { value: 2, label: 'Won' },
        { value: 3, label: 'Lost' },
        { value: 4, label: 'Cancelled' },
    ]

    const resetFilters = () => {
        setGameType({ value: 1, label: 'M2E' });
        setStartDate(null);
        setEndDate(null);
        setScripId(scripDataFilter[0])
        setBookingType({ value: null, label: 'All' });
        setWinningType({ value: null, label: 'All' });
        setScripId({ value: null, label: 'All' });
        setId({ id: 0 });
        setSearch("");
        setSlotId(slotOptions[0])
    }

    const resetFiltersSummary = () => {
        setSummaryStartDate(null);
        setSummaryEndDate(null);
        setScripIdSummary(scripDataFilter[0]);
        setUserId({ id: 0 });
        setSearch("");
        setSlotId(slotOptions[0])
    }

    const getCustomersList = () => {
        var url = "/general/customer-list-filter";

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let temp_options = []
                temp_options.push({ label: "All", value: 0 })
                response.data.data.map((item) => {
                    return (
                        temp_options.push({
                            label: item.customer_name,
                            value: item.customer_id
                        })
                    )
                })
                setCustomerData(temp_options)
            }
        })
    }

    const openDetail = (row) => {
        window.open(`/user-detail?id=${row.user_id}`, "_blank");
    }

    function convertArrayOfObjectsToCSV(data) {
        setDownloadLoader(true)
        // Empty array for storing the values
        var csvRows = [];

        // Headers is basically a keys of an
        // object which is id, name, and
        // profession
        if (tabIndex === 1) {
            if (index !== 3) {
                //eslint-disable-next-line
                var titles = [
                    'Name',
                    'Customer ID',
                    'Date',
                    'Time',
                    'Game ID',
                    'Symbol',
                    'Gainer Qty',
                    'Loser Qty',
                    'Booking amount',
                    'Trade status',
                    'Result date time',
                    'Reward',
                    'TDS',
                    'Platform fees',
                    'Cancel date time'

                ];
                if (index === 2) {
                    titles.push('Slot')
                }
            } else {
                //eslint-disable-next-line
                var titles = [
                    'Name',
                    'Customer ID',
                    'Date',
                    'Symbol',
                    'Quantity',
                    'Buy price',
                    'Buy time',
                    'Sell price',
                    'Sell time',
                    'Returns %',
                    'Trigger price',
                    'SL',
                    'Type',
                    'Result date',
                    'Result time',
                ];
            }
        } else if (tabIndex === 2) {
            if (index !== 3) {
                //eslint-disable-next-line
                var titles = [
                    'Scrip name',
                    'Booking amount',
                    'Winning amount',
                    'P&L',
                    'Platform fee',
                    'TDS',
                    'Gainer Qty',
                    'Loser Qty',
                    'Win Qty',
                    'Lose Qty',
                    'Platform fee'

                ];
            } else {
                //eslint-disable-next-line
                var titles = [
                    'Scrip name',
                    'Bookings',
                    'Positive entries',
                    'Negative entries',
                    'Booking amount',
                    'Buy entries',
                    'Sell entries',
                ];
            }
        } else {
            if (index !== 3) {
                //eslint-disable-next-line
                var titles = [
                    'Name',
                    'Gainer',
                    "Loser"
                ]
            } else {
                //eslint-disable-next-line
                var titles = [
                    'Name',
                    'Custome ID',
                    "Date",
                    "Entries",
                    "Avg. score",
                    "Exited"
                ]
            }
        }

        const headers = titles
        // As for making csv format, headers
        // must be separated by comma and
        // pushing it into array
        csvRows.push(headers.join(','));

        // Pushing Object values into array
        // with comma separation
        for (let i = 0; i < data.length; i++) {
            if (tabIndex === 1) {
                if (index !== 3) {
                    //eslint-disable-next-line
                    var values = [
                        data[i]['customer_name'],
                        data[i]['client_id'],
                        data[i]['date'],
                        data[i]['time'],
                        data[i]['game_id'],
                        data[i]['scrip_code'],
                        data[i]['gainer_qty'],
                        data[i]['looser_qty'],
                        data[i]['amount'],
                        data[i]['trade_status'],
                        data[i]['result_datetime'],
                        data[i]['reward'],
                        data[i]['tds'],
                        data[i]['platform_fees'],
                        data[i]['cancelled_at']
                    ]
                    if (index === 2) {
                        values.push(data[i]["slot_time"])
                    }

                } else {
                    //eslint-disable-next-line
                    var values = [data[i]['customer_name'],
                    data[i]['client_id'],
                    data[i]['date'],
                    data[i]['scrip_code'],
                    data[i]['quantity'],
                    data[i]['buy_price'],
                    data[i]['buy_placed_time'],
                    data[i]['sell_price'],
                    data[i]['sell_placed_time'],
                    data[i]['returns_percentage'],
                    data[i]['trigger_price'],
                    data[i]['stop_loss'],
                    data[i]['order_type'],
                    data[i]['result_date'],
                    data[i]['result_time'],
                    ]
                }
            } else if (tabIndex === 2) {
                if (index !== 3) {
                    //eslint-disable-next-line
                    var values = [
                        data[i]['scrip_code'],
                        data[i]['booking_amount'],
                        data[i]['wining_amount'],
                        data[i]['p&l'],
                        data[i]['platform_fees'],
                        data[i]['tds'],
                        data[i]['gainer_qty'],
                        data[i]['loser_qty'],
                        data[i]['win_qty'],
                        data[i]['lose_qty'],
                        data[i]['platform_fees']
                    ]
                } else {
                    //eslint-disable-next-line
                    var values = [
                        data[i]['scrip_code'],
                        data[i]['no_of_bookings'],
                        data[i]['number_of_positive_entry'],
                        data[i]['number_of_negative_entry'],
                        data[i]['booking_amount'],
                        data[i]['number_of_buy_entry'],
                        data[i]['number_of_sell_entry']
                    ]
                }
            } else {
                if (index !== 3) {
                    //eslint-disable-next-line  
                    var values = [
                        data[i]['name'],
                        data[i]['gainer'],
                        data[i]['loser']
                    ]
                } else {
                    //eslint-disable-next-line
                    var values = [
                        data[i]['name'],
                        data[i]['customer_id'],
                        data[i]['joined_at'],
                        data[i]['entries'],
                        data[i]['exited']
                    ]
                }
            }

            csvRows.push(values.join(","))
        }
        // Returning the array joining with new line
        setCheck([])
        return csvRows.join('\n')


    }

    function downloadCSV(data) {
        setDownloadLoader(true)
        // Creating a Blob for having a csv file format
        // and passing the data with type
        setCheck([])
        const blob = new Blob([data], { type: 'text/csv' });

        // Creating an object for downloading url
        const url = window.URL.createObjectURL(blob)

        // Creating an anchor(a) tag of HTML
        const a = document.createElement('a')

        // Passing the blob downloading url
        a.setAttribute('href', url)

        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute('Bookings', 'Bookings.csv');

        // Performing a download with click
        a.click()


    }

    const downloadBookings = () => {
        setDownloadLoader(true);
        set_btn_loader(true);
        let url = "/user/report/booking";

        let data = {
            "user_id": Number(id?.id),
            "game_type": index,
            "booking_type": bookingType?.value,
            "start_date": startDate,
            "end_date": endDate,
            "scrip_id": scripId?.value,
            "trade_status": tradeType?.value,
            "slot_id": slotId?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                setCheck([])
                document.body.removeChild(a);
                setDownloadLoader(false);
                set_btn_loader(false);
            } else {
                set_btn_loader(false);
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }

    const downloadSummary = () => {
        setDownloadLoader(true);
        set_btn_loader(true);
        if (index === 1) {
            var url = "/user/report/m2e-booking ";
        } else if (index === 2) {
            //eslint-disable-next-line
            var url = "/user/report/m2m-booking ";
        } else {
            //eslint-disable-next-line
            var url = "/report/skill-race-booking-summary";

        }

        if (index === 1) {
            var data = {
                "user_id": userId?.id,
                "from_date": summaryStartDate,
                "to_date": summaryEndDate,
                "scrip_id": scripIdSummary?.value,
            }
        } else if (index === 2) {
            //eslint-disable-next-line
            var data = {
                "user_id": userId?.id,
                "from_date": summaryStartDate,
                "to_date": summaryEndDate,
                "scrip_id": scripIdSummary?.value,
                "slot_id": slotId?.value
            }
        } else {
            //eslint-disable-next-line
            var data = {
                "from_date": summaryStartDate,
                "to_date": summaryEndDate,
                "scrip_id": scripIdSummary?.value,
                "user_id": userId?.id,
                "page_num": spage,
                "page_size": perSPage,
                "is_download": true
                // "slot_id": slotId.value
            }
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                set_btn_loader(false);
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
                setCheck([]);
            } else {
                set_btn_loader(false);
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }
    return (
        <>
            <div className='e-tab-position'>
                <GameTabs data={
                    ['M2E', 'M2M', 'Skillrace']
                }
                    index={index}
                    setTab={(temp) => {
                        setIndex(temp);
                        setPage(1);
                        setSPage(1);
                        setPerPage(200);
                        setPerSPage(200);
                        setCheck([])
                        setSlotId(slotOptions[0]);
                        set_btn_loader(false);
                        setSearch("");
                        setCheck([]);
                        setFilterDown(false);
                        setTotalLength(null);
                        setStartDate(null);
                        setEndDate(null);
                        setSummaryEndDate(null);
                        setSummaryStartDate(null);
                        setTotalBookingStartDate(null);
                        setTotalBookingEndDate(null);
                        setGameId(gameIds[0]);
                    }}
                    type={true} />
            </div>
            <div className='e-users-container e-pagination'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card e-dashboard-card border-0">
                            <div className="row pt-4">

                                <div className="col-lg-6 pt-2 ps-4 mt-1 col-8">
                                    <GameTabs
                                        index={tabIndex}
                                        data={[
                                            'All bookings',
                                            'Total Bookings Summary',
                                            'Total bookings'
                                        ]}
                                        setTab={(temp) => {
                                            setTabindex(temp);
                                            setSearch("");
                                            setCheck([]);
                                            setFilterDown(false);
                                            setTotalLength(null);
                                            setStartDate(null);
                                            setEndDate(null);
                                            setSummaryEndDate(null);
                                            setSummaryStartDate(null);
                                            setTotalBookingStartDate(null);
                                            setTotalBookingEndDate(null);
                                            setSlotId(slotOptions[0]);
                                            set_btn_loader(false);
                                            setGameId(gameIds[0]);
                                        }} />
                                    {/* <h6 className="e-card-title ps-3">All bookings</h6> */}
                                </div>
                                <div className="col-lg-2 offset-lg-3 col-2 text-end">
                                    <DownloadBtn
                                        check={['check']}
                                        name={"Download report"}
                                        loaderClass={btn_loader ? "e-bounce" : ""}
                                        className={btn_loader ? "e-disabled" : ""}
                                        onClick={() => {
                                            if (tabIndex === 1) {
                                                if (check.length === 0 || check.length === bookingData.length) {
                                                    downloadBookings()
                                                } else {
                                                    const csvData = convertArrayOfObjectsToCSV(check)
                                                    downloadCSV(csvData)
                                                }
                                            } else if (tabIndex === 2) {

                                                if (check.length === 0 || check.length === summaryData.length) {
                                                    downloadSummary()
                                                } else {
                                                    const csvData = convertArrayOfObjectsToCSV(check)
                                                    downloadCSV(csvData)
                                                }

                                            } else {
                                                if (check.length === 0 || check.length === totalBookingData.length) {
                                                    getTotalBookings(true)
                                                } else {
                                                    const csvData = convertArrayOfObjectsToCSV(check)
                                                    downloadCSV(csvData)
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-lg-1 col-1">
                                    <FilterBtn
                                        className={
                                            filterDown ?
                                                "e-filter-btn e-active"
                                                :
                                                "e-filter-btn"
                                        }
                                        handleClick={() => {
                                            setFilterDown(!filterDown)
                                            if (filterDown === true) {
                                                if (tabIndex === 1) {
                                                    resetFilters();
                                                } else {
                                                    resetFiltersSummary();
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                {
                                    filterDown ?
                                        <div className='row pe-0'>
                                            <div className='col-lg-12 px-0'>
                                                <div>
                                                    {
                                                        tabIndex === 1 ?
                                                            <div className='e-filter-wraper mt-3 pt-0'>
                                                                <>
                                                                    {/* <div className='e-filter d-inline-block'>
                                                                        <label className='mb-2'>Game type</label>
                                                                        <SelectBox
                                                                            className="me-2 e-bookings-sort"
                                                                            options={selectOptions}
                                                                            value={gameType}
                                                                            selectchange={(temp) => { setGameType(temp); }}
                                                                            isSearchable={false}
                                                                            placeholder="Game type"
                                                                        />
                                                                    </div> */}
                                                                    <div className='e-filter  d-inline-block'>
                                                                        <label className='mb-2'>Scrip </label>
                                                                        <SelectBox
                                                                            className="me-2 e-bookings-sort"
                                                                            options={scripDataFilter}
                                                                            value={scripId}
                                                                            selectchange={(temp) => { setScripId(temp); }}
                                                                            isSearchable={true}
                                                                            placeholder="Scrip"
                                                                        />
                                                                    </div>
                                                                    {
                                                                        index === 2 ?
                                                                            <div className='e-filter d-inline-block e-m2m-slot'>
                                                                                <label className='mb-2'>Slot</label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={slotOptions}
                                                                                    value={slotId}
                                                                                    selectchange={(temp) => { setSlotId(temp); }}
                                                                                    isSearchable={false}
                                                                                    placeholder="Select "
                                                                                />
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }

                                                                    <div className='e-filter  d-inline-block e-bookings-input me-2'>
                                                                        <label className='mb-2'>Customer</label>
                                                                        {/* <SelectBox
                                                                        isSearchable={true}
                                                                        className="me-2 e-bookings-sort"
                                                                        defaultValue={customerData[0]}
                                                                        options={customerData}
                                                                        selectchange={(temp) => {
                                                                            setId(temp?.value)
                                                                        }}
                                                                    /> */}
                                                                        <Search
                                                                            value={id?.id === 0 ? {} : id}
                                                                            search={search}
                                                                            setSearch={(temp) => {
                                                                                setSearch(temp);
                                                                                if (temp === "") {
                                                                                    setId({
                                                                                        id: 0
                                                                                    });
                                                                                }
                                                                            }}
                                                                            userDetail={(temp) => setId(temp)} />
                                                                    </div>
                                                                    {
                                                                        index === 3 ?
                                                                            <div className='e-filter  d-inline-block'>
                                                                                <label className='mb-2'>Winning  status</label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={statusOption}
                                                                                    value={winningType}
                                                                                    selectchange={(temp) => { setWinningType(temp); }}
                                                                                    isSearchable={false}
                                                                                    placeholder="Winning  status"
                                                                                />
                                                                            </div>
                                                                            :
                                                                            <div className='e-filter  d-inline-block'>
                                                                                <label className='mb-2'>Booking type</label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={bookingTypeOption}
                                                                                    value={bookingType}
                                                                                    selectchange={(temp) => { setBookingType(temp); }}
                                                                                    isSearchable={false}
                                                                                    placeholder="Booking  type"
                                                                                />
                                                                            </div>
                                                                    }

                                                                    <div className='e-filter  d-inline-block'>
                                                                        <label className='mb-2'>Trade  status</label>
                                                                        <SelectBox
                                                                            className="me-2 e-bookings-sort"
                                                                            options={index !== 3 ? tradeOption : skillraceTardeOption}
                                                                            value={tradeType}
                                                                            selectchange={(temp) => { setTradeType(temp); }}
                                                                            isSearchable={false}
                                                                            placeholder="Winning  status"
                                                                        />
                                                                    </div>
                                                                    <div className='mt-4 pt-2 e-filter  d-inline-block'>
                                                                        <label className='mb-2'>{
                                                                            "Filter by game creation date"
                                                                        }</label>
                                                                        <Rangepicker
                                                                            placeholder={['From', 'To']}
                                                                            start={startDate}
                                                                            end={endDate}
                                                                            disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                            onDatesChange={(start, end) => {
                                                                                setStartDate(start);
                                                                                setEndDate(end);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </>
                                                            </div>
                                                            :
                                                            tabIndex === 2 ?
                                                                <div className='e-filter-wraper mt-3 pt-4'>
                                                                    <div className='row mt-2'>
                                                                        <div className='col-lg-2'>
                                                                            <label className='mb-2'>Scrip</label>
                                                                            <SelectBox
                                                                                className="me-2 e-bookings-sort"
                                                                                options={scripDataFilter}
                                                                                value={scripIdSummary}
                                                                                selectchange={(temp) => { setScripIdSummary(temp); }}
                                                                                isSearchable={true}
                                                                                placeholder="Scrip"
                                                                            />
                                                                        </div>
                                                                        {
                                                                            index === 2 ?
                                                                                <div className='col-lg-2 e-m2m-slot'>
                                                                                    <label className='mb-2'>Slot</label>
                                                                                    <SelectBox
                                                                                        className="me-2 e-bookings-sort"
                                                                                        options={slotOptions}
                                                                                        value={slotId}
                                                                                        selectchange={(temp) => { setSlotId(temp); }}
                                                                                        isSearchable={false}
                                                                                        placeholder="Select "
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                        <div className='col-lg-2 e-bookings-input me-3'>
                                                                            <label className='mb-2'>Customer</label>
                                                                            <Search
                                                                                value={userId?.id === 0 ? {} : userId}
                                                                                search={search}
                                                                                setSearch={(temp) => {
                                                                                    setSearch(temp);
                                                                                    if (temp === "") {
                                                                                        setUserId({
                                                                                            id: 0
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                userDetail={(temp) => setUserId(temp)} />
                                                                        </div>
                                                                        <div className='col-lg-4 e-accordion-date ps-0'>
                                                                            <label className='mb-2'>{
                                                                                index === 1 ?
                                                                                    "Game result date"
                                                                                    :
                                                                                    "Filter by game creation date"
                                                                            }</label>
                                                                            <Rangepicker
                                                                                start={summaryStartDate}
                                                                                disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                                end={summaryEndDate}
                                                                                onDatesChange={(start, end) => {
                                                                                    setSummaryStartDate(start);
                                                                                    setSummaryEndDate(end);
                                                                                }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='e-filter-wraper mt-3 pt-4'>
                                                                    <div className='row mt-2'>
                                                                        {/* <div className='col-lg-2'>
                                                                            <label className='mb-2'>Game type</label>
                                                                            <SelectBox
                                                                                className="me-2 e-bookings-sort"
                                                                                options={selectOptions}
                                                                                value={selectedGameType}
                                                                                selectchange={(temp) => { setSelectedGameType(temp); }}
                                                                                isSearchable={false}
                                                                                placeholder="Game type"
                                                                            />
                                                                        </div> */}
                                                                        <div className='col-lg-2'>
                                                                            <label className='mb-2'>Game</label>
                                                                            <SelectBox
                                                                                className="me-2 e-bookings-sort"
                                                                                options={gameIds}
                                                                                value={gameId}
                                                                                selectchange={(temp) => { setGameId(temp); }}
                                                                                isSearchable={true}
                                                                                placeholder="Game id"
                                                                            />
                                                                        </div>
                                                                        <div className='col-lg-4 e-accordion-date ps-0'>
                                                                            <label className='mb-2'>{
                                                                                index === 1 ?
                                                                                    "Game result date"
                                                                                    :
                                                                                    "Game date"
                                                                            }</label>
                                                                            <Rangepicker
                                                                                start={totalBookingStartDate}
                                                                                disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                                                                end={totalBookingEndDate}
                                                                                onDatesChange={(start, end) => {
                                                                                    setTotalBookingStartDate(start);
                                                                                    setTotalBookingEndDate(end);
                                                                                }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                <div className="col-lg-12 e-booking-table pt-3">
                                    {
                                        loader ?
                                            <Loader />
                                            :
                                            tabIndex === 1 ?
                                                bookingData.length > 0 ?
                                                    <>
                                                        <DataTable
                                                            pagination={true}
                                                            columns={index !== 3 ? columns : skillrace_columns}
                                                            data={bookingData}
                                                            selectableRows={true}
                                                            selectRows={(row) => {
                                                                handleChange(row);
                                                            }}
                                                            size={perPage}
                                                            // onChangePage={(p) => console.log(p)}
                                                            onChangeRowsPerPage={(p) => {
                                                                setPage(1)
                                                                setPerPage(p)
                                                            }}
                                                            paginationComponentOptions={paginationData}
                                                            paginationTotalRows={total}
                                                            paginationIconNext={totalLength === page ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setPage(page + 1)} style={{ cursor: "pointer" }} />}
                                                            paginationIconPrevious={page === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPage(page - 1) }} />}
                                                            clearSelectedRows={downloadLoader}
                                                        />
                                                        <div className='col-lg-12 text-end pe-3'>
                                                            <p>Page no : <span style={{ fontWeight: "600" }}>{page}</span></p>
                                                        </div>
                                                        <div className='row px-4 pt-3 pb-3'>
                                                            {
                                                                index !== 3 ?
                                                                    <div className='row px-4 pb-3 pt-4'>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total booking amount</h6>
                                                                            <p>₹{totalGainerLoser?.total_booking_amount}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total gainer qty</h6>
                                                                            <p>{totalGainerLoser?.total_gainer_qty}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total loser qty</h6>
                                                                            <p>{totalGainerLoser?.total_looser_qty}</p>
                                                                        </div>
                                                                        <div className='col-lg-1 px-0'>
                                                                            <h6>Total p&l </h6>
                                                                            <p>{totalGainerLoser?.total_p_l}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total platform fees </h6>
                                                                            <p>₹{totalGainerLoser?.total_platform_fees}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total rewards</h6>
                                                                            <p>₹{totalGainerLoser?.total_reward}</p>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            <h6>Total tds</h6>
                                                                            <p>{totalGainerLoser?.total_tds}</p>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='row px-4 pb-3 pt-4'>
                                                                        <div className='col-lg-6'>
                                                                            <h6>Total booking amount</h6>
                                                                            <p>₹{totalGainerLoser?.total_booking_amount}</p>
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <h6>Total qty</h6>
                                                                            <p>₹{totalGainerLoser?.total_quantity}</p>
                                                                        </div>

                                                                    </div>
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <EmptyNote img={Nodata} data="No data found" />
                                                :
                                                tabIndex === 2 ?
                                                    summaryData.length > 0 ?
                                                        <>
                                                            <DataTable
                                                                pagination={true}
                                                                columns={index === 3 ? skillraceSummaryColumns : summaryColumns}
                                                                data={summaryData}
                                                                selectableRows={true}
                                                                onChangeRowsPerPage={(p) => {
                                                                    setSPage(1)
                                                                    setPerSPage(p)
                                                                }}
                                                                selectRows={(row) => {
                                                                    handleChange(row);
                                                                }}
                                                                size={perSPage}
                                                                paginationComponentOptions={paginationDataSummary}
                                                                paginationTotalRows={total}
                                                                paginationIconNext={totalLength === spage ? <img src={Next} alt="" width="26px" style={{ opacity: "0.5" }} /> : <img src={Next} alt="" width="26px" onClick={() => setSPage(spage + 1)} style={{ cursor: "pointer" }} />}
                                                                paginationIconPrevious={spage === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.5" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setSPage(spage - 1) }} />}
                                                                // rowClick={(row) => {
                                                                //     navigate(`/user-detail?id=${row.user_id}`);
                                                                // }}
                                                                clearSelectedRows={downloadLoader}
                                                            />
                                                            <div className='col-lg-12 text-end pe-3'>
                                                                <p>Page no : <span style={{ fontWeight: "600" }}>{spage}</span></p>
                                                            </div>
                                                            {
                                                                index !== 3 ?
                                                                    <div className='row px-3 pb-3 pt-4'>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total booking amount</h6>
                                                                            <p>₹{totals?.total_booking_amount}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total gainer qty</h6>
                                                                            <p>{totals?.total_gainer_qty}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total loser qty</h6>
                                                                            <p>{totals?.total_looser_qty}</p>
                                                                        </div>
                                                                        <div className='col-lg-1 px-0'>
                                                                            <h6>Total p&l </h6>
                                                                            <p>{totals?.total_p_l}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total platform fees </h6>
                                                                            <p>₹{totals?.total_platform_fees}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total rewards</h6>
                                                                            <p>₹{totals?.total_reward}</p>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            <h6>Total tds</h6>
                                                                            <p>{totals?.total_tds}</p>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='row px-3 pb-3 pt-4'>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total booking amount</h6>
                                                                            <p>₹{totals?.total_booking_amount}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total bookings qty</h6>
                                                                            <p>{totals?.total_bookings_qty}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total positive entries</h6>
                                                                            <p>{totals?.total_positive_entries}</p>
                                                                        </div>
                                                                        <div className='col-lg-2 px-0'>
                                                                            <h6>Total negative entries </h6>
                                                                            <p>{totals?.total_negative_entries}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total buy entries </h6>
                                                                            <p>{totals?.total_buy_entries}</p>
                                                                        </div>
                                                                        <div className='col-lg-2'>
                                                                            <h6>Total sell entries</h6>
                                                                            <p>{totals?.total_sell_entries}</p>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </>
                                                        :
                                                        <EmptyNote img={Nodata} data="No data found" />
                                                    :

                                                    totalBookingData.length > 0 ?
                                                        <>
                                                            <DataTable
                                                                pagination={true}
                                                                columns={index === 3 ? bookingSkillraceColumns : bookingColumns}
                                                                data={totalBookingData}
                                                                selectableRows={true}
                                                                selectRows={(row) => {
                                                                    handleChange(row);
                                                                }}

                                                                // rowClick={(row) => {
                                                                //     navigate(`/user-detail?id=${row.user_id}`);
                                                                // }}
                                                                clearSelectedRows={downloadLoader}
                                                            />

                                                            {
                                                                index !== 3 ?
                                                                    <div className='row px-3 pt-3 pb-3'>
                                                                        <div className='col-lg-4'>
                                                                            <h6 className='d-inline'>Total </h6>
                                                                        </div>
                                                                        <div className='col-lg-4 px-3'>
                                                                            <p className='d-inline ms-4'>{totalGainerLoser?.total_gainer}</p>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <p className='d-inline ms-3'>{totalGainerLoser?.total_loser}</p>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='row px-3 pt-3 pb-3'>
                                                                        <div className='col-lg-3'>
                                                                            <h6 className='d-inline'>Total </h6>
                                                                        </div>
                                                                        <div className='col-lg-3 px-3'>
                                                                            <p className='d-inline ms-4'>{totalGainerLoser?.total_avg_score}</p>
                                                                        </div>
                                                                        <div className='col-lg-3'>
                                                                            <p className='d-inline ms-3'>{totalGainerLoser?.total_open_entries}</p>
                                                                        </div>
                                                                        <div className='col-lg-3'>
                                                                            <p className='d-inline ms-3'>{totalGainerLoser?.total_closed_entries}</p>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </>
                                                        :
                                                        <EmptyNote img={Nodata} data="No data found" />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index;
