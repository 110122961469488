/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for dashboard container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 11-11-2021
 */

/*import packages*/
import { useState, useEffect, useRef } from 'react';

import moment from 'moment';



/*import assets*/
import Revenue from '../../assets/images/card/summary/revenue.svg';
import Users from '../../assets/images/card/summary/users.svg';
import Platform from '../../assets/images/card/summary/platform.svg';
import Nonforecast from '../../assets/images/card/summary/non-forecast.svg';
import Winners from '../../assets/images/card/summary/winners.svg';
import Fees from '../../assets/images/card/summary/fee.svg';
import Active from '../../assets/images/card/summary/active.svg';
import Units from '../../assets/images/card/summary/units.svg';
import Taxes from '../../assets/images/card/summary/taxes.svg';
import Referral from '../../assets/images/card/summary/referral.svg';

/*import components*/
import { Summary } from '../../components/card/index';
import { BarChart, LineChart, PieChart } from '../../components/chart/index';
import SelectBox from '../../components/dropdown/selectBox';
import Loader from '../../components/loader/loader';
import DatePicker from '../../components/input/date';

/*import services*/
import service from '../../services/service';


const Index = (props) => {
    const wrapperRef = useRef(null)
    const wrapRef = useRef(null)
    // var today = moment(new Date());
    // var newDate = today.format("YYYY-MM-DD")
    //eslint-disable-next-line
    const [error, setError] = useState(null);
    const [dashboardData, setDashboardData] = useState({});
    const [loader, setLoader] = useState(false);
    const [timeFilter, setShowTimeFilter] = useState(false);
    const [secondTimeFilter, setShowSecondTimeFilter] = useState(false);
    const [dashbaordFilter, setDashboardFilter] = useState([]);
    const [id, setId] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [filterId, setFilterId] = useState(null);
    const [selectedGameType, setSelectedGameType] = useState({ label: "All Games", value: 0 });
    const [graphtimeFilter, setShowGraphTimeFilter] = useState(false);
    const [selectedGraphGameType, setSelectedGraphGameType] = useState({ label: "All", value: null });
    const [secondGraphTimeFilter, setShowSecondGraphTimeFilter] = useState(false);
    const [graphId, setGraphId] = useState(null);
    const [selectedGraphFilter, setSelectedGraphFilter] = useState("All");
    const [filterGraphId, setFilterGraphId] = useState(null);
    const [range, setRange] = useState({ start: "", end: "" })
    const [graphRange, setGraphRange] = useState({ start: "", end: "" });
    const [revenueData, setRevenueData] = useState({});
    const [avgRevenueData, setAvgRevenueData] = useState({});
    const [userData, setUserData] = useState({});
    const [dates, setDates] = useState({
        checkin: "",
        checkout: ""
    })
    const [graphdates, setGraphDates] = useState({
        checkin: "",
        checkout: ""
    })

    const [open, setOpen] = useState(false)
    const [graphOpen, setGrpahOpen] = useState(false);
    const [seriesData, setSeriesData] = useState([]);
    const [barChartData, setBarChartData] = useState([]);


    useEffect(() => {
        if (dates.checkin !== "" && dates.checkout !== "") {
            setOpen(false)
            setSelectedFilter("")
            let start = moment(dates.checkin).format("YYYY-MM-DD")
            let end = moment(dates.checkout).format("YYYY-MM-DD")
            setRange({
                start: start,
                end: end
            });
            setShowTimeFilter(false)
        }
    }, [dates.checkin, dates.checkout])

    useEffect(() => {
        if (graphdates.checkin !== "" && graphdates.checkout !== "") {
            setSelectedGraphFilter("")
            setGrpahOpen(false)
            let start = moment(graphdates.checkin).format("YYYY-MM-DD")
            let end = moment(graphdates.checkout).format("YYYY-MM-DD")
            setGraphRange({
                start: start,
                end: end
            })
            setShowGraphTimeFilter(false)
        } else {
            setGraphRange({
                start: "",
                end: ""
            });
        }
    }, [graphdates.checkin, graphdates.checkout])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getDasboardFilter();
    }, []);

    useEffect(() => {
        getDasboardData();
        //eslint-disable-next-line
    }, [filterId, selectedGameType.value, range.end]);

    useEffect(() => {
        getAnalaytics();
        //eslint-disable-next-line
    }, [filterGraphId, selectedGraphGameType.value, graphRange.end]);

    const _closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowTimeFilter(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);

    const _closeMenuGraph = (event) => {
        if (wrapRef.current && !wrapRef.current.contains(event.target)) {
            setShowGraphTimeFilter(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', _closeMenuGraph);
        return () => {
            document.removeEventListener('mouseup', _closeMenuGraph);
        };
    }, []);

    const gameOptions = [
        {
            value: 0, label: 'All Games'
        },
        {
            value: 1, label: 'M2E'
        },
        {
            value: 2, label: 'M2M'
        },
        {
            value: 3, label: 'Skill race'
        },
        {
            value: 4, label: 'V2R',
        },
    ]

    /* ---- get dashboard data ---*/
    const getDasboardData = () => {
        setLoader(true);
        let url = "/dashboard/overview";
        let data = {
            "filter_id": id,
            "start_date": range.start === "" ? null : range.start,
            "end_date": range.end === "" ? null : range.end,
            "filter_sub_id": filterId,
            "game_type": selectedGameType?.value
        }
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setDashboardData(response.data.data);
                setLoader(false);
            }
            else {
                setError(response.data.message);
                setLoader(false);
            }
        });
    }


    /* ---- get dashboard filter ---*/
    const getDasboardFilter = () => {

        let url = "/dashboard/filter-data";

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setDashboardFilter(response.data.data);
            }
            else {
                setError(response.data.message);
            }
        });
    }

    /* get analytics data */
    const getAnalaytics = () => {
        setRevenueData({});
        setSeriesData([]);
        setBarChartData([]);
        setUserData({});
        setAvgRevenueData({});
        let url = "/dashboard/analytics";

        let data = {
            "filter_id": graphId,
            "filter_sub_id": filterGraphId,
            "start_date": graphRange.start === "" ? null : graphRange.start,
            "end_date": graphRange.end === "" ? null : graphRange.end,
            "game_type": selectedGraphGameType?.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let temp_series = []
                response.data.data.revenue_data.series.map((item, key) => {
                    return (
                        temp_series.push({
                            name: item.name,
                            type: "area",
                            data: item.data,
                        })
                    )
                })
                setSeriesData(temp_series)
                setRevenueData(response.data.data.revenue_data);
                setUserData(response.data.data.user_data);
                let temp_user_series = []
                response.data.data.user_data.series.map((item, key) => {
                    return (
                        temp_user_series.push({
                            name: item.name,
                            data: item.data,
                        })
                    )
                })
                setBarChartData(temp_user_series)
                setAvgRevenueData(response.data.data.avg_revenue_data);
            } else {
                setRevenueData({});
                setUserData({});
                setAvgRevenueData({});
            }
        });
    }


    const chartColors = [
        '#258CF4',
        '#9C1FE9',
        '#F5B277',
        "#d97910"
    ]


    const m2mChartColors = [
        '#9C1FE9',
    ]

    const srChartColors = [
        '#F5B277',
    ]

    const v2rChartColors = [
        "#d97910"
    ]

    const pieChartColors = ['#F5B277', "#9C1FE9",]

    return (
        <>
            <div className='row'>
                <div >
                    <div className='col-lg-3'>
                        <h3 className='e-header-title'>Overview</h3>
                    </div>
                    <div className='col-lg-4'>
                        <div className="e-dashboard-select">

                            <div className='positon-relative d-flex'>

                                <div className='e-dashbaord-dropdown' onClick={() => setShowTimeFilter(!timeFilter)}>
                                    {selectedFilter ? selectedFilter : dates.checkin !== "" && dates.checkout !== "" ? `${range.start} - ${range.end}` : "Choose date range"}
                                </div>
                                <SelectBox
                                    isSearchable={false}
                                    defaultValue={gameOptions[0]}
                                    className="e-dashboard-card-sort me-3"
                                    options={gameOptions}
                                    placeholder=""
                                    selectchange={(temp) => setSelectedGameType(temp)} />
                                {
                                    timeFilter ?
                                        <div className='e-show-dropdown' ref={wrapperRef} >
                                            {
                                                dashbaordFilter.map((item) => {
                                                    return (

                                                        <>
                                                            <p className={item.data.length > 0 ? "e-arrow-show" : null}
                                                                onClick={() => {
                                                                    if (item.data.length > 0) {
                                                                        setShowSecondTimeFilter(!secondTimeFilter);
                                                                        setId(item.id)
                                                                        // setDates({
                                                                        //     checkin: "",
                                                                        //     checkout: ""
                                                                        // })
                                                                    } else {
                                                                        setSelectedFilter("All");
                                                                        setFilterId(null);
                                                                        setShowTimeFilter(false);
                                                                        setShowSecondTimeFilter(false);
                                                                        setId(null);
                                                                        setRange({
                                                                            checkin: "",
                                                                            checkout: ""
                                                                        })
                                                                    }
                                                                }}>{item.name}</p>
                                                            {
                                                                item.id === id && secondTimeFilter && item.data.length > 0 ?
                                                                    <div className='e-second-show-dropdown' >
                                                                        {
                                                                            item.data.map((option) => {
                                                                                return (

                                                                                    <p onClick={() => {
                                                                                        setSelectedFilter(option.name);
                                                                                        setFilterId(option.id);
                                                                                        setShowTimeFilter(!timeFilter);
                                                                                        setShowSecondTimeFilter(false);
                                                                                        setDates({
                                                                                            checkin: "",
                                                                                            checkout: ""
                                                                                        })
                                                                                    }}>{option.name}</p>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </>
                                                    )
                                                })
                                            }

                                            <DatePicker
                                                dates={dates}
                                                setDates={setDates}
                                                open={open}
                                                setOpen={setOpen}

                                            >
                                                <p className=" e-arrow-show mb-0" onClick={() => { setOpen(!open); setShowSecondTimeFilter(false); setId(1); setFilterId(null); }}>
                                                    Date range
                                                </p>
                                            </DatePicker>

                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='e-dashboard-container'>
                {
                    loader ?
                        <div className="position-relative">
                            <Loader />
                        </div>
                        :
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Platform fee',
                                        count: dashboardData.platform_fee,
                                        icon: Platform,
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Non-Forecasted Amount',
                                        count: dashboardData.non_forcasted_amount,
                                        icon: Nonforecast,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Gross-Revenue',
                                        count: dashboardData.gross_amount,
                                        icon: Fees,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Total Revenue',
                                        count: dashboardData.total_revenue,
                                        icon: Revenue
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'KYC Completed Users / Registered Users',
                                        count: dashboardData.kyc_completed_users + " / " + dashboardData.registered_users,
                                        icon: Users,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Active Participants / Registered Users',
                                        count: dashboardData.active_users + " / " + dashboardData.registered_users,
                                        icon: Active,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Units Contributed',
                                        count: dashboardData.total_units,
                                        icon: Units,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Winners / Losers',
                                        count: `${dashboardData.total_winners} / ${dashboardData.total_loosers}`,
                                        icon: Winners,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'GST',
                                        count: `${dashboardData.taxes}`,
                                        icon: Taxes,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'TDS',
                                        count: `${dashboardData.tds_amount_string}`,
                                        icon: Taxes,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Payin Total Users  / Payin Total',
                                        count: `${dashboardData.total_pay_in_users} / ${dashboardData.total_pay_in}`,
                                        icon: Revenue,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Payout Total Users  / Payout Total',
                                        count: `${dashboardData.total_pay_out_users} / ${dashboardData.total_pay_out}`,
                                        icon: Revenue,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Referral commissions',
                                        count: dashboardData.referral_commissions,
                                        icon: Referral,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Wallet balance',
                                        count: dashboardData.total_wallet_balance,
                                        icon: Fees,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Welcome Bonus / Users',
                                        count: `${dashboardData.welcome_bonus} / ${dashboardData.welcome_bonus_user_count}`,
                                        icon: Referral,
                                    }} />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <Summary
                                    data={{
                                        title: 'Referral Users / Direct User Count',
                                        count: `${dashboardData.referral_users}/${dashboardData.direct_user_count}`,
                                        icon: Referral,
                                    }} />
                            </div>
                        </div>
                }
                <div className='row my-4 pt-4'>
                    <div className='col-lg-8'>
                        <h6 className='pt-3 mb-0'>Games overview</h6>
                    </div>
                    <div className='col-lg-4 '>
                        <div className="e-dashboard-graph-select">
                            <div className='positon-relative d-flex'>
                                <div className='e-dashbaord-dropdown' onClick={() => setShowGraphTimeFilter(!graphtimeFilter)}>
                                    {selectedGraphFilter ? selectedGraphFilter : graphdates.checkin !== "" && graphdates.checkout !== "" ? `${graphRange.start} - ${graphRange.end}` : "Choose date range"}
                                </div>
                                <SelectBox
                                    isSearchable={false}
                                    defaultValue={gameOptions[0]}
                                    className="e-dashboard-card-sort"
                                    options={gameOptions}
                                    placeholder=""
                                    selectchange={(temp) => setSelectedGraphGameType(temp)} />
                                {
                                    graphtimeFilter ?
                                        <div className='e-show-dropdown' ref={wrapRef} >
                                            {
                                                dashbaordFilter.map((item) => {
                                                    return (

                                                        <>
                                                            <p className={item.data.length > 0 ? "e-arrow-show" : null}
                                                                onClick={() => {
                                                                    if (item.data.length > 0) {
                                                                        setShowSecondGraphTimeFilter(!secondGraphTimeFilter);
                                                                        setGraphId(item.id)

                                                                    } else {
                                                                        setSelectedGraphFilter("All");
                                                                        setFilterGraphId(null);
                                                                        setShowGraphTimeFilter(false);
                                                                        setShowSecondGraphTimeFilter(false);
                                                                        setGraphId(null);
                                                                        setGraphRange({
                                                                            checkin: "",
                                                                            checkout: ""
                                                                        })
                                                                    }
                                                                }}>{item.name}</p>
                                                            {
                                                                item.id === graphId && secondGraphTimeFilter && item.data.length > 0 ?
                                                                    <div className='e-second-show-dropdown' >
                                                                        {
                                                                            item.data.map((option) => {
                                                                                return (

                                                                                    <p onClick={() => {
                                                                                        setSelectedGraphFilter(option.name);
                                                                                        setFilterGraphId(option.id);
                                                                                        setShowGraphTimeFilter(!graphtimeFilter);
                                                                                        setShowSecondGraphTimeFilter(false);
                                                                                        setGraphDates({
                                                                                            checkin: "",
                                                                                            checkout: ""
                                                                                        })
                                                                                    }}>{option.name}</p>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                        </>
                                                    )
                                                })
                                            }
                                            <DatePicker
                                                dates={graphdates}
                                                setDates={setGraphDates}
                                                open={graphOpen}
                                                setOpen={setGrpahOpen}

                                            >
                                                <p className=" e-arrow-show mb-0" onClick={() => { setGrpahOpen(!graphOpen); setShowSecondGraphTimeFilter(false); setGraphId(1); setFilterGraphId(null) }}>
                                                    Date range
                                                </p>
                                            </DatePicker>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 mb-2">
                        <div className="card e-dashboard-card e-round-chart-container border-0">
                            {
                                Object.keys(revenueData).length > 0 && revenueData && revenueData.series.length > 0 && seriesData.length > 0 ?
                                    <>
                                        <h6 className="e-card-title ps-3">Revenue</h6>
                                        <LineChart
                                            categories={revenueData.label}
                                            colors={selectedGraphGameType.value === 0 || selectedGraphGameType.value === 1 ? chartColors : selectedGraphGameType.value === 2 ? m2mChartColors : selectedGraphGameType.value === 3 ? srChartColors : selectedGraphGameType.value === 4 ? v2rChartColors : chartColors}
                                            series_data={seriesData}
                                            game_type={selectedGraphGameType.value}
                                            currency="₹" />
                                        <div className="e-piechart-label-type mt-2 px-3">
                                            <div className='d-flex'>
                                                {
                                                    revenueData.series.map((item, key) => {
                                                        return (
                                                            <p className="me-2">
                                                                <div className="rounded-circle me-2 pt-1" style={{ backgroundColor: selectedGraphGameType.value === 0 || selectedGraphGameType.value === 1 ? chartColors[key] : selectedGraphGameType.value === 2 ? m2mChartColors[key] : selectedGraphGameType.value === 3 ? srChartColors[key] : selectedGraphGameType.value === 4 ? v2rChartColors[key] : chartColors[key], width: '16px', height: '16px', display: 'inline-block', position: 'relative', top: '3px' }}>
                                                                </div>{item.name}
                                                            </p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 mb-2">
                        <div className="card e-dashboard-card e-round-chart-container border-0 ">
                            {
                                Object.keys(userData).length > 0 && userData && userData.series && barChartData.length > 0 ?
                                    <>
                                        <h6 className="e-card-title ps-3">User participation</h6>
                                        <BarChart
                                            colors={selectedGraphGameType.value === 0 || selectedGraphGameType.value === 1 ? chartColors : selectedGraphGameType.value === 2 ? m2mChartColors : selectedGraphGameType.value === 3 ? srChartColors : selectedGraphGameType.value === 4 ? v2rChartColors : chartColors}
                                            // data={userData.series}
                                            series_data={barChartData}
                                            categories={userData.label}
                                            currency="₹" />
                                        <div className="e-piechart-label-type mt-2 px-3">
                                            <div className='d-flex'>
                                                {
                                                    userData.series.map((item, key) => {
                                                        return (
                                                            <p className="me-2">
                                                                <div className="rounded-circle me-2 pt-1" style={{ backgroundColor: selectedGraphGameType.value === 0 || selectedGraphGameType.value === 1 ? chartColors[key] : selectedGraphGameType.value === 2 ? m2mChartColors[key] : selectedGraphGameType.value === 3 ? srChartColors[key] : selectedGraphGameType.value === 4 ? v2rChartColors[key] : chartColors[key], width: '16px', height: '16px', display: 'inline-block', position: 'relative', top: '3px' }}>
                                                                </div>{item.name}
                                                            </p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 mb-2">
                        <div className="card e-dashboard-card e-round-chart-container border-0">
                            {
                                Object.keys(avgRevenueData).length > 0 && avgRevenueData && avgRevenueData.data ?
                                    <>
                                        <h6 className="e-card-title ps-3 mb-5">Average revenue</h6>
                                        <PieChart
                                            data={avgRevenueData.data}
                                            categories={avgRevenueData.label}
                                            colors={['#F5B277', "#9C1FE9",]} currency="₹" />
                                        <div className='row e-piechart-label-type mt-3'>
                                            {
                                                avgRevenueData.data.map((item, key) => {
                                                    return (
                                                        <div className='d-flex mb-3'>
                                                            <p className="me-2">
                                                                <div className="rounded-circle me-2 pt-1" style={{ backgroundColor: pieChartColors[key], width: '16px', height: '16px', display: 'inline-block', position: 'relative', top: '3px' }}>
                                                                </div>{avgRevenueData.label[key]}
                                                            </p>
                                                            <span className='pt-1'>
                                                                {item}
                                                            </span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </div>

                    {/* <div className="col-lg-6">
	 				<div className="card e-dashboard-card e-round-chart-container border-0 p-3">
	 					<div className="d-flex justify-content-between">
	 						<h6 className="e-card-title">All transactions</h6>
	 						<SelectBox options={options} defaultValue={options[0]} className="e-dashboard-card-sort" selectchange={(temp)=> setSelected(temp)}/>
	 					</div>
	 					<div className="col-lg-12 position-relative">
	 						<RadialBar data={ [100, 500] } colors={ ['#6DD735','#FFB61D', '#EF4A3C'] } labels={ ['Gold 100k', 'Mutual funds 500k'] }/>
	 						<div className="position-absolute e-piechart-label">
	 							<h2>150<span>k</span></h2>
	 							<p><div className="rounded-circle me-2" style={{backgroundColor: '#6DD735', width: '9px', height: '10px', display: 'inline-block'}}></div>Successfull</p>
	 							<h2>100<span>k</span></h2>
	 							<p><div className="rounded-circle me-2" style={{backgroundColor: '#FFB61D', width: '9px', height: '10px', display: 'inline-block'}}></div>Pending</p>
	 							<h2>10<span>k</span></h2>
	 							<p><div className="rounded-circle me-2" style={{backgroundColor: '#EF4A3C', width: '9px', height: '10px', display: 'inline-block'}}></div>Failed</p>
	 						</div>
	 					</div>
	 				</div>
	 			</div>
                 <div>
	 			<div className="col-lg-8 mt-4">
	 				<div className="card e-dashboard-card e-dashboard-row-2 border-0 p-3">
	 					<div className="d-flex justify-content-between">
	 						<h6 className="e-card-title ms-3">Recent transactions</h6>
	 					</div>
	 					<div className="col-lg-12">
	 						<DataTable pagination={false} columns={columns} data={data}/>
	 					</div>
	 					<div className="text-center mt-3 position-relative">
	 						<SecondaryBtn name="View all" className="e-s-btn"/>
	 					</div>
	 				</div>
	 			</div>
	 			<div className="col-lg-4 mt-4">
	 				<div className="card e-dashboard-card e-dashboard-row-2 border-0 p-3">
	 					<div className="d-flex justify-content-between">
	 						<h6 className="e-card-title">Yearly transactions</h6>
	 						<SelectBox options={barOpt} defaultValue={barOpt[0]} className="e-dashboard-card-sort" selectchange={(temp)=> setSelected(temp)}/>
	 					</div>
	 					<div className="col-lg-12 ms-3">
	 						<BarChart color={ ['#FFB61D', '#6DD735' ] } data={ [10, 40, 50, 50 ] } label={ [ 'Jan', 'Feb', 'Mar', 'Apr'] } />
	 					</div>
	 				</div>
	 			</div> */}
                </div>
            </div>
        </>
    )
}
export default Index;