/*
 *   File : editUserDetail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';

/*import componets*/
import { Input } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';


export default function EditFund(props) {
  //eslint-disable-next-line
  const [organization, setOrganization] = useState();
  // const [item2, setItem2] = useState();
  // const [item3, setItem3] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    var editModal = document.getElementById('editFund');
    editModal.addEventListener('shown.bs.modal', async function () {
      if (props.data) {
        setData(props.data)
      }
    })
  }, [props.data])


  // const [answer,setAnswer] = useState();

  return (
    <div className={`${styles.e_add_faq} modal fade`} id='editFund' tabIndex="-1" aria-labelledby="success" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6>Edit Fund</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="container px-3">
            <div className="row">
              <div className="col-lg-12">
                <label>Organization</label>
                <Input value={data && data.organization} valueChange={(value) => { setOrganization(value) }} type="text" />
              </div>
              {/* <div className="col-lg-12 pt-3">
                <label>{data && data.title2}</label>
                <Input value={data && data.item2} valueChange={(value) => { setItem2(value) }} type="text"/>
              </div>
              <div className="col-lg-12 pt-3">
                <label>{data && data.title3}</label>
                <Input value={data && data.item3} valueChange={(value) => { setItem3(value) }} type="text"/>
              </div>*/}
            </div>
            <div className="row pt-4 mt-2 pb-2">
              <div className="col-lg-12">
                <div className="d-flex justify-content-end">
                  <SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" />
                  <PrimaryBtn name="Save Details" className="e-modal-submit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
