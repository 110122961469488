/*
 *   File : tax-settings.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : tax settings container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 12-04-2022
 */
/*import packages*/
import { useState, useEffect } from 'react';

/*import components */
import { Input, Timepicker } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';
import SelectBox from '../../components/dropdown/selectBox';
import { SingleDate } from '../../components/input/index';
import { Toast } from '../../components/note';

/* import services */
import service from '../../services/service';

/* import assets */
import CloseIcon from '../../assets/images/admins/close.png';

const PlatformSetings = (props) => {
    const [click, setClick] = useState(1);
    const [selectedState, setSelectedState] = useState({ label: "Choose a state", value: null });
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [name, setName] = useState("");
    const [holidays, setHolidays] = useState([]);
    const [blockStates, setBlockStates] = useState([]);
    //eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    //eslint-disable-next-line
    const [error, setError] = useState(null);
    const [date, setDate] = useState(null);
    const [holidayDate, setHolidayDate] = useState(null);
    const [stateList, setStateList] = useState([]);
    const [marketStartTime, setMarketStartTime] = useState("9:15 am");
    const [marketEndTime, setMarketEndTime] = useState("3:30 pm");
    const [tradingData, setTradingData] = useState([]);
    const [success, setSuccess] = useState(null);
    const [occasion, setOccasion] = useState("");

    // window.scrollTo({ top: 0, behavior: 'smooth' });

    useEffect(() => {

        getBlockedStates();
        getHolidays();
        getTradingDays();

        // window.scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // });
    }, []);

    useEffect(() => {
        getStateList();
        //eslint-disable-next-line
    }, [blockStates])

    useEffect(() => {
        setTimeout(() => {
            if (error !== null) {
                setError(null)
            }
        }, 3000);
    }, [error])

    useEffect(() => {
        setTimeout(() => {
            if (success !== null) {
                setSuccess(null)
            }
        }, 3000);
    }, [success])


    /*--------get states list-------- */
    const getStateList = () => {
        setLoader(true)
        let url = '/settings/get-states';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let res = [];
                let temp_state_list = [];
                // response.data.data.map((item) => (
                //     temp_state_list.push({
                //         value: item.id,
                //         label: item.name
                //     })

                // ));

                res = response.data.data.filter(el => {
                    return !blockStates.find(element => {
                        return element.id === el.id;
                    });
                });
                console.log(res)
                res.map((item) => {
                    return (
                        temp_state_list.push({
                            value: item.id,
                            label: item.name
                        })
                    )
                })
                setStateList(temp_state_list);
                setLoader(false);
            }
            else {
                setLoader(false);
                setStateList([]);
                // setError(response.data.message);
            }
        });
    }

    /*---------add block states---- */
    const addBlockState = () => {
        let url = "/settings/block-states";
        let data = {
            "state_id": selectedState.value

        }
        // console.log(data);
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                getBlockedStates();
                setSuccess("State added to block list")
                setSelectedState({ label: "State", value: null })
                getStateList()
            } else {
                setError(response.data.message);
            }
        })
    }

    /*--------get Blocked states------ */
    const getBlockedStates = () => {
        setLoader(true)
        let url = '/settings/list-blocked-states';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setBlockStates(response.data.data);
                setLoader(false);
            }
            else {
                setLoader(false);
                setBlockStates([]);
                // setError(response.data.message);
            }
        });
    }

    /*-------remove block state-------- */
    const removeBlockState = (id) => {
        setLoader(true)
        let url = '/settings/remove-blocked-state';
        let data = {
            "state_id": id

        }
        // console.log(data);
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                getBlockedStates();

            } else {
                setError(response.data.message);
            }
        })

    }


    /*----- get holidays ----*/
    const getHolidays = () => {
        setLoader(true)
        let url = '/settings/get-holidays';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setHolidays(response.data.data);
                setLoader(false);
            }
            else {
                setLoader(false);
                setHolidays([]);
                // setError(response.data.message);
            }
        });
    }

    /*------add holiday------ */
    const addHoliday = () => {
        let url = "/settings/add-holiday";
        let data = {
            "date": date,
            "remark": occasion
        }
        // console.log(data);
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                getHolidays();
                setDate(null);
                setOccasion("");
                setSuccess("Holiday added")
            } else {
                setError(response.data.message);
            }
        });
    }

    /*----------remove holiday----- */
    const removeHoliday = (id) => {
        setLoader(true)
        let url = '/settings/remove-holiday';
        let data = {
            "holiday_id": id

        }
        // console.log(data);
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                getHolidays();

            } else {
                setError(response.data.message);
            }
        });
    }

    /*------add trading day------ */
    const addTradingDay = () => {
        let url = "/settings/add-special-tradingday";
        let data = {
            name: name,
            date: holidayDate,
            start_time: startTime,
            end_time: endTime

        }
        // console.log(data);
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                getTradingDays();
                setHolidayDate(null);
                setName("");
                setStartTime("");
                setEndTime("");
                setSuccess("Trading days added")
            } else {
                setError(response.data.message);
            }
        });
    }

    /*----- get holidays ----*/
    const getTradingDays = () => {
        setLoader(true)
        let url = '/settings/get-special-tradingdays';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setTradingData(response.data.data);
                setLoader(false);
            }
            else {
                setLoader(false);
                setTradingData([]);
                // setError(response.data.message);
            }
        });
    }

    /*----------remove trading day----- */
    const removeTradingDay = (id) => {
        setLoader(true)
        let url = '/settings/delete-special-tradingdays';
        let data = {
            "tradingday_id": id

        }
        // console.log(data);
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                getTradingDays();

            } else {
                setError(response.data.message);
            }
        });
    }

    /*------add trading day------ */
    const updateMarketHours = () => {
        let url = "/settings/update-market-hours";
        let data = {
            start_time: marketStartTime,
            end_time: marketEndTime

        }
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setSuccess("Market hours updated")
                // setMarketEndTime("");
                // setMarketStartTime("");
            } else {
                setError(response.data.message);
            }
        });
    }


    return (


        <>
            <div className='e-game-settings-accordion e-add-milestone-wrap e-platform-settings-accordion p-3'>
                <div className="accordion" id="accordionExample">
                    {
                        localStorage.getItem("type") !== "4" ?
                            <div className="accordion-item">
                                <div onClick={() => setClick(1)}>
                                    {
                                        click !== 1 ?
                                            <h2 className="accordion-header" id="headingOne" >
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <div className='row'>
                                                        <div className='col-lg-10'>
                                                            Block a state
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-plus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>
                                <div onClick={() => setClick(null)}>
                                    {
                                        click === 1 ?
                                            <h2 className="accordion-header" id="headingOne" >
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <div className='row'>
                                                        <div className='col-lg-10'>
                                                            Block a state
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-minus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>


                                {
                                    click === 1 ?
                                        <>
                                            <div className="accordion-body pt-0">

                                                <div className='row'>
                                                    <div className='col-lg-4'>
                                                        <label>Choose states</label>
                                                        <SelectBox
                                                            options={stateList}
                                                            placeholder="Choose a state"
                                                            value={selectedState}
                                                            // defaultValue={stateOptions[0]} 
                                                            className="e-dashboard-card-sort e-sort-border pe-3"
                                                            selectchange={(temp) => setSelectedState(temp)} />
                                                    </div>
                                                </div>
                                                <div className='row pt-4'>
                                                    <div className='col-lg-3'>
                                                        {
                                                            selectedState ?
                                                                <PrimaryBtn
                                                                    name="Add to blocklist"
                                                                    className="e-add-to-blocklist"
                                                                    handleClick={() => addBlockState()}
                                                                />
                                                                :
                                                                <PrimaryBtn
                                                                    name="Add to blocklist"
                                                                    className="e-add-to-blocklist e-disabled"

                                                                />
                                                        }


                                                    </div>
                                                </div>
                                                {
                                                    blockStates.length > 0 ?
                                                        <div className='row pt-2'>
                                                            <div className='col-lg-12'>
                                                                <div className='row pt-1'>
                                                                    <div className='col-lg-12'>
                                                                        <span className='e-previous-blocked'>Blocked states</span>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    blockStates.map((items, key) => {
                                                                        return (
                                                                            <>

                                                                                <span
                                                                                    className="e-blocked-span mb-2 me-3">
                                                                                    {items.name}
                                                                                    <img src={CloseIcon} className="e-close-icon"
                                                                                        alt=""
                                                                                        onClick={() => removeBlockState(items.id)} />
                                                                                </span>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </>
                                        :
                                        null
                                }
                            </div>
                            :
                            null
                    }
                    <div className="accordion-item">
                        <div onClick={() => setClick(2)}>
                            {
                                click !== 2 ?
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">

                                            <div className='row'>
                                                <div className='col-lg-10'>
                                                    Holiday master
                                                </div>
                                                <div className='col-lg-2'>
                                                    <span className='e-accordion-icon-plus'></span>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    :
                                    null
                            }
                        </div>
                        <div onClick={() => setClick(null)}>
                            {
                                click === 2 ?
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">

                                            <div className='row'>
                                                <div className='col-lg-10'>
                                                    Holiday master
                                                </div>
                                                <div className='col-lg-2'>
                                                    <span className='e-accordion-icon-minus'></span>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    :
                                    null
                            }
                        </div>
                        {
                            click === 2 ?
                                <div>
                                    <div className="accordion-body pt-0">

                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <label>Choose date</label>
                                                {/* <SingleDate placeholder="Choose date" className="mt-2" /> */}
                                                <SingleDate
                                                    id="dob"
                                                    value={date}
                                                    placeholder="dd-mm-yyyy"
                                                    defaultValue={null}

                                                    onChange={(dateString) => {
                                                        setDate(dateString);
                                                    }} />
                                            </div>
                                            <div className='col-lg-4'>
                                                <label>Occasion</label>
                                                {/* <SingleDate placeholder="Choose date" className="mt-2" /> */}
                                                <Input
                                                    placeholder="Occasion"
                                                    type="text"
                                                    value={occasion}
                                                    valueChange={(temp) => {
                                                        setOccasion(temp)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='row pt-4'>
                                            <div className='col-lg-3'>
                                                {
                                                    date && occasion ?
                                                        <PrimaryBtn
                                                            name="Add to holiday"
                                                            className="e-add-to-blocklist"
                                                            handleClick={() => addHoliday()}
                                                        />
                                                        :
                                                        <PrimaryBtn
                                                            name="Add to holiday"
                                                            className="e-add-to-blocklist e-disabled"

                                                        />

                                                }

                                            </div>
                                        </div>
                                        {
                                            holidays.length > 0 ?
                                                <div className='row pt-2'>
                                                    <div className='row pt-1'>
                                                        <div className='col-lg-12'>
                                                            <span className='e-previous-blocked'>Holidays</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12'>
                                                        {
                                                            holidays.map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <span className="e-blocked-span mb-2 me-3">{item.date}<img src={CloseIcon} alt="" className="e-close-icon" onClick={() => removeHoliday(item.id)} /></span>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="accordion-item">
                        <div onClick={() => setClick(3)}>
                            {
                                click !== 3 ?
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">

                                            <div className='row'>
                                                <div className='col-lg-10'>
                                                    Special trading days
                                                </div>
                                                <div className='col-lg-2'>
                                                    <span className='e-accordion-icon-plus'></span>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    :
                                    null
                            }
                        </div>
                        <div onClick={() => setClick(null)}>
                            {
                                click === 3 ?
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <div className='row'>
                                                <div className='col-lg-10'>
                                                    Special trading days
                                                </div>
                                                <div className='col-lg-2'>
                                                    <span className='e-accordion-icon-minus'></span>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    :
                                    null
                            }
                        </div>

                        {
                            click === 3 ?
                                <div >
                                    <div className="accordion-body pt-0">

                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <label>Name of the event/day</label>
                                                <Input
                                                    placeholder="Event name"
                                                    type="text"

                                                    value={name}
                                                    valueChange={(temp) => {
                                                        setName(temp)
                                                    }}
                                                />
                                            </div>
                                            <div className='col-lg-4'>
                                                <label>Choose date</label>
                                                <SingleDate
                                                    value={holidayDate}
                                                    placeholder="Choose date"
                                                    onChange={(date) => setHolidayDate(date)} />
                                            </div>
                                            <div className='col-lg-4'>
                                                <label>Trading time</label>
                                                <Timepicker
                                                    value={[startTime, endTime]}
                                                    className="e-input-suffix"
                                                    onChange={(time) => {
                                                        setStartTime(time[0]);
                                                        setEndTime(time[1])
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='row pt-4'>
                                            <div className='col-lg-3'>
                                                {
                                                    name && startTime && endTime && holidayDate ?
                                                        <PrimaryBtn
                                                            name="Add special day"
                                                            className="e-add-to-blocklist"
                                                            handleClick={() => addTradingDay()}
                                                        />
                                                        :
                                                        <PrimaryBtn
                                                            name="Add special day"
                                                            className="e-add-to-blocklist e-disabled"

                                                        />
                                                }
                                            </div>
                                        </div>
                                        {
                                            tradingData.length > 0 ?
                                                <div className='row pt-2'>
                                                    <div className='row pt-1'>
                                                        <div className='col-lg-12'>
                                                            <span className='e-previous-blocked'>Trading days</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12'>
                                                        {
                                                            tradingData.map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <div className="e-blocked-span mb-2 me-3">
                                                                            {item.name},{item.date}<img src={CloseIcon} alt="" className="e-close-icon" onClick={() => removeTradingDay(item.id)} />
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="accordion-item">
                        <div onClick={() => setClick(4)}>
                            {
                                click !== 4 ?
                                    <h2 className="accordion-header" id="headingFour">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">

                                            <div className='row'>
                                                <div className='col-lg-10'>
                                                    General market hours
                                                </div>
                                                <div className='col-lg-2'>
                                                    <span className='e-accordion-icon-plus'></span>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    :
                                    null
                            }
                        </div>
                        <div onClick={() => setClick(null)}>
                            {
                                click === 4 ?
                                    <h2 className="accordion-header" id="headingFour">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            <div className='row'>
                                                <div className='col-lg-10'>
                                                    General market hours
                                                </div>
                                                <div className='col-lg-2'>
                                                    <span className='e-accordion-icon-minus'></span>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    :
                                    null
                            }
                        </div>

                        {
                            click === 4 ?
                                <div class="accordion-body pt-0">
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <label>Market hours</label>
                                            <Timepicker
                                                value={[marketStartTime, marketEndTime]}
                                                className="e-input-suffix"
                                                onChange={(time) => {
                                                    setMarketStartTime(time[0]);
                                                    setMarketEndTime(time[1])
                                                }}
                                            />
                                        </div>
                                        <div className='row pt-4'>
                                            <div className='col-lg-3'>
                                                {
                                                    marketStartTime && marketEndTime ?
                                                        <PrimaryBtn
                                                            name="Update"
                                                            className="e-add-to-blocklist"
                                                            handleClick={() => updateMarketHours()}
                                                        />
                                                        :
                                                        <PrimaryBtn
                                                            name="Update"
                                                            className="e-add-to-blocklist e-disabled"

                                                        />
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                :
                                null
                        }
                    </div>

                </div>
                {
                    error !== null ?
                        <Toast
                            data={error}
                            type="fail"
                            format={1}
                        />
                        :
                        null
                }
                {
                    success !== null ?
                        <Toast
                            data={success}
                            type="success"
                            format={1}
                        />
                        :
                        null
                }
            </div>


        </>




    )
}

export default PlatformSetings;
