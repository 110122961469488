/*
 *   File : config.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Config file for the template
 *   Version : 1.0.0
 *   Created : 10-11-2021
 */

/*import side bar icons*/
import Dashboard from './../assets/images/sidebar/dashboard.svg';
import Users from './../assets/images/sidebar/users.svg';
import Transactions from './../assets/images/sidebar/transactions.svg';
import Bookings from './../assets/images/sidebar/bookings.svg';
// import Results from './../assets/images/sidebar/results.svg';
import ManageGames from './../assets/images/sidebar/manage-games.svg';
import ManageAdmins from '../assets/images/sidebar/Admins.png';
import AdminsActive from '../assets/images/sidebar/admins-active.png';
import Leaderboards from './../assets/images/sidebar/leaderboards.svg';
import Earning from './../assets/images/sidebar/earnings.svg';
import Milestone from './../assets/images/sidebar/milestone.svg';
import Scrips from './../assets/images/sidebar/scrips.svg';
import Content from './../assets/images/sidebar/content.svg';
import Settings from './../assets/images/sidebar/settings.svg';
import App from './../assets/images/sidebar/app.png';

import DashboardActive from './../assets/images/sidebar/dashboard-active.svg';
import UsersActive from './../assets/images/sidebar/users-active.svg';
import TransationsActive from './../assets/images/sidebar/transaction-active.svg';
import BookingsACtive from './../assets/images/sidebar/bookings-active.svg';
// import ResultsACtive from './../assets/images/sidebar/results-active.svg';
import ManageGamesActive from './../assets/images/sidebar/manage-games-active.svg';
import LeaderboardActive from './../assets/images/sidebar/leaderboard-active.svg';
import EarningsActive from './../assets/images/sidebar/earnings-active.svg';
import MilestoneActive from './../assets/images/sidebar/milestone-active.svg';
import ScripsActive from './../assets/images/sidebar/scrips-active.svg';
import ContentActive from './../assets/images/sidebar/content-active.svg';
import SettingsActive from './../assets/images/sidebar/settings-active.svg';
import AppActive from './../assets/images/sidebar/app-active.png';
/*
import DashboardCollapseActive from './../assets/images/sidebar/dashboard.svg';
import UsersCollapseActive from './../assets/images/sidebar/users.svg';
import TransationsCollapseActive from './../assets/images/sidebar/transactions.svg';
import ManageGamesCollapseActive from './../assets/images/sidebar/Admins.png';
import LeaderboardCollapseActive from './../assets/images/sidebar/leaderboards.svg';
import EarningsCollapseActive from './../assets/images/sidebar/earnings.svg';
import MilestoneCollapseActive from './../assets/images/sidebar/milestone.svg';
import ScripsCollapseActive from './../assets/images/sidebar/scrips.svg';
import ContentCollapseActive from './../assets/images/sidebar/content.svg';
import AdminsCollapseActive from '../assets/images/sidebar/admins-active.png';
import ManageAdminsCollapseActive from '../assets/images/sidebar/Admins.png';
*/

/*import footer images*/
import FooterLogo from './../assets/images/navigation/footer-logo.svg';

/*import header images*/
import OuterHeaderLogo from './../assets/images/navigation/new_logo.svg';

/*----Meta -----*/
const Metadata = { title: 'Stock Sports Admin' }

/*----- Sidebar ------ */
const Sidebar = [
  {
    name: 'Dashboard',
    icon: Dashboard,
    link: '/',
    activeIcon: DashboardActive,
    collapseActiveIcon: DashboardActive,
  },
  {
    name: 'Users',
    icon: Users,
    link: '/users',
    header: 'Users',
    activeIcon: UsersActive,
    collapseActiveIcon: UsersActive,
  },
  {
    name: 'Bookings',
    icon: Bookings,
    link: '/bookings',
    // header: 'Bookings',
    activeIcon: BookingsACtive,
    collapseActiveIcon: BookingsACtive,
  },
  {
    name: 'Transactions',
    icon: Transactions,
    link: '/transactions',
    header: 'Transactions',
    activeIcon: TransationsActive,
    collapseActiveIcon: TransationsActive,
  },
  {
    name: 'Results',
    icon: Transactions,
    link: '/results',
    header: '',
    activeIcon: TransationsActive,
    collapseActiveIcon: TransationsActive,
  },
  {
    name: 'Reports',
    icon: Transactions,
    link: '/reports',
    header: 'Reports and History',
    activeIcon: TransationsActive,
    collapseActiveIcon: TransationsActive,
  },
  {
    name: 'Tax reports',
    icon: Transactions,
    link: '/tax-reports',
    header: 'Tax reports',
    activeIcon: TransationsActive,
    collapseActiveIcon: TransationsActive,
  },
  {
    name: 'Manage games',
    icon: ManageGames,
    link: '/manage-games',
    header: 'Manage games',
    activeIcon: ManageGamesActive,
    collapseActiveIcon: ManageGamesActive,
  },
  {
    name: 'Scrips & voting',
    icon: Scrips,
    link: '/scrips-voting',
    // header: 'Manage games',
    activeIcon: ScripsActive,
    collapseActiveIcon: ScripsActive,
  },

  {

    name: 'Leaderboard',
    icon: Leaderboards,
    link: '/leaderboard',
    header: 'Leaderboard',
    activeIcon: LeaderboardActive,
    collapseActiveIcon: LeaderboardActive,
  },
  {
    name: 'Earnings',
    icon: Earning,
    link: '/earnings',
    activeIcon: EarningsActive,
    collapseActiveIcon: EarningsActive,
  },
  {
    name: 'Milestones',
    icon: Milestone,
    link: '/milestones',
    header: '',
    activeIcon: MilestoneActive,
    collapseActiveIcon: MilestoneActive,
  },
  {
    name: 'Manage content',
    icon: Content,
    link: '/manage-content',
    // header: 'Milestones',
    activeIcon: ContentActive,
    collapseActiveIcon: ContentActive,
  },
  {
    name: 'Manage admins',
    icon: ManageAdmins,
    link: '/manage-admins',
    header: 'All admins',
    activeIcon: AdminsActive,
    collapseActiveIcon: AdminsActive,
  },
  {
    name: 'Platform settings',
    icon: Settings,
    link: '/platform-settings',
    // header: '',
    activeIcon: SettingsActive,
    collapseActiveIcon: SettingsActive,
  },
  {
    name: 'Download reports',
    icon: Settings,
    link: '/downloads',
    header: 'Download',
    activeIcon: SettingsActive,
    collapseActiveIcon: SettingsActive,
  },
  {
    name: 'App notifications',
    icon: App,
    link: '/notifications',
    header: 'Send app notifications',
    activeIcon: AppActive,
    collapseActiveIcon: AppActive,
    paddingLeft: '12px',
    paddingLeftInactive: '20px'
  },

]

/*----- Sidebar ------ */
const SidebarAdmin = [
  {
    name: 'Dashboard',
    icon: Dashboard,
    link: '/',
    activeIcon: DashboardActive,
    collapseActiveIcon: DashboardActive,
  },
  {
    name: 'Users',
    icon: Users,
    link: '/users',
    header: 'Users',
    activeIcon: UsersActive,
    collapseActiveIcon: UsersActive,
  },
  {
    name: 'Bookings',
    icon: Bookings,
    link: '/bookings',
    header: 'Bookings',
    activeIcon: BookingsACtive,
    collapseActiveIcon: BookingsACtive,
  },
  {
    name: 'Transactions',
    icon: Transactions,
    link: '/transactions',
    header: 'Transactions',
    activeIcon: TransationsActive,
    collapseActiveIcon: TransationsActive,
  },
  {
    name: 'Results',
    icon: Transactions,
    link: '/results',
    header: '',
    activeIcon: TransationsActive,
    collapseActiveIcon: TransationsActive,
  },
  {
    name: 'Reports',
    icon: Transactions,
    link: '/reports',
    header: 'Reports',
    activeIcon: TransationsActive,
    collapseActiveIcon: TransationsActive,
  },
  {
    name: 'Tax reports',
    icon: Transactions,
    link: '/tax-reports',
    header: 'Tax reports',
    activeIcon: TransationsActive,
    collapseActiveIcon: TransationsActive,
  },
  {
    name: 'Scrips & voting',
    icon: Scrips,
    link: '/scrips-voting',
    // header: 'Manage games',
    activeIcon: ScripsActive,
    collapseActiveIcon: ScripsActive,
  },

  {

    name: 'Leaderboard',
    icon: Leaderboards,
    link: '/leaderboard',
    header: 'Leaderboard',
    activeIcon: LeaderboardActive,
    collapseActiveIcon: LeaderboardActive,
  },
  {
    name: 'Earnings',
    icon: Earning,
    link: '/earnings',
    activeIcon: EarningsActive,
    collapseActiveIcon: EarningsActive,
  },
  {
    name: 'Manage content',
    icon: Content,
    link: '/manage-content',
    // header: 'Milestones',
    activeIcon: ContentActive,
    collapseActiveIcon: ContentActive,
  },
  {
    name: 'Manage admins',
    icon: ManageAdmins,
    link: '/manage-admins',
    header: 'All admins',
    activeIcon: AdminsActive,
    collapseActiveIcon: AdminsActive,
  },
  {
    name: 'Platform settings',
    icon: Settings,
    link: '/platform-settings',
    // header: '',
    activeIcon: SettingsActive,
    collapseActiveIcon: SettingsActive,
  },
  {
    name: 'Download reports',
    icon: Settings,
    link: '/downloads',
    header: 'Download',
    activeIcon: SettingsActive,
    collapseActiveIcon: SettingsActive,
  },
  {
    name: 'App notifications',
    icon: App,
    link: '/notifications',
    header: 'Send app notifications',
    activeIcon: AppActive,
    collapseActiveIcon: AppActive,
    paddingLeft: '12px',
    paddingLeftInactive: '20px'
  },

]

const SidebarAccount = [
  {
    name: 'Transactions',
    icon: Transactions,
    link: '/',
    header: 'Transactions',
    activeIcon: TransationsActive,
    collapseActiveIcon: TransationsActive,
  },
  {
    name: 'Tax reports',
    icon: Transactions,
    link: '/tax-reports',
    header: 'Tax reports',
    activeIcon: TransationsActive,
    collapseActiveIcon: TransationsActive,
  },
]

const SidebarStaff = [

  {
    name: 'Users',
    icon: Users,
    link: '/',
    header: 'Users',
    activeIcon: UsersActive,
    collapseActiveIcon: UsersActive,
  },
  {
    name: 'Platform settings',
    icon: Settings,
    link: '/platform-settings',
    // header: '',
    activeIcon: SettingsActive,
    collapseActiveIcon: SettingsActive,
  },

]

/*-------Outer Header-----*/
const OuterHeader = {
  OuterHeaderLogo: OuterHeaderLogo,
}

/*----- Footer ----- */
const Footer = {
  footerLogo: FooterLogo,
  footerCopyRight: `© ${new Date().getFullYear()} Eqplus Pvt. Ltd.`,
  supportLink: 'support@stocksports.com',
  poweredby: 'Evoque Innovative Lab',
  footerItems: [
    {
      name: 'Home',
      link: '',
    },
    {
      name: 'Terms',
      link: '',
    },
    {
      name: 'Privacy',
      link: '',
    },
  ]
}

var type = localStorage.getItem("type")
var devconfig = {
  sidebar: Number(type) === 2 ? SidebarAdmin : Number(type) === 3 ? SidebarAccount : Number(type) === 4 ? SidebarStaff : Sidebar,
  footer: Footer,
  outerHeader: OuterHeader,
  meta: Metadata
}

export default devconfig;
