
/*
 *   File : gameTab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-02-2022
*/

/*import package*/
import { useEffect, useState } from 'react';

/*import styles*/
import styles from '../../styles/components/gameTab.module.scss';

const GameTabs = (props) => {
  const [index, setIndex] = useState(props.index);

  useEffect(() => {
    setIndex(props.index)
  }, [props.index])
  return (
    <div className="d-flex">
      {
        props.type ?
          <>
            {
              props.data.map((item, key) => {
                return (
                  <span
                    className={index === (key + 1) ? `${styles.e_active_scrip_tab} me-4` : `${styles.e_scrip_tab} me-4`}
                    onClick={() => { setIndex((key + 1)); props.setTab((key + 1)) }} key={key}
                  >
                    {item}
                  </span>
                )
              })
            }
          </>
          :
          <>
            {
              props.data.map((item, key) => {
                return (
                  <span
                    className={index === (key + 1) ? `${styles.e_active_tab} me-4` : `${styles.e_tab} me-4`}
                    onClick={() => { setIndex((key + 1)); props.setTab((key + 1)) }} key={key}
                  >
                    {item}
                  </span>
                )
              })
            }
          </>

      }

    </div>
  )
}

export default GameTabs;

