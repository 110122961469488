/*
 *   File : login.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : 03-03-2022
 *   Version : 1.0.0
 *   Created : 07-02-2022
 */

/*import packages*/
import { useState, useEffect } from 'react';

/*import assets*/
import BannerLeft from '../../assets/images/auth/banner-left.svg';
import BannerRight from '../../assets/images/auth/banner-right.svg';

/*import components*/
import { OuterHeader, Footer } from '../../components/navigation/index';
import { Input } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../../components/note';

/*services*/
import service from '../../services/service';

const Login = (props) => {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    //eslint-disable-next-line
    const [login, setLogin] = useState(true);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    /*---- validation for password eg: Admin@12 ----*/
    const validate = () => {
        var pattern = new RegExp(/^(?=.*\d)(?=.*?[a-zA-Z])(?=.*?[\W_]).{8,100}$/);
        if (!pattern.test(password)) {
            setError("Password should contain one alphabet, one special character and one digit with minimum 8 characters");
        } else {
            Authentication();
        }
    }
    /*--- login api integration ---*/
    const Authentication = () => {
        setLoader(true);

        let url = '/auth/login';

        let data = {
            username: name,
            password: password
        };

        service(true, url, data).then((response) => {

            if (response.data.status_code === 200) {
                // message.success("Login successful");
                // console.log('token', response.data.data.access_token);

                setLoader(false);
                localStorage.setItem("type", response.data.data.type_id)
                localStorage.setItem("token", response.data.data.access_token);
                window.location.href = "/";
            } else {
                setError(response.data.message);
                setLoader(false);
            }
        });
    }
    return (
        <>
            {
                login ?
                    <>
                        <div className="container-fluild e-auth-wrapper">
                            <OuterHeader type={1} />
                            <div className="container-fluid mb-5 pb-5">
                                <div className="row">
                                    <div className='col-lg-4'>
                                        <img src={BannerLeft} className='e-left-banner' alt="Stock Sports" />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-2  pt-5 px-4">
                                        <div className="e-form-wrap">
                                            <h4>Admin Login</h4>
                                            <div className="pt-3">
                                                <label className='pb-1'>Username</label>
                                                <Input
                                                    placeholder="Username"
                                                    value={name}
                                                    valueChange={(value) => {
                                                        setName(value)
                                                    }}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="pt-4">
                                                <label className='pb-1'>Password</label>
                                                <Input
                                                    placeholder="Password"
                                                    value={password}
                                                    valueChange={(value) => {
                                                        setPassword(value)
                                                    }}
                                                    type="password"
                                                />
                                            </div>
                                            <div className="row mt-4 pt-2">
                                                <div className="col-lg-4 col-md-5 col-sm-4">
                                                    {
                                                        name && password ?
                                                            <PrimaryBtn
                                                                name="Login"
                                                                className={loader === true ? "e-btn-loader" : ""}
                                                                handleClick={() => { validate() }} />
                                                            :
                                                            <PrimaryBtn
                                                                name="Login"
                                                                className="e-disabled" />
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className='row mt-4 pt-2'>
                                            <div className='col-lg-6'>
                                                <Link to="/change-password">
                                                    <TextBtn name="Forgot password?" />
                                                </Link>
                                            </div>
                                            <div className='col-lg-6 text-end'>
                                                <Link to={"/change-password?change=" + true}>
                                                    <TextBtn name="Change password" />
                                                </Link>
                                            </div>
                                        </div>*/}
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img src={BannerRight} className='e-right-banner' alt="Stock Sports" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
                    :
                    null
            }
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </>
    )
}

export default Login;

