/*
 *   File : detail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Details
 *   Integrations : 03-03-2022
 *   Version : 1.0.0
 *   Created : 25-01-2022
 */

/* import packages */
import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';
import $ from 'jquery';
import moment from 'moment';

/* import components */
import { TextArea, Input, FileUpload, ColorPicker, Timepicker, CheckBox } from '../../components/input/index';
import { PrimaryBtn, PrimaryLink } from '../../components/cta/index';
import SelectBox from '../../components/dropdown/selectBox';
import { Editor } from '../../components/input/index';
import { Confirmation, AddBlockRule } from '../modal';
import M2Etable from './m2eTable';
import { Toast } from '../note';
import Loader from '../loader/loader';
import LoaderGif from '../../assets/images/loader/loader1.gif';

/*Style Import*/
import style from '../../styles/components/setPageAccess.module.scss';

/* import services */
import service from '../../services/service';

import Down from '../../assets/images/dataFormating/down.svg'

const options = [
    {
        value: true,
        label: 'Both sides',
    }, {
        value: false,
        label: 'Single side'
    }];

const ruleOptions = [
    {
        value: 1,
        label: "Days"
    }, {
        value: 2,
        label: "Month"
    }, {
        value: 3,
        label: "Quarter"
    }, {
        value: 4,
        label: "Year"
    }];

const M2EGameSettingsAccordion = (props) => {
    const wrapperRef = useRef()
    let [m2eBlockRuleData, setM2eBlockRuleData] = useState([]);
    const [listscrip, setListScrip] = useState(null);
    const [resultscrip, setResultScrip] = useState(null);
    const [permission, setPermission] = useState({});
    const [gameStartTime, setGameStartTime] = useState(null);
    const [gameEndTime, setGameEndTime] = useState(null);
    const [blockingStartTime, setBlockingStartTime] = useState("");
    const [blockingEndTime, setBlockingEndTime] = useState("");
    const [coolingStartTime, setCoolingStartTime] = useState("");
    const [coolingEndTime, setCoolingEndTime] = useState("");
    const [unitFee, setUnitFee] = useState(null);
    const [unitsPerScrip, setUnitsPerScrip] = useState(null);
    const [unitsPerGame, setUnitsPerGame] = useState(null);
    const [commissionPercent, setCommissionPercent] = useState(null);
    const [minimumPrice, setMinimumPrice] = useState(null);
    const [error, setError] = useState(null);
    const [loseAmount, setLoseAmount] = useState(null);
    const [loseIn, setLoseIn] = useState(null);
    const [period, setPeriod] = useState(null);
    const [blockFor, setBlockFor] = useState(null);
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState('');
    const [editorData, setEditorData] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("");
    const [uploadImage, setUploadImage] = useState("");
    const [imageUrl, setUploadImageUrl] = useState("");
    const [imageLoader, setImageLoader] = useState(false);
    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [id, setId] = useState("");
    const [m2eTableData, setM2eTableData] = useState([]);
    const [updated, setUpdated] = useState(false);
    const [loader, setLoader] = useState(false);
    const [m2eLoader, setM2eLoader] = useState(false);
    const [click, setClick] = useState(1);
    //eslint-disable-next-line
    const [selectDisable, setSelectDisable] = useState("");
    const [blockCode, setBlockCode] = useState(null);
    const [show, setShow] = useState(null);
    const [activeWeb, setActiveWeb] = useState(false);
    const [activeMobile, setActiveMobile] = useState(false);
    const [mobileUrl, setMobileUrl] = useState(null);

    useEffect(() => {
        getM2eDetails();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    const _closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShow(null)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);

    //api request body to update basic info 
    const m2eApiData = {
        "game_scrip_count": listscrip,
        "is_dual_booking_allowed": permission?.value,
        "game_start_time": gameStartTime,
        "game_end_time": gameEndTime,
        "booking_start_time": blockingStartTime,
        "booking_end_time": blockingEndTime,
        "cooling_start_time": coolingStartTime,
        "cooling_end_time": coolingEndTime,
        "unit_fee": unitFee,
        "commission_percentage": commissionPercent,
        "max_units_per_scrip": unitsPerScrip,
        "max_units_per_game": unitsPerGame,
        "minimum_prize": minimumPrice,
        "result_percentage_count": resultscrip

    }

    // const updateData = () => {
    // 	setListScrip(null);
    // 	setPermission({});
    // 	setGameStartTime("");
    // 	setGameEndTime("");
    // 	setBlockingEndTime("");
    // 	setBlockingStartTime("");
    // 	setCoolingEndTime("");
    // 	setCoolingStartTime("");
    // 	setUnitFee(null);
    // 	setCommissionPercent(null);
    // 	setUnitsPerGame(null);
    // 	setUnitsPerScrip(null);
    // 	setMinimumPrice(null);
    // }

    /* API - customize table */
    const customizeTable = () => {
        setUpdated(false);
        setLoader(true);
        let url = "/m2e/customize-table";

        let body = {
            table_data: m2eTableData
        };

        service(true, url, body).then((response) => {
            if (response.data.status_code === 200) {
                if (response.data.status === true) {
                    // message.success("Table customized");
                    setUpdated(true);
                    setLoader(false);
                } else {
                    setError(response.data.message);
                    setUpdated(false);
                    setLoader(false);
                }
            } else {
                setError(response.data.message);
                setUpdated(false);
                setLoader(false);
            }
        });
    }

    /* API - table data api */

    const getM2eTableInfo = () => {
        let url = '/m2e/custom-table-info';
        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setError(null);
            } else {
                setError(response.data.message);
            }
        });
    }

    /* m2e details */
    const getM2eDetails = () => {
        setM2eLoader(true);
        let url = '/m2e/detail';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setM2eLoader(false);
                let basicinfo = response.data.data.basic_info;
                setListScrip(basicinfo.game_scrip_count);
                setResultScrip(basicinfo.result_percentage_count)
                setPermission({ label: basicinfo.is_dual_booking_allowed === true ? "Both sides" : "Single side", value: basicinfo.is_dual_booking_allowed });
                setGameStartTime(moment(basicinfo.game_start_time, 'HH:mm A').format('h:mm a'));
                setGameEndTime(moment(basicinfo.game_end_time, 'HH:mm A').format('h:mm a'));
                setBlockingStartTime(moment(basicinfo.booking_start_time, 'HH:mm A').format('h:mm a'));
                setBlockingEndTime(moment(basicinfo.booking_end_time, 'HH:mm A').format('h:mm a'));
                setCoolingEndTime(moment(basicinfo.cooling_end_time, 'HH:mm A').format('h:mm a'));
                setCoolingStartTime(moment(basicinfo.cooling_start_time, 'HH:mm A').format('h:mm a'));
                setUnitFee(basicinfo.unit_fee);
                setUnitsPerScrip(basicinfo.max_units_per_scrip);
                setUnitsPerGame(basicinfo.max_units_per_game);
                setMinimumPrice(basicinfo.minimum_prize);
                setCommissionPercent(basicinfo.commission_percentage);
                let blockrule = response.data.data.block_rules;
                setM2eBlockRuleData(blockrule);
                if (updated === true) {
                    getM2eTableInfo();
                }
                let settings = response.data.data.settings;
                setUrl(settings.video_url);
                setDescription(settings.about_two_line);
                setEditorData(settings.about);
                setUploadImageUrl(settings.image_url);
                setBackgroundColor(settings.background_color)
                setActiveWeb(settings.active_web);
                setActiveMobile(settings.active_mobile);
                setMobileUrl(settings.mobile_video_url);
            } else {
                setError(response.data.message);
                setM2eLoader(false);
            }
        })
    }

    /* Uploading to s3 */
    const uploadDocument = async (data, file) => {
        setImageLoader(true);
        var formdata = new FormData();
        formdata.append("key", data.upload_info.fields.key);
        formdata.append("x-amz-algorithm", data.upload_info.fields['x-amz-algorithm']);
        formdata.append("x-amz-credential", data.upload_info.fields['x-amz-credential']);
        formdata.append("x-amz-date", data.upload_info.fields['x-amz-date']);
        formdata.append("policy", data.upload_info.fields['policy']);
        formdata.append("x-amz-signature", data.upload_info.fields['x-amz-signature']);
        formdata.append("file", file, file.name);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(data.upload_info.url, requestOptions)
            .then(response => response.text())
            .then(result => {
                setUploadImageUrl(data.view_info); // file got uploaded successfully
                setImageLoader(false);
            })
            .catch(error => {
                console.log('error', error); // show error toast here
                setImageLoader(false);
            });
    }

    /* API - Create pre signed url in s3 */
    const preSignedUrl = (file) => {
        var date = new Date()
        setImageLoader(true);
        let url = "/app/presigned-url-upload";
        let data = {
            key: `m2e/` + date.toISOString() + '/thumbnail.png',
            is_public: true
        };

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setImageLoader(true);
                uploadDocument(response.data.data, file); // upload file in AWS s3
            } else {
                setError(response.data.message);
                setImageLoader(false);
            }
        });
    }

    /*--- edit rule ---*/
    const editRule = (data, key) => {
        console.log(key)
        setId(data.block_rule_id)
        document.getElementById(`amount${key}`).disabled = false;
        document.getElementById(`loseIn${key}`).disabled = false;
        setSelectDisable(`select${key}`)
        document.getElementById(`block${key}`).disabled = false;
        document.getElementById(`code${key}`).disabled = false;
        document.getElementById(`type${key}`).style.pointerEvents = "all"
    }

    /* update block rule */
    const updateBlockRule = (item) => {
        let url = "/m2e/update-blockrule";

        let data = {
            "block_rule_id": item.block_rule_id,
            "loss_amount": item.loss_amount,
            "period": item.period,
            "period_type": item.period_type,
            "block_for_days": item.block_for_days,
            "block_code": item.block_code
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                // message.success("Block rule updated");
                getM2eDetails();
                setSelectDisable("")
                setId("");
                setClick(3);
            } else {
                setError(response.data.message);
            }
        })
    }

    /*update m2e settings */
    const updateM2eSettings = () => {
        let apiUrl = "/m2e/update-settings";
        let data = {
            "background_color": backgroundColor,
            "image_url": imageUrl,
            "video_url": url,
            "about_two_line": description,
            "about": editorData,
            "active_web": activeWeb,
            "active_mobile": activeMobile,
            "mobile_video_url": mobileUrl,

        }
        service(true, apiUrl, data).then((response) => {
            if (response.data.status_code === 200) {
                getM2eDetails();

            } else {
                setError(response.data.message);
            }
        })
    }

    const disabledHours = () => {
        let max = Math.max(gameStartTime?.split(":")[0], gameEndTime?.split(":")[0])
        let min = Math.min(gameStartTime?.split(":")[0], gameEndTime?.split(":")[0])
        const hours = [];
        for (let i = min; i < max; i += 1) hours.push(i);
        return hours
    };


    return (
        <>
            <div className='e-game-settings-accordion e-manage-game-accordion e-add-milestone-wrap px-4'>
                {
                    m2eLoader ?
                        <Loader />
                        :
                        <div className="accordion" id="accordionExample">

                            {/* {fee commission and script} with value 1 */}
                            <div className="accordion-item">

                                {/* click to open accordion */}
                                <div onClick={() => setClick(1)}>
                                    {
                                        click !== 1 ?
                                            <h2 className="accordion-header" id="headingOne" >
                                                <button className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    <div className='row'>
                                                        <div className='col-lg-10'>
                                                            Fee, commission & virtual deposit
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-plus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* click to close accordion */}
                                <div onClick={() => setClick(null)}>
                                    {
                                        click === 1 ?
                                            <h2 className="accordion-header" id="headingOne" >
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <div className='row'>
                                                        <div className='col-lg-10'>
                                                            Fee, commission & virtual deposit
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-minus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* accordion body */}
                                {
                                    click === 1 ?
                                        <div >
                                            <div className="accordion-body pt-0">

                                                <div className='row'>
                                                    <div className='col-lg-4'>
                                                        <label className="mb-1">Listing scrip count</label>
                                                        <Input
                                                            placeholder="Scrip count"
                                                            type="number"
                                                            value={parseInt(listscrip)}
                                                            valueChange={(temp) => {
                                                                parseInt(setListScrip(temp))
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <label className="mb-1">Result scrip count</label>
                                                        <Input
                                                            placeholder="Result scrip count"
                                                            type="number"
                                                            value={parseInt(resultscrip)}
                                                            valueChange={(temp) => {
                                                                parseInt(setResultScrip(temp))
                                                            }}
                                                        />

                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <label className="mb-1">Booking permission</label>
                                                        <SelectBox
                                                            options={options}
                                                            value={permission}
                                                            className="e-milestone-sort"
                                                            isSearchable={false}
                                                            selectchange={(temp) => {
                                                                setPermission(temp)
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row pt-4'>
                                                    <div className='col-lg-4'>
                                                        <label className="mb-1">Game time</label>
                                                        <Timepicker
                                                            value={[gameStartTime, gameEndTime]}
                                                            className="e-input-suffix"
                                                            onChange={(time) => {
                                                                setGameStartTime(time[0]);
                                                                setGameEndTime(time[1]);
                                                                setBlockingStartTime("");
                                                                setBlockingEndTime("");
                                                                setCoolingStartTime("");
                                                                setCoolingEndTime("");
                                                            }}
                                                        />
                                                    </div>
                                                    {/* <div className='col-lg-4'>
                                                        <label className="mb-1">Booking time</label>
                                                        <Timepicker
                                                            value={[blockingStartTime, blockingEndTime]}
                                                            disabledHours={disabledHours}
                                                            className="e-input-suffix"
                                                            onChange={(time) => {
                                                                setBlockingStartTime(time[0]);
                                                                setBlockingEndTime(time[1])
                                                            }} />
                                                    </div> */}
                                                    <div className='col-lg-4'>
                                                        <label className="mb-1">Cooling period</label>
                                                        <Timepicker
                                                            value={[coolingStartTime, coolingEndTime]}
                                                            className="e-input-suffix"
                                                            disabledHours={disabledHours}
                                                            onChange={(time) => {
                                                                setCoolingStartTime(time[0]);
                                                                setCoolingEndTime(time[1])
                                                            }} />
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <label className="mb-1">Unit fee</label>
                                                        <Input
                                                            placeholder="Unit fee"
                                                            type="number"
                                                            className="e-input-suffix-rs"
                                                            value={parseFloat(unitFee)}
                                                            valueChange={(temp) => {
                                                                parseFloat(setUnitFee(temp))
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row pt-4'>

                                                    <div className='col-lg-4'>
                                                        <label className="mb-1">Max units per scrip</label>
                                                        <Input
                                                            placeholder="Max units per scrip"
                                                            type="number"
                                                            value={parseInt(unitsPerScrip)}
                                                            valueChange={(temp) => {
                                                                parseInt(setUnitsPerScrip(temp))
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <label className="mb-1">Max units per game</label>
                                                        <Input
                                                            placeholder="Max units per game"
                                                            type="number"
                                                            value={parseInt(unitsPerGame)}
                                                            valueChange={(temp) => {
                                                                parseInt(setUnitsPerGame(temp))
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <label className="mb-1">Minimum price money</label>
                                                        <Input
                                                            placeholder="Minimum price money"
                                                            suffix="₹"
                                                            value={minimumPrice}
                                                            valueChange={(temp) => {
                                                                setMinimumPrice(temp)
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row pt-4'>

                                                    <div className='col-lg-4'>
                                                        <label className="mb-1">Commission percentage</label>
                                                        <Input
                                                            placeholder="Commission percentage"
                                                            type="number"
                                                            suffix="%"
                                                            value={parseInt(commissionPercent)}
                                                            valueChange={(temp) => {
                                                                parseInt(setCommissionPercent(temp))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row pt-3'>
                                                    {
                                                        listscrip && resultscrip && Object.values(permission).length > 0 &&
                                                            gameStartTime && gameEndTime &&  //blockingStartTime && blockingEndTime &&
                                                            coolingStartTime && coolingEndTime &&
                                                            unitFee && unitsPerGame && unitsPerScrip &&
                                                            minimumPrice && commissionPercent !== "" ?
                                                            <div className='col-lg-2'>
                                                                <PrimaryBtn
                                                                    name="Save changes"
                                                                    className="e-acc-btn"
                                                                    handleClick={() => {
                                                                        var modal = new Modal(document.getElementById("confirmation"), {});
                                                                        modal.show();
                                                                        setType("m2e");
                                                                        setTitle("Do yor really want to save changes made to M2E settings?")
                                                                    }}
                                                                />
                                                            </div>
                                                            :
                                                            <div className='col-lg-2'>
                                                                <PrimaryBtn
                                                                    name="Save changes"
                                                                    className="e-acc-btn e-disabled"
                                                                />
                                                            </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            {/* table  with state click value 2*/}
                            <div className="accordion-item">

                                {/* click to open accordion */}
                                <div onClick={() => setClick(2)}>
                                    {
                                        click !== 2 ?
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">

                                                    <div className='row'>
                                                        <div className='col-lg-10'>
                                                            Customize table
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-plus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* click to close accordion */}
                                <div onClick={() => setClick(null)}>
                                    {
                                        click === 2 ?
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">

                                                    <div className='row'>
                                                        <div className='col-lg-10'>
                                                            Customize table
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-minus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* accordion body */}
                                {
                                    click === 2 ?
                                        <div>
                                            <div className="accordion-body pt-0">
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className={`col-lg-12 ${style.e_card_wrap} border-0`}>
                                                            <M2Etable
                                                                tableData={(data) => {
                                                                    setM2eTableData(data);
                                                                }}
                                                                type="m2e" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row pt-3'>
                                                    <div className='col-lg-2'>
                                                        <PrimaryBtn
                                                            name="Save changes"
                                                            className={loader ? "e-acc-btn e-btn-loader" : "e-acc-btn"}
                                                            handleClick={() => customizeTable()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            {/* block rules with state click value 3*/}
                            <div className="accordion-item">

                                {/* click to open accordion */}
                                <div onClick={() => setClick(3)}>
                                    {
                                        click !== 3 ?
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">

                                                    <div className='row'>
                                                        <div className='col-lg-10'>
                                                            Block rules
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-plus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* clcik to close accordion */}
                                <div onClick={() => setClick(null)}>
                                    {
                                        click === 3 ?
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <div className='row'>
                                                        <div className='col-lg-10'>
                                                            Block rules <span><PrimaryLink name="Add new rule" className="ps-2" handleClick={() => {
                                                                setClick(3);
                                                                var modal = new Modal(document.getElementById("add-block-rule"), {})
                                                                modal.show()

                                                            }} /></span>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-minus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* accordion body */}
                                {
                                    click === 3 ?
                                        <div >
                                            <div className="accordion-body pt-0">
                                                {
                                                    m2eBlockRuleData?.map((item, i) => (
                                                        <div className='row' key={item.block_rule_id}>
                                                            <div className='col-lg-3'>
                                                                <label className="mb-1">Lose amount</label>
                                                                <Input
                                                                    type="number"
                                                                    disabled={true}
                                                                    id={`amount${i}`}
                                                                    placeholder="Lose amount"
                                                                    suffix="₹"
                                                                    defaultValue={item.loss_amount}
                                                                    value={loseAmount}
                                                                    valueChange={(temp) => {
                                                                        m2eBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setLoseAmount(temp) : item.loss_amount);
                                                                        let tempArr = [...m2eBlockRuleData];
                                                                        tempArr[i]['loss_amount'] = temp;
                                                                        m2eBlockRuleData = tempArr;
                                                                        setM2eBlockRuleData(m2eBlockRuleData)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='col-lg-3 e-add-block-in position-relative'>
                                                                <label className="mb-1">in</label>

                                                                <Input id={`loseIn${i}`}
                                                                    suffixId={`type${i}`}
                                                                    suffix={loseIn !== null ? loseIn : item.period_type === 1 ? <span onClick={() => { setShow(i) }}>Days <img src={Down} alt="" /></span> : item.period_type === 2 ? <span onClick={() => { setShow(i) }}>Month <img src={Down} alt="" /></span> : item.period_type === 3 ? <span onClick={() => { setShow(i) }}>Quarter <img src={Down} alt="" /></span> : <span onClick={() => { setShow(i) }}>Year <img src={Down} alt="" /></span>}
                                                                    disabled={true} placeholder="days" value={period}
                                                                    defaultValue={item.period}
                                                                    valueChange={(temp) => {
                                                                        m2eBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setPeriod(temp) : item.period);
                                                                        let tempArr = [...m2eBlockRuleData];
                                                                        tempArr[i]['period'] = parseInt(temp);
                                                                        m2eBlockRuleData = tempArr;
                                                                        setM2eBlockRuleData(m2eBlockRuleData)
                                                                    }}
                                                                />
                                                                {
                                                                    show === i ?
                                                                        <div className="e-block-show" ref={wrapperRef}>
                                                                            {
                                                                                ruleOptions.map((ruleItem) => {
                                                                                    return (
                                                                                        <p className={ruleItem.value === item.period_type ? 'e-selected mb-2 mt-2' : 'mb-2 mt-2'}
                                                                                            onClick={() => {
                                                                                                m2eBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setLoseIn(ruleItem.value) : item.period_type);
                                                                                                let tempArr = [...m2eBlockRuleData];
                                                                                                tempArr[i]['period_type'] = parseInt(ruleItem.value);
                                                                                                m2eBlockRuleData = tempArr;
                                                                                                setM2eBlockRuleData(m2eBlockRuleData)
                                                                                                setShow(null)
                                                                                            }}>{ruleItem.label}</p>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className='col-lg-3 e-add-block-rule position-relative'>
                                                                <label className="mb-1">Block for</label>
                                                                <Input id={`block${i}`}
                                                                    suffix={"Days"}
                                                                    disabled={true} placeholder="days" value={blockFor}
                                                                    defaultValue={item.block_for_days}
                                                                    valueChange={(temp) => {
                                                                        m2eBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setBlockFor(temp) : item.block_for_days);
                                                                        let tempArr = [...m2eBlockRuleData];
                                                                        tempArr[i]['block_for_days'] = parseInt(temp);
                                                                        m2eBlockRuleData = tempArr;
                                                                        setM2eBlockRuleData(m2eBlockRuleData)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <label className="mb-1">Block code</label>
                                                                <Input id={`code${i}`}
                                                                    disabled={true}
                                                                    placeholder="days"
                                                                    value={blockCode}
                                                                    defaultValue={item.block_code}
                                                                    valueChange={(temp) => {
                                                                        m2eBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setBlockCode(temp) : item.block_for_days);
                                                                        let tempArr = [...m2eBlockRuleData];
                                                                        tempArr[i]['block_code'] = temp;
                                                                        m2eBlockRuleData = tempArr;
                                                                        setM2eBlockRuleData(m2eBlockRuleData)
                                                                    }} />
                                                            </div>
                                                            <div className='col-lg-1  ps-0'>
                                                                <span className='d-inline-flex mt-4 pt-3'>
                                                                    {
                                                                        item.block_rule_id === id ?
                                                                            <PrimaryLink name="Save" className="e-save-btn" handleClick={() => { updateBlockRule(item) }} /> :
                                                                            <PrimaryLink name="Edit" className="e-edit-btn" handleClick={() => editRule(item, i)} />
                                                                    }
                                                                    <PrimaryLink name="Delete" className="e-delete-btn" target="#confirmation" toggle="modal" handleClick={() => {
                                                                        setId(item.block_rule_id);
                                                                        setTitle("Confirmation");
                                                                        setType("m2e");
                                                                    }} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            {/* settings sec with state click value 4 */}
                            <div className="accordion-item">

                                {/* click to open accordion */}
                                <div onClick={() => setClick(4)}>
                                    {
                                        click !== 4 ?
                                            <h2 className="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">

                                                    <div className='row'>
                                                        <div className='col-lg-10'>
                                                            Game image & color
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-plus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* clcik to close accordion */}
                                <div onClick={() => setClick(null)}>
                                    {
                                        click === 4 ?
                                            <h2 className="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <div className='row'>
                                                        <div className='col-lg-10'>
                                                            Game image & color
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-minus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* accordion body */}
                                {
                                    click === 4 ?
                                        <div >
                                            <div class="accordion-body pt-0">

                                                <div className='row'>
                                                    <div className='col-lg-4 '>
                                                        {/* banner image for the game */}
                                                        <div className='row pe-2'>
                                                            <label className="mb-1">Choose image</label>
                                                            <div className="e-uploader" onClick={() => { $('#e-upload').click() }}>
                                                                {
                                                                    uploadImage ?
                                                                        <>
                                                                            <p className='text-center mb-2'>Choose image</p>
                                                                            <div className='text-center mb-0 setting-detail'>(Selected: {uploadImage})</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <p className='text-center mb-2'>Choose image</p>
                                                                            <p className='text-center mb-0'>
                                                                                <span>(500 X 500 png recommended)</span>
                                                                            </p>
                                                                        </>
                                                                }

                                                                <FileUpload accept="application/pdf, image/*"
                                                                    className="hidden"
                                                                    id="e-upload"
                                                                    key="e-upload-image"
                                                                    upload={(temp) => {
                                                                        preSignedUrl(temp);
                                                                        setUploadImage(temp.name)
                                                                    }} />
                                                            </div>
                                                        </div>

                                                        {/* color palette */}
                                                        <div className='row pt-5 pe-2'>
                                                            <label className="mb-1">Choose background color</label>
                                                            <ColorPicker color={(data) => { setBackgroundColor(data.hex); }} />
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-4 ps-5'>
                                                        <label className="mb-1">Image & bg color preview</label>
                                                        <div className='e-preview-sec'>
                                                            <div style={{ backgroundColor: backgroundColor }}
                                                                className="e-preview-sub-sec">
                                                                <img src={imageLoader ? LoaderGif : imageUrl} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row pt-4'>
                                                    <div className='col-lg-2'>
                                                        <CheckBox
                                                            check={activeWeb}
                                                            checkboxText="Show game in Web"
                                                            onChange={(e) => setActiveWeb(e.target.checked)} />
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <CheckBox
                                                            check={activeMobile}
                                                            checkboxText="Show game in Mobile"
                                                            onChange={(e) => setActiveMobile(e.target.checked)} />
                                                    </div>
                                                </div>
                                                <div className='row pt-3 pe-0'>
                                                    <div className='col-lg-4 pe-0'>
                                                        <label className="mb-1">Tutorial video link for Web</label>
                                                        <Input
                                                            placeholder="Url"
                                                            value={url}
                                                            valueChange={(temp) => setUrl(temp)} />
                                                    </div>
                                                </div>
                                                <div className='row pt-3 pe-0'>
                                                    <div className='col-lg-4 pe-0'>
                                                        <label className="mb-1">Tutorial video link for Mobile</label>
                                                        <Input
                                                            placeholder="Url"
                                                            value={mobileUrl}
                                                            valueChange={(temp) => setMobileUrl(temp)} />
                                                    </div>
                                                </div>
                                                <div className='row pt-3'>
                                                    <div className='col-lg-12'>
                                                        <label className="mb-1">Two liner about game</label>
                                                        <TextArea
                                                            placeholder="Description"
                                                            value={description}
                                                            valueChange={(temp) => setDescription(temp)} />
                                                    </div>
                                                </div>
                                                <div className='row e-accordion-editor pt-3'>
                                                    <div className='col-lg-12'>
                                                        <label className="mb-1">About game</label>
                                                        <Editor
                                                            editorChange={(temp) => {
                                                                setEditorData(temp)
                                                            }}
                                                            value={editorData}
                                                        />
                                                    </div>
                                                </div>


                                                <div className='row pt-3'>
                                                    <div className='col-lg-2'>
                                                        <PrimaryBtn name="Save changes" className="e-acc-btn" handleClick={() => updateM2eSettings()} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                }
            </div>
            <Confirmation type={type}
                apiData={m2eApiData}
                content="Are you sure want to update the settings?"
                title={title}
                block_rule_id={id}
                close={() => { }}
                update={() => {
                    getM2eDetails();
                }} />

            {
                error !== null ?
                    <Toast data={error}
                        type="fail"
                        format={1} />
                    :
                    null
            }

            <AddBlockRule type="m2e"
                ruleAdded={() => getM2eDetails()} />
        </>
    );
}

export default M2EGameSettingsAccordion;
