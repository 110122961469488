/*
 *   File : summary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Summary Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-11-2021
 */

/*import packages*/
import React from 'react';

/*Style Import*/
import styles from '../../styles/components/leaderboard.module.scss';

const LeaderboardCard = (props) => {

  return (
    <div className={`${styles.e_leaderboard_card} card`}>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <h5>
            {props.data.title}
          </h5>
        </div>
        <div className="col-lg-8 col-md-10 col-10">
          <span>
            <span className={styles.e_prefix}>{props.data.prefix}</span><span className={props.className}>{props.data.count}</span><span className={styles.e_text}>{props.data.text}</span></span>
        </div>
        <div className="col-lg-4 col-md-2 col-2 text-end">
          <div className="mt-2">
            <img src={props.data.icon} alt="" className={styles.e_summary_card_icon} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeaderboardCard