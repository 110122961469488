
/*
 *   File : tab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-11-2021
*/

/*import package*/
import { useState } from 'react';

/*import styles*/
import styles from '../../styles/components/tab.module.scss';

const Tab = (props) => {
	const [index, setIndex] = useState(1);
	return (
		<div className="d-flex">
			{
				props.data.map((item, key) => {
					return (
						<span
							className={index === (key + 1) ? `${styles.e_active_tab} me-1 e-active-tab` : `${styles.e_tab} me-1 e-tab`}
							onClick={() => { setIndex((key + 1)); props.setTab((key + 1)) }} key={key}
						>
							{item}
						</span>
					)
				})
			}
		</div>
	)
}

export default Tab;

