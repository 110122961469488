/*
 *   File : primary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-11-2021
 */

/*Style Import*/
import styles from '../../styles/components/primary.module.scss';

const PrimaryBtn = (props) =>{
  return (
    <button id={props.id}
    	// className={`${styles.e_p_btn} ${props.className}`}
      className={props.type ? `${styles.e_p_w_btn} ${props.className}`  : `${styles.e_p_btn} ${props.className}` }
    	data-bs-dismiss={props.cancel}
    	data-bs-toggle={props.toggle}
    	data-bs-target={props.target}
    	onClick={props.handleClick}
    	type={props.type ? props.type : "submit"}
    ><span>{props.name}</span></button>
  );
}

export default PrimaryBtn;
