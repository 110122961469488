/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakcages*/
import React from 'react';

/*import components*/
import { GameCard } from '../../components/card/index';

/*import assets*/
import Game1 from '../../assets/images/card/m2e.svg';
import Game2 from '../../assets/images/card/m2m.svg';
import Game3 from '../../assets/images/card/skill-race.svg';


const Index = (props) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });

  let games = [
    {
      game_name: 'M2E',
      game_icon: Game1,
      background_color: '#15008B',
      link: '/M2E-game'

    },
    {
      'game_name': 'M2M',
      'game_icon': Game2,
      'background_color': '#9085DF',
      link: '/M2M-game'
    },
    {
      'game_name': 'Skill race',
      'game_icon': Game3,
      'background_color': '#D9A214',
      link: '/skill-race-game'
    },
  ];

  return (
    <div className='e-users-container'>
      <div className="row">
        <div className="col-lg-12">
          <div className="card e-dashboard-card e-game-listing-card border-0 p-4">
            <div className="row">
              {
                games.map((item, key) => {
                  return (
                    <div className='col-lg-3' key={key}>
                      {/* <GameCard data={item} /> */}
                      <GameCard data={item} onClick={() => window.location.href = item.link} />
                    </div>
                  )
                })
              }
            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default Index;
