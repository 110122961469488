/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for transaction container;
 *   Integrations : 05-04-2022
 *   Version : v1.0
 *   Created : 10-02-2022
 */
/*import pakcages*/
import { useState, useEffect } from 'react';
import moment from 'moment';

/*import components*/
import { DataTable } from '../../../components/dataFormating/index';
import { SingleDate } from '../../../components/input/index';

/* import services */
import service from '../../../services/service';
import Loader from '../../../components/loader/loader';
import { Toast } from '../../../components/note';
import SelectBox from '../../../components/dropdown/selectBox'

const Scrips = (props) => {
    // var todayDate = new Date().toISOString().slice(0, 10);

    const [click, setClick] = useState("1");
    const [error, setError] = useState(null);
    const [date, setDate] = useState(null);
    const [summaryLoader, setSummaryLoader] = useState(false);
    const [summaryData, setSummaryData] = useState([]);
    const [gameIds, setGameIds] = useState([])
    const [selectedGameId, setSelectedGameId] = useState({ label: "All", value: null })
    const [total, setTotal] = useState({});

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError(null)
        }, 3000)
    }, [error])

    useEffect(() => {
        if (date === null) {
            _getSummary();
        } else {
            _getSummary();
        }
        //eslint-disable-next-line
    }, [date, selectedGameId])

    //m2e game-summary
    const _getSummary = () => {
        setSummaryLoader(true)
        let temp_game_ids = []
        let url = "/m2e/game-summary";

        let data = {
            date,
            game_id: selectedGameId?.value
        }

        service(true, url, data).then((response) => {
            console.log(response)
            if (response.status === 200) {
                if (response.data.status === true) {
                    setSummaryData(response.data.data);
                    setTotal(response.data.total_data);
                    // console.log("options: ", response.data.game_ids)
                    temp_game_ids.push({
                        value: null,
                        label: "All"
                    })
                    response.data.game_ids.map((item) => {
                        return (
                            temp_game_ids.push({
                                value: item,
                                label: `Game id ${item}`
                            })
                        )
                    });
                    setGameIds(temp_game_ids)
                    setSummaryLoader(false);
                } else {
                    setSummaryData([]);
                    setSummaryLoader(false);
                }
            } else {
                setSummaryLoader(false);
                setSummaryData([]);
            }
        });
    }

    const conditionalRowStyles = [
        {
            when: row => row.is_winner === true && row.change > 0,
            style: {
                backgroundColor: 'rgba(14, 147, 75, 0.1)'
            },
        },
        {
            when: row => row.is_winner === true && row.change < 0,
            style: {
                backgroundColor: 'rgba(176, 35, 35, 0.1)'
            },
        },
    ];

    // table columns
    const columns = [
        {
            name: 'Scrip',
            selector: "scrip_code",
            sortable: true,
            left: true,
            minWidth: "150px",
            // Cell: row => row.is_winner === true ? <span style={{ background: `green` }}>{row.scrip_name}</span> : <span style={{ background: "red" }}>jdsjk</span>
        },
        {
            name: 'Booking LTP',
            selector: 'previous_closing',
            sortable: true,
            left: true,
            minWidth: "160px",
        },
        {
            name: 'Closing LTP',
            selector: 'today_closing',
            sortable: true,
            left: true,
            minWidth: "160px",
        },
        {
            name: 'Change %',
            selector: 'change',
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Gainer Qty',
            selector: 'performer_unit',
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Loser Qty',
            selector: 'under_perfromer_unit',
            sortable: true,
            left: true,
            minWidth: "150px",
            // minWidth: "200px",
        },
        {
            name: 'Gainer Amt',
            selector: 'perform_amount',
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Loser Amt',
            selector: 'under_perform_amount',
            sortable: true,
            left: true,
            minWidth: "150px",
            // minWidth: "200px",
        },
        {
            name: 'Prize',
            selector: 'prize_amount',
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Winner',
            selector: row => <>{
                row.is_winner ?
                    <>Yes</>
                    :
                    <>No</>
            }</>,
            sortable: true,
            left: true,
        },

    ]


    const disabledDate = (current) => {
        // Can not select days before today 
        return current && current > moment().endOf('day');
    }
    return (
        <div className='e-users-container e-users-list'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className='row pe-4'>
                            {/* tabs */}
                            <div className='col-lg-6'>
                                <div className='d-flex'>
                                    <span className='ps-2 ms-2 pt-3 mt-1 e-labels'>Show</span>
                                    <div className="e-span-wrap mt-3 ms-3">
                                        <span className={
                                            click === "1"
                                                ?
                                                "e-profile-active-btn"
                                                :
                                                "e-profile-btn"
                                        }
                                            onClick={() => setClick("1")}>
                                            All scrips
                                        </span>
                                        <span className={
                                            click === "2"
                                                ?
                                                "e-profile-active-btn"
                                                :
                                                "e-profile-btn"
                                        }
                                            onClick={() => setClick("2")}>
                                            Performers
                                        </span>
                                        <span className={
                                            click === "3"
                                                ?
                                                "e-profile-active-btn"
                                                :
                                                "e-profile-btn"
                                        }
                                            onClick={() => setClick("3")}>
                                            Under performers
                                        </span>

                                    </div>
                                </div>
                            </div>
                            {/* filter */}
                            <div className='col-lg-2 offset-lg-2'>
                                <SelectBox
                                    options={gameIds}
                                    isSearchable={true}
                                    value={selectedGameId}
                                    selectchange={(temp) => {
                                        // _getSummary(temp.value)
                                        setSelectedGameId(temp)
                                        setClick("1")
                                    }}
                                    placeholder="Game id"

                                />

                            </div>
                            <div className='col-lg-2 e-m2e-ant-picker'>
                                <div className='d-flex'>
                                    <span className='pt-2 mt-1 e-labels me-3'>Date</span>
                                    <SingleDate
                                        placeholder="Date"

                                        className="mt-2"
                                        value={date}
                                        disabledDate={disabledDate}
                                        onChange={(date) => setDate(date)} />
                                    {/* <span className='ps-4 ms-2 pt-3 mt-1 e-labels me-3'>Slot</span>
                                    <SingleDate placeholder="Date" className="mt-2" /> */}
                                </div>
                            </div>
                        </div>

                        {/* table */}
                        <div className="row">
                            <div className='col-lg-12 pt-2'>
                                {
                                    summaryLoader ?
                                        <Loader />
                                        :
                                        <>
                                            <DataTable
                                                pagination={true}
                                                columns={columns}
                                                data={click === "2" ? summaryData.filter((item) => (item.change > 0 && item.is_winner === true)) : click === "3" ? summaryData.filter((item) => (item.change < 0 && item.is_winner === true)) : summaryData}
                                                conditionalRowStyles={conditionalRowStyles}

                                            />
                                            <div className='row px-3 pb-3 pt-4'>
                                                <div className='col-lg-2'>
                                                    <h6>Total prize amount</h6>
                                                    <p>{total?.total_prize_amount !== "-" ? `₹${total?.total_prize_amount}` : "--"}</p>
                                                </div>
                                                <div className='col-lg-2'>
                                                    <h6>Total perfomer qty</h6>
                                                    <p>{total?.total_perform_quantity}</p>
                                                </div>
                                                <div className='col-lg-2'>
                                                    <h6>Total underformer qty</h6>
                                                    <p>{total?.total_under_perform_quantity}</p>
                                                </div>

                                                <div className='col-lg-2'>
                                                    <h6>Total performer amount </h6>
                                                    <p>{total?.total_perform_amount !== "-" ? `₹${total?.total_perform_amount}` : "--"}</p>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <h6>Total underformer amount</h6>
                                                    <p>{total?.total_under_perform_amount !== "-" ? `₹${total?.total_under_perform_amount}` : "--"}</p>
                                                </div>

                                            </div>
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </div>

    )
}

export default Scrips;
