/*
 *   File : footer.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Footer
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-11-2021
 */

/*import packages*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/footer.module.scss';

/*import config*/
import devconfig from '../../config/config';


export default function Footer(props) {

  return (
    <>
      <div className={`${styles.e_footer}`}>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="d-inline-flex">
              {/* <img src={devconfig.footer.footerLogo} alt="footerLogo" className={`${styles.e_footer_logo} ms-2`} /> */}
              <div className={`${styles.e_footer_left} pt-1`}>{devconfig.footer.footerCopyRight}</div>
              <span className={`${styles.e_footer_label} ms-3 pt-2`}>Powered by</span><a className={`${styles.e_footer_powered} pt-2 ms-2`} href="https://evoqueinnovativelab.com/" target="_blank" rel="noreferrer">{devconfig.footer.poweredby}</a>
            </div>
          </div>
          {/* <div className="col-lg-3">
            
          </div> */}
          <div className="col-lg-6 col-md-6 text-end">
            {/* <div className={`${styles.e_social_wrap} d-inline-flex pt-1`}>
              {
                devconfig.footer.footerItems.map((item, key)=>{
                  return(
                    <div className={key === (devconfig.footer.footerItems.length-1) ?  `${styles.e_link}` : `${styles.e_link} me-5`}>
                      <a href={item.link} target="_blank">{item.name}</a>
                    </div>
                  )
                })
              }
              <a className={`${styles.e_footer_company_link} ms-5`} href="https://equplus.in/" target="_blank" rel="noreferrer">{devconfig.footer.supportLink}</a>
            </div> */}
          </div>

        </div>
      </div>
    </>
  )
}

