/*
 *   File : badge.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : badge
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 08-11-2021
 */

import React from 'react';

/*import styles*/
import styles from '../../styles/components/badge.module.scss';

export default function Badge(props) {
  return (
    <>
      {
        !props.status ?
          <span className={props.type === "Success" || props.type === "SUCCESS" ? `${styles.e_success} ${props.className}` : props.type === "Processing" || props.type === "PROCESSING" ? `${styles.e_wait} ${props.className}` : `${styles.e_fail} ${props.className}`} onClick={props.handleClick}>{props.text?.charAt(0).toUpperCase() + props.text?.slice(1).toLowerCase()}</span>
          :
          <span className={props.type === "Success" || props.type === "SUCCESS" ? `${styles.e_success} ${props.className}` : props.type === "Processing" || props.type === "PROCESSING" ? `${styles.e_wait} ${props.className}` : `${styles.e_fail} ${props.className}`} onClick={props.handleClick}>{props.text?.charAt(0).toUpperCase() + props.text?.slice(1).toLowerCase()}</span>
      }
    </>
  )
}