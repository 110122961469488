/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 08-02-2022
 */
/*import pakcages*/
import { useState, useEffect } from 'react';
import moment from 'moment';

/*import components*/
import { Rangepicker } from '../../components/input';
import SelectBox from '../../components/dropdown/selectBox';
import { PrimaryBtn, PrimaryLink } from '../../components/cta';
import Loader from '../../components/loader/loader';
import { DataTable } from '../../components/dataFormating';

/* import services */
import GetURLVar from '../../services/getUrl';
import service from '../../services/service';

/*import assets */
import Nodata from '../../assets/images/note/no-data.png';
import { EmptyNote } from '../../components/note';

const Reports = (props) => {
    const [userId, setUserId] = useState(null)
    const [dates, setDates] = useState({ start: null, end: null });
    const [scripid, setScripId] = useState({ label: "All", value: null })
    const [error, setError] = useState(null);
    const [scripDataFilter, setScripDataFilter] = useState([])
    const [loader, setLoader] = useState(false);
    const [summaryData, setSummaryData] = useState([]);
    const [totals, setTotals] = useState({});
    const [gameType, setGameType] = useState({ label: "M2E", value: 1 });
    const [slotOptions, setSlotOptions] = useState([])
    const [slotId, setSlotId] = useState({});
    const [downloadLoader, setDownloadLoader] = useState(false);

    useEffect(() => {

        getUrl()

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        getScripFilter()
        _getSlots();
    }, []);

    const getUrl = async () => {
        let url = await GetURLVar();
        setUserId(url.id);
    }

    useEffect(() => {
        if (userId) {
            getSummary(1);
        }
        //eslint-disable-next-line
    }, [userId, scripid, dates])

    useEffect(() => {
        if (error != null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    const _getSlots = () => {
        const url = "/m2m/game-slots-filter"

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tempSlot = []
                tempSlot.push({ label: "All", value: null })
                response.data.data.map((item) => {
                    return (
                        tempSlot.push({
                            label: `${item.name}`,
                            value: item.slot_id
                        })
                    )
                })
                setSlotOptions(tempSlot)
                setSlotId(tempSlot[0])
            } else {
                setSlotOptions([])
            }
        })
    }

    const columns = [
        {
            name: 'Scrip name',
            selector: 'scrip_code',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row =>
                <div >
                    {row.scrip_code}
                </div>
        },
        {
            name: 'Gainer Qty',
            selector: row => row.gainer_qty,
            sortable: false,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Loser Qty',
            selector: row => row.loser_qty,
            sortable: false,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Booking amount',
            selector: row => parseFloat(row.booking_amount),
            sortable: true,
            left: true,
            minWidth: "170px",
            cell: (row) => { return <div>{row.booking_amount ? `₹ ${row.booking_amount}` : "--"}</div> }
        },
        {
            name: 'Winning amount',
            selector: row => parseFloat(row.wining_amount),
            sortable: true,
            left: true,
            minWidth: "170px",
            cell: (row) => { return <div>{row.wining_amount ? `₹ ${row.wining_amount}` : "--"}</div> }
        },
        {
            name: 'P&L',
            selector: "p&l",
            sortable: false,
            left: true,
            minWidth: '120px',
            cell: (row) => <>
                {
                    row["p&l"] < 0 ?
                        <span style={{ color: "red" }}>
                            ₹ {row["p&l"]}

                        </span>
                        :
                        row["p&l"] !== "-" ?
                            <span style={{ color: "green" }}>
                                ₹ +{row["p&l"]}

                            </span>
                            :
                            "-"
                }
            </>
        },
        {
            name: 'Win Qty',
            selector: row => Number(row.win_qty),
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Lose Qty',
            selector: row => Number(row.lose_qty),
            sortable: true,
            left: true,
            minWidth: "150px",
        },

        {
            name: 'Platform fee',
            selector: row => parseFloat(row.platform_fees),
            cell: row => `₹ ${parseFloat(row.platform_fees)}`,
            sortable: true,
            left: true,
            minWidth: '140px'
        },
        {
            name: 'TDS',
            selector: row => row.tds,
            sortable: false,
            left: true
        },
    ];

    const skillraceSummaryColumns = [
        // {
        //     name: 'Client ID',
        //     selector: 'client_id',
        //     sortable: false,
        //     left: true,
        //     minWidth: "130px"
        // },
        // {
        //     name: 'Name',
        //     selector: 'client_name',
        //     sortable: false,
        //     left: true,
        //     minWidth: "140px",
        //     cell: row => <div className='e-name-link' onClick={() => navigate(`/user-detail?id=${row.user_id}`)}>{row.client_name}</div>
        // },
        {
            name: 'Scrip name',
            selector: 'scrip_code',
            sortable: true,
            left: true,
            minWidth: '160px'
        },

        {
            name: 'Bookings',
            selector: row => Number(row.no_of_bookings),
            sortable: true,
            left: true,
            minWidth: "160px",
        },
        {
            name: 'Positive entries',
            selector: row => Number(row.number_of_positive_entry),
            sortable: true,
            left: true,
            minWidth: "180px",
            cell: (row) => { return <div>{row.number_of_positive_entry ? row.number_of_positive_entry : "--"}</div> }
        },
        {
            name: 'Negative entries',
            selector: row => parseFloat(row.number_of_negative_entry),
            sortable: true,
            left: true,
            minWidth: "180px",
        },
        {
            name: 'Booking amount',
            selector: row => Number(row.booking_amount),
            cell: row => "₹ " + Number(row.booking_amount),
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Buy entries',
            selector: row => Number(row.number_of_buy_entry),
            sortable: true,
            left: true,
            minWidth: "150px",
        },
        {
            name: 'Sell entries',
            selector: row => parseFloat(row.number_of_sell_entry),
            sortable: true,
            left: true,
            minWidth: '140px'
        },

    ];

    /*-------- get filters for scrips ----*/
    const getScripFilter = (temp) => {
        let url = '/scrip/scrip-filter';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let tArray = [{ value: null, label: 'All' }];
                for (let index = 0; index < response.data.data.length; index++) {
                    const element = response.data.data[index];

                    tArray.push({ value: element.scrip_id, label: element.scrip_code })
                }
                setScripDataFilter(tArray);
            }
        })
    }

    const getSummary = (temp, slot) => {
        setLoader(true)
        let url = ""
        if (temp === 1) {
            url = "/report/m2e-booking-summary";
            //eslint-disable-next-line
            var data = {
                "from_date": dates.start,
                "to_date": dates.end,
                "scrip_id": scripid?.value,
                "customer_id": Number(userId)
            }
        } else if (temp === 2) {
            url = "/report/m2m-booking-summary";
            //eslint-disable-next-line
            var data = {
                "from_date": dates.start,
                "to_date": dates.end,
                "scrip_id": scripid?.value,
                "slot_id": slot,
                "customer_id": Number(userId)
            }
        } else {
            url = "/report/skill-race-booking-summary";
            //eslint-disable-next-line
            var data = {
                "from_date": dates.start,
                "to_date": dates.end,
                "scrip_id": scripid?.value,
                "customer_id": Number(userId),
                "is_download": false
            }
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                setSummaryData(response.data.data);
                setTotals(response.data.total_data);
            } else {
                setLoader(false);
                setSummaryData([]);
            }
        })
    }

    const resetFilters = () => {
        setDates({
            start: null,
            end: null
        });
        setScripId({ label: "All", value: null });
        setSlotId(slotOptions[0])
    }

    const downloadSummary = () => {
        setDownloadLoader(true)
        if (gameType.value === 1) {
            //eslint-disable-next-line
            var url = "/user/report/m2e-booking ";
        } else if (gameType.value === 2) {
            //eslint-disable-next-line
            var url = "/user/report/m2m-booking ";
        } else {
            //eslint-disable-next-line
            var url = "/report/skill-race-booking-summary";

        }

        if (gameType.value === 1) {
            var data = {
                "user_id": Number(userId),
                "from_date": dates.start,
                "to_date": dates.end,
                "scrip_id": scripid?.value,
            }
        } else if (gameType.value === 2) {
            //eslint-disable-next-line
            var data = {
                "user_id": Number(userId),
                "from_date": dates.start,
                "to_date": dates.end,
                "scrip_id": scripid?.value,
                "slot_id": slotId?.value
            }
        } else {
            //eslint-disable-next-line
            var data = {
                "from_date": dates.start,
                "to_date": dates.end,
                "scrip_id": scripid?.value,
                "user_id": Number(userId),
                "is_download": true
                // "slot_id": slotId.value
            }
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownloadLoader(false);
            } else {
                setDownloadLoader(false);
                setError(response.data.message)
            }
        });
    }
    const Export = ({ onExport }) => <PrimaryLink left={true}
        className={downloadLoader ? "e-disabled ps-0" : "ps-0"}
        name={downloadLoader ? "Downloading" : "Download"}
        handleClick={e => onExport(e.target.value)}>Export</PrimaryLink>;

    const selectOptions = [
        // { label: 'All', value: null },
        { label: 'M2E', value: 1 },
        { label: 'M2M', value: 2 },
        { label: 'Skill race', value: 3 }
    ]

    return (
        <div className='e-users-container'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className="row">
                            <div className="col-lg-12 pt-4">
                                <h6 className="e-card-title ps-3">Bookings summary</h6>
                            </div>
                        </div>
                        <div className="row pt-4 px-3">
                            <div className='row'>
                                <div className='col-lg-2 col-4'>
                                    <label className='mb-2'>Game type</label>
                                    <SelectBox
                                        className="me-2 e-bookings-sort"
                                        options={selectOptions}
                                        value={gameType}
                                        selectchange={(temp) => {
                                            setGameType(temp);
                                            getSummary(temp?.value)
                                        }}
                                        isSearchable={false}
                                        placeholder="Game  type"
                                    />
                                </div>
                                <div className='col-lg-2 col-4'>
                                    <label className='mb-2'>Scrip</label>
                                    <SelectBox
                                        className="me-2 e-bookings-sort"
                                        options={scripDataFilter}
                                        value={scripid}
                                        selectchange={(temp) => { setScripId(temp); }}
                                        isSearchable={true}
                                        placeholder="Scrip"
                                    />
                                </div>
                                {
                                    gameType?.value === 2 ?
                                        <div className='col-lg-2 col-4 e-m2m-slot'>
                                            <label className='mb-2'>Slot</label>
                                            <SelectBox
                                                className="me-2 e-bookings-sort"
                                                options={slotOptions}
                                                value={slotId}
                                                selectchange={(temp) => { setSlotId(temp); getSummary(2, temp.value) }}
                                                isSearchable={false}
                                                placeholder="Select "
                                            />
                                        </div>
                                        :
                                        null
                                }
                                <div className='col-lg-3 col-5 e-accordion-date ps-0'>
                                    <label className='mb-2'>{
                                        gameType.value === 1 ?
                                            "Game result date"
                                            :
                                            "Filter by game creation date   "
                                    }</label>
                                    <Rangepicker
                                        start={dates.start}
                                        placeholder={['From', "To"]}
                                        disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                        end={dates.end}
                                        onDatesChange={(start, end) => {
                                            setDates({
                                                start: start,
                                                end: end
                                            })
                                        }} />
                                </div>
                                <div className='col-lg-1 col-2 mt-4 pt-1'>
                                    <PrimaryBtn name="Submit" className="e-report-download-btn" handleClick={() => { getSummary() }} />
                                </div>
                                <div className='col-lg-1 col-2 pe-0 mt-4 pt-2'>
                                    <PrimaryLink
                                        name="Clear all"
                                        className='me-2'
                                        handleClick={() => {
                                            resetFilters()
                                        }}
                                    />
                                </div>
                                <div className='col-lg-1 col-1 text-start mt-2 pt-2'>
                                    {
                                        summaryData && summaryData.length ?
                                            <Export onExport={() => {

                                                downloadSummary()

                                            }} />
                                            :
                                            <PrimaryLink
                                                left={true}
                                                name="Download"
                                                className="e-disabled e-download-booking"
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 e-booking-table pt-3 px-0">
                            {
                                loader ?
                                    <Loader />
                                    :
                                    summaryData.length > 0 ?
                                        <>
                                            <DataTable
                                                pagination={true}
                                                columns={gameType.value === 3 ? skillraceSummaryColumns : columns}
                                                data={summaryData}

                                            />
                                            {
                                                gameType.value !== 3 ?
                                                    <div className='row px-4 pb-3 pt-4'>
                                                        <div className='col-lg-2'>
                                                            <h6>Total booking amount</h6>
                                                            <p>₹{totals?.total_booking_amount}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total gainer qty</h6>
                                                            <p>{totals?.total_gainer_qty}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total loser qty</h6>
                                                            <p>{totals?.total_looser_qty}</p>
                                                        </div>
                                                        <div className='col-lg-1 px-0'>
                                                            <h6>Total p&l </h6>
                                                            <p>{totals?.total_p_l}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total platform fees </h6>
                                                            <p>₹{totals?.total_platform_fees}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total rewards</h6>
                                                            <p>₹{totals?.total_reward}</p>
                                                        </div>
                                                        <div className='col-lg-1'>
                                                            <h6>Total tds</h6>
                                                            <p>{totals?.total_tds}</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='row px-3 pb-3 pt-4'>
                                                        <div className='col-lg-2'>
                                                            <h6>Total booking amount</h6>
                                                            <p>₹{totals?.total_booking_amount}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total bookings qty</h6>
                                                            <p>{totals?.total_bookings_qty}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total positive entries</h6>
                                                            <p>{totals?.total_positive_entries}</p>
                                                        </div>
                                                        <div className='col-lg-2 px-0'>
                                                            <h6>Total negative entries </h6>
                                                            <p>{totals?.total_negative_entries}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total buy entries </h6>
                                                            <p>{totals?.total_buy_entries}</p>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6>Total sell entries</h6>
                                                            <p>{totals?.total_sell_entries}</p>
                                                        </div>
                                                    </div>
                                            }
                                        </>
                                        :
                                        <EmptyNote img={Nodata} data="No data found" />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Reports;