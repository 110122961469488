/*
 *   File : addTransaction.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add transaction Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input, RadioButton } from '../../components/input/index';
import { PrimaryBtn, PrimaryLink, SecondaryBtn } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';
import service from '../../services/service';
import { Toast } from '../note';

import Logo from '../../assets/images/scrips/logo.svg'
import Delete from '../../assets/images/scrips/delete.svg'
import SelectBox from '../dropdown/selectBox';
import Loader from '../loader/loader';

const options = [
    {
        value: 'nse50', label: 'NSE 50',
    },
    {
        value: 'nse100', label: 'NSE 100'
    },
    {
        value: 'm2e', label: 'M2E'
    },
    {
        value: 'm2m', label: 'M2M'
    },
    {
        value: 'skillrace', label: 'Skill race'
    },
]

export default function UpdateScripType(props) {
    //eslint-disable-next-line
    const wrapperRef = useRef(null);
    const apiRef = useRef(false)
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState("");
    const [show, setShow] = useState(false);
    const [scripData, setscripData] = useState([]);
    const [chosenScrips, setChosenScrips] = useState([]);
    const [scripIds, setScripIds] = useState([])
    // const [scripCount, setScriCount] = useState(null);
    const [scripType, setScripType] = useState(options[0]);
    const [type, setType] = useState(false);
    const [dataLoader, setDataLoader] = useState(false);
    //eslint-disable-next-line
    const [placeholders, setPlaceholder] = useState([

        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
        {
            id: 0,
            scrip_image: null,
            srip_name: null,
            scrip_code: null
        },
    ]);

    useEffect(() => {
        var myModalEl = document.getElementById('updateScripType')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            setChosenScrips([]);
            setScripIds([]);
            setScripType(options[0]);
            setType(false);
            setLoader(false);
            props.closeModal()
        })
        //eslint-disable-next-line
    }, [])


    useEffect(() => {


        getScrip();

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    const _closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShow(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);

    /*---- search user ----*/
    const searchScrip = (value, status) => {
        setShow(status);
        // setSearch(value)
        let url = "/scrip/search";

        let data = {
            query: value,
            // type: scripType.value
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setscripData(response.data.data);
            } else {
                setscripData([]);
            }
        });
    }

    //update scrip api  
    const updateScrip = () => {
        setLoader(true);
        let url = "/scrip/update-scrip-type";
        let data = {
            scrip_ids: scripIds,
            scrip_type: scripType.value,
            remove_type: type
        }
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                $("#updateScripType").trigger("click");
                props.success();
                setLoader(false);
            } else {
                setError(response.data.message);
                setLoader(false);
            }
        })
    }

    /* m2e details */
    const getScrip = (type) => {

        setDataLoader(true);
        let url = "/scrip/scrip-list"

        let data = {
            type: type ? type : 'nse50'
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                apiRef.current = true
                setDataLoader(false);
                let tempScrips = []
                response.data.data.map((item) => {
                    return (
                        tempScrips.push({
                            name: item.scrip_name,
                            code: item.scrip_code,
                            scrip_code: item.scrip_code,
                            scrip_id: item.scrip_id
                        })
                    )
                })
                let tempScripsIds = []
                response.data.data.map((item) => {
                    return (
                        tempScripsIds.push(
                            item.scrip_id
                        )
                    )
                })
                setScripIds(tempScripsIds)
                setChosenScrips(tempScrips)
                // console.log(response.data.data.basic_info.game_scrip_count)
            } else {
                setChosenScrips([]);
                setScripIds([])
                setDataLoader(false);
            }
        })
    }

    // console.log(scripCount)
    return (
        <>
            <div className={`${styles.e_add_faq} modal fade e-add-block-rule`} id="updateScripType" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Update scrips</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { }}></button>
                        </div>
                        <div className="container px-3">

                            <div className="row">


                                <div className="col-lg-8 position-relative" onClick={() => { searchScrip(" ", true) }}>
                                    <label className="mb-2">Scrips</label>
                                    <Input
                                        placeholder="Search scrips"
                                        value={search}
                                        valueChange={(value) => {
                                            searchScrip(value, true);
                                            setSearch(value)
                                        }}
                                        type="text"
                                    />
                                    <>
                                        {
                                            show ?
                                                scripData?.length > 0 ?
                                                    <div className='e-get-scrip-list' ref={wrapperRef}>
                                                        {
                                                            scripData.map((element) => {
                                                                return (
                                                                    <div className='e-list-items mb-2' >
                                                                        <div className='d-flex'>
                                                                            <span>{element.name},{` (${element.code}) `}</span>
                                                                            <span className='ms-auto'>
                                                                                {
                                                                                    scripIds.includes(element.scrip_id) ?
                                                                                        null
                                                                                        :
                                                                                        <SecondaryBtn
                                                                                            name="+Add"
                                                                                            className={"px-3 py-2"}
                                                                                            handleClick={() => {
                                                                                                let temp = [...chosenScrips]
                                                                                                let tempIds = [...scripIds]
                                                                                                let idx = tempIds.findIndex((item) => item === element.scrip_id)
                                                                                                let idxChosen = temp.findIndex((item) => item.scrip_id === element.scrip_id)
                                                                                                if (idx === -1) {
                                                                                                    tempIds.push(element.scrip_id)
                                                                                                }
                                                                                                if (idxChosen === -1) {
                                                                                                    temp.push(element)
                                                                                                }
                                                                                                setScripIds(tempIds)
                                                                                                setChosenScrips(temp)
                                                                                            }} />
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    <div className='e-get-list d-flex justify-content-center align-items-center'>
                                                        No data found
                                                    </div>
                                                :
                                                null
                                        }

                                    </>
                                </div>
                                <div className='col-lg-4'>
                                    <label className="mb-1">Scrip type</label>
                                    <SelectBox
                                        value={scripType}
                                        options={options}
                                        isSearchable={false}
                                        className="e-milestone-sort"
                                        selectchange={(temp) => {
                                            setScripType(temp)
                                            getScrip(temp.value)
                                        }}
                                    />

                                </div>
                                <div className='col-lg-12 d-flex'>
                                    <RadioButton checked={!type}
                                        label="Add"
                                        id={1}
                                        onClick={(e) => {
                                            setType(false);
                                        }} />
                                    <RadioButton checked={type}
                                        label="Remove"
                                        id={2}
                                        onClick={(e) => {
                                            setType(true);
                                        }} />
                                </div>


                                <div className='row pt-4 mt-2 mb-4 pb-2  '>
                                    <div className='col-lg-10 col-md-11 col-10 d-flex'>
                                        <h5 className='pt-1'>Scrips you chose</h5>
                                        {
                                            scripIds.length > 0 ?
                                                <PrimaryLink name="Clear all scrips"
                                                    className="ps-3"
                                                    handleClick={() => {
                                                        setChosenScrips([]);
                                                        setScripIds([]);
                                                    }} />
                                                :
                                                null
                                        }
                                    </div>
                                    {/* <div className='col-lg-2 col-md-1 col-2 text-end'>
                                        <span>{chosenScrips.length}/{scripCount}</span>
                                    </div> */}

                                </div>
                                <div className='row e-scrips-list'>
                                    {
                                        dataLoader ?
                                            <Loader />
                                            :
                                            chosenScrips.length > 0 ?
                                                chosenScrips.map((item, key) => {
                                                    return (
                                                        <div className='col-lg-6 col-md-6 mb-4 pb-2' key={key}>
                                                            <div className='d-flex'>
                                                                {/* <img alt="" src={item.scrip_image ? item.scrip_image : Logo} className='me-2 e-scrip-votin-logo' /> */}
                                                                <div className={item.name ? "" : "mt-2"}>
                                                                    <span>{item.name ? item.name : ' To be added'}</span>
                                                                    {
                                                                        item.scrip_code ?
                                                                            <span className='d-block'>{item.code}</span>
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                                <img src={Delete} alt="" className='ms-auto' style={{ cursor: "pointer" }} onClick={() => {

                                                                    let tempChosen = [...chosenScrips]
                                                                    let idxChosen = tempChosen.findIndex((element) => element.scrip_id === item.scrip_id)
                                                                    tempChosen.splice(idxChosen, 1)
                                                                    setChosenScrips(tempChosen)

                                                                    let temp = [...scripIds]
                                                                    let idx = temp.findIndex((element) => element === item.scrip_id)
                                                                    temp.splice(idx, 1)
                                                                    setScripIds(temp)

                                                                }} />
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                                :
                                                placeholders.map((item, key) => {
                                                    return (
                                                        <div className='col-lg-6 col-md-6 mb-4 pb-2' key={key}>
                                                            <div className='d-flex'>
                                                                <img alt="" src={item.scrip_image ? item.scrip_image : Logo} className='me-2 e-scrip-votin-logo' />
                                                                <div className={item.scrip_name ? "" : "mt-2"}>
                                                                    <span>{item.scrip_name ? item.scrip_name : ' To be added'}</span>
                                                                    {
                                                                        item.scrip_code ?
                                                                            <span className='d-block'>{item.scrip_code}</span>
                                                                            :
                                                                            null
                                                                    }

                                                                </div>

                                                            </div>

                                                        </div>
                                                    )
                                                })
                                    }
                                </div>


                            </div>
                            <div className="row pt-3 mt-2  pb-2">
                                <div className="col-lg-12 pt-3 mt-2 text-end">
                                    <label></label>
                                    {
                                        // scripIds.length > 0 ?  // userId?.id && amount && remarks && selectedType ?
                                        <PrimaryBtn
                                            name="Save"
                                            className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                            handleClick={() => {
                                                updateScrip()
                                            }}
                                        />
                                        // :
                                        // <PrimaryBtn
                                        //     name="Save"
                                        //     className={`${styles.e_disabled} e-modal-submit `}
                                        // />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }
        </>
    )
}
