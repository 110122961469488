/*
 *   File : kycData.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc data Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { Input, RadioButton } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';


/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';

/* import services */
import service from '../../services/service';
import { Toast } from '../note';
import SelectBox from '../dropdown/selectBox';


export default function ManageKYC(props) {

    const [reason, setReason] = useState("");
    const [valid, setValid] = useState(null);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [bankData, setBankData] = useState([]);
    const [bankId, setBankId] = useState(null);


    useEffect(() => {
        let temp = []
        props.bank_details.map((item) => {
            return (
                temp.push({
                    label: `${item.bankname} - ${item.account_number} ${item.is_default ? "(Default)" : ""}`,
                    value: item.bank_id
                })
            )
        })
        setBankData(temp)
    }, [props.bank_details])

    useEffect(() => {
        var myModalEl = document.getElementById('kyc-data')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            props.Close()
        })
        //eslint-disable-next-line
    }, [])

    /* manage kyc api */

    const manage = () => {
        setLoader(true);
        let url = "/user/manage-kyc";

        let data = {
            "user_id": props.id,
            "is_valid": valid === 1 ? true : false,
            "remark": reason,
            "bank_id": bankId
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                $("#kyc-data").trigger("click");
                $(".fade").removeClass("modal-backdrop");
                props.Success()
                setLoader(false);
            } else {
                setLoader(false);
                setError(response.data.message);
                setTimeout(() => {
                    setError(null)
                }, 3000)
            }
        });
    }


    return (
        <>
            <div className={`${styles.e_add_faq} e-manage-faq modal fade`} id="kyc-data" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Manage KYC</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.Close()}></button>
                        </div>
                        <div className="container px-3">
                            <div className="row">
                                <label>Is valid</label>
                                <div className="col-lg-12 d-flex">
                                    <RadioButton checked={Number(valid) === 1 ? true : false} label="Yes" id="valid" onClick={(e) => setValid(1)} />
                                    <RadioButton checked={Number(valid) === 2 ? true : false} label="No" id="invalid" onClick={(e) => setValid(2)} />
                                </div>
                                <div className="col-lg-6 mt-2">
                                    <label>Remark</label>
                                    <Input
                                        placeholder="Remark"
                                        value={reason}
                                        maxLength={50}
                                        valueChange={(value) => {
                                            setReason(value)
                                        }}
                                        type="text"
                                    />
                                </div>
                                <div className="col-lg-6 mt-2">
                                    <label>Select default bank</label>
                                    <SelectBox
                                        placeholder="Select bank"
                                        options={bankData}
                                        isSearchable={false}
                                        selectchange={(temp) => {
                                            setBankId(temp?.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row pt-4 mt-2 pb-2">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className="e-cancel"
                                            cancel="modal"
                                            handleClick={() => props.Close()} />
                                        {
                                            valid !== null ?
                                                <PrimaryBtn
                                                    name="Save"
                                                    className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                    handleClick={() => manage()}
                                                />
                                                :
                                                <PrimaryBtn
                                                    name="Save"
                                                    className={`e-modal-submit e-disabled`}
                                                />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error}
                        type="fail"
                        format={1} />
                    :
                    null
            }
        </>
    )
}
