/*
 *   File : blocked-users.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Blocked users;
 *   Integrations :
 *   Version : v1.0
 *   Created : 15-02-2022
 */
/*import pakcages*/
import { useState, useEffect } from 'react';
import moment from 'moment';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { SearchInput } from '../../components/input/index';
import { Rangepicker } from '../../components/input/index';
import { EmptyNote } from '../../components/note';
import Loader from '../../components/loader/loader';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import services*/
import service from '../../services/service';

/* import assets */
import Nodata from '../../assets/images/note/no-data.png';
import { DownloadBtn } from '../../components/cta';

const SkillraceToper = (props) => {
    // var today = moment(new Date());
    // var newDate = today.format("YYYY-MM-DD")
    const [search, setSearch] = useState("");
    // const [date, setDate] = useState(newDate);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [m2etoperData, setM2eToperData] = useState([]);
    const [btn_loader, set_btn_loader] = useState(false);
    const [range, setRange] = useState({
        start: null,
        end: null
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        getM2ELeaderboardList();
        //eslint-disable-next-line
    }, [range])

    const openDetail = (id) => {
        window.open(`/user-detail?id=${id}`, "_blank");
    }

    const columns = [
        {
            name: 'Rank',
            selector: 'rank',
            sortable: true,
            left: true,
            width: "100px",
        },
        {
            name: 'Client ID',
            selector: 'user_id',
            sortable: true,
            left: true,
            cell: row => (row.client_id),
            minWidth: "140px",
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            left: true,
            minWidth: "180px",
            cell: row => <div className='e-name-link' onClick={() => openDetail(row.user_id)}>{row.name}</div>,
        },
        {
            name: 'Booking amount',
            selector: 'booking_amount',
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <div>₹{row.booking_amount}</div>,
        },
        {
            name: 'Prize amount',
            selector: 'prize_amount',
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <div   >₹{row.prize_amount}</div>,
        },
        {
            name: 'Rating',
            selector: 'rating',
            sortable: true,
            left: true,

            // cell: row => <div className="d-flex">
            //   {row.blocked_on}
            //   <div className="e-time ps-3">5.00 PM</div>
            // </div>
        },
        {
            name: 'Won/Played',
            selector: row => <span>{row.won}/{row.played}</span>,
            sortable: false,
            left: true,
            minWidth: "140px",
            // width: "120px",

        },
        {
            name: 'Returns',
            selector: 'return_percentage',
            sortable: false,
            left: true,
            cell: row => row.return_percentage + "%",
            minWidth: '120px'
            // width: "180px",
        },
        {
            name: 'Pay in',
            selector: 'pay_in_amount',
            sortable: false,
            left: true,
            cell: row => <>₹{row.pay_in_amount}</>,
            minWidth: "160px",
        },
        {
            name: 'Pay out',
            selector: 'pay_in_out',
            sortable: false,
            left: true,
            cell: row => <>₹{row.pay_in_out}</>,
            minWidth: "160px",
            // cell: row => <div className="e-action-primary">
            //   {row.action}
            // </div>
        },
        {
            name: 'Wallet bal',
            selector: 'wallet_balance',
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <div className={row.wallet_balance > 0 ? "e-positive" : "e-negative"}>₹{row.wallet_balance}</div>,
        },
        {
            name: 'P/L',
            selector: 'profit_loss',
            sortable: true,
            left: true,
            width: "140px",
            cell: row => <div className={row.profit_loss > 0 ? "e-positive" : "e-negative"}>₹{row.profit_loss}</div>,
        },
        {
            name: 'Email',
            selector: 'email',
            cell: row => row.email,
            sortable: false,
            right: false,
            minWidth: "200px",
        },
        {
            name: 'Phone',
            selector: row => row.mobile,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
    ]

    /* get loaderboardlist */
    const getM2ELeaderboardList = (query, download) => {
        if (!download) {
            setLoader(true);
        } else {
            set_btn_loader(true)
        }
        let url = "/leaderboard/get";

        let data = {
            // "date": date,
            "game_type": 3,
            "query": query ? query.value : search,
            "from_date": range?.start,
            "to_date": range?.end,
            "is_download": download ? download : false
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {

                if (download) {
                    let a = document.createElement("a");
                    a.href = response.data.data.url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    set_btn_loader(false)
                } else {
                    setM2eToperData(response.data.data);
                    setLoader(false);
                }
            } else {
                setLoader(false);
                setError(response.data.message);
                setM2eToperData([]);
            }
        });
    }



    return (
        <div className='e-users-container'>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className='row pe-4 ps-4'>
                            {/* <div className='col-lg-3'>
                                <div className='d-flex'>
                                    <span className='ps-4 ms-2 pt-1 mt-1 e-labels me-3'>Date</span>
                                    <SingleDate
                                        placeholder="Date"
                                        value={date}
                                        disabledDate={disabledDate}
                                        className="mt-2"
                                        onChange={(date) => setDate(date)} />
                                </div>
                            </div> */}
                            <div className='col-lg-4 col-4'>
                                <div className='d-flex'>
                                    <span className='pt-1 mt-1 mb-2 e-labels me-3'>Date</span>
                                    <Rangepicker
                                        placeholder={['From', 'To']}
                                        start={range.start}
                                        end={range.end}
                                        disabledDate={(day => { if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true })}
                                        onDatesChange={(start, end) => {
                                            setRange({
                                                start: start,
                                                end: end
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="offset-lg-3 col-lg-3 col-4 text-end position-relative ">
                                <SearchInput
                                    placeholder="Search for users"
                                    value={search}
                                    valueChange={(value) => {
                                        setSearch(value);
                                        if (value === "") {
                                            getM2ELeaderboardList({
                                                value: value
                                            })
                                        }
                                    }}
                                    onSearch={(value) => {
                                        getM2ELeaderboardList({
                                            value: value
                                        })
                                    }} />
                                {
                                    search && search.length ?
                                        <>
                                            <span
                                                className={`${styles.e_search_submit} `}
                                                onClick={() => getM2ELeaderboardList()} >
                                            </span>
                                            <button
                                                className={`${styles.e_search_close} pe-3`}
                                                onClick={() => {
                                                    setSearch("");
                                                    getM2ELeaderboardList({
                                                        value: ""
                                                    });
                                                }} >
                                            </button>
                                        </>
                                        :
                                        null
                                }
                            </div>
                            <div className={"col-lg-2 col-2 px-0"}>
                                <DownloadBtn
                                    check={["hi"]}
                                    className={btn_loader ? "e-disabled" : ""}
                                    loaderClass={btn_loader ? "e-bounce" : ""}
                                    onClick={() => {
                                        getM2ELeaderboardList(search, true)
                                        // maxPayoutList(search, true)

                                    }}
                                />
                            </div>
                        </div>
                        {/*data table*/}
                        <div className="row">
                            {
                                loader ?
                                    <Loader />
                                    :
                                    m2etoperData.length > 0 ?
                                        <div className="col-lg-12 e-blocked-users pt-2">
                                            <DataTable
                                                pagination={true}
                                                columns={columns}
                                                data={m2etoperData} />
                                        </div>
                                        :
                                        <EmptyNote data="No milestones found" img={Nodata} />
                            }
                        </div>

                    </div>
                </div>
            </div>
            {/*error toast
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="fail"
                        format={1}
                    />
                    :
                    null
            }*/}
        </div>

    )
}

export default SkillraceToper;
