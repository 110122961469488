/*
 *   File : add-milestone.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : m2e container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 16-02-2022
 */
/*import packages*/
import React, { useState } from 'react';

/* import assets */
import Nodata from '../../assets/images/note/no-data.png';

/*import components*/
import Datatable from '../../components/dataFormating/dataTable';
import { PrimaryBtn, PrimaryLink } from '../../components/cta/index';
import { UpdateScrip, Updatevoting } from '../../components/modal';
import service from '../../services/service';
import { useEffect } from 'react';
import Loader from '../../components/loader/loader';
import { EmptyNote } from '../../components/note';
import { Rangepicker } from '../../components/input';
import moment from 'moment';

const ListedScrips = (props) => {
  const [loader, setLoader] = useState(true);
  const [votesData, setVotesData] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [dates, setDates] = useState({})


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    getCurrentSettings();

  }, [])

  useEffect(() => {
    getVotes();
    //eslint-disable-next-line
  }, [start, end])


  const disabledDate = (current) => {
    // Can not select future dates
    return current > moment().startOf('day');
  }
  const columns = [
    {
      name: 'Scrip',
      selector: 'scrip_code',
      sortable: true,
      left: true,
      width: "400px"
    },

    {
      name: 'Votes',
      selector: 'no_of_votes',
      sortable: true,
      left: true,
      // width: "240px"
    },
  ]

  const getVotes = () => {
    setLoader(true)
    let url = '/scrip/votes';

    let data = {
      start_date: start,
      end_date: end
    }

    service(true, url, data).then((response) => {

      if (response.data.status_code === 200) {
        setLoader(false);
        setVotesData(response.data.data)
      }
      else {
        setLoader(false);
        setVotesData([])
      }
    });
  }

  const getCurrentSettings = () => {

    let url = '/scrip/get-voting-config';

    service(false, url).then((response) => {

      if (response.data.status_code === 200) {
        setDates(response.data.data)
      }
      else {
        setDates({})
      }
    });
  }

  return (


    <>
      <div className="row mt-4 e-scrip-voting-header">
        <div className="col-lg-8">
          <span className='e-labels ms-4'>Specify voting time period</span>
          <p className='pt-3'>
            <PrimaryLink name="Change" className="e-link ps-4" toggle="modal" target="#update-voting" />
          </p>
        </div>
        <div className='col-lg-2 ps-5 mt-4'>
          <h6>
            Last initiated on:
            <p className='pt-1'>{dates.start_date}</p>
          </h6>
        </div>
        <div className='col-lg-2 ps-0 mt-4'>
          <h6>
            Next initiation on:
            <p className='pt-1'>{dates.end_date}</p>
          </h6>
        </div>
      </div>
      <div className="row mt-4 ">
        <div className="col-lg-12">
          <p className='e-voted-for ps-4'>Top voted scrips for - <span>{dates.start_date} to {dates.end_date}</span></p>
        </div>
      </div>
      <div className='row'>

        <div className='col-lg-12'>
          <div className='row px-2'>
            <div className='col-lg-3 ps-4'>
              <Rangepicker
                placeholder={["Start", "End"]}
                start={start}
                end={end}
                className="mt-2"
                disabledDate={disabledDate}
                onDatesChange={(start, end) => {
                  setStart(start);
                  setEnd(end)
                }} />
            </div>
            <div className='col-lg-9 text-end '>
              <PrimaryBtn
                name="Update scrips"
                toggle="modal"
                target="#updateScrips" />
            </div>
          </div>
        </div>
        <div className='col-lg-12 e-blocked-users'>
          {
            loader ?
              <Loader />
              :
              votesData.length > 0 ?
                <Datatable columns={columns} data={votesData} />
                :
                <EmptyNote data="No data found" img={Nodata} />
          }

        </div>
      </div>
      <Updatevoting success={() => { getCurrentSettings() }} dates={dates} />
      <UpdateScrip success={() => { getVotes() }} />
    </>
  )
}

export default ListedScrips;
