/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 11-11-2021
 */
/*import pakcages*/
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import { Modal } from 'bootstrap'
/*import components*/
import { DataTable, GameTabs } from '../../components/dataFormating/index';
import { FilterBtn, PrimaryBtn } from '../../components/cta/index';
import { SearchInput, Rangepicker, Input } from '../../components/input/index';
import { DownloadBtn } from '../../components/cta/index';
import SelectBox from '../../components/dropdown/selectBox';
import Loader from '../../components/loader/loader';
import { EmptyNote, Toast } from '../../components/note';
import CardIconStyleToolTip from '../../components/tooltip/card';
import { BlockUser, Update } from '../../components/modal';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import View from '../../assets/images/dataFormating/view.svg';
import Verify from '../../assets/images/users/verified.svg';
import Block from '../../assets/images/users/block.svg';
import Pending from '../../assets/images/users/pending.svg';
import Nodata from '../../assets/images/note/no-data.png';
import Next from '../../assets/images/dataFormating/next.svg';
import Prev from '../../assets/images/dataFormating/prev.svg';

/* import service */
import service from '../../services/service';

const influencerOptions = [
	{
		value: null,
		label: 'All'
	},
	{
		value: true,
		label: 'Influencer'
	},
	{
		value: false,
		label: 'Not an influencer'
	}
]

const Index = (props) => {
	const wrapperRef = useRef(null);
	const [search, setSearch] = useState("");
	const [more, setMore] = useState(false);
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState({ start: null, end: null });
	const [status, setStatus] = useState(null);
	const [kycStatus, setKycStatus] = useState(null);
	const [bankId, setBankId] = useState(null);
	const [check, setCheck] = useState([]);
	const [refKey, setRefKay] = useState();
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState(null);
	const [userData, setUserData] = useState([]);
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [success, setSuccess] = useState(null)
	const [bankOptions, setBankOptions] = useState([]);
	const [isShown, setIsShown] = useState(null);
	const [id, setId] = useState(null);
	const [name, setName] = useState(null);
	const [blockStatus, setBlockStatus] = useState(false);
	const [reason, setReason] = useState("");
	const [statusLabel, setStatusLabel] = useState("All");
	const [kycstatusLabel, setKycStatusLabel] = useState("All");
	const [bankName, setBankName] = useState("All");
	const [total, setTotal] = useState(null);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(200);
	const [totalLength, setTotalLength] = useState(null);
	const [balanceType, setBalanceType] = useState({ value: null, label: "All" });
	const [amount, setAmount] = useState(null);
	const [range, setRange] = useState({ value: null, label: "All" })
	const [rangeOptions, setRangeOptions] = useState([]);
	const [stateOptions, setStateOptions] = useState([]);
	const [selectedState, setSelectedState] = useState({});
	const [influencerValue, setInfluencerValue] = useState(influencerOptions[0])

	const [paginationData, setPaginationData] = useState({})
	const [paginationDataReferral, setPaginationDataReferral] = useState({})
	const [paginationDataBlocked, setPaginationDataBlocked] = useState({})
	const [paginationDataDeleted, setPaginationDataDeleted] = useState({})

	const [index, setIndex] = useState(1);

	const [referralList, setReferrallist] = useState([]);
	const [refPage, setRefPage] = useState(1);
	const [refPerPage, setRefPerPage] = useState(200);
	const [refTotal, setRefTotal] = useState(null);
	const [reftotalLength, setRefTotalLength] = useState(null);

	const [deletedList, setDeletedlist] = useState([]);
	const [deletedPage, setDeletedPage] = useState(1);
	const [deletedPerPage, setDeletedPerPage] = useState(200);
	const [deletedTotal, setDeletedTotal] = useState(null);
	const [deletedtotalLength, setDeletedTotalLength] = useState(null);

	const [blockedUserList, setBlockedUserlist] = useState([]);
	const [blockPage, setBlockPage] = useState(1);
	const [blockPerPage, setBlockPerPage] = useState(200);
	const [blockTotal, setBlockTotal] = useState(null);
	const [blocktotalLength, setBlockTotalLength] = useState(null);
	const [btn_loader, set_btn_loader] = useState(false);
	const [userId, setUserId] = useState(null);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		_getBankFilter();
		_getAgeFilter();
		_getStateFilter();
	}, []);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null)
			}, 2000);
		}
	}, [success]);

	useEffect(() => {
		if (index === 1) {
			_getUsersList();
		}
		//eslint-disable-next-line
	}, [dates, status, kycStatus, bankId, index, page, perPage, balanceType, amount, range, selectedState]);

	useEffect(() => {
		if (index === 2) {
			_getReferralUsersList();
		}
		//eslint-disable-next-line
	}, [refPage, refPerPage, index, influencerValue])

	useEffect(() => {
		if (index === 4) {
			_getDeletedUsers(true, false);
		}
		//eslint-disable-next-line
	}, [deletedPage, deletedPerPage, index])

	useEffect(() => {
		if (index === 3) {
			_getBlockedUsersList();
		}
		//eslint-disable-next-line
	}, [blockPage, blockPerPage, index])

	useEffect(() => {
		if (downloadLoader === true && index !== 1) {
			setDownloadLoader(false)
			setCheck([]);
		}
	}, [downloadLoader, index, check])


	const _closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false)
		}
	}

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null)
			}, 2000);
		}
	}, [error]);

	useEffect(() => {
		document.addEventListener('mouseup', _closeMenu);
		return () => {
			document.removeEventListener('mouseup', _closeMenu);
		};
	}, []);

	const customStringSort = (a, b, selector) => {
		if (a[selector] && b[selector]) {
			let stringA = a[selector].toUpperCase().trim();
			let stringB = b[selector].toUpperCase().trim();
			if (stringA < stringB) return -1;
			else if (stringA > stringB) return 1;
			else return 0;
		}
		else if (a[selector]) return -1;
		else if (b[selector]) return 1;
		else return 0;
	}

	const openBlockModal = () => {
		var modal = new Modal(document.getElementById("block-user"), {});
		modal.show()
	}

	const openDetail = (id) => {
		window.open(`/user-detail?id=${id}`, "_blank");
	}

	const customDateSort = (a, b, selector, given) => {
		let format = given ? given : "DD MMM YYYY"
		if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
		else if (a[selector]) return -1;
		else if (b[selector]) return 1;
		else return 0;
	}



	//user list columns
	const columns = [
		{
			name: 'S no.',
			selector: 'si_no',
			sortable: true,
			left: true,
			minWidth: '80px'
		},
		{
			name: 'Date of joining',
			selector: 'doj',
			sortable: true,
			left: true,
			cell: row => row.doj,
			minWidth: "150px",
			sortFunction: (a, b) => customDateSort(a, b, 'doj')
		},
		{
			name: 'Client ID',
			selector: "id",
			sortable: true,
			left: true,
			cell: row => row.customer_id,
			maxWidth: "130px",
		},
		{
			name: 'Name',
			// selector: 'name',
			sortable: true,
			left: true,
			minWidth: "140px",
			cell: row => <div className='e-name-link' onClick={() => openDetail(row.id)}>{row.name}</div>,
			sortFunction: (a, b) => customStringSort(a, b, 'name')
		},
		{
			name: 'Wallet bal.',
			selector: row => Number(row.balance),
			sortable: true,
			right: false,
			minWidth: "140px",
			cell: row => "₹" + Math.round((row.balance + Number.EPSILON) * 100) / 100,
		},
		{
			name: 'Platform fees',
			selector: row => Number(row.platform_fee),
			sortable: true,
			right: false,
			minWidth: "160px",
			cell: row => "₹" + Math.round((row.platform_fee + Number.EPSILON) * 100) / 100,
		},
		{
			name: 'Bank',
			selector: 'bank_logo',
			sortable: false,
			right: false,
			width: "80px",
			cell: row =>
				row.bank_logo !== null ?
					<img src={row.bank_logo} width="34px" height="34px" alt="" />
					:
					null
		},
		// {
		// 	name: 'Platform fee',
		// 	selector: 'platform_fee',
		// 	sortable: true,
		// 	right: false,
		// 	// maxWidth: "120px",
		// },
		{
			name: 'Phone',
			selector: row => row.mobile,
			sortable: false,
			right: false,
			minWidth: "160px",
		},
		{
			name: 'Email',
			selector: 'email',
			sortable: false,
			right: false,
			minWidth: "280px",
			cell: row => row.email
		},
		{
			name: 'KYC status',
			selector: 'kyc_status',
			sortable: false,
			left: true,
			minWidth: "180px",
			// cell: row => <div>{row.kyc_status ? "Verified" : "Pending"} </div>,
		},
		{
			name: 'KYC updated at',
			selector: 'kyc_status_updated_at',
			sortable: false,
			left: true,
			minWidth: '180px'
		},
		{
			name: 'Status',
			cell: row =>
				<div className='position-relative'>
					{
						row.is_active ?

							<span className='e-verified-status'>
								<img src={Verify} className="pe-3" alt=""
									onMouseEnter={() => setIsShown(row.customer_id)}
									onMouseLeave={() => setIsShown(null)}
								/>
							</span>
							:
							<span className='e-verified-status'>
								<img src={Pending} className="pe-3" alt=""
									onMouseEnter={() => setIsShown(row.customer_id)}
									onMouseLeave={() => setIsShown(null)} />
							</span>


					}
					{
						isShown === row.customer_id ?
							<CardIconStyleToolTip content={row.is_active ? "User active" : "User inactive"} />
							:
							null
					}
				</div>
		},
		{
			name: "Blocked",
			minWidth: "140px",
			cell: row => <>
				{
					row.is_blocked ?
						"YES"
						:
						"NO"
				}
			</>
		},
		{
			name: 'Gender',
			selector: row => row.gender,
			sortable: false,
			right: false,
			minWidth: "130px",
		},
		{
			name: 'DOB',
			selector: row => row.dob,
			sortable: false,
			right: false,
			minWidth: "140px",
		},
		{
			name: 'Aadhaar',
			selector: row => row.aadhar_number,
			sortable: false,
			right: false,
			minWidth: "180px",
		},
		{
			name: 'PAN',
			selector: row => row.pan_number,
			sortable: false,
			right: false,
			minWidth: "140px",
		},
		{
			name: 'PAN Name',
			selectore: 'pan_name',
			cell: row => <>{row.pan_name}</>,
			sortable: false,
			right: false,
			minWidth: "180px",
		},
		{
			name: 'Pincode',
			selector: row => row.pincode ? row.pincode : "-",
			sortable: false,
			right: false,
			minWidth: "120px",
		},
		{
			name: 'State',
			selector: row => row.state,
			sortable: false,
			right: false,
			minWidth: "160px",
		},
		{
			name: 'Remarks',
			selector: row => row.block_remarks ? row.block_remarks : "--",
			sortable: false,
			right: false,
			minWidth: "160px",
		},
		{
			name: 'No. of referral',
			selector: 'number_of_referrals',
			sortable: false,
			right: false,
			minWidth: "160px",
		},
		{
			name: 'Account holder name',
			selector: 'account_holder_name',
			sortable: true,
			right: false,
			minWidth: '220px'
		},
		{
			name: 'Account type',
			selector: 'account_type',
			sortable: false,
			right: false,
			minWidth: '160px'
		},
		{
			name: 'Masked Account no.',
			selector: 'masked_account_number',
			sortable: false,
			right: false,
			minWidth: '220px'
		},
		{
			name: 'Account no.',
			selector: 'account_number',
			sortable: false,
			right: false,
			minWidth: '220px'
		},
		{
			name: 'IFSC',
			selector: 'ifsc',
			sortable: false,
			right: false,
			minWidth: '160px'
		},
		{
			name: 'Branch',
			selector: 'branch',
			sortable: false,
			right: false,
			minWidth: '160px'
		},
		{
			name: 'Bank name',
			selector: 'bankname',
			sortable: false,
			right: false,
			minWidth: '160px',
			cell: row => <>
				{
					row.bankname
				}
			</>
		},
		{
			name: 'Verified at',
			selector: 'verified_at',
			sortable: false,
			right: false,
			minWidth: '220px'
		},
		{
			name: '',
			selector: 'kyc_status',
			sortable: false,
			left: true,
			maxWidth: "120px !important;",
			cell: row =>
				<div className="">
					<span className="e-more-users" onClick={() => { setRefKay(row.id); setMore(true) }} ref={wrapperRef}></span>
					{
						more && row.id === refKey ?
							<div className="e-card-user" ref={wrapperRef}>
								<span onClick={() => { openDetail(row.id); }}><img src={View} alt="View details" className="me-2" />View details</span>

								{
									row.is_blocked === false ?
										<span onClick={() => {
											setId(row.id);
											setName("Block user")
											openBlockModal();
											setBlockStatus(row.is_blocked);
											setReason("")
										}}>
											<img src={Block} alt="Block user" className="me-2" />Block user</span>
										:
										<span onClick={() => {
											setId(row.id);
											setName("Unblock user")
											openBlockModal();
											setBlockStatus(row.is_blocked);
											setReason(row.block_remarks)
										}}>
											<img src={Block} alt="Block user" className="me-2" />Unblock user</span>
								}
							</div>
							:
							null
					}

				</div>
		},


	];

	//referral columns
	const referralCoumns = [
		{
			name: 'S no.',
			selector: 'sl_no',
			sortable: true,
			left: true,
			minWidth: '100px'
		},
		{
			name: 'Client ID',
			selector: "customer_id",
			sortable: true,
			left: true,
			cell: row => row.client_id,
			minWidth: "170px",
		},
		{
			name: 'Name',
			// selector: 'name',
			sortable: true,
			left: true,
			minWidth: "140px",
			cell: row => <div className='e-name-link' onClick={() => openDetail(row.customer_id)}>{row.name}</div>,
			sortFunction: (a, b) => customStringSort(a, b, 'name')
		},
		{
			name: 'Mobile',
			selector: row => row.mobile,
			sortable: false,
			right: false,
			minWidth: "160px",
		},
		{
			name: 'Email',
			selector: "email",
			sortable: false,
			right: false,
			minWidth: "280px",
			cell: row => <>
				{
					row.email
				}
			</>
		},
		{
			name: 'PAN',
			selector: row => row.pan,
			sortable: false,
			right: false,
			minWidth: "140px",
		},
		{
			name: 'KYC status',
			selector: 'kyc_status',
			sortable: false,
			left: true,
			minWidth: "180px",
			// cell: row => <div>{row.kyc_status ? "Verified" : "Pending"} </div>,
		},
		{
			name: "Blocked",
			minWidth: "140px",
			cell: row => <>
				{
					row.is_blocked ?
						"YES"
						:
						"NO"
				}
			</>
		},
		{
			name: 'No. of referral',
			selector: 'no_of_referrals',
			sortable: true,
			right: false,
			minWidth: "160px",
		},
		{
			name: 'Referral amount',
			selector: 'referral_amount',
			sortable: true,
			right: false,
			minWidth: "160px",
			cell: row => <>₹ {row.referral_amount}</>
		},
		{
			name: 'Referral paid amount',
			selector: 'paid_referral_amount',
			sortable: true,
			right: false,
			minWidth: "180px",
			cell: row => <>₹ {row.paid_referral_amount}</>
		},
		{
			name: 'Referral due amount',
			selector: 'due_referral_amount',
			sortable: true,
			right: false,
			minWidth: "180px",
			cell: row => <> ₹ {row.due_referral_amount}</>
		},
	]

	//blocked list columns 
	const blockColumns = [
		{
			name: 'S no.',

			sortable: true,
			left: true,
			cell: (row, index) => <>
				{
					index + 1
				}
			</>,
			minWidth: "120px",
			maxWidth: "120px"
		},
		{
			name: 'Client ID',
			selector: "id",
			sortable: true,
			left: true,
			cell: row => row.client_id,
			minWidth: "150px",
		},
		{
			name: 'Name',
			// selector: 'name',
			sortable: true,
			left: true,
			minWidth: "140px",
			cell: row => <div className='e-name-link' onClick={() => openDetail(row.id)}>{row.customer_name}</div>,
			sortFunction: (a, b) => customStringSort(a, b, 'customer_name')
		},
		{
			name: 'Remark',
			selector: 'remark',
			sortable: false,
			right: false,
			minWidth: "220px",
			cell: row => <>
				{
					row.remark
				}
			</>
		},
		{
			name: 'Block code',
			selector: row => row.block_code,
			sortable: false,
			right: false,
			minWidth: "120px",
			maxWidth: "120px",
		},
		{
			name: 'Blocked on',
			selector: 'blocked_on',
			cell: row => <>{row.blocked_on}</>,
			sortable: false,
			right: false,
			minWidth: "140px",
		},
		{
			name: 'Blocked by',
			selector: row => row.blocked_by,
			sortable: false,
			right: false,
			minWidth: "160px",
		},
		{
			name: 'Modified by',
			selector: row => row.modified_by,
			sortable: false,
			right: false,
			minWidth: "160px",
			omit: localStorage.getItem("type") === "1" ? false : true
		},
		{
			name: 'Unblock in',
			selector: row => row.unblock_in,
			sortable: false,
			right: false,
			minWidth: "160px",
		},
	]

	//deleted list columns 
	const deletedColumns = [
		{
			name: 'S no.',

			sortable: true,
			left: true,
			cell: (row, index) => <>
				{
					index + 1
				}
			</>,
			minWidth: "120px",
			maxWidth: "120px"
		},
		{
			name: 'Client ID',
			selector: "id",
			sortable: true,
			left: true,
			cell: row => row.client_id,
			minWidth: "150px",
		},
		{
			name: 'Name',
			// selector: 'name',
			sortable: true,
			left: true,
			minWidth: "140px",
			cell: row => <div className='e-name-link' onClick={() => openDetail(row.id)}>{row.name}</div>,
			sortFunction: (a, b) => customStringSort(a, b, 'customer_name')
		},
		{
			name: 'Phone',
			selector: row => row.mobile,
			sortable: false,
			right: false,
			minWidth: "140px",
		},
		{
			name: 'Email',
			selector: 'email',
			sortable: false,
			right: false,
			minWidth: "180px",
			cell: row => row.email
		},
		{
			name: 'DOJ',
			selector: 'doj',
			sortable: false,
			right: false,
			minWidth: "120px",
			cell: row => row.doj
		},
		{
			name: 'Deleted by	',
			selector: 'deleted_by',
			sortable: false,
			right: false,
			minWidth: "140px",
			cell: row => <>
				{
					row.deleted_by
				}
			</>
		},

		{
			name: "",
			selectoe: 'user_status',
			minWidth: '180px',
			cell: row => <>
				<PrimaryBtn
					name="Restore"
					target="#update"
					toggle="modal"
					handleClick={() => {
						setUserId(row.id)
					}} />
			</>
		}

	]

	/* status Options */
	const statusOptions = [
		{ value: null, label: "All" },
		{ value: "ACTIVE", label: "Active" },
		{ value: "INACTIVE", label: "Inactive" },
	]

	/*kyc status Options */
	const kycStatusOptions = [
		{ value: null, label: "All" },
		{ value: "PENDING", label: "Pending" },
		{ value: "SUBMITTED", label: "Under Progress" },
		{ value: "VERIFIED", label: "KYC Success" },
		{ value: "REJECTED", label: "Failed" }
	]

	const balanceOptions = [
		{ value: null, label: 'All' },
		{ value: 1, label: "Less than " },
		{ value: 2, label: "Greater than " }
	]
	const disabledDate = (current) => {
		// Can not select days before today 
		return current && current > moment().endOf('day');
	}

	const _handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]['id']);
		}
		setCheck(selected);
		setDownloadLoader(false)
		// setCheck(row.selectedRows);
	}

	const _handleChangeRef = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]);
		}
		setCheck(selected);
		setDownloadLoader(false)
		// setCheck(row.selectedRows);
	}

	/*--------- active users list -----------*/
	const _getUsersList = (temp) => {
		setLoader(true)
		let url = '/user/list';

		let data = JSON.stringify({
			from_date: dates.start,
			to_date: dates.end,
			bank_id: bankId,
			status: status,
			kyc_status: kycStatus,
			query: temp ? temp.query : search,
			page_num: page,
			page_size: perPage,
			balance_type: balanceType?.value,
			balance_amount: parseFloat(amount),
			age_range_id: range?.value,
			state: selectedState.value
		});

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				if (response.data.status === true) {
					setUserData(response.data.data);
					setLoader(false);
					setTotal(response.data.number_of_records);
					let remainder = response.data.number_of_records % perPage
					let quotient = response.data.number_of_records / perPage
					if (Number(remainder) > 0) {
						setTotalLength(parseInt(Number(quotient) + 1))

					} else {
						setTotalLength(Number(quotient))
					}
					setPaginationData({
						rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
					})

				} else {
					setLoader(false);
					setUserData(response.data.data);
				}
			}
			else {
				setLoader(false);
				setUserData([]);
			}
		});
	}

	/*--------- active users list -----------*/
	const _getReferralUsersList = (temp, is_download) => {
		if (is_download) {
			set_btn_loader(true)
		} else {
			setLoader(true);
		}
		let url = '/report/referral-report';

		let data = JSON.stringify({
			query: temp ? temp.query : search,
			page_num: refPage,
			page_size: refPerPage,
			is_influencer: influencerValue.value,
			is_download: is_download ? is_download : false
		});

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				if (response.data.status === true) {
					set_btn_loader(false)
					if (is_download) {
						setLoader(false);
						let a = document.createElement("a");
						a.href = response.data.data.url;
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
						setCheck([]);
					} else {
						setReferrallist(response.data.data);
						setLoader(false);
						let remainder = response.data.number_of_records % refPerPage
						let quotient = response.data.number_of_records / refPerPage
						if (Number(remainder) > 0) {
							setRefTotalLength(parseInt(Number(quotient) + 1))

						} else {
							setRefTotalLength(Number(quotient))
						}
						setRefTotal(response.data.number_of_records);
						setPaginationDataReferral({
							rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
						})
						// setRefTotalLength(response.data.number_of_records / refPerPage)
					}
				} else {
					setLoader(false);
					setReferrallist(response.data.data);
				}
			}
			else {
				setLoader(false);
				setReferrallist([]);
			}
		});
	}

	/*--------- active users list -----------*/
	const _getBlockedUsersList = (is_download) => {
		if (is_download) {
			set_btn_loader(true)
		} else {
			setLoader(true);
		}
		let url = '/general/blocked-customers';

		let data = JSON.stringify({
			page_num: blockPage,
			page_size: blockPerPage,
			is_download: is_download ? is_download : false
		});

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				if (response.data.status === true) {
					set_btn_loader(false)
					if (is_download) {
						let a = document.createElement("a");
						a.href = response.data.data.url;
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
						setCheck([]);
						setLoader(false);
					} else {
						setBlockedUserlist(response.data.data);
						setLoader(false);
						let remainder = response.data.number_of_records % blockPerPage
						let quotient = response.data.number_of_records / blockPerPage
						if (Number(remainder) > 0) {
							setBlockTotalLength(parseInt(Number(quotient) + 1))

						} else {
							setBlockTotalLength(Number(quotient))
						}
						setBlockTotal(response.data.number_of_records);
						setPaginationDataBlocked({
							rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
						})
					}
				} else {
					set_btn_loader(false)
					setLoader(false);
					setBlockedUserlist([]);
				}
			}
			else {
				set_btn_loader(false)
				setLoader(false);
				setBlockedUserlist([]);
			}
		});
	}

	/*--------- deleted users list -----------*/
	const _getDeletedUsers = (is_first, is_download) => {
		setLoader(is_first);
		set_btn_loader(is_download)
		let url = '/general/deleted-customers';

		let data = JSON.stringify({
			page_num: deletedPage,
			page_size: deletedPerPage,
			is_download: is_download
		});

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				if (response.data.status === true) {
					set_btn_loader(false)
					if (is_download) {
						let a = document.createElement("a");
						a.href = response.data.data.url;
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
						setCheck([]);
						setLoader(false);
					} else {
						setDeletedlist(response.data.data);
						setLoader(false);
						let remainder = response.data.number_of_records % blockPerPage
						let quotient = response.data.number_of_records / blockPerPage
						if (Number(remainder) > 0) {
							setDeletedTotalLength(parseInt(Number(quotient) + 1))

						} else {
							setDeletedTotalLength(Number(quotient))
						}
						setDeletedTotal(response.data.number_of_records);
						setPaginationDataDeleted({
							rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
						})
					}
				} else {
					setLoader(false);
					setDeletedlist([]);
				}
			}
			else {
				setLoader(false);
				setDeletedlist([]);
			}
		});
	}

	//download user list 

	const _downloadUserList = () => {
		setDownloadLoader(true);

		let url = '/user/download';

		let data = JSON.stringify({
			user_ids: check.length === userData.length ? [] : check,
			from_date: dates.start,
			to_date: dates.end,
			bank_id: bankId,
			status: status,
			kyc_status: kycStatus,
			query: search,
			balance_type: balanceType?.value,
			balance_amount: parseFloat(amount),
			age_range_id: range?.value,
			state: selectedState.value
		});

		service(true, url, data).then((response) => {
			if (response.data.status_code === 200) {
				if (response.data.status === true) {
					console.log(response)
					let a = document.createElement("a");
					a.href = response.data.data.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setCheck([]);
					setDownloadLoader(false);
					// setSearch("");
					setSuccess("Download successful");
				} else {
					setError(response.data.message);
					setDownloadLoader(false);
					setCheck([]);
				}
			}
			else {
				setError(response.data.message);
				setDownloadLoader(false);
				setCheck([]);
			}
		})
	}


	//down referral data 
	function convertArrayOfObjectsToCSVRef(data) {
		setDownloadLoader(true)
		// Empty array for storing the values
		var csvRows = [];

		// Headers is basically a keys of an
		// object which is id, name, and
		// profession
		const titles = Object.keys(data[0])

		var headers = [titles]
		// As for making csv format, headers
		// must be separated by comma and
		// pushing it into array
		csvRows.push(headers.join(','));

		// Pushing Object values into array
		// with comma separation

		for (let i = 0; i < data.length; i++) {
			var values = [Object.values(data[i])].join(",")
			csvRows.push(values)
		}



		// Returning the array joining with new line
		console.log(csvRows)
		return csvRows.join('\n')
	}

	function downloadCSVRef(data) {
		setDownloadLoader(true)
		// Creating a Blob for having a csv file format
		// and passing the data with type
		const blob = new Blob([data], { type: 'text/csv' });

		// Creating an object for downloading url,
		const url = window.URL.createObjectURL(blob)

		// Creating an anchor(a) tag of HTML
		const a = document.createElement('a')

		// Passing the blob downloading url
		a.setAttribute('href', url)

		// Setting the anchor tag attribute for downloading
		// and passing the download file name
		a.setAttribute('bookings', 'bookings.csv');

		// Performing a download with click
		a.click()
	}


	// bank filter

	const _getBankFilter = () => {
		let url = '/user/bank-filter-data';

		let temp_bank_options = [];
		service(false, url).then((response) => {
			if (response.data.status_code === 200) {
				temp_bank_options.push({
					value: null,
					label: "All"
				})
				response.data.data.map((item) => {
					return (
						temp_bank_options.push({
							value: item.id,
							label: item.name
						})
					)
				})
				setBankOptions(temp_bank_options)
			}
		});
	}

	// age filter

	const _getAgeFilter = () => {
		let url = '/user/age-filter-data';

		let temp_bank_options = [];
		service(false, url).then((response) => {
			if (response.data.status_code === 200) {
				temp_bank_options.push({
					value: null,
					label: "All"
				})
				response.data.data.map((item) => {
					return (
						temp_bank_options.push({
							value: item.id,
							label: item.label
						})
					)
				})
				setRangeOptions(temp_bank_options)
			}
		});
	}

	const _getStateFilter = () => {
		let url = '/user/state-filter-data';

		let temp_state_options = [];
		service(false, url).then((response) => {
			if (response.data.status_code === 200) {
				temp_state_options.push({
					value: "",
					label: "All"
				})
				response.data.data.map((item) => {
					return (
						temp_state_options.push({
							value: item,
							label: item
						})
					)
				})
				setStateOptions(temp_state_options)
				setSelectedState(temp_state_options[0])
			}
		});
	}
	return (
		<div className='e-users-container e-users-list e-pagination'>
			<div className="row">
				<div className="col-lg-12">
					<div className="card e-dashboard-card border-0">
						<div className="row pt-4">
							<div className="col-lg-6 ps-4">
								{/* <h6 className="e-card-title ps-4">All users</h6> */}
								<GameTabs
									index={index}
									data={[
										"All users",
										"Referral user list",
										"Blocked users",
										"Deleted users"
									]}
									setTab={(temp) => {
										setIndex(temp);
										setSearch("");
										setCheck([]);
										setFilterDown(false);
										setPage(1);
										setRefPage(1);
										setBlockPage(1);
										setPerPage(200);
										setRefPerPage(200);
										setBlockPerPage(200);
									}}
								/>
							</div>

							<div className={index === 3 || index === 4 ? "col-lg-2 col-5 px-0 text-center offset-lg-4" : "col-lg-2 col-5 px-0 text-end"} >
								{
									index === 1 ?
										<DownloadBtn
											check={check.length > 0 ? check : ["check"]}
											name={check.length > 0 ? "Download report" : "Download All"}
											loaderClass={downloadLoader ? "e-bounce" : ""}
											className={downloadLoader ? "e-disabled" : ""}
											onClick={() => {
												// if (check.length > 0) {
												_downloadUserList()
												// } else {
												// 	const csvdata = convertArrayOfObjectsToCSV(userData);
												// 	downloadCSV(csvdata)
												// }
											}} />
										:
										index === 2 ?
											<DownloadBtn
												check={["check"]}
												name={"Download report"}
												loaderClass={btn_loader ? "e-bounce" : ""}
												className={btn_loader ? "e-disabled" : ""}
												onClick={() => {
													if (check.length === 0 || check.length === referralList.length) {
														_getReferralUsersList(search, true)
													} else {
														const csvdata = convertArrayOfObjectsToCSVRef(check);
														downloadCSVRef(csvdata)
													}
												}} />
											:
											index === 3 ?
												<DownloadBtn
													check={check.length > 0 ? check : ["check"]}
													name={"Download report"}
													loaderClass={btn_loader ? "e-bounce" : ""}
													className={btn_loader ? "e-disabled" : ""}
													disabled={blockedUserList.length > 0 ? false : true}
													onClick={() => {
														if (check.length === 0 || blockedUserList.length === check.length) {
															_getBlockedUsersList(true)

														} else {
															const csvdata = convertArrayOfObjectsToCSVRef(check);
															downloadCSVRef(csvdata)

														}
													}} />
												:
												<DownloadBtn
													check={check.length > 0 ? check : ["check"]}
													name={"Download report"}
													className={btn_loader ? "e-disabled" : ""}
													loaderClass={btn_loader ? "e-bounce" : ""}
													disabled={deletedList.length > 0 ? false : true}
													onClick={() => {
														if (check.length === 0 || blockedUserList.length === check.length) {
															_getBlockedUsersList(true)

														} else {
															const csvdata = convertArrayOfObjectsToCSVRef(check);
															downloadCSVRef(csvdata)

														}
													}} />
								}
							</div>

							{
								index === 3 || index === 4 ?
									null
									:
									<div className="col-lg-3 position-relative col-5">
										<SearchInput
											placeholder="User name/client id"
											value={search}
											onSearch={(value) => {
												if (index === 1) {
													_getUsersList({ query: value })
												} else {
													_getReferralUsersList({ query: value })
												}
											}}
											valueChange={(value) => {
												setSearch(value);
												if (value === "") {
													if (index === 1) {
														_getUsersList({ query: "" })
													} else {
														_getReferralUsersList({ query: "" })
													}
												}
											}}
										/>
										{
											search && search.length ?
												<>
													<span className={`${styles.e_search_submit} `} onClick={() => _getUsersList()} ></span>
													<button
														className={`${styles.e_search_close} pe-3`}
														onClick={() => {
															setSearch("");
															if (index === 1) {
																_getUsersList({ query: "" })
															} else {
																_getReferralUsersList({ query: "" })
															}
															// _getUsersList({ query: "" })
														}} >

													</button>
												</>
												:
												null
										}

									</div>
							}
							{
								index !== 3 && index !== 4 ?
									<div className="col-lg-1 col-1">
										<FilterBtn
											className={
												filterDown ?
													"e-filter-btn e-active" :
													"e-filter-btn"
											}
											handleClick={() => {
												setFilterDown(!filterDown)
											}}
										/>
									</div>
									:
									null
							}
							{
								filterDown ?
									<div className='row pe-0'>
										<div className='col-lg-12 px-0'>
											<div className="e-filter-wraper mt-3">
												{index === 1 ?
													<div className="row pe-0">
														<div className='col-lg-12'>
															<div className="e-filter d-inline-block pe-2">
																<label className="mb-2">Joined from - till</label>
																<Rangepicker
																	start={dates.start}
																	end={dates.end}
																	disabledDate={disabledDate}
																	placeholder={["From", "To"]}
																	onDatesChange={(start, end) => {

																		setDates({
																			start: start,
																			end: end
																		});
																	}}
																/>
															</div>
															<div className="e-filter d-inline-block pe-2">
																<label className="mb-2">State </label>
																<SelectBox
																	value={selectedState}
																	isSearchable={true}
																	options={stateOptions}
																	selectchange={(temp) => {
																		setSelectedState(temp);
																	}}
																/>
															</div>
															<div className="e-filter d-inline-block pe-2">
																<label className="mb-2">Banks </label>
																<SelectBox
																	value={{ value: bankId, label: bankName }}
																	isSearchable={true}
																	options={bankOptions}
																	selectchange={(temp) => {
																		setBankId(temp?.value);
																		setBankName(temp?.label)
																	}}
																/>
															</div>
															<div className="e-filter d-inline-block pe-2">
																<label className="mb-2">Status</label>
																<SelectBox
																	value={{ value: status, label: statusLabel }}
																	isSearchable={false}
																	options={statusOptions}
																	selectchange={(temp) => {
																		setStatus(temp?.value)
																		setStatusLabel(temp?.label)
																	}}
																/>
															</div>
															<div className="e-filter d-inline-block pe-2">
																<label className="mb-2">KYC status</label>
																<SelectBox
																	isSearchable={false}
																	value={{ value: kycStatus, label: kycstatusLabel }}
																	options={kycStatusOptions}
																	selectchange={(temp) => {
																		setKycStatus(temp?.value)
																		setKycStatusLabel(temp?.label)
																	}}
																/>
															</div>
															<div className="e-filter d-inline-block pe-2">
																<label className="mb-2">Age range</label>
																<SelectBox
																	isSearchable={false}
																	value={range}
																	options={rangeOptions}
																	selectchange={(temp) => {
																		setRange(temp)
																	}}
																/>
															</div>
															<div className="e-filter d-inline-block pe-2">
																<label className="mb-2">Balance filter</label>
																<SelectBox
																	isSearchable={false}
																	value={balanceType}
																	options={balanceOptions}
																	selectchange={(temp) => {
																		setBalanceType(temp)
																	}}
																/>
															</div>
															<div className="e-filter d-inline-block pe-2">
																<label className="mb-2">Balance amount</label>
																<Input
																	type="number"
																	value={amount}
																	placeholder="200.0"
																	valueChange={(temp) => {
																		setAmount(temp)
																	}}
																/>
															</div>
														</div>
													</div>
													:
													<div className='row'>
														<div className="col-lg-3">
															<label className="mb-2">Influencer </label>
															<SelectBox
																value={influencerValue}
																isSearchable={false}
																options={influencerOptions}
																selectchange={(temp) => {
																	setInfluencerValue(temp);
																}}
															/>
														</div>
													</div>
												}
											</div>
										</div>
									</div>
									:
									null
							}
							{
								loader ?
									<Loader />
									:
									index === 1 ?
										userData.length > 0 ?
											<div className="col-lg-12 e-table-scroll">
												<DataTable
													pagination={true}
													columns={columns}
													data={userData}
													selectableRows={true}
													clearSelectedRows={downloadLoader ? true : false}
													onChangeRowsPerPage={(p) => {
														setPage(1)
														setPerPage(p)
													}}
													selectRows={(row) => {
														_handleChange(row)
													}}
													paginationComponentOptions={paginationData}
													paginationTotalRows={total}
													size={perPage}
													paginationIconNext={totalLength === page ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setPage(page + 1)} style={{ cursor: "pointer" }} />}
													paginationIconPrevious={page === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPage(page - 1) }} />}
												// rowClick={(row) => {
												// 	navigate(`/user-detail?id=${row.id}`);
												// 	console.log(row.id)
												// }}
												/>
												<div className='col-lg-12 text-end pe-3'>
													<p>Page no : <span style={{ fontWeight: "600" }}>{page}</span></p>
												</div>
											</div>
											:
											<EmptyNote img={Nodata} data="No data found" />
										:
										index === 2 ?
											referralList.length > 0 ?
												<div className="col-lg-12 e-table-scroll">
													<DataTable
														pagination={true}
														columns={referralCoumns}
														data={referralList}
														selectableRows={true}
														clearSelectedRows={downloadLoader ? true : false}
														paginationComponentOptions={paginationDataReferral}
														onChangeRowsPerPage={(p) => {
															setRefPage(1)
															setRefPerPage(p)
														}}
														selectRows={(row) => {
															_handleChangeRef(row)
														}}
														paginationTotalRows={refTotal}
														size={refPerPage}
														paginationIconNext={reftotalLength === refPage ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setRefPage(refPage + 1)} style={{ cursor: "pointer" }} />}
														paginationIconPrevious={refPage === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setRefPage(refPage - 1) }} />}

													/>
													<div className='col-lg-12 text-end pe-3'>
														<p>Page no : <span style={{ fontWeight: "600" }}>{refPage}</span></p>
													</div>
												</div>
												:
												<EmptyNote img={Nodata} data="No data found" />
											:
											index === 3 ?
												blockedUserList.length > 0 ?
													<div className="col-lg-12 e-table-scroll">
														<DataTable
															pagination={true}
															columns={blockColumns}
															data={blockedUserList}
															selectableRows={true}
															paginationComponentOptions={paginationDataBlocked}
															clearSelectedRows={downloadLoader ? true : false}
															onChangeRowsPerPage={(p) => {
																setBlockPage(1)
																setBlockPerPage(p)
															}}
															selectRows={(row) => {
																_handleChangeRef(row)
															}}
															paginationTotalRows={blockTotal}
															size={blockPerPage}
															paginationIconNext={blocktotalLength === blockPage ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setBlockPage(blockPage + 1)} style={{ cursor: "pointer" }} />}
															paginationIconPrevious={blockPage === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setBlockPage(blockPage - 1) }} />}

														/>
														<div className='col-lg-12 text-end pe-3'>
															<p>Page no : <span style={{ fontWeight: "600" }}>{blockPage}</span></p>
														</div>
													</div>
													:
													<EmptyNote img={Nodata} data="No data found" />
												:
												deletedList.length > 0 ?
													<div className="col-lg-12 e-table-scroll">
														<DataTable
															pagination={true}
															columns={deletedColumns}
															data={deletedList}
															selectableRows={true}
															paginationComponentOptions={paginationDataDeleted}
															clearSelectedRows={downloadLoader ? true : false}
															onChangeRowsPerPage={(p) => {
																setDeletedPage(1)
																setDeletedPerPage(p)
															}}
															selectRows={(row) => {
																_handleChangeRef(row)
															}}
															paginationTotalRows={deletedTotal}
															size={deletedPerPage}
															paginationIconNext={deletedtotalLength === deletedPage ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setDeletedPage(deletedPage + 1)} style={{ cursor: "pointer" }} />}
															paginationIconPrevious={deletedPage === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setDeletedPage(deletedPage - 1) }} />}

														/>
														<div className='col-lg-12 text-end pe-3'>
															<p>Page no : <span style={{ fontWeight: "600" }}>{deletedPage}</span></p>
														</div>
													</div>
													:
													<EmptyNote img={Nodata} data="No data found" />

							}
						</div>
					</div>
				</div>
			</div>
			{/* block user modal */}
			<BlockUser
				name={name}
				blockId={id}
				remark={reason}
				Close={() => { _getUsersList() }}
				blocked={blockStatus} />

			{/* update status modal */}
			<Update id={userId}
				title="Are you sure you want to Restore the user ?"
				update={() => {
					_getDeletedUsers(false, false)
				}}
				close={() => {

				}} />
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						format={1}
					/>
					:
					null
			}
			{
				success !== null ?
					<Toast
						data={success}
						type="success"
						format={1}
					/>
					:
					null
			}
		</div >

	)
}

export default Index;
