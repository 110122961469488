/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for earnings container;
 *   Integrations : 19-04-2022
 *   Version : v1.0
 *   Created : 15-11-2021
 */

/*import packages*/
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

/*import components*/
import { Summary } from '../../components/card/index';
// import SelectBox from '../../components/dropdown/selectBox';
import { DataTable } from '../../components/dataFormating/index';
import { Rangepicker, SearchInput } from '../../components/input/index';
import { DownloadBtn, FilterBtn } from '../../components/cta/index';
import SelectBox from '../../components/dropdown/selectBox';
import Loader from '../../components/loader/loader';
import { EmptyNote, Toast } from '../../components/note';
import DatePicker from '../../components/input/date';

/*import assets*/
import Earning1 from '../../assets/images/card/summary/earning-1.svg';
import Earning2 from '../../assets/images/card/summary/earning-2.svg';
import Earning3 from '../../assets/images/card/summary/earning-3.svg';
import Earning4 from '../../assets/images/card/summary/earning-4.svg';
import Revenues from '../../assets/images/card/summary/revenues.svg';
import Forecaste from '../../assets/images/card/summary/forecast.svg';
import Tax from '../../assets/images/card/summary/tax.svg';
import Earning from '../../assets/images/card/summary/earning.svg';
import Nodata from '../../assets/images/note/no-data.png';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/* import services */
import service from '../../services/service';

const Index = (props) => {
    const wrapperRef = useRef()
    // const [ selected, setSelected ] = useState();
    const [search, setSearch] = useState("");
    const [check, setCheck] = useState([]);
    const [selected, setSelected] = useState({ label: "M2E", value: 1 });;
    const [earningData, setEarningData] = useState([]);
    const [timeFilter, setShowTimeFilter] = useState(false);
    const [secondTimeFilter, setShowSecondTimeFilter] = useState(false);
    const [dashbaordFilter, setDashboardFilter] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    //eslint-disable-next-line
    const [page, setPage] = useState(1);
    //eslint-disable-next-line
    const [perPage, setPerPage] = useState(200);
    const [id, setId] = useState(null);
    const [overviewData, setoverviewData] = useState({});
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [filterId, setFilterId] = useState(null);
    const [overviewLoader, setOverviewLoader] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [success, setSuccess] = useState(null);
    const [filterDown, setFilterDown] = useState(false);
    const [range, setRange] = useState({ start: "", end: "" });
    const [dates, setDates] = useState({
        checkin: "",
        checkout: ""
    })
    const [open, setOpen] = useState(false);
    const [earningDates, setEarningDates] = useState({
        start: null,
        end: null
    })
    const [gameIds, setGameIds] = useState([]);
    const [gameId, setGameId] = useState({ label: "All", value: 0 })


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        getDasboardFilter();
    }, []);

    useEffect(() => {
        if (dates.checkin !== "" && dates.checkout !== "") {
            setOpen(false)
            setSelectedFilter("")
            let start = moment(dates.checkin).format("YYYY-MM-DD")
            let end = moment(dates.checkout).format("YYYY-MM-DD")
            setRange({
                start: start,
                end: end
            });
            setShowTimeFilter(false)
        }
    }, [dates.checkin, dates.checkout])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null)
            }, 2000);
        }
    }, [success]);

    useEffect(() => {

        getEarningsOverview();
        //eslint-disable-next-line
    }, [filterId, range.end]);

    useEffect(() => {
        getEarnings();
        //eslint-disable-next-line
    }, [selected, page, perPage, earningDates, gameId]);

    const _closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowTimeFilter(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);

    const options = [
        {
            value: 0, label: 'All games',
        },
        {
            value: 1, label: 'M2E'
        },
        {
            value: 2, label: 'M2M'
        },
        {
            value: 3, label: 'Skill race'
        },
    ]

    const openDetail = (row) => {
        window.open(`/user-detail?id=${row.user_id}`, "_blank");
    }

    const customDateSort = (a, b, selector, given) => {
        let format = given ? given : "DD MMM YYYY"
        if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
        else if (a[selector]) return -1;
        else if (b[selector]) return 1;
        else return 0;
    }

    const columns = [
        {
            name: 'Client ID',
            seletor: 'user_id',
            cell: row => (row.client_id),
            sortable: false,
            left: true,
            maxWidth: "160px",
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            left: true,
            minWidth: "140px",
            cell: row => <div className='e-name-link' onClick={() => openDetail(row)}>{row.name}</div>
        },

        {
            name: 'Game',
            selector: 'game',
            sortable: false,
            left: true,
        },
        {
            name: 'Platform fee',
            selector: 'paid_fee',
            sortable: true,
            left: true,
            cell: row => <div className="">₹ {row.paid_fee}</div>
        },
        {
            name: 'TDS',
            selector: 'tds',
            sortable: true,
            left: true,
            cell: row => <div className="">₹ {row.tds}</div>
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            left: true,
            cell: row => <div className="col-lg-12">
                {row.date}
                {/* <div className="e-time">5.00 PM</div> */}
            </div>,
            sortFunction: (a, b) => customDateSort(a, b, 'date')
        },
        {
            name: 'P/L',
            selector: "earnings",
            sortable: true,
            left: true,
            cell: row => row.earnings ? row.earnings > 0 ? <span style={{ color: "green" }}>₹{row.earnings}</span> : <span style={{ color: "red" }}>₹{row.earnings}</span> : null
        },
    ]

    const handleChange = (row) => {
        let selected = []
        for (let i = 0; i < row.selectedRows.length; i++) {
            selected.push(row.selectedRows[i]['user_id']);
        }
        setCheck(selected);
    }

    /* ---- get dashboard filter ---*/
    const getDasboardFilter = () => {

        let url = "/dashboard/filter-data";

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setDashboardFilter(response.data.data);
            }
            else {
                setError(response.data.message);
            }
        });
    }

    /* get earnings */
    const getEarnings = (query) => {
        setLoader(true);
        let url = "/earnings/get";

        let data = {
            "game_type": selected?.value,
            "query": query ? query.value : search,
            "page_num": page,
            "page_size": 200,
            "game_id": gameId?.value,
            "from_date": earningDates.start,
            "to_date": earningDates.end

        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                setEarningData(response.data.data);
                let temp_options = [];
                temp_options.push({ label: "All", value: 0 })
                response.data.game_ids.map((item) => {
                    return (
                        temp_options.push({
                            label: `Game id ${item}`,
                            value: item,
                        })
                    )
                })
                setGameIds(temp_options)
            } else {
                setLoader(false);
                setEarningData([]);
            }
        });
    }

    /* ---- get overiview data ---*/
    const getEarningsOverview = () => {
        setoverviewData({});
        setOverviewLoader(true);
        let url = "/earnings/overview";
        let data = {
            "filter_id": id,
            "start_date": range.start === "" ? null : range.start,
            "end_date": range.end === "" ? null : range.end,
            "filter_sub_id": filterId,
            // "game_type": 0
        }
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setoverviewData(response.data.data);
                setOverviewLoader(false);
            }
            else {
                setError(response.data.message);
                setOverviewLoader(false);
                setoverviewData({});
            }
        });
    }

    //download earnings 
    const _downloadEarnings = () => {
        setDownloadLoader(true);

        let url = '/earnings/client-activity-overview-report';

        let data = JSON.stringify({
            customer_ids: check,
            game_type: selected?.value,
        });

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                if (response.data.status === true) {
                    let a = document.createElement("a");
                    a.href = response.data.data.url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setCheck([]);
                    setDownloadLoader(false);
                    setSearch("");
                    setSuccess("Download successful");
                } else {
                    setError(response.data.message);
                    setDownloadLoader(false);
                    setCheck([]);
                }
            }
            else {
                setError(response.data.message);
                setDownloadLoader(false);
                setCheck([]);
            }
        })
    }
    return (
        <>
            <div className='row'>
                <div >
                    <div className='col-lg-3'>
                        <h3 className='e-header-title'>Earnings</h3>
                    </div>
                    <div className='col-lg-4'>
                        <div className="e-dashboard-select">
                            <div className='positon-relative d-flex'>
                                <div className='e-dashbaord-dropdown' onClick={() => {
                                    setShowTimeFilter(!timeFilter);
                                }}>
                                    {selectedFilter ? selectedFilter : dates.checkin !== "" && dates.checkout !== "" ? `${range.start} - ${range.end}` : "Choose date range"}
                                </div>
                                {/* <DownloadBtn
                                    check={['earningData']}
                                    className="e-earning-download-btn" /> */}
                                {/* <SelectBox isSearchable={false} defaultValue={options[0]} className="e-dashboard-card-sort" options={options} placeholder="" selectchange={(temp) => setSelectedGameType(temp)} /> */}
                                {
                                    timeFilter ?
                                        <div className='e-show-dropdown' ref={wrapperRef} >
                                            {
                                                dashbaordFilter.map((item) => {
                                                    return (

                                                        <>
                                                            {console.log("selected::", selectedFilter)}
                                                            <p className={item.data.length > 0 ? "e-arrow-show" : null}
                                                                onClick={() => {
                                                                    if (item.data.length > 0) {
                                                                        setShowSecondTimeFilter(!secondTimeFilter);
                                                                        setId(item.id);
                                                                        // setDates({
                                                                        //     checkin: "",
                                                                        //     checkout: ""
                                                                        // })
                                                                    } else {
                                                                        setSelectedFilter("All");
                                                                        setFilterId(null);
                                                                        setShowTimeFilter(false);
                                                                        setShowSecondTimeFilter(false);
                                                                        setId(null);
                                                                        setRange({
                                                                            checkin: "",
                                                                            checkout: ""
                                                                        })
                                                                    }
                                                                }}>{item.name}</p>
                                                            {
                                                                item.id === id && secondTimeFilter && item.data.length > 0 ?
                                                                    <div className='e-second-show-dropdown' >
                                                                        {
                                                                            item.data.map((option) => {
                                                                                return (

                                                                                    <p onClick={() => {
                                                                                        setSelectedFilter(option.name);
                                                                                        setFilterId(option.id);
                                                                                        setShowTimeFilter(!timeFilter);
                                                                                        setShowSecondTimeFilter(false);
                                                                                        setDates({
                                                                                            checkin: "",
                                                                                            checkout: ""
                                                                                        })
                                                                                    }}>{option.name}</p>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                            <DatePicker
                                                dates={dates}
                                                setDates={setDates}
                                                open={open}
                                                setOpen={setOpen}
                                            >
                                                <p className=" e-arrow-show mb-0" onClick={() => { setOpen(!open); setShowSecondTimeFilter(false); setId(1); setFilterId(null) }}>
                                                    Date range
                                                </p>
                                            </DatePicker>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='e-dashboard-container e-earnings'>
                {
                    overviewLoader ?
                        <div className='position-relative'>
                            <Loader />
                        </div>
                        :
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <Summary
                                    data={{
                                        title: 'M2E Earnings',
                                        count: overviewData?.m2e_earnings,
                                        icon: Earning1,
                                        text: "",

                                    }} />
                            </div>
                            <div className="col-lg-3 mb-3">
                                <Summary
                                    data={{
                                        title: 'M2M Earnings',
                                        count: overviewData?.m2m_earnings,
                                        icon: Earning2,
                                        text: "",

                                    }} />
                            </div>
                            <div className="col-lg-3 mb-3">
                                <Summary
                                    data={{
                                        title: 'Skill Race Earnings',
                                        count: overviewData?.skillrace_earnings,
                                        icon: Earning3,
                                        text: "",

                                    }} />
                            </div>
                            <div className="col-lg-3 mb-3">
                                <Summary
                                    data={{
                                        title: 'V2R Earnings',
                                        count: overviewData?.v2r_earnings,
                                        icon: Earning4,
                                        text: "",

                                    }} />
                            </div>
                            <div className="col-lg-3 mb-3">
                                <Summary
                                    data={{
                                        title: 'Gross Revenue',
                                        count: overviewData?.gross_revenue,
                                        icon: Revenues,
                                        text: "",

                                    }} />
                            </div>
                            <div className="col-lg-3 mb-3">
                                <Summary
                                    data={{
                                        title: 'Non Forecasted Revenue',
                                        count: overviewData?.non_forcasted_revenue,
                                        icon: Forecaste,
                                        text: "",

                                    }} />
                            </div>
                            <div className="col-lg-3 mb-3">
                                <Summary
                                    data={{
                                        title: 'Taxes',
                                        count: overviewData?.taxes,
                                        icon: Tax,
                                        text: "",

                                    }} />
                            </div>
                            <div className="col-lg-3 mb-3">
                                <Summary
                                    data={{
                                        title: 'Referral Bouns & Commission',
                                        count: overviewData?.referral_bonus,
                                        icon: Earning,
                                        text: "",

                                    }} />
                            </div>
                            <div className="col-lg-3 mb-3">
                                <Summary
                                    data={{
                                        title: 'Net Earnings',
                                        count: overviewData?.gross_amount,
                                        icon: Forecaste,
                                        text: "",

                                    }} />
                            </div>
                            <div className="col-lg-3 mb-3">
                                <Summary
                                    data={{
                                        title: 'Welcome bonus',
                                        count: overviewData?.welcome_bonus,
                                        icon: Forecaste,
                                        text: "",

                                    }} />
                            </div>
                        </div>
                }
                <div className='e-users-container'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card e-dashboard-card border-0 pe-0">
                                <div className="row">
                                    <div className="col-lg-6">

                                        <span className='ps-2 pt-3 mt-1 e-labels me-3'>Client activity overview</span>
                                        {/* <SelectBox
                                                value={selected}
                                                options={options}
                                                isSearchable={false}
                                                placeholder="All games"
                                                className="e-sort mt-2"
                                                selectchange={(temp) => setSelected(temp)} /> */}

                                    </div>
                                    <div className="col-lg-2 text-end">
                                        <DownloadBtn
                                            check={check}
                                            onClick={() => _downloadEarnings()} />
                                    </div>


                                    <div className="col-lg-3 position-relative text-end ps-0">
                                        <SearchInput
                                            placeholder="User name / client id"
                                            value={search}
                                            onSearch={(value) => getEarnings({ value: value })}
                                            valueChange={(value) => {
                                                setSearch(value);
                                                if (value === "") {
                                                    getEarnings({ value: "" });
                                                }
                                            }} />
                                        {
                                            search && search.length ?
                                                <>
                                                    <span style={{ right: "10px" }}
                                                        className={`${styles.e_search_submit} `}
                                                        onClick={() => getEarnings()} >
                                                    </span>
                                                    <button
                                                        className={`${styles.e_search_close} pe-3`}
                                                        onClick={() => {
                                                            setSearch("");
                                                            getEarnings({
                                                                value: ""
                                                            })
                                                        }} >
                                                    </button>
                                                </>
                                                :
                                                null
                                        }

                                    </div>
                                    <div className='col-lg-1'>
                                        <FilterBtn
                                            className={
                                                filterDown ?
                                                    "e-filter-btn e-active" :
                                                    "e-filter-btn"
                                            }
                                            handleClick={() => {
                                                setFilterDown(!filterDown)
                                            }} />
                                    </div>
                                    {
                                        filterDown ?
                                            <div className='row pe-0'>
                                                <div className='col-lg-12 px-0'>
                                                    <div className='e-filter-wraper mt-3 mx-0'>
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <label className="mb-2">Transactions from - till</label>
                                                                <Rangepicker
                                                                    start={earningDates.start}
                                                                    end={earningDates.end}
                                                                    // disabledDate={disabledDate}
                                                                    placeholder={["From", "To"]}
                                                                    onDatesChange={(start, end) => {
                                                                        setEarningDates({
                                                                            start: start,
                                                                            end: end
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-2">
                                                                <label className="mb-2">Type</label>
                                                                <SelectBox
                                                                    value={selected}
                                                                    options={options}
                                                                    isSearchable={false}
                                                                    placeholder="All games"
                                                                    className="e-sort"
                                                                    selectchange={(temp) => setSelected(temp)} />
                                                            </div>
                                                            <div className="col-lg-2">
                                                                <label className="mb-2">Game ID</label>
                                                                <SelectBox
                                                                    value={gameId}
                                                                    options={gameIds}
                                                                    isSearchable={false}
                                                                    placeholder="All games"
                                                                    className="e-sort"
                                                                    selectchange={(temp) => setGameId(temp)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }


                                    {/* data table */}
                                    <div className="col-lg-12 e-booking-table pt-3 ps-0">
                                        {
                                            loader ?
                                                <Loader />
                                                :
                                                earningData.length > 0 ?
                                                    <>
                                                        <DataTable
                                                            pagination={true}
                                                            columns={columns}
                                                            data={earningData}
                                                            selectableRows={true}
                                                            selectRows={(row) => { handleChange(row) }}
                                                            clearSelectedRows={downloadLoader ? true : false}

                                                        />
                                                    </>
                                                    :
                                                    <EmptyNote data="No data found" img={Nodata} />
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    {
                        error !== null ?
                            <Toast
                                data={error}
                                type="fail"
                                format={1}
                            />
                            :
                            null
                    }
                    {
                        success !== null ?
                            <Toast
                                data={success}
                                type="success"
                                format={1}
                            />
                            :
                            null
                    }
                </div>
            </div>
        </>
    )
}
export default Index;