/*
 *   File : detail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Details
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 25-01-2022
 */

/* import packages */
import React, { useState, useRef, useEffect } from 'react';
import $ from 'jquery';
import { Modal } from 'bootstrap';

/* import components */
import { TextArea, Input, FileUpload, ColorPicker, Timepicker, CheckBox } from '../input/index';
import { PrimaryBtn, PrimaryLink } from '../cta/index';
import SelectBox from '../dropdown/selectBox';
import { Editor } from '../input/index';
import { AddBlockRule, Confirmation } from '../modal';
import M2Etable from './m2eTable';

/*Style Import*/
import style from '../../styles/components/setPageAccess.module.scss';

/* import assets */

/* import services */
import service from '../../services/service';
import moment from 'moment';
import Loader from '../loader/loader';

import Down from '../../assets/images/dataFormating/down.svg'

const M2MGameSettingsAccordion = (props) => {
    //eslint-disable-next-line
    const editorRef = useRef();
    const durationRef = useRef();
    const wrapperRef = useRef()
    let [m2mBlockRuleData, setM2mBlockRuleData] = useState([]);
    const [listscrip, setListScrip] = useState([]);
    const [resultscrip, setResultScrip] = useState([]);
    const [permission, setPermission] = useState({});
    const [gameStartTime, setGameStartTime] = useState('');
    const [gameEndTime, setGameEndTime] = useState('');
    const [intervalStartTime, setIntervalStartTime] = useState('');
    const [intervalEndTime, setIntervalEndTime] = useState('');
    const [commissionPercent, setCommissionPercent] = useState(null)
    const [unitFee, setUnitFee] = useState(null);
    const [unitsPerScrip, setUnitsPerScrip] = useState(null);
    const [unitsPerGame, setUnitsPerGame] = useState(null);
    const [minimumPrice, setMinimumPrice] = useState(null);
    const [loseAmount, setLoseAmount] = useState(null);
    const [loseIn, setLoseIn] = useState(null);
    const [period, setPeriod] = useState(null);
    const [blockFor, setBlockFor] = useState(null);
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("");
    const [imageUrl, setUploadImageUrl] = useState("");
    //eslint-disable-next-line
    const [editorData, setEditorData] = useState();
    //eslint-disable-next-line
    const [uploadImage, setUploadImage] = useState("");
    const [changePrice, setChangePrice] = useState(false);
    const [openPrice, setOpenPrice] = useState(false);
    const [click, setClick] = useState(1);
    const [loader, setLoader] = useState(false);
    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [m2mTableData, setM2mTableData] = useState([]);
    //eslint-disable-next-line
    const [updated, setUpdated] = useState(false);
    //eslint-disable-next-line
    const [error, setError] = useState(null);
    const [slotType, setSlotType] = useState({})
    const [id, setId] = useState("");
    //eslint-disable-next-line
    const [imageLoader, setImageLoader] = useState(false);
    const [blockCode, setBlockCode] = useState(null);
    //eslint-disable-next-line
    const [selectDisable, setSelectDisable] = useState("");
    const [duration, setDuration] = useState([]);
    const [duratonPicker, setDurationPicker] = useState(false);
    const [m2mLoader, setM2mLoader] = useState(false)
    const [show, setShow] = useState(null);
    const [activeWeb, setActiveWeb] = useState(false);
    const [activeMobile, setActiveMobile] = useState(false);
    const [mobileUrl, setMobileUrl] = useState(null);

    useEffect(() => {
        getReturn()
        getM2mDetails();
        //eslint-disable-next-line
    }, [])

    const _closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShow(null)
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);

    const closeMenu = (event) => {
        if (durationRef.current && !durationRef.current.contains(event.target)) {
            setDurationPicker(false);
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };
    }, []);
    const options = [
        {
            value: true, label: 'Both sides',
        },
        {
            value: false, label: 'Single side'
        },
    ]

    const ruleOptions = [
        {
            value: 1,
            label: "Days"
        },
        {
            value: 2,
            label: "Month"
        },
        {
            value: 3,
            label: "Quarter"
        },
        {
            value: 4,
            label: "Year"
        }];

    /* m2e details */
    const getM2mDetails = () => {
        setM2mLoader(true);
        let url = '/m2m/details';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setM2mLoader(false);
                let basicinfo = response.data.data.basic_info;
                setSlotType({ value: basicinfo.slot_type, label: basicinfo.slot_type === 1 ? "60min slot" : basicinfo.slot_type === 2 ? "30min slot" : "15min slot" });
                setChangePrice(basicinfo.show_change_price);
                setOpenPrice(basicinfo.show_open_price);
                setPermission({ value: basicinfo.is_dual_booking_allowed, label: basicinfo.is_dual_booking_allowed === true ? "Both sides" : "Single side" })
                setResultScrip(basicinfo.result_scrips)
                // setPermission({ label: basicinfo.is_dual_booking_allowed === true ? "Both sides" : "Single side", value: basicinfo.is_dual_booking_allowed });
                setGameStartTime(moment(basicinfo.game_start_time, 'HH:mm A').format('h:mm a'));
                setGameEndTime(moment(basicinfo.game_end_time, 'HH:mm A').format('h:mm a'));
                setIntervalStartTime(basicinfo.interval_start_time);
                setIntervalEndTime(basicinfo.interval_end_time);
                setUnitFee(basicinfo.unit_fee);
                setCommissionPercent(basicinfo.commission_per_booking);
                setUnitsPerScrip(basicinfo.max_units_per_scrip);
                setUnitsPerGame(basicinfo.max_units_per_game);
                setMinimumPrice(basicinfo.minimum_prize);
                setListScrip(basicinfo.stocks)
                setResultScrip(basicinfo.result_percentage_count)

                // setBlockingStartTime(moment(basicinfo.booking_start_time, 'HH:mm:ss').format('h:mm a'));
                // setBlockingEndTime(moment(basicinfo.booking_end_time, 'HH:mm:ss').format('h:mm a'));
                // setCoolingEndTime(moment(basicinfo.cooling_end_time, 'HH:mm:ss').format('h:mm a'));
                // setCoolingStartTime(moment(basicinfo.cooling_start_time, 'HH:mm:ss').format('h:mm a'));
                // setUnitFee(basicinfo.unit_fee);
                // setUnitsPerScrip(basicinfo.max_units_per_scrip);
                // setUnitsPerGame(basicinfo.max_units_per_game);
                // setMinimumPrice(basicinfo.minimum_prize);
                // setCommissionPercent(basicinfo.commission_percentage);
                let blockrule = response.data.data.block_rules;
                setM2mBlockRuleData(blockrule);
                if (updated === true) {
                    // getM2eTableInfo();
                }
                let settings = response.data.data.settings;
                setUrl(settings.video_url);
                setDescription(settings.about_two_line);
                setEditorData(settings.about);
                setUploadImageUrl(settings.image_url);
                setBackgroundColor(settings.background_color);
                setActiveWeb(settings.active_web);
                setActiveMobile(settings.active_mobile);
                setMobileUrl(settings.mobile_video_url);
            } else {
                setError(response.data.message);
                setM2mLoader(false);
            }
        })
    }

    //api request body to update basic info 
    const m2mApiData = {
        "stocks": Number(listscrip),
        "result_scrips": Number(resultscrip),
        "is_dual_booking_allowed": permission.value,
        "game_start_time": gameStartTime,
        "game_end_time": gameEndTime,
        "interval_start_time": intervalStartTime,
        "interval_end_time": intervalEndTime,
        "unit_fee": Number(unitFee),
        "commission_per_booking": Number(commissionPercent),
        "max_units_per_scrip": Number(unitsPerScrip),
        "max_units_per_game": Number(unitsPerGame),
        "minimum_prize": Number(minimumPrice),
        "slot_type": slotType?.value,
        // "show_open_price": openPrice,
        // "show_change_price": changePrice,
    }

    const openCloseRequest = {
        "show_open_price": openPrice,
        "show_change_price": changePrice,
    }

    /* API - customize table */
    const customizeTable = () => {
        setUpdated(false);
        setLoader(true);
        let url = "/m2m/customize-table";

        let body = {
            table_data: m2mTableData
        };

        service(true, url, body).then((response) => {
            if (response.data.status_code === 200) {
                if (response.data.status === true) {
                    setUpdated(true);
                    setLoader(false);
                } else {
                    setError(response.data.message);
                    setUpdated(false);
                    setLoader(false);
                }
            } else {
                setError(response.data.message);
                setUpdated(false);
                setLoader(false);
            }
        });
    }

    const slotOptions = [
        { label: "60min slot", value: 1 },
        { label: "30min slot", value: 2 },
        { label: "15min slot", value: 3 }
    ]

    /*--- edit rule ---*/
    const editRule = (data, key) => {
        console.log(key)
        setId(data.block_rule_id)
        document.getElementById(`amount${key}`).disabled = false;
        document.getElementById(`loseIn${key}`).disabled = false;
        setSelectDisable(`select${key}`)
        document.getElementById(`block${key}`).disabled = false;
        document.getElementById(`code${key}`).disabled = false;
        document.getElementById(`type${key}`).style.pointerEvents = "all"
    }

    /* update block rule */
    const updateBlockRule = (item) => {
        let url = "/m2m/update-blockrule";

        let data = {
            "block_rule_id": item.block_rule_id,
            "loss_amount": item.loss_amount,
            "transaction_period": item.transaction_period,
            "block_period": item.block_for_days,
            "block_rule_type": item.period_type,
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                // message.success("Block rule updated");
                getM2mDetails();
                setSelectDisable("")
                setId("");
                setClick(3);
            } else {
                setError(response.data.message);
            }
        })
    }

    /* API - Create pre signed url in s3 */
    const preSignedUrl = (file) => {
        var date = new Date()
        setImageLoader(true);
        let url = "/app/presigned-url-upload";
        let data = {
            key: `m2m/` + date.toISOString() + '/thumbnail.png',
            is_public: true
        };

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setImageLoader(true);
                uploadDocument(response.data.data, file); // upload file in AWS s3
            } else {
                setError(response.data.message);
                setImageLoader(false);
            }
        });
    }

    /* Uploading to s3 */
    const uploadDocument = async (data, file) => {
        setImageLoader(true);
        var formdata = new FormData();
        formdata.append("key", data.upload_info.fields.key);
        formdata.append("x-amz-algorithm", data.upload_info.fields['x-amz-algorithm']);
        formdata.append("x-amz-credential", data.upload_info.fields['x-amz-credential']);
        formdata.append("x-amz-date", data.upload_info.fields['x-amz-date']);
        formdata.append("policy", data.upload_info.fields['policy']);
        formdata.append("x-amz-signature", data.upload_info.fields['x-amz-signature']);
        formdata.append("file", file, file.name);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(data.upload_info.url, requestOptions)
            .then(response => response.text())
            .then(result => {
                setUploadImageUrl(data.view_info); // file got uploaded successfully
                setImageLoader(false);
            })
            .catch(error => {
                console.log('error', error); // show error toast here
                setImageLoader(false);
            });
    }

    /*update m2m settings */
    const updateM2mSettings = () => {
        let apiUrl = "/m2m/add-design";
        let data = {
            "colour": backgroundColor,
            "image": imageUrl,
            "video": url,
            "title": editorData,
            "description": description,
            "active_web": activeWeb,
            "active_mobile": activeMobile,
            "mobile_video_url": mobileUrl,
        }
        service(true, apiUrl, data).then((response) => {
            if (response.data.status_code === 200) {
                getM2mDetails();

            } else {
                setError(response.data.message);
            }
        })
    }


    const getReturn = () => {
        for (let i = 1; i <= 60; i++) {
            let temp = duration
            if (temp.length <= 60) {
                if (i < 10) {
                    temp.push(`00:0${i}:00`)
                } else {
                    temp.push(`00:${i}:00`)
                }
            }
            setDuration(temp)
        }
    }




    return (
        <>
            <div className='e-game-settings-accordion e-manage-game-accordion e-add-milestone-wrap px-4'>
                {
                    m2mLoader ?
                        <Loader />
                        :
                        <div class="accordion" id="accordionExample">

                            {/*update basic info */}
                            <div class="accordion-item">
                                {/* click to open accordion */}
                                <div onClick={() => setClick(1)}>
                                    {
                                        click !== 1 ?
                                            < h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    <div className="row">
                                                        <div className="col-lg-10 text-start">
                                                            Winner scrip count, booking time & quantity
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-plus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* click to close accordion */}
                                <div onClick={() => setClick(null)}>
                                    {
                                        click === 1 ?
                                            < h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    <div className="row">
                                                        <div className="col-lg-10 text-start">
                                                            Winner scrip count, booking time & quantity
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-minus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* accordion body */}

                                {
                                    click === 1 ?
                                        <div class="accordion-body pt-0">
                                            <div className='row'>
                                                <div className='col-lg-4'>
                                                    <label className="mb-1">Listing scrip count</label>
                                                    <Input
                                                        placeholder="Scrip count"
                                                        type="number"
                                                        value={listscrip}
                                                        valueChange={(temp) => {
                                                            setListScrip(temp)
                                                        }} />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <label className="mb-1">Result scrip count</label>
                                                    <Input
                                                        placeholder="Result scrip count"
                                                        type="number"
                                                        value={resultscrip}
                                                        valueChange={(temp) => {
                                                            setResultScrip(temp);
                                                        }} />

                                                </div>
                                                <div className='col-lg-4'>
                                                    <label className="mb-1">Booking permission</label>
                                                    <SelectBox
                                                        options={options}
                                                        isSearchable={false}
                                                        value={permission}
                                                        className="e-milestone-sort"
                                                        selectchange={(temp) => {
                                                            setPermission(temp);
                                                        }} />
                                                    {/* <Input placeholder="Scrip count" value={resultscrip} valueChange={(temp) => setResultScrip(temp)} /> */}

                                                </div>
                                            </div>

                                            <div className='row pt-4'>
                                                <div className='col-lg-4'>
                                                    <label className="mb-1">Game time</label>
                                                    <Timepicker
                                                        value={[gameStartTime, gameEndTime]}
                                                        className="e-input-suffix"
                                                        onChange={(time) => {
                                                            setGameStartTime(time[0]);
                                                            setGameEndTime(time[1])
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-lg-4 position-relative'>
                                                    <label className="mb-1">Interval time duration</label>
                                                    <div className='e-time-duration' onClick={() => setDurationPicker(!duratonPicker)}>
                                                        {
                                                            intervalStartTime ?
                                                                <span className='e-duration-selected'>{intervalStartTime}</span>
                                                                :
                                                                <span className='e-duration-placeholder'>{'Select time duration'}</span>
                                                        }
                                                    </div>
                                                    {
                                                        duratonPicker ?
                                                            <div className='e-show-duration-picker' ref={durationRef} >
                                                                {
                                                                    duration.map((item, key) => {
                                                                        return (
                                                                            <p className='e-duration-value' onClick={() => { setIntervalStartTime(item); setIntervalEndTime(item); setDurationPicker(false) }}>{item}</p>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                <div className='col-lg-4'>
                                                    <label className="mb-1">Commission percentage</label>
                                                    <Input
                                                        placeholder="Commission percentage"
                                                        type="number"
                                                        suffix="%"
                                                        value={parseInt(commissionPercent)}
                                                        valueChange={(temp) => {
                                                            parseInt(setCommissionPercent(temp))
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-lg-4'>
                                                    <label className="mb-1">Max units per scrip</label>
                                                    <Input
                                                        placeholder="Max units per scrip"
                                                        type="number"
                                                        value={unitsPerScrip}
                                                        valueChange={(temp) => {
                                                            setUnitsPerScrip(temp)
                                                        }} />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <label className="mb-1">Unit fee</label>
                                                    <Input
                                                        placeholder="Unit fee"
                                                        type="number"
                                                        className="e-input-suffix-rs"
                                                        value={unitFee}
                                                        valueChange={(temp) => {
                                                            setUnitFee(temp)
                                                        }} />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <label className="mb-1">Max units per game</label>
                                                    <Input
                                                        placeholder="Max units per game"
                                                        type="number"
                                                        value={unitsPerGame}
                                                        valueChange={(temp) => {
                                                            setUnitsPerGame(temp)
                                                        }} />
                                                </div>
                                            </div>
                                            <div className='row pt-4'>
                                                <div className='col-lg-4'>
                                                    <label className="mb-1">Minimum price money</label>
                                                    <Input
                                                        placeholder="Minimum price money"
                                                        value={minimumPrice}
                                                        valueChange={(temp) => {
                                                            setMinimumPrice(temp);
                                                        }} />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <label className="mb-1">Slot type</label>
                                                    <SelectBox
                                                        placeholder="Select slot type"
                                                        options={slotOptions}
                                                        isSearchable={false}
                                                        value={Object.keys(slotType).length > 0 ? slotType : null}
                                                        selectchange={(temp) => {
                                                            setSlotType(temp);
                                                        }} />
                                                </div>
                                            </div>
                                            {/* <div className='row pt-4'>
                                                <div className='col-lg-4'>
                                                    <CheckBox
                                                        check={changePrice}
                                                        checkboxText="Show change percentage in booking time"
                                                        onChange={(e) => setChangePrice(e.target.checked)} />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <CheckBox
                                                        check={openPrice}
                                                        checkboxText="Show open price in booking time"
                                                        onChange={(e) => setOpenPrice(e.target.checked)} />
                                                </div>
                                            </div> */}
                                            <div className='row pt-3'>
                                                {
                                                    listscrip && resultscrip && Object.values(permission).length > 0 &&
                                                        gameStartTime && gameEndTime && intervalStartTime && intervalEndTime &&
                                                        unitFee && unitsPerGame && unitsPerScrip &&
                                                        minimumPrice && commissionPercent !== "" ?
                                                        <div className='col-lg-2'>
                                                            <PrimaryBtn
                                                                name="Save changes"
                                                                className="e-acc-btn"
                                                                handleClick={() => {
                                                                    var modal = new Modal(document.getElementById("confirmation"), {});
                                                                    modal.show();
                                                                    setType("m2m");
                                                                    setTitle("Do yor really want to save changes made to M2M settings?")
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className='col-lg-2'>
                                                            <PrimaryBtn
                                                                name="Save changes"
                                                                className="e-acc-btn e-disabled" />
                                                        </div>
                                                }
                                            </div>

                                        </div>
                                        :
                                        null
                                }

                            </div>

                            <div class="accordion-item">
                                {/* click to open accordion */}
                                <div onClick={() => setClick(5)}>
                                    {
                                        click !== 5 ?
                                            < h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    <div className="row">
                                                        <div className="col-lg-10 text-start">
                                                            Open close update
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-plus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* click to close accordion */}
                                <div onClick={() => setClick(null)}>
                                    {
                                        click === 5 ?
                                            < h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    <div className="row">
                                                        <div className="col-lg-10 text-start">
                                                            Open close update
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <span className='e-accordion-icon-minus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* accordion body */}

                                {
                                    click === 5 ?
                                        <div class="accordion-body pt-0">
                                            <div className='row pt-4'>
                                                <div className='col-lg-4'>
                                                    <CheckBox
                                                        check={changePrice}
                                                        checkboxText="Show change percentage in booking time"
                                                        onChange={(e) => setChangePrice(e.target.checked)} />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <CheckBox
                                                        check={openPrice}
                                                        checkboxText="Show open price in booking time"
                                                        onChange={(e) => setOpenPrice(e.target.checked)} />
                                                </div>
                                            </div>
                                            <div className='row pt-3'>

                                                <div className='col-lg-2'>
                                                    <PrimaryBtn
                                                        name="Save changes"
                                                        className="e-acc-btn"
                                                        handleClick={() => {
                                                            var modal = new Modal(document.getElementById("confirmation"), {});
                                                            modal.show();
                                                            setType("m2m");
                                                            setTitle("Do yor really want to save changes made to M2M settings?")
                                                        }}
                                                    />
                                                </div>

                                            </div>

                                        </div>
                                        :
                                        null
                                }

                            </div>

                            {/*table*/}
                            <div class="accordion-item">
                                {/* click to open accordion */}
                                <div onClick={() => setClick(2)}>
                                    {
                                        click !== 2 ?
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo">

                                                    <div className="row">
                                                        <div className="col-lg-10 text-start">
                                                            Customize table
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <span className='e-accordion-icon-plus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* click to close accordion */}
                                <div onClick={() => setClick(null)}>
                                    {
                                        click === 2 ?
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo">
                                                    <div className="row">
                                                        <div className="col-lg-10 text-start">
                                                            Customize table
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <span className='e-accordion-icon-minus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* accordion body */}
                                {
                                    click === 2 ?
                                        <div className="accordion-body pt-0">
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <div className={`col-lg-12 ${style.e_card_wrap} border-0`}>
                                                        <M2Etable
                                                            tableData={(data) => {
                                                                setM2mTableData(data);
                                                            }}
                                                            type="m2m" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row pt-3'>
                                                <div className='col-lg-2'>
                                                    <PrimaryBtn
                                                        name="Save changes"
                                                        className={loader ? "e-acc-btn e-btn-loader" : "e-acc-btn"}
                                                        handleClick={() => customizeTable()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                            </div>

                            {/*block rule*/}
                            <div class="accordion-item">
                                {/* click to open accordion */}
                                <div onClick={() => setClick(3)}>
                                    {
                                        click !== 3 ?
                                            <h2 class="accordion-header" id="headingThree">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                    <div className="row">
                                                        <div className="col-lg-10 text-start">
                                                            Block rules
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <span className='e-accordion-icon-plus'></span>
                                                        </div>
                                                    </div>

                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* click to close accordion */}
                                <div onClick={() => setClick(null)}>
                                    {
                                        click === 3 ?
                                            <h2 class="accordion-header" id="headingThree">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                    <div className="row">
                                                        <div className="col-lg-10 text-start">
                                                            Block rules<span><PrimaryLink name="Add new rule" className="ps-2" handleClick={() => {
                                                                setClick(3)
                                                                var modal = new Modal(document.getElementById("add-block-rule"), {})
                                                                modal.show()

                                                            }} /></span>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <span className='e-accordion-icon-minus'></span>
                                                        </div>
                                                    </div>

                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* accordion body */}
                                {
                                    click === 3 ?
                                        <div class="accordion-body pt-0 ">
                                            {
                                                m2mBlockRuleData.map((item, i) => {
                                                    return (
                                                        <div className='row'>
                                                            <div className='col-lg-3'>
                                                                <label className="mb-1">Lose amount</label>
                                                                <Input
                                                                    type="number"
                                                                    disabled={true}
                                                                    id={`amount${i}`}
                                                                    placeholder="Lose amount"
                                                                    suffix="₹"
                                                                    defaultValue={item.loss_amount}
                                                                    value={loseAmount}
                                                                    valueChange={(temp) => {
                                                                        m2mBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setLoseAmount(temp) : item.loss_amount);
                                                                        let tempArr = [...m2mBlockRuleData];
                                                                        tempArr[i]['loss_amount'] = temp;
                                                                        m2mBlockRuleData = tempArr;
                                                                        setM2mBlockRuleData(m2mBlockRuleData)
                                                                    }} />
                                                            </div>
                                                            <div className='col-lg-3 e-add-block-in position-relative'>
                                                                <label className="mb-1">in</label>
                                                                <Input id={`loseIn${i}`}
                                                                    suffixId={`type${i}`}
                                                                    suffix={loseIn !== null ? loseIn : item.period_type === 1 ? <span onClick={() => { setShow(i) }}>Days <img src={Down} alt="" /></span> : item.period_type === 2 ? <span onClick={() => { setShow(i) }}>Month <img src={Down} alt="" /></span> : item.period_type === 3 ? <span onClick={() => { setShow(i) }}>Quarter <img src={Down} alt="" /></span> : <span onClick={() => { setShow(i) }}>Year <img src={Down} alt="" /></span>}
                                                                    disabled={true} placeholder="days" value={period}
                                                                    defaultValue={item.transaction_period}
                                                                    valueChange={(temp) => {
                                                                        m2mBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setPeriod(temp) : item.transaction_period);
                                                                        let tempArr = [...m2mBlockRuleData];
                                                                        tempArr[i]['transaction_period'] = parseInt(temp);
                                                                        m2mBlockRuleData = tempArr;
                                                                        setM2mBlockRuleData(m2mBlockRuleData)
                                                                    }}
                                                                />
                                                                {
                                                                    show === i ?
                                                                        <div className="e-block-show" ref={wrapperRef}>
                                                                            {
                                                                                ruleOptions.map((ruleItem) => {
                                                                                    return (
                                                                                        <p className={ruleItem.value === item.period_type ? 'e-selected mb-2 mt-2' : 'mb-2 mt-2'}
                                                                                            onClick={() => {
                                                                                                m2mBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setLoseIn(ruleItem.value) : item.period_type);
                                                                                                let tempArr = [...m2mBlockRuleData];
                                                                                                tempArr[i]['period_type'] = parseInt(ruleItem.value);
                                                                                                m2mBlockRuleData = tempArr;
                                                                                                setM2mBlockRuleData(m2mBlockRuleData)
                                                                                                setShow(null)
                                                                                            }}>{ruleItem.label}</p>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className='col-lg-3 e-add-block-rule position-relative'>
                                                                <label className="mb-1">Block for</label>
                                                                <Input
                                                                    id={`block${i}`}
                                                                    suffix={"Days"}
                                                                    disabled={true} placeholder="days" value={blockFor}
                                                                    defaultValue={item.block_for_days}
                                                                    valueChange={(temp) => {
                                                                        m2mBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setBlockFor(temp) : item.block_for_days);
                                                                        let tempArr = [...m2mBlockRuleData];
                                                                        tempArr[i]['block_for_days'] = parseInt(temp);
                                                                        m2mBlockRuleData = tempArr;
                                                                        setM2mBlockRuleData(m2mBlockRuleData)
                                                                    }} />
                                                            </div>
                                                            <div className='col-lg-2 pe-0'>
                                                                <label className="mb-1">Block code</label>
                                                                <Input
                                                                    id={`code${i}`}
                                                                    disabled={true}
                                                                    placeholder="days"
                                                                    value={blockCode}
                                                                    defaultValue={item.block_code}
                                                                    valueChange={(temp) => {
                                                                        m2mBlockRuleData.includes((data) => data.block_rule_id === item.block_rule_id ? setBlockCode(temp) : item.block_for_days);
                                                                        let tempArr = [...m2mBlockRuleData];
                                                                        tempArr[i]['block_code'] = temp;
                                                                        m2mBlockRuleData = tempArr;
                                                                        setM2mBlockRuleData(m2mBlockRuleData)
                                                                    }} />
                                                            </div>
                                                            <div className='col-lg-1  ps-0'>
                                                                <span className='d-inline-flex mt-4 pt-3'>
                                                                    {
                                                                        item.block_rule_id === id ?
                                                                            <PrimaryLink name="Save" className="e-save-btn" handleClick={() => { updateBlockRule(item) }} /> :
                                                                            <PrimaryLink name="Edit" className="e-edit-btn" handleClick={() => editRule(item, i)} />
                                                                    }
                                                                    <PrimaryLink name="Delete" className="e-delete-btn" target="#confirmation" toggle="modal" handleClick={() => {
                                                                        setId(item.block_rule_id);
                                                                        setTitle("Confirmation");
                                                                        setType("m2m");
                                                                    }} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        null
                                }


                            </div>

                            {/*settings*/}

                            <div class="accordion-item">

                                {/* click to open accordion */}
                                <div onClick={() => setClick(4)}>
                                    {
                                        click !== 4 ?
                                            <h2 class="accordion-header" id="headingFour">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="collapseFour">
                                                    <div className="row">
                                                        <div className="col-lg-10 text-start">
                                                            Game image & color
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <span className='e-accordion-icon-plus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>

                                {/* click to close accordion */}
                                <div onClick={() => setClick(null)}>
                                    {
                                        click === 4 ?
                                            <h2 class="accordion-header" id="headingFour">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="collapseFour">
                                                    <div className="row">
                                                        <div className="col-lg-10 text-start">
                                                            Game image & color
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <span className='e-accordion-icon-minus'></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            :
                                            null
                                    }
                                </div>
                                {/* accordion body */}
                                {
                                    click === 4 ?
                                        <div class="accordion-body pt-0">

                                            <div className='row'>
                                                <div className='col-lg-4'>
                                                    <div className='row pe-2'>
                                                        <label className="mb-1">Choose image</label>
                                                        <div className="e-uploader" onClick={() => { $('#e-upload').click() }}>
                                                            {
                                                                uploadImage ?
                                                                    <>
                                                                        <p className='text-center mb-2'>Choose image</p>
                                                                        <div className='text-center mb-0 setting-detail'>(Selected: {uploadImage})</div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p className='text-center mb-2'>Choose image</p>
                                                                        <p className='text-center mb-0'>
                                                                            <span>(500 X 500 png recommended)</span>
                                                                        </p>
                                                                    </>

                                                            }

                                                            <FileUpload
                                                                accept="application/pdf, image/*"
                                                                className="hidden"
                                                                id="e-upload"
                                                                upload={(temp) => {
                                                                    preSignedUrl(temp);
                                                                    setUploadImage(temp.name)
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className='row pt-5 pe-2'>
                                                        <label className="mb-1">Choose background color</label>
                                                        <ColorPicker
                                                            color={(data) => {
                                                                setBackgroundColor(data.hex);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 ps-5'>
                                                    <label className="mb-1">Image & bg color preview</label>
                                                    <div className='e-preview-sec'>
                                                        <div style={{ backgroundColor: backgroundColor }}
                                                            className="e-preview-sub-sec">
                                                            <img src={imageUrl} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row pt-4'>
                                                <div className='col-lg-2'>
                                                    <CheckBox
                                                        check={activeWeb}
                                                        checkboxText="Show game in Web"
                                                        onChange={(e) => setActiveWeb(e.target.checked)} />
                                                </div>
                                                <div className='col-lg-3'>
                                                    <CheckBox
                                                        check={activeMobile}
                                                        checkboxText="Show game in Mobile"
                                                        onChange={(e) => setActiveMobile(e.target.checked)} />
                                                </div>
                                            </div>
                                            <div className='row pt-3 pe-0'>
                                                <div className='col-lg-4 pe-0'>
                                                    <label className="mb-1">Tutorial video link for Web</label>
                                                    <Input
                                                        placeholder="Url"
                                                        value={url}
                                                        valueChange={(temp) => {
                                                            setUrl(temp);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className='row pt-3 pe-0'>
                                                <div className='col-lg-4 pe-0'>
                                                    <label className="mb-1">Tutorial video link for Mobile</label>
                                                    <Input
                                                        placeholder="Url"
                                                        value={mobileUrl}
                                                        valueChange={(temp) => setMobileUrl(temp)} />
                                                </div>
                                            </div>
                                            <div className='row pt-3'>
                                                <div className='col-lg-12'>
                                                    <label className="mb-1">Two liner about game</label>
                                                    <TextArea
                                                        placeholder="Description"
                                                        value={description}
                                                        valueChange={(temp) => {
                                                            setDescription(temp);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className='row e-accordion-editor pt-3'>
                                                <div className='col-lg-12'>
                                                    <label className="mb-1">About game</label>
                                                    <Editor
                                                        editorChange={(temp) => {
                                                            setEditorData(temp);
                                                        }}
                                                        value={editorData} />
                                                </div>
                                            </div>

                                            <div className='row pt-3'>
                                                <div className='col-lg-2'>
                                                    <PrimaryBtn
                                                        name="Save changes"
                                                        className="e-acc-btn"
                                                        handleClick={() => updateM2mSettings()} />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                }
            </div>
            <AddBlockRule type="m2m"
                ruleAdded={() => { getM2mDetails() }} />
            <Confirmation type={type}
                apiData={m2mApiData}
                click={click}
                title={title}
                block_rule_id={id}
                openClose={openCloseRequest}
                close={() => { }}
                update={() => {
                    getM2mDetails()
                }} />
        </>
    );
}


export default M2MGameSettingsAccordion;