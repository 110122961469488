import axios from 'axios';

export default async function service(type, url, data) {
	if (type === true) {
		let API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE + url : process.env.REACT_APP_PRO_MODE + url;
		let token = localStorage.getItem("token")
		return await axios.post(
			API_URL,
			data,
			{
				'headers': {
					'method': 'post',
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			}

		).then(function (response) {
			return response
		}).catch((error) => {
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				// // console.log("error.response.status : ", error.response.status);
				// // console.log("error.response.headers : ", error.response.headers);
				if (error.response.data.status_code === 401) {
					console.log("error::", error.response)
					localStorage.clear();
					sessionStorage.clear();
					sessionStorage.removeItem("token");
					window.location.href = "/"
				}
				return error.response;

			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log("error.request", error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				// console.log('Error', error.message);
			}
		})
	} else {
		let API_URL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_MODE + url : process.env.REACT_APP_PRO_MODE + url;
		let token = localStorage.getItem("token")
		return await axios.get(
			API_URL,
			{
				headers: {
					'method': 'get',
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			}
		).then(function (response) {
			return response

		}).catch((error) => {
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				// console.log("error.response.data : ", error.response.data);
				// // console.log("error.response.status : ", error.response.status);
				// // console.log("error.response.headers : ", error.response.headers);
				if (error.response.data.status_code === 401) {
					console.log("error::", error.response)
					localStorage.clear();
					sessionStorage.clear();
					sessionStorage.removeItem("token");
					window.location.href = "/"
				}
				return error.response;

			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log("error.request", error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				// console.log('Error', error.message);
			}
		})
	}
} 
