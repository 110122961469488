/*
 *   File : funds.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Funds Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 12-11-2021
*/

/*import packages*/
import { PrimaryBtn, TextBtn } from '../../components/cta/index';
import { AddFund } from '../../components/modal/index';


/*import styles*/
import styles from '../../styles/components/funds.module.scss';

/*import assets*/
import Edit from '../../assets/images/cta/edit.png';

const FundsCard = (props) => {
	return (
		<>
			<div className={`${styles.e_fund_card} card border-0 p-4`}>
				<div className="row">
					<div className="col-lg-8">
						<h6 className={`d-inline-block ${styles.e_card_title}`}>{props.data.title}</h6>
						{
							props.data.risk === 'low' ?
								<span className={`${styles.e_low_risk} ms-2`}>Low risk</span>
								: props.data.risk === 'medium' ?
									<span className={`${styles.e_medium_risk} ms-2`}>Moderate risk</span>
									:
									<span className={`${styles.e_high_risk} ms-2`}>High risk</span>
						}
					</div>
					<div className="col-lg-4 text-end">
						{
							props.data.returns ?
								<TextBtn left={true} image={Edit} name="Edit" toggle="modal" target="#addFund" />
								:
								null
						}
					</div>
					<div className="col-lg-12 mt-3">
						<div className={`${styles.e_inner_card} p-3`}>
							{
								props.data.returns && props.data.returns.length ?
									<>
										<div className="row">
											<div className="col-lg-2">
												<img src={props.data.icon} alt="" className={styles.e_logo} />
											</div>
											<div className="col-lg-10">
												<h6 className={styles.e_inner_card_title}>{props.data.organization}</h6>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-12">
												<label>Returns</label>
											</div>
										</div>
										<div className="row">
											{
												props.data.returns.map((item, key) => {
													return (
														<div className="col-lg-4" key={key}>
															<p className={styles.e_returns}><span>{item.year}</span> {item.percentage}</p>
														</div>
													)
												})
											}
										</div>
									</>
									:
									<div className="col-lg-12 text-center mt-4">
										<PrimaryBtn name="+ Add fund" className={`${styles.e_p_btn}`} toggle="modal" target="#addFund" />
									</div>
							}
						</div>
					</div>
				</div>
			</div>
			<AddFund />
		</>
	)
}

export default FundsCard;
