/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for milestone container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 16-02-2022
 */
/*import pakcages*/
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

/*import components*/
import { DataTable, GameTabs } from '../../components/dataFormating/index';
import Loader from '../../components/loader/loader';
import { SearchInput } from '../../components/input';
import SelectBox from '../../components/dropdown/selectBox';

/*import containers */
import M2E from './m2e.js';
// import BlockedUsers from './blocked-users.js';

/*import assets*/
import Arrow from '../../assets/images/milestone/arrow.svg';
import { DownloadBtn, FilterBtn } from '../../components/cta/index';
import NoData from '../../assets/images/note/no-data.png';

/* import services */
import service from '../../services/service';


/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';
import { EmptyNote } from '../../components/note';
import Datatable from '../../components/dataFormating/dataTable';

const WINNING_STATUS_OPTIONS = [
    { value: null, label: 'All' },
    { value: true, label: 'Won' },
    { value: false, label: 'Lost' }
]

const PAID_OPTIONS = [
    { value: null, label: 'All' },
    { value: true, label: 'Paid' },
    { value: false, label: 'Not paid' }
]

const STATUS_OPTIONS = [
    { value: null, label: 'All' },
    { value: true, label: 'Active' },
    { value: false, label: 'In-active' }
]

const GAME_OPTIONS = [
    { label: 'All', value: null },
    { label: 'M2E', value: 1 },
    { label: 'M2M', value: 2 },
    { label: 'Skill race', value: 3 }
]

const Index = (props) => {
    const location = useLocation();
    const [tabIndex, setTabindex] = useState(1);
    const [list, setList] = useState([]);
    const [completedMilestones, setCompletedMilestones] = useState([]);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({});
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(200)
    const [milestoneReport, setMilestoneReport] = useState([]);
    const [status, setStatus] = useState(WINNING_STATUS_OPTIONS[0])
    const [gameType, setGameType] = useState(GAME_OPTIONS[0]);
    const [filterDown, setFilterDown] = useState(false);
    const [check, setCheck] = useState([]);
    //eslint-disable-next-line
    const [total, setTotal] = useState(null);
    //eslint-disable-next-line
    const [totalLength, setTotalLength] = useState(null);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [paid, setPaid] = useState(PAID_OPTIONS[0]);
    const [paginationData, setPaginationData] = useState({});
    const [activeStatus, setActiveStatus] = useState(STATUS_OPTIONS[1]);
    const [mileStoneId, setMilestoneId] = useState(0);
    const [milestoneOptions, setMilestoneOptions] = useState([])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        _getMilestoneFilter();
    }, [])

    useEffect(() => {
        getLocation();
        //eslint-disable-next-line
    }, [location])

    const getLocation = async () => {
        if (location && location.state) {
            await setTabindex(Number(location?.state?.id))
        }
    }

    useEffect(() => {
        if (tabIndex !== 4) {
            _mileStones(1)
        }
        //eslint-disable-next-line
    }, [tabIndex])

    useEffect(() => {
        if (search === "" && tabIndex === 4) {
            _mileStonesReport()
        }
        //eslint-disable-next-line
    }, [gameType, tabIndex, search, status, page, perPage, paid, activeStatus, mileStoneId])

    //mile stone list
    const _mileStones = (index) => {
        setLoader(true)
        let url = "/milestone/list"

        let data = {
            game_type_id: tabIndex
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false)
                setList(response.data.data.active_milestones)
                setCompletedMilestones(response.data.data.completed_milestones)
            } else {
                setList([])
                setLoader(false);
                setCompletedMilestones([])
            }
        })
    }

    const _getMilestoneFilter = () => {
        const url = "/report/milestone-filter";

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                let temp_milestone_options = []
                temp_milestone_options.push({ label: "All", value: 0 })
                response.data.data.map((item) => {
                    return (
                        temp_milestone_options.push({
                            label: item.milestone_name,
                            value: item.milestone_id
                        })
                    )
                })
                setMilestoneOptions(temp_milestone_options);
                setMilestoneId(temp_milestone_options[0]);
            } else {
                setMilestoneOptions([]);
            }
        });
    }

    //mile stone list
    const _mileStonesReport = (temp) => {
        setLoader(true)
        let url = "/report/milestone-report"

        let data = {
            "game_type_id": gameType.value,
            "query": temp ? temp.query : search,
            "win_status": status?.value,
            "milestone_id": mileStoneId.value,
            "is_active": activeStatus.value,
            "is_paid": paid?.value,
            "page_num": page,
            "page_size": perPage,
        }

        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false)
                setMilestoneReport(response.data.data);
                setTotal(response.data.number_of_records);
                let remainder = response.data.number_of_records % perPage
                let quotient = response.data.number_of_records / perPage
                if (Number(remainder) > 0) {
                    setTotalLength(parseInt(Number(quotient) + 1))

                } else {
                    setTotalLength(Number(quotient))
                }
                setPaginationData({
                    rowsPerPageText: `${response.data.rows} of ${response.data.number_of_records} | Rows per page:`,
                })

            } else {
                setMilestoneReport([])
                setLoader(false)
            }
        })
    }


    const openDetail = (id) => {
        window.open(`/user-detail?id=${id}`, "_blank");
    }

    const columns = [
        {
            name: 'Name',
            selector: 'milestone_name',
            sortable: false,
            minWidth: '180px',
            cell: row => <>
                {row.milestone_name}
            </>
        },
        {
            name: 'Type',
            selector: 'game_type',
            sortable: false
        },
        {
            name: 'Max possible bookings in a day',
            selector: 'max_possible_bookings_in_a_day',
            sortable: false,
            minWidth: '320px',
            cell: row => <>
                {row.max_possible_bookings_in_a_day}
            </>
        },
        {
            name: 'Max possible bookings in a month',
            selector: 'max_possible_bookings_in_a_month',
            sortable: false,
            minWidth: '320px',
            cell: row => <>
                {row.max_possible_bookings_in_a_month}
            </>
        },
        {
            name: 'Required target points',
            selector: 'required_target_points',
            sortable: true,
            minWidth: '220px'
        },
        {
            name: 'Required target points %',
            selector: 'required_target_points_percentage',
            sortable: true,
            minWidth: '220px'
        },
        {
            name: 'Max reward in a day',
            selector: 'max_reward_in_a_day',
            sortable: true,
            minWidth: '220px',
            cell: row => <>₹{row.max_reward_in_a_day}</>
        },
        {
            name: 'Reward',
            selector: 'rewards',
            sortable: true,
            cell: row => <>₹{row.rewards}</>
        },
        {
            name: 'Time period',
            selector: 'time_period_range',
            sortable: false,
            minWidth: '220px',
            cell: row => <>
                {
                    row.time_period_range
                }
            </>
        },
        {
            name: 'Total trading days',
            selector: 'total_trading_days',
            sortable: false,
            minWidth: '220px',
            cell: row => <>
                {
                    row.total_trading_days
                }
            </>
        },

        {
            name: 'Winning status',
            selector: 'win_status',
            sortable: false,
            cell: row => <>{
                row.win_status ?
                    "Yes"
                    :
                    "No"
            }
            </>
        },
    ]

    const reportColumns = [
        {
            name: 'Milestone name',
            selector: 'milestone_name',
            sortable: false,
            minWidth: '180px',
            // cell: row => <div className='e-name-link' onClick={() => openDetail(row.customer_id)}>{row.name}</div>,
        },

        {
            name: 'Start date',
            selector: 'start_date',
            sortable: false,
            minWidth: "160px",
        },
        {
            name: 'End date',
            selector: 'end_date',
            sortable: false,
            minWidth: "160px",
        },
        {
            name: 'Client ID',
            selector: 'client_id',
            sortable: false,
            minWidth: '120px',
            maxWidth: '120px',
            cell: row => <>
                {row.client_id}
            </>
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            minWidth: '180px',
            cell: row => <div className='e-name-link' onClick={() => openDetail(row.customer_id)}>{row.name}</div>,
        },
        {
            name: 'Phone',
            selector: row => row.mobile,
            sortable: false,
            right: false,
            minWidth: "160px",
        },
        {
            name: 'Total no. of games',
            selector: 'no_of_games',
            sortable: false,
            minWidth: "160px",
        },
        {
            name: 'Win Qty',
            selector: 'win_qty',
            sortable: false,
            minWidth: "160px",
        },
        {
            name: 'Total Qty',
            selector: 'total_qty',
            sortable: false,
            minWidth: "160px",
        },
        {
            name: 'Points',
            selector: 'points',
            sortable: false,
            minWidth: "160px",
        },
        {
            name: 'Required target points',
            selector: 'required_target_points',
            sortable: true,
            minWidth: '220px'
        },
        {
            name: 'Winning status',
            selector: 'win_status',
            sortable: false,
            cell: row => <>{
                row.is_winner ?
                    "Yes"
                    :
                    "No"
            }
            </>
        },
        {
            name: 'Reward',
            selector: 'rewards',
            sortable: true,
            minWidth: "160px",
            cell: row => <>₹{row.rewards}</>
        },

        {
            name: 'Paid status',
            selector: 'is_paid',
            sortable: false,
            minWidth: "160px",
            cell: row => <>{
                row.is_paid ?
                    "Yes"
                    :
                    "No"
            }
            </>
        },
        {
            name: 'TDS %',
            selector: 'tds_percentage',
            sortable: false,
            minWidth: "160px",
        },
        {
            name: 'TDS',
            selector: 'tds',
            sortable: false,
            minWidth: "160px",
        },
        {
            name: 'Net revenue',
            selector: 'net_revenue',
            sortable: false,
            minWidth: "160px",
        },
        {
            name: 'Paid date',
            selector: 'paid_date',
            sortable: false,
            minWidth: "160px",
        },
    ]

    //down referral data 
    function convertArrayOfObjectsToCSVRef(data) {
        setDownloadLoader(true)
        // Empty array for storing the values
        var csvRows = [];

        // Headers is basically a keys of an
        // object which is id, name, and
        // profession
        const titles = Object.keys(data[0])

        var headers = [titles]
        // As for making csv format, headers
        // must be separated by comma and
        // pushing it into array
        csvRows.push(headers.join(','));

        // Pushing Object values into array
        // with comma separation

        for (let i = 0; i < data.length; i++) {
            var values = [Object.values(data[i])].join(",")
            csvRows.push(values)
        }



        // Returning the array joining with new line
        console.log(csvRows)
        return csvRows.join('\n')
    }

    function downloadCSVRef(data) {
        setDownloadLoader(true)
        // Creating a Blob for having a csv file format
        // and passing the data with type
        const blob = new Blob([data], { type: 'text/csv' });

        // Creating an object for downloading url,
        const url = window.URL.createObjectURL(blob)

        // Creating an anchor(a) tag of HTML
        const a = document.createElement('a')

        // Passing the blob downloading url
        a.setAttribute('href', url)

        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute('bookings', 'bookings.csv');

        // Performing a download with click
        a.click()
    }

    const _handleChangeRef = (row) => {
        let selected = []
        for (let i = 0; i < row.selectedRows.length; i++) {
            selected.push(row.selectedRows[i]);
        }
        setCheck(selected);
        setDownloadLoader(false)
        // setCheck(row.selectedRows);
    }

    return (
        <>
            <div className='col-lg-3'>
                <h3 className='e-header-title'>
                    {
                        Object.values(data).length > 0 ?
                            <>
                                <Link to="/milestones"><img src={Arrow} alt="" className="me-3" onClick={() => setData({})} /></Link> Edit milestone
                            </>
                            :
                            "Milestones"
                    }</h3>
            </div>
            <div className='e-users-container'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={Object.values(data).length > 0 ? null : "card e-dashboard-card e-milestone-wrap border-0 pe-0"}>
                            <div className="row">
                                {
                                    Object.values(data).length > 0 ?
                                        null
                                        :
                                        <div className="col-lg-12">
                                            <GameTabs data={['M2E', 'M2M', 'Skill race', 'Milestone report']} index={tabIndex} setTab={(temp) => setTabindex(temp)} />
                                        </div>
                                }
                                {
                                    tabIndex === 1 ?
                                        <>
                                            {
                                                loader ?
                                                    <Loader />
                                                    :
                                                    <>
                                                        <M2E title="M2E" id={tabIndex} list={list} mileStoneData={(data) => setData(data)} data={data} update={() => { _mileStones(); setData({}); }} />
                                                        {
                                                            Object.values(data).length > 0 ?
                                                                null
                                                                :
                                                                completedMilestones.length > 0 ?
                                                                    <div className='row mt-3'>
                                                                        <div className="col-lg-12 ps-4 pt-2">
                                                                            <h6 className="e-card-title">Completed milestones</h6>
                                                                        </div>
                                                                        <Datatable
                                                                            data={completedMilestones}
                                                                            columns={columns}
                                                                            pagination={true} />
                                                                    </div>
                                                                    :
                                                                    null

                                                        }
                                                    </>
                                            }

                                        </>
                                        : tabIndex === 2 ?
                                            <>
                                                {/* <Scrips /> */}
                                                {
                                                    loader ?
                                                        <Loader />
                                                        :
                                                        <>
                                                            <M2E title="M2M" id={tabIndex} list={list} mileStoneData={(data) => setData(data)} data={data} update={() => { _mileStones(); setData({}); }} />
                                                            {
                                                                Object.values(data).length > 0 ?
                                                                    null
                                                                    :
                                                                    completedMilestones.length > 0 ?
                                                                        <div className='row mt-3'>
                                                                            <div className="col-lg-12 ps-4 pt-2">
                                                                                <h6 className="e-card-title">Completed milestones</h6>
                                                                            </div>
                                                                            <Datatable
                                                                                data={completedMilestones}
                                                                                columns={columns}
                                                                                pagination={true} />
                                                                        </div>
                                                                        :
                                                                        null
                                                            }
                                                        </>
                                                }
                                            </>

                                            :
                                            tabIndex === 3 ?
                                                <>
                                                    {/* <BlockedUsers/> */}
                                                    {
                                                        loader ?
                                                            <Loader />
                                                            :
                                                            <>
                                                                <M2E title="Skill race" id={tabIndex} list={list} mileStoneData={(data) => setData(data)} data={data} update={() => { _mileStones(); setData({}); }} />

                                                                {
                                                                    Object.values(data).length > 0 ?
                                                                        null
                                                                        :
                                                                        completedMilestones.length > 0 ?
                                                                            <div className='row mt-3'>
                                                                                <div className="col-lg-12 ps-4 pt-2">
                                                                                    <h6 className="e-card-title">Completed milestones</h6>
                                                                                </div>
                                                                                <Datatable
                                                                                    data={completedMilestones}
                                                                                    columns={columns}
                                                                                    pagination={true} />
                                                                            </div>
                                                                            :
                                                                            null
                                                                }

                                                            </>
                                                    }
                                                </>
                                                :
                                                <div className='row px-0 mt-2'>
                                                    <div className='col-lg-6 ps-4'>
                                                        <h6 className='pt-3'>
                                                            Milestone report
                                                        </h6>
                                                    </div>
                                                    <div className='col-lg-2 text-end'>
                                                        <DownloadBtn
                                                            check={check.length > 0 ? check : ["check"]}
                                                            name={check.length > 0 ? "Download report" : "Download All"}
                                                            onClick={() => {
                                                                if (check.length > 0) {
                                                                    const csvdata = convertArrayOfObjectsToCSVRef(check);
                                                                    downloadCSVRef(csvdata)
                                                                } else {
                                                                    const csvdata = convertArrayOfObjectsToCSVRef(milestoneReport);
                                                                    downloadCSVRef(csvdata)
                                                                }
                                                            }} />
                                                    </div>
                                                    <div className="col-lg-3 position-relative col-5">
                                                        <SearchInput
                                                            placeholder="User name/client id"
                                                            value={search}
                                                            onSearch={(value) => {
                                                                _mileStonesReport({ query: value })
                                                            }}
                                                            valueChange={(value) => {
                                                                setSearch(value)
                                                            }}
                                                        />
                                                        {
                                                            search && search.length ?
                                                                <>
                                                                    <span className={`${styles.e_search_submit} `} onClick={() => _mileStonesReport()} ></span>
                                                                    <button
                                                                        className={`${styles.e_search_close} pe-3`}
                                                                        onClick={() => {
                                                                            setSearch("");
                                                                            // _getUsersList({ query: "" })
                                                                        }} >

                                                                    </button>
                                                                </>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                    <div className="col-lg-1 col-1">
                                                        <FilterBtn
                                                            className={
                                                                filterDown ?
                                                                    "e-filter-btn e-active" :
                                                                    "e-filter-btn"
                                                            }
                                                            handleClick={() => {
                                                                setFilterDown(!filterDown)
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        filterDown ?
                                                            <>
                                                                <div className='col-lg-12 px-0'>
                                                                    <div className="e-filter-wraper mt-3 mx-0">
                                                                        <div className="row">
                                                                            <div className='col-lg-2'>
                                                                                <label className='mb-2'>Game type</label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={GAME_OPTIONS}
                                                                                    value={gameType}
                                                                                    selectchange={(temp) => { setGameType(temp); }}
                                                                                    isSearchable={false}
                                                                                    placeholder="Game type"
                                                                                />
                                                                            </div>
                                                                            <div className='col-lg-2'>
                                                                                <label className='mb-2'>Winning  status</label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={WINNING_STATUS_OPTIONS}
                                                                                    value={status}
                                                                                    selectchange={(temp) => { setStatus(temp); }}
                                                                                    isSearchable={false}
                                                                                    placeholder="Winning  status"
                                                                                />

                                                                            </div>
                                                                            <div className='col-lg-2'>
                                                                                <label className='mb-2'>Paid  status</label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={PAID_OPTIONS}
                                                                                    value={paid}
                                                                                    selectchange={(temp) => { setPaid(temp); }}
                                                                                    isSearchable={false}
                                                                                    placeholder="Paid  status"
                                                                                />

                                                                            </div>
                                                                            <div className='col-lg-2'>
                                                                                <label className='mb-2'>Active status</label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={STATUS_OPTIONS}
                                                                                    value={activeStatus}
                                                                                    selectchange={(temp) => { setActiveStatus(temp); }}
                                                                                    isSearchable={false}
                                                                                    placeholder="Active"
                                                                                />

                                                                            </div>
                                                                            <div className='col-lg-2'>
                                                                                <label className='mb-2'>Miletsones</label>
                                                                                <SelectBox
                                                                                    className="me-2 e-bookings-sort"
                                                                                    options={milestoneOptions}
                                                                                    value={mileStoneId}
                                                                                    selectchange={(temp) => { setMilestoneId(temp); }}
                                                                                    isSearchable={false}
                                                                                    placeholder="Milestone"
                                                                                />

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    <div className="col-lg-12 e-booking-table pt-3 e-pagination">
                                                        {
                                                            loader ?
                                                                <Loader />
                                                                :
                                                                milestoneReport.length > 0 ?
                                                                    <>
                                                                        <DataTable
                                                                            pagination={true}
                                                                            columns={reportColumns}
                                                                            data={milestoneReport}
                                                                            selectableRows={true}
                                                                            // paginationTotalRows={total}
                                                                            paginationComponentOptions={paginationData}
                                                                            selectRows={(row) => {
                                                                                _handleChangeRef(row,);
                                                                            }}
                                                                            onChangeRowsPerPage={(p) => {
                                                                                setPage(1)
                                                                                setPerPage(p)
                                                                            }}
                                                                            size={perPage}
                                                                            // paginationIconNext={totalLength == page ? <img src={Next} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Next} alt="" width="26px" onClick={() => setPage(page + 1)} style={{ cursor: "pointer" }} />}
                                                                            // paginationIconPrevious={page === 1 ? <img src={Prev} alt="" width="26px" style={{ opacity: "0.3" }} /> : <img src={Prev} alt="" width="26px" style={{ cursor: "pointer" }} onClick={() => { setPage(page - 1) }} />}
                                                                            clearSelectedRows={downloadLoader}
                                                                        />
                                                                        <div className='col-lg-12 text-end pe-3'>
                                                                            <p>Page no : <span style={{ fontWeight: "600" }}>{page}</span></p>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <EmptyNote data="No data found" img={NoData} />
                                                        }
                                                    </div>

                                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index;
