/*
 *   File : empty.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Empty Note
 *   Integrations :
 *   Version : v1.0
 *   Created : 29-10-2021
 */

/*Import Packages*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/emptyNote.module.scss';

export default function EmptyNote(props) {
  return (
    <div className="container align-items-center justify-content-center p-5">
      <div className='p-5'>
        <div className={`${styles.e_empty_note} `}>
          <img src={props.img} className={`${styles.e_empty_img}`} alt="" />
        </div>
        <div className={`${styles.e_empty_data} ps-5 ms-4`}>{props.data}</div>
      </div>
    </div>
  )
}
