/*
 *   File : tax-settings.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : tax settings container;
 *   Integrations :
 *   Version : v1.0
 *   Created : 12-04-2022
 */
/*import packages*/
import { useState, useEffect } from 'react';

/*import components */
import { Input } from '../../components/input/index';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';
import { Toast } from '../../components/note';

/* import services */
import service from '../../services/service';
import Loader from '../../components/loader/loader';

const TaxSettings = (props) => {
    const [priceAmount, setPriceAmount] = useState(0);
    const [tds, setTds] = useState(0);
    const [cess, setCess] = useState(0);
    const [tdss, setTdss] = useState(null);
    const [commission, setCommission] = useState(0);
    const [platformFees, setPlatformFees] = useState(null);
    const [cgst, setCgst] = useState(null);
    const [sgst, setSgst] = useState(null);
    const [igst, setIgst] = useState(null);
    const [cgstOther, setCgstOther] = useState(null);
    const [sgstOther, setSgstOther] = useState(null);
    const [igstOther, setIgstOther] = useState(null);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [tdsCommission, setTdsCommission] = useState(0);
    //eslint-disable-next-line
    const [taxData, setTaxData] = useState({});
    const [success, setSuccess] = useState(null);
    const [edit, setEdit] = useState(false);
    const [sedit, setSEdit] = useState(false);
    const [oedit, setOEdit] = useState(false);

    // window.scrollTo({ top: 0, behavior: 'smooth' });

    useEffect(() => {
        taxSettings(true);
        // window.scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (error !== null) {
                setError(null)
            }
        }, 3000);
    }, [error])

    useEffect(() => {
        document.getElementById("pamount").disabled = !edit;
        document.getElementById("ptds").disabled = !edit;
        document.getElementById("pcess").disabled = !edit;
        document.getElementById("camount").disabled = !edit;
        document.getElementById("ctds").disabled = !edit;
        document.getElementById("famount").disabled = !edit;
        document.getElementById("ftds").disabled = !edit;
    }, [edit])

    useEffect(() => {
        document.getElementById("icgst").disabled = !sedit;
        document.getElementById("isgst").disabled = !sedit;
        document.getElementById("iigst").disabled = !sedit;
    }, [sedit]);

    useEffect(() => {
        document.getElementById("ocgst").disabled = !oedit;
        document.getElementById("osgst").disabled = !oedit;
        document.getElementById("oigst").disabled = !oedit;
    }, [oedit])

    useEffect(() => {
        setTimeout(() => {
            if (success !== null) {
                setSuccess(null)
            }
        }, 3000);
    }, [success])

    /*----- get tax ----*/
    const taxSettings = (status) => {
        setLoader(status)
        let url = '/settings/get-tds';

        service(false, url).then((response) => {
            if (response.data.status_code === 200) {
                setLoader(false);
                setTaxData(response.data.data);
                setPriceAmount(response.data.data.reward.amount);
                setTds(response.data.data.reward.tds);
                setCess(response.data.data.reward.cess);
                setCommission(response.data.data.commission.amount);
                setTdsCommission(response.data.data.commission.tds);
                setPlatformFees(response.data.data.platform_fee.amount);
                setTdss(response.data.data.platform_fee.tds)
                setCgst(response.data.data.normal_gst.cgst);
                setSgst(response.data.data.normal_gst.sgst);
                setIgst(response.data.data.normal_gst.igst);
                setCgstOther(response.data.data.custom_gst.cgst);
                setIgstOther(response.data.data.custom_gst.igst);
                setSgstOther(response.data.data.custom_gst.sgst);
            }
            else {
                setLoader(false);
            }
        });
    }

    /*set tds */
    const taxTds = () => {
        let url = "/settings/set-tds";
        let data = {

            "reward": {
                "amount": parseFloat(priceAmount) ? parseFloat(priceAmount) : 0,
                "tds": parseFloat(tds) ? parseFloat(tds) : 0,
                "cess": parseFloat(cess) ? parseFloat(cess) : 0

            },
            "commission": {
                "amount": Number(commission),
                "tds": parseFloat(tdsCommission) ? parseFloat(tdsCommission) : 0
            },
            "platform_fee": {
                "amount": Number(platformFees),
                "tds": parseFloat(tdss) ? parseFloat(tdss) : 0,
            }
        }
        // console.log(data);
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setSuccess("Successfully saved")
                // message.success("Successfully calculated TDS");
                // setPriceAmount(response.data.reward.amount);
                // setTds(response.data.reward.tds);
                // setCess(response.data.reward.cess);
                // setTdsCommission(response.data.commission.amount);
                // setCommission(response.data.commission.tds);
                taxSettings(false);
                setEdit(false);
            } else {
                setError(response.data.message);
            }
        })
    }


    /*gst for TN and other states */

    const gstforStates = (type) => {
        let url = "/settings/set-gst";

        if (type === "OTHER") {
            var data = {
                "type": type,
                "cgst": Number(cgstOther),
                "sgst": Number(sgstOther),
                "igst": Number(igstOther),

            }
        } else {
            //eslint-disable-next-line
            var data = {
                "type": type,
                "cgst": Number(cgst),
                "sgst": Number(sgst),
                "igst": Number(igst),

            }
        }
        service(true, url, data).then((response) => {
            if (response.data.status_code === 200) {
                setSuccess("Successfully calculated GST")
                // message.success("Successfully calculated GST");
                // setCgst(null);
                // setSgst(null);
                // setIgst(null);
                // setCgstOther(null);
                // setSgstOther(null);
                // setIgstOther(null);
                if (type === "OTHER") {
                    setOEdit(false);
                } else {
                    setSEdit(false)
                }
                taxSettings(false);
            } else {
                setError(response.data.message);
            }
        })
    }

    /*Clear states */
    const cancelStates = () => {
        taxSettings(false)
    }

    const editFields = () => {
        setEdit(true)
    }
    const editStateTax = (type) => {
        if (type === "OTHER") {
            setOEdit(true)
        } else {
            setSEdit(true)
        }
    }

    return (


        <>
            {
                loader ?
                    <Loader />
                    :
                    <div className='e-tax-container p-3'>
                        <div className="row ">
                            <div className='col-lg-12'>
                                <h6>TDS calculations</h6>
                            </div>
                        </div>
                        <div className='row pt-1'>
                            <div className='col-lg-4'>
                                <label className="mb-1">Price amount & rewards above</label>
                                <Input
                                    placeholder="Price amount"
                                    suffix="₹"
                                    value={parseFloat(priceAmount)}
                                    id="pamount"
                                    type="number"
                                    disabled={true}
                                    valueChange={(temp) => {
                                        parseFloat(setPriceAmount(temp))
                                    }}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <label className="mb-1">TDS %</label>
                                <Input
                                    placeholder="TDS"
                                    type="number"
                                    suffix="%"
                                    id="ptds"
                                    disabled={true}
                                    value={parseFloat(tds)}
                                    valueChange={(temp) => {
                                        parseFloat(setTds(temp))
                                    }}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <label className="mb-1">CESS tax %</label>
                                <Input
                                    placeholder="CESS"
                                    type="number"
                                    disabled={true}
                                    id="pcess"
                                    suffix="%"
                                    value={parseFloat(cess)}
                                    valueChange={(temp) => {
                                        parseFloat(setCess(temp))
                                    }}
                                />
                            </div>
                        </div>
                        <div className='row pt-2'>
                            <div className='col-lg-4'>
                                <label className="mb-1">Commission</label>
                                <Input
                                    placeholder="Commission"
                                    type="number"
                                    suffix="₹"
                                    id="camount"
                                    disabled={true}
                                    value={parseInt(commission)}
                                    valueChange={(temp) => {
                                        setCommission(temp)
                                    }}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <label className="mb-1">TDS %</label>
                                <Input
                                    placeholder="TDS"
                                    type="number"
                                    id="ctds"
                                    suffix="%"
                                    disabled={true}
                                    value={parseFloat(tdsCommission)}
                                    valueChange={(temp) => {
                                        parseFloat(setTdsCommission(temp))
                                    }}
                                />
                            </div>

                        </div>
                        <div className='row pt-2'>
                            <div className='col-lg-4'>
                                <label className="mb-1">Platform fees</label>
                                <Input
                                    placeholder="Platform fees"
                                    suffix="₹"
                                    type="number"
                                    disabled={true}
                                    id="famount"
                                    value={platformFees}
                                    valueChange={(temp) => {
                                        setPlatformFees(temp)
                                    }}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <label className="mb-1">TDS %</label>
                                <Input
                                    placeholder="TDS"
                                    type="number"
                                    suffix="%"
                                    id="ftds"
                                    disabled={true}
                                    value={parseFloat(tdss)}
                                    valueChange={(temp) => {
                                        parseFloat(setTdss(temp))
                                    }}
                                />
                            </div>

                        </div>

                        <div className='row pt-2'>
                            <div className='col-lg-1'>
                                <SecondaryBtn
                                    name="Cancel"
                                    className="e-cancel-tax"
                                    handleClick={() => { cancelStates(); setEdit(false) }}

                                />
                            </div>
                            <div className='col-lg-1 ms-3'>
                                {
                                    (priceAmount ||
                                        commission ||
                                        cess ||
                                        tds ||
                                        tdsCommission ||
                                        platformFees ||
                                        tdss) &&
                                        !edit ?
                                        <PrimaryBtn
                                            name="Edit"
                                            handleClick={() => { editFields() }}

                                        /> :
                                        priceAmount &&
                                            commission >= 0 &&
                                            cess >= 0 &&
                                            tds >= 0 &&
                                            tdsCommission &&
                                            platformFees >= 0 &&
                                            tdss >= 0 ?
                                            <PrimaryBtn
                                                name="Save"
                                                className=""
                                                handleClick={() => taxTds()}
                                            />
                                            :
                                            <PrimaryBtn
                                                name="Save"
                                                className="e-disabled"
                                            />
                                }
                            </div>
                        </div>

                        <div className="row pt-4">
                            <div className='col-lg-12'>
                                <h6>Invoice GST calculations for Tamil Nadu</h6>
                            </div>
                        </div>
                        <div className='row pt-1'>
                            <div className='col-lg-4'>
                                <label className="mb-1">CGST%</label>
                                <Input
                                    placeholder="CGST"
                                    type="number"
                                    suffix="%"
                                    id="icgst"
                                    disabled={true}
                                    value={parseFloat(cgst)}
                                    valueChange={(temp) => {
                                        parseFloat(setCgst(temp))
                                    }}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <label className="mb-1">SGST</label>
                                <Input
                                    placeholder="SGST"
                                    type="number"
                                    suffix="%"
                                    id="isgst"
                                    disabled={true}
                                    value={parseFloat(sgst)}
                                    valueChange={(temp) => {
                                        parseFloat(setSgst(temp))
                                    }}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <label className="mb-1">IGST</label>
                                <Input
                                    placeholder="IGST"
                                    type="number"
                                    suffix="%"
                                    id="iigst"
                                    disabled={true}
                                    value={parseFloat(igst)}
                                    valueChange={(temp) => {
                                        parseFloat(setIgst(temp))
                                    }}
                                />
                            </div>
                        </div>

                        <div className='row pt-2'>
                            <div className='col-lg-1'>
                                <SecondaryBtn
                                    name="Cancel"
                                    className="e-cancel-tax"
                                    handleClick={() => { cancelStates(); setSEdit(false) }}

                                />
                            </div>
                            <div className='col-lg-1 ms-3'>
                                {
                                    (cgst ||
                                        sgst ||
                                        igst) && !sedit
                                        ?
                                        <PrimaryBtn
                                            name="Edit"
                                            handleClick={() => editStateTax()}

                                        /> :
                                        cgst >= 0 &&
                                            sgst >= 0 &&
                                            igst >= 0 ?
                                            <PrimaryBtn
                                                name="Save"
                                                className=""
                                                handleClick={() => gstforStates("OWN_STATE")}
                                            />
                                            :
                                            <PrimaryBtn
                                                name="Save"
                                                className="e-disabled"
                                            />
                                }
                            </div>
                        </div>

                        <div className="row pt-4">
                            <div className='col-lg-12'>
                                <h6>Invoice GST calculations for other states</h6>
                            </div>
                        </div>
                        <div className='row pt-1'>
                            <div className='col-lg-4'>
                                <label className="mb-1">CGST%</label>
                                <Input
                                    placeholder="CGST"
                                    type="number"
                                    suffix="%"
                                    id="ocgst"
                                    disabled={true}
                                    value={parseFloat(cgstOther)}
                                    valueChange={(temp) => {
                                        parseFloat(setCgstOther(temp))
                                    }}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <label className="mb-1">SGST</label>
                                <Input
                                    placeholder="SGST"
                                    type="number"
                                    suffix="%"
                                    id="osgst"
                                    disabled={true}
                                    value={parseFloat(sgstOther)}
                                    valueChange={(temp) => {
                                        parseFloat(setSgstOther(temp))
                                    }}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <label className="mb-1">IGST</label>
                                <Input
                                    placeholder="IGST"
                                    type="number"
                                    suffix="%"
                                    id="oigst"
                                    disabled={true}
                                    value={parseFloat(igstOther)}
                                    valueChange={(temp) => {
                                        parseFloat(setIgstOther(temp))
                                    }}
                                />
                            </div>
                        </div>

                        <div className='row pt-2'>
                            <div className='col-lg-1'>
                                <SecondaryBtn
                                    name="Cancel"
                                    className="e-cancel-tax"
                                    handleClick={() => { cancelStates(); setOEdit(false) }}

                                />
                            </div>
                            <div className='col-lg-1 ms-3'>
                                {
                                    (cgstOther ||
                                        sgstOther ||
                                        igstOther) &&
                                        !oedit ?
                                        <PrimaryBtn
                                            name="Edit"
                                            handleClick={() => editStateTax("OTHER")}
                                        /> :
                                        cgstOther >= 0 &&
                                            sgstOther >= 0 &&
                                            igstOther >= 0 ?
                                            <PrimaryBtn
                                                name="Save"
                                                className=""
                                                handleClick={() => gstforStates("OTHER")}
                                            />
                                            :
                                            <PrimaryBtn
                                                name="Save"
                                                className="e-disabled"
                                            />
                                }
                            </div>
                        </div>
                        {
                            error !== null ?
                                <Toast
                                    data={error}
                                    type="fail"
                                    format={1}
                                />
                                :
                                null
                        }

                        {
                            success !== null ?
                                <Toast
                                    data={success}
                                    type="success"
                                    format={1}
                                />
                                :
                                null
                        }

                    </div>

            }

        </>




    )
}

export default TaxSettings;
