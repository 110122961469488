/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : index for manage contents;
 *   Integrations :
 *   Version : v1.0
 *   Created : 17-02-2022
 */
/*import packages*/
import { useState } from 'react';

/*import components*/
import { GameTabs } from '../../components/dataFormating/index';

/*import container*/
import Faq from './faq.js';
import Privacy from './privacy.js';
import Terms from './terms.js';
import Disclaimer from './disclaimer.js';
import Policy from './policy';

const AllScrips = (props) => {

  window.scrollTo({ top: 0, behavior: 'smooth' });
  const [tabIndex, setTabindex] = useState(1);


  return (
    <>
      <div className='e-tab-position'>
        <GameTabs data={[
          'FAQ',
          'Privacy',
          'Terms',
          'Disclaimer',
          'Refund policy'
        ]}
          index={tabIndex}
          setTab={(temp) => setTabindex(temp)}
          type={true} />
      </div>
      <div className='e-users-container pt-3'>
        <div className="row">
          <div className="col-lg-12">
            <div className="card e-dashboard-card e-milestone-wrap e-add-milestone-wrap border-0">
              {
                tabIndex === 1 ?
                  <Faq />

                  : tabIndex === 2 ?
                    <Privacy />

                    : tabIndex === 3 ?
                      <Terms />
                      : tabIndex === 4 ?
                        <Disclaimer />
                        :
                        <Policy />
              }


            </div>
          </div>
        </div>
      </div>

    </>

  )
}

export default AllScrips;
